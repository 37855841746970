define("admin/components/alfrello-react/alfrello-profile-header", ["exports", "admin/compiled/alfrello-profile-header/AlfrelloProfileHeader", "admin/react-component"], function (_exports, _AlfrelloProfileHeader, _reactComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  
  */
  {
    "id": "rLz8Lwka",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/components/alfrello-react/alfrello-profile-header.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _reactComponent.default.extend({
    store: Ember.inject.service(),
    utils: Ember.inject.service(),
    apiManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    renderComponent: function renderComponent() {
      var _this = this;
      this.reactRender(React.createElement(_AlfrelloProfileHeader.AlfrelloProfileHeader, {
        name: this.application.get('profile.name'),
        email: this.application.get('profile.email'),
        phonenumber: this.application.get('profile.phonenumber'),
        age: this.application.get('profile.age'),
        image: this.application.get('profile.image'),
        color: this.columns.find(function (c) {
          return c.id === _this.application.columnId;
        }).color,
        intl: this.intl,
        interview: this.application.get('interview.id') ? this.application.get('interview') : null,
        videoInterview: this.application.get('videoInterview.id') ? this.application.get('videoInterview') : null,
        close: this.close,
        showModal: this.showModal.bind(this),
        application: this.application,
        hasPurchasedVi: this.job.videoInterviewPurchased,
        isCopied: this.application.get('copied'),
        jobIsHRB: this.job.isHRB
      }));
    }
  }));
  _exports.default = _default;
});