define("admin/compiled/job-card/job-card-applications-btn", ["exports", "@alfred-is/alfred-design-system", "classnames", "react", "admin/compiled/job-card/job-card"], function (_exports, _alfredDesignSystem, _classnames, _react, _jobCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.JobCardApplicationsBtn = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };
  var JobCardApplicationsBtn = function JobCardApplicationsBtn(_a) {
    var applications = _a.applications,
      disabled = _a.disabled,
      children = _a.children,
      rest = __rest(_a, ["applications", "disabled", "children"]);
    var isHovered = (0, _jobCard.useJobCardContext)().isHovered;
    return _react.default.createElement(_alfredDesignSystem.CtaButton, __assign({
      size: _alfredDesignSystem.SizeT.small,
      disabled: disabled,
      variant: isHovered ? _alfredDesignSystem.CtaButtonVariant.ORANGE : _alfredDesignSystem.CtaButtonVariant.WHITE_GRAY,
      icon: isHovered ? _alfredDesignSystem.AlfredIconT.ArrowRight : undefined,
      iconPosition: _alfredDesignSystem.HorizontalPosition.RIGHT,
      iconSize: _alfredDesignSystem.SizeT.large,
      className: (0, _classnames.default)('tw-rounded-sm', {
        'tw-font-extrabold': isHovered
      })
    }, rest), _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-rounded-sm', 'tw-px-2', 'tw-mr-3', {
        'tw-bg-white tw-text-orange-500': isHovered,
        'tw-bg-gray-200 tw-mr-2 tw-text-gray-500': !isHovered
      })
    }, applications), ' ', children);
  };
  _exports.JobCardApplicationsBtn = JobCardApplicationsBtn;
});