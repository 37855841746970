define("admin/controllers/client/edit-job/skills", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = Ember.Controller.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_obj = {
    gtm: Ember.inject.service(),
    chosenTab: 'chosenSkills',
    switchTab: function switchTab(tab) {
      this.set('chosenTab', tab);
    },
    toggleAddingSkill: function toggleAddingSkill() {
      this.toggleProperty('isAddingSkills');
    },
    toggle: function toggle(skill) {
      if (this.get('model.job.skillIds').includes(skill)) {
        this.get('model.job.skillIds').removeObject(skill);
      } else {
        if (this.get('model.job.generalApplication')) {
          this.gtm.generalApplicationAddSkills(this.get('model.job.brandName'));
        }
        this.get('model.job.skillIds').pushObject(skill);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "switchTab", [_dec], Object.getOwnPropertyDescriptor(_obj, "switchTab"), _obj), _applyDecoratedDescriptor(_obj, "toggleAddingSkill", [_dec2], Object.getOwnPropertyDescriptor(_obj, "toggleAddingSkill"), _obj), _applyDecoratedDescriptor(_obj, "toggle", [_dec3], Object.getOwnPropertyDescriptor(_obj, "toggle"), _obj)), _obj)));
  _exports.default = _default;
});