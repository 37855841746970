define("admin/templates/locked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XX7guV2f",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"tw-text-center tw-p-5 tw-shadow tw-bg-red-500 tw-text-white tw-fixed tw-left-0 tw-top-0 tw-w-full tw-z-20\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"blacklist.message\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"tw-w-full tw-h-full tw-fixed tw-left-0 tw-top-0 tw-z-10 tw-bg-gray-100\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"tw-flex tw-w-full tw-h-full tw-justify-center tw-items-center tw-text-white\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"tw-bg-blue-500 tw-text-white tw-border-white tw-rounded-full tw-p-4 tw-font-bold hover:tw-bg-blue-600 tw-min-w-[120px]\"],[4,[38,1],[\"click\",[32,0,[\"logout\"]]],null],[12],[1,[30,[36,0],[\"menu.sign_out\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"on\"]}",
    "meta": {
      "moduleName": "admin/templates/locked.hbs"
    }
  });
  _exports.default = _default;
});