define("admin/routes/admin/messages/users/edit", ["exports", "admin/routes/admin/_form/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    controllerName: 'admin.messages.users.form',
    renderTemplate: function renderTemplate() {
      this._super('messages.users');
    },
    model: function model(_ref) {
      var user_id = _ref.user_id;
      return this.store.peekRecord('sysmsg/messageuser', user_id);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.model.rollbackAttributes();
      }
    },
    actions: {
      save: function save() {
        var _this = this;
        var application = this.controllerFor('application');
        application.set('loading', true);
        this.controller.get('model').save().then(function () {
          _this.transitionTo('admin.messages.users');
        }).finally(function () {
          application.set('loading', false);
        });
      }
    }
  });
  _exports.default = _default;
});