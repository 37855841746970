define("admin/templates/components/applications/alfrello-profile-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "o1qGC+3s",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"next \",[30,[36,1],[[35,0,[\"next\"]],\"disabled\"],null]]]],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"client.applications.profile.profile\",[34,0,[\"next\"]]]],[[\"default\"],[{\"statements\":[[1,[30,[36,2],[\"general.next\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"links\",\"unless\",\"t\"]}",
    "meta": {
      "moduleName": "admin/templates/components/applications/alfrello-profile-nav.hbs"
    }
  });
  _exports.default = _default;
});