define("admin/compiled/utils/emberContext", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.EmberProvider = void 0;
  _exports.useEmberContext = useEmberContext;
  var EmberContext = _react.default.createContext({});
  var EmberProvider = EmberContext.Provider;
  _exports.EmberProvider = EmberProvider;
  var _default = EmberContext;
  _exports.default = _default;
  function useEmberContext() {
    var emberContext = (0, _react.useContext)(EmberContext);
    return emberContext;
  }
});