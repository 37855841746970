define("admin/components/forms/sortable-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['sortable-list'],
    items: '.sort-item',
    placeholder: 'row sort-placeholder',
    handle: '.sort-handle',
    selectable: false,
    init: function init() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      var self = this;
      this.$().sortable({
        items: self.get('items'),
        placeholder: self.get('placeholder'),
        handle: self.get('handle'),
        delay: 15,
        // cursor: "move",
        update: function update() {
          self.get('update')(self.$().sortable('toArray'));
        }
      });
      if (!this.selectable) {
        this.$().disableSelection();
      }
    }
  });
  _exports.default = _default;
});