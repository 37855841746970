define("admin/components/users/access-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    user: Ember.computed.alias('model.user'),
    brands: Ember.computed.reads('model.brands'),
    firstName: Ember.computed('user.name', function () {
      return this.get('user.name') ? this.get('user.name').split(' ')[0] : '"Notandi"';
    }),
    brandNameList: Ember.computed('user.brandaccess.length', function () {
      var count = this.get('user.brandaccess.length');
      if (count) {
        var string = '';
        var sep = ', ';
        this.get('user.brandaccess').forEach(function (brand, i) {
          if (i + 2 === count) {
            sep = ' og ';
          } else if (i + 1 === count) {
            sep = '';
          }
          string += "<strong>".concat(brand.get('name'), "</strong>").concat(sep);
        });
        return Ember.String.htmlSafe(string);
      }
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.get('brands.length') === 1) {
        Ember.run.next(this, function () {
          this.get('user.brandaccess').pushObject(this.get('brands.firstObject'));
        });
      }
    },
    actions: {
      selectBrand: function selectBrand(brand) {
        if (this.get('user.brandaccess').includes(brand)) {
          this.get('user.brandaccess').removeObject(brand);
        } else {
          this.get('user.brandaccess').pushObject(brand);
        }
      }
    }
  });
  _exports.default = _default;
});