define("admin/templates/components/ui/labeled-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "yGdoAh9H",
    "block": "{\"symbols\":[],\"statements\":[[10,\"label\"],[12],[1,[34,0]],[13],[2,\"\\n\"],[8,\"input\",[[16,\"placeholder\",[34,4]]],[[\"@maxlength\",\"@id\",\"@value\",\"@enter\",\"@autocomplete\"],[[34,1],[34,2],[34,3],[30,[36,5],[[32,0],\"enterAction\"],null],\"off\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"label\",\"maxLength\",\"inputId\",\"value\",\"placeholder\",\"action\"]}",
    "meta": {
      "moduleName": "admin/templates/components/ui/labeled-input.hbs"
    }
  });
  _exports.default = _default;
});