define("admin/components/video-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['video-js', 'vjs-fluid'],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
    },
    actions: {}
  });
  _exports.default = _default;
});