define("admin/models/settings/invoice", ["exports", "@ember-data/model", "ember-api-actions"], function (_exports, _model, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    month: (0, _model.attr)('string'),
    invoiceno: (0, _model.attr)('string'),
    amount: (0, _model.attr)('number'),
    issued: (0, _model.attr)('string'),
    due: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    type: (0, _model.attr)('string'),
    url: (0, _model.attr)('string'),
    ssn: (0, _model.attr)('string'),
    paid: (0, _emberApiActions.memberAction)({
      path: 'paid',
      type: 'get'
    }),
    email: (0, _emberApiActions.memberAction)({
      path: 'email',
      type: 'post'
    })
  });
  _exports.default = _default;
});