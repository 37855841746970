define("admin/components/brand/form-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="brand-form-section">
    <div class="brand-form-section--top">
      <div class="brand-form-section--title">
        {{@title}}
        {{#if @tooltip}}
          <div class="tooltipstered">
            <EmberTooltip @side="top">
              {{{@tooltip}}}
            </EmberTooltip>
          </div>
        {{/if}}
      </div>
      {{#if (and @action @actionText)}}
        <div class="brand-form-section--action" {{on "click" @action}}>
          <span class="brand-form-section--action-icon"></span>
          {{@actionText}}
        </div>
      {{/if}}
    </div>
    <div class="brand-form-section--content">
      {{yield}}
    </div>
  </div>
  
  */
  {
    "id": "cso4k+h2",
    "block": "{\"symbols\":[\"@action\",\"@actionText\",\"@tooltip\",\"@title\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"brand-form-section\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"brand-form-section--top\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"brand-form-section--title\"],[12],[2,\"\\n      \"],[1,[32,4]],[2,\"\\n\"],[6,[37,1],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"tooltipstered\"],[12],[2,\"\\n          \"],[8,\"ember-tooltip\",[],[[\"@side\"],[\"top\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[2,[32,3]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[6,[37,1],[[30,[36,2],[[32,1],[32,2]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"div\"],[24,0,\"brand-form-section--action\"],[4,[38,0],[\"click\",[32,1]],null],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"brand-form-section--action-icon\"],[12],[13],[2,\"\\n        \"],[1,[32,2]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"brand-form-section--content\"],[12],[2,\"\\n    \"],[18,5,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"if\",\"and\"]}",
    "meta": {
      "moduleName": "admin/components/brand/form-section.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});