define("admin/compiled/spotlight/spotlight-purchase/spotlight-purchase", ["exports", "@alfred-is/alfred-design-system", "classnames", "react"], function (_exports, _alfredDesignSystem, _classnames, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SpotlightPurchaseTop = _exports.SpotlightPurchaseContainer = _exports.SpotlightPurchaseBottom = void 0;
  var SpotlightPurchaseTop = function SpotlightPurchaseTop(_a) {
    var title = _a.title,
      description = _a.description,
      scheduleDays = _a.scheduleDays,
      faqUrl = _a.faqUrl,
      children = _a.children;
    var t = (0, _alfredDesignSystem.useNxTranslation)().t;
    return _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-p-9', 'tw-flex', 'tw-flex-col', 'md:tw-flex-row', 'tw-items-stretch')
    }, _react.default.createElement("div", {
      className: 'tw-flex-1'
    }, _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-text-md', 'tw-font-bold', 'tw-uppercase')
    }, title), _react.default.createElement("div", {
      className: 'tw-mt-1'
    }, description), _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-flex', 'tw-mt-5', 'tw-gap-3', 'tw-flex-col', 'sm:tw-flex-row', 'sm:tw-gap-6')
    }, _react.default.createElement("div", null, _react.default.createElement(_alfredDesignSystem.TextWithIcon, {
      icon: _alfredDesignSystem.AlfredIconT.Eye,
      color: _alfredDesignSystem.ColorT.black,
      size: _alfredDesignSystem.SizeT.large
    }, "".concat(t('spotlight.display_time_label_prefix'), " ").concat(scheduleDays, " ").concat(t('spotlight.display_time_label_postfix')))), faqUrl && _react.default.createElement("div", null, _react.default.createElement(_alfredDesignSystem.TextWithCircularIcon, {
      size: _alfredDesignSystem.SizeT.small,
      icon: _alfredDesignSystem.AlfredIconT.Info,
      bgColor: _alfredDesignSystem.ColorT.blue
    }, _react.default.createElement("a", {
      href: faqUrl,
      target: '_blank',
      className: "tw-text-blue-500 hover:tw-text-blue-600",
      rel: "noreferrer"
    }, t('spotlight.detail_info')))))), _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-mt-5', 'md:tw-mt-0', 'md:tw-ml-9', 'tw-flex-1')
    }, children));
  };
  _exports.SpotlightPurchaseTop = SpotlightPurchaseTop;
  var SpotlightPurchaseBottom = function SpotlightPurchaseBottom(_a) {
    var children = _a.children;
    return _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-p-9', 'tw-flex', 'tw-gap-4', 'tw-flex-col', 'md:tw-flex-row', 'tw-border-t', 'tw-border-gray-200')
    }, children);
  };
  _exports.SpotlightPurchaseBottom = SpotlightPurchaseBottom;
  var SpotlightPurchaseContainer = function SpotlightPurchaseContainer(_a) {
    var children = _a.children;
    return _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-bg-white', 'tw-border', 'tw-border-gray-200', 'tw-rounded-sm')
    }, children);
  };
  _exports.SpotlightPurchaseContainer = SpotlightPurchaseContainer;
});