define("admin/templates/components/jobs/demo-text-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "X5KsvyhE",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@overlayClass\"],[[30,[36,0],[[32,0],\"close\"],null],\"modal-popup\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"default-dialog\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[1,[30,[36,1],[\"string.demo\"],null]],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"edit-form demo\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"editor-max-wrap\"],[12],[2,\"\\n        \"],[8,\"tinymce-editor\",[],[[\"@options\",\"@value\",\"@onValueChanged\"],[[34,2],[34,3],[30,[36,0],[[32,0],[30,[36,4],[[35,3]],null]],null]]],null],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn gray-brd inline\"],[4,[38,0],[[32,0],\"close\"],null],[12],[1,[30,[36,1],[\"string.cancel\"],null]],[13],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn blue inline\"],[4,[38,0],[[32,0],\"useDemo\"],null],[12],[1,[30,[36,1],[\"string.use_demo\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"options\",\"demotext\",\"mut\"]}",
    "meta": {
      "moduleName": "admin/templates/components/jobs/demo-text-modal.hbs"
    }
  });
  _exports.default = _default;
});