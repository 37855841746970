define("admin/compiled/stats-page/StatsPage", ["exports", "react", "admin/compiled/stats-container/StatsContainer", "admin/compiled/stats-filter/StatsFilter", "admin/compiled/stats-graph/StatsGraph", "admin/compiled/utils", "admin/compiled/utils/StatsUtils", "admin/compiled/utils/emberContext"], function (_exports, _react, _StatsContainer, _StatsFilter, _StatsGraph, _utils, _StatsUtils, _emberContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.StatsPage = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var __awaiter = void 0 && (void 0).__awaiter || function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P ? value : new P(function (resolve) {
        resolve(value);
      });
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }
      function rejected(value) {
        try {
          step(generator["throw"](value));
        } catch (e) {
          reject(e);
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };
  var __generator = void 0 && (void 0).__generator || function (thisArg, body) {
    var _ = {
        label: 0,
        sent: function sent() {
          if (t[0] & 1) throw t[1];
          return t[1];
        },
        trys: [],
        ops: []
      },
      f,
      y,
      t,
      g;
    return g = {
      next: verb(0),
      "throw": verb(1),
      "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
      return this;
    }), g;
    function verb(n) {
      return function (v) {
        return step([n, v]);
      };
    }
    function step(op) {
      if (f) throw new TypeError("Generator is already executing.");
      while (g && (g = 0, op[0] && (_ = 0)), _) try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];
        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;
          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };
          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;
          case 7:
            op = _.ops.pop();
            _.trys.pop();
            continue;
          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }
            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }
            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }
            if (t && _.label < t[2]) {
              _.label = t[2];
              _.ops.push(op);
              break;
            }
            if (t[2]) _.ops.pop();
            _.trys.pop();
            continue;
        }
        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
      if (op[0] & 5) throw op[1];
      return {
        value: op[0] ? op[1] : void 0,
        done: true
      };
    }
  };
  var StatsPage = function StatsPage(_a) {
    var brands = _a.brands;
    var _b = (0, _react.useContext)(_emberContext.default),
      intl = _b.intl,
      apiClient = _b.apiClient,
      moment = _b.moment;
    var _c = (0, _react.useState)('YEAR'),
      range = _c[0],
      setRange = _c[1];
    var _d = (0, _react.useState)(),
      brand = _d[0],
      _setBrand = _d[1];
    var _e = (0, _react.useState)(false),
      isLoading = _e[0],
      setIsLoading = _e[1];
    var _f = (0, _react.useState)([]),
      jobs = _f[0],
      setJobs = _f[1];
    var _g = (0, _react.useState)([]),
      applicationsStats = _g[0],
      setApplicationsStats = _g[1];
    var _h = (0, _react.useState)([]),
      categories = _h[0],
      setCategories = _h[1];
    var statsUtils = new _StatsUtils.default(jobs, categories, applicationsStats);
    var getRangeInfo = function getRangeInfo(range) {
      if (range === 'MONTH') {
        return {
          from: moment().subtract(1, 'month'),
          to: moment(),
          granularity: 'days'
        };
      }
      if (range === 'YEAR') {
        return {
          from: moment().subtract(1, 'year'),
          to: moment(),
          granularity: 'months'
        };
      }
      if (Math.abs(range.from.diff(range.to, 'months')) > 3) {
        return __assign(__assign({}, range), {
          granularity: 'months'
        });
      }
      return __assign(__assign({}, range), {
        granularity: 'days'
      });
    };
    var rangeInfo = getRangeInfo(range);
    console.log(rangeInfo);
    var inclusiveRangeInfo = __assign(__assign({}, rangeInfo), {
      to: rangeInfo.to.clone().add(1, 'day')
    });
    (0, _react.useEffect)(function () {
      setIsLoading(true);
      var fetchData = function fetchData() {
        return __awaiter(void 0, void 0, void 0, function () {
          var resJobs, resApplications;
          return __generator(this, function (_a) {
            switch (_a.label) {
              case 0:
                return [4 /*yield*/, apiClient.get("/jobs?from=".concat(inclusiveRangeInfo.from.format('YYYY-MM-DD'), "&to=").concat(inclusiveRangeInfo.to.format('YYYY-MM-DD')).concat(brand ? '&brandids=' + brand.val.id : ''))];
              case 1:
                resJobs = _a.sent();
                return [4 /*yield*/, apiClient.get("/statistics/applications?from=".concat(inclusiveRangeInfo.from.format('YYYY-MM-DD'), "&to=").concat(inclusiveRangeInfo.to.format('YYYY-MM-DD')).concat(brand ? '&brandids=' + brand.val.id : ''))];
              case 2:
                resApplications = _a.sent();
                setIsLoading(false);
                setApplicationsStats(resApplications);
                setCategories(resJobs === null || resJobs === void 0 ? void 0 : resJobs.categories);
                setJobs(resJobs === null || resJobs === void 0 ? void 0 : resJobs.jobs);
                return [2 /*return*/];
            }
          });
        });
      };

      fetchData();
    }, [range, brand]);
    var getChartTitle = function getChartTitle(chart) {
      var ret = "".concat(chart, " ").concat(intl.t('statistics.chart_for'));
      if (!brand) {
        ret += " ".concat(intl.t('statistics.chart_all_brands'));
      } else {
        ret += " ".concat(brand.name);
      }
      ret += " ".concat(intl.t('statistics.chart_between'), " ").concat(rangeInfo.from.format('DD. MMM YYYY'), " ").concat(intl.t('statistics.chart_and'), " ").concat(rangeInfo.to.format('DD. MMM YYYY'));
      return ret;
    };
    var downloadAdsForRange = function downloadAdsForRange() {
      return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              setIsLoading(true);
              return [4 /*yield*/, (0, _utils.downloadFile)('data:application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "".concat(intl.t('statistics.file_ads'), "-").concat(inclusiveRangeInfo.from.format('YYYY-MM-DD'), "-").concat(inclusiveRangeInfo.to.format('YYYY-MM-DD'), ".xlsx"), function () {
                return apiClient.get("/jobs/exportrange?from=".concat(inclusiveRangeInfo.from.format('YYYY-MM-DD'), "&to=").concat(inclusiveRangeInfo.to.format('YYYY-MM-DD')).concat(brand ? '&brandids=' + brand.val.id : ''));
              })];
            case 1:
              _a.sent();
              setIsLoading(false);
              return [2 /*return*/];
          }
        });
      });
    };

    var downloadApplicationsForRange = function downloadApplicationsForRange() {
      return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              setIsLoading(true);
              return [4 /*yield*/, (0, _utils.downloadFile)('data:application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "".concat(intl.t('statistics.file_applications'), "-").concat(inclusiveRangeInfo.from.format('YYYY-MM-DD'), "-").concat(inclusiveRangeInfo.to.format('YYYY-MM-DD'), ".xlsx"), function () {
                return apiClient.get("/statistics/applications/excelexport?from=".concat(inclusiveRangeInfo.from.format('YYYY-MM-DD'), "&to=").concat(inclusiveRangeInfo.to.format('YYYY-MM-DD')).concat(brand ? '&brandids=' + brand.val.id : ''));
              })];
            case 1:
              _a.sent();
              setIsLoading(false);
              return [2 /*return*/];
          }
        });
      });
    };

    return _react.default.createElement("div", {
      id: 'stats-page'
    }, _react.default.createElement(_StatsFilter.StatsFilter, {
      brands: brands,
      brand: brand,
      range: range,
      rangeInfo: rangeInfo,
      setBrand: function setBrand(brand) {
        if (brand) {
          _setBrand({
            name: brand.name,
            val: brand
          });
        } else {
          _setBrand(undefined);
        }
      },
      setRange: setRange,
      isLoading: isLoading
    }), _react.default.createElement("div", {
      className: 'container'
    }, _react.default.createElement("div", {
      className: 'stats-page--stats-cards'
    }, _react.default.createElement(_StatsContainer.StatsContainer, {
      stats: [{
        stat: jobs.length,
        title: intl.t('statistics.ads')
      }, {
        stat: statsUtils.applicationsCount(),
        title: intl.t('statistics.applications')
      }, {
        stat: categories.length,
        tooltipItems: categories.map(function (c) {
          return "".concat(c.name, " (").concat(c.count, " ").concat(intl.t('statistics.ads_count'), ")");
        }),
        title: intl.t('statistics.categories')
      }]
    }), _react.default.createElement(_StatsContainer.StatsContainer, {
      stats: [{
        stat: statsUtils.averageApplications(),
        title: intl.t('statistics.average_applications')
      }, {
        stat: statsUtils.averageAdLength(),
        title: intl.t('statistics.average_ad_length')
      }]
    })), _react.default.createElement(_StatsGraph.StatsGraph, {
      data: statsUtils.adsForPeriod(rangeInfo.granularity, rangeInfo.from.clone(), rangeInfo.to.clone()),
      onDownload: downloadAdsForRange,
      title: getChartTitle(intl.t('statistics.ads'))
    }), _react.default.createElement(_StatsGraph.StatsGraph, {
      data: statsUtils.applicationsForPeriod(rangeInfo.granularity, rangeInfo.from.clone(), rangeInfo.to.clone()),
      onDownload: downloadApplicationsForRange,
      title: getChartTitle(intl.t('statistics.applications'))
    })));
  };
  _exports.StatsPage = StatsPage;
});