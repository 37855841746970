define("admin/components/question-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      Ember.run.scheduleOnce('afterRender', this, function () {
        if (!_this.model.id) {
          var options = Ember.A();
          for (var i = 0; i < 2; i++) {
            options.pushObject(_this._newQuestion());
          }
          _this.set('model.options', options);
        }
      });
    },
    classNames: ['question-options'],
    store: Ember.inject.service(),
    _newQuestion: function _newQuestion() {
      return this.store.createRecord('questionoption', {
        active: true,
        option: "",
        questionid: this.model.id
      });
    },
    actions: {
      addOption: function addOption() {
        this.get('model.options').pushObject(this._newQuestion());
      },
      removeOption: function removeOption(option) {
        this.get('model.options').removeObject(option);
      }
    }
  });
  _exports.default = _default;
});