define("admin/components/skills/manage-skills", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    apiAdmin: Ember.inject.service(),
    classNames: ['manage-skills'],
    save: function save(skill) {
      Ember.set(skill, 'loadingIcon', true);
      this.apiAdmin.patch("/skills/".concat(skill.id), {
        skill: skill
      }).then(function (res) {
        // set(skill, 'icon', res.skill ? res.skill.icon : null);
        Ember.set(skill, 'loadingIcon', false);
      });
    },
    actions: {
      saveSkill: function saveSkill(skill) {
        this.save(skill);
      },
      deleteSkill: function deleteSkill(skill) {
        var _this = this;
        this.apiAdmin.delete("/skills/".concat(skill.id)).then(function () {
          return _this.refreshData();
        });
      },
      saveIcon: function saveIcon(skill, base64) {
        Ember.set(skill, 'base64Icon', base64);
        this.save(skill);
      }
    }
  });
  _exports.default = _default;
});