define("admin/initializers/accounting", ["exports", "accounting/settings", "admin/config/environment"], function (_exports, _settings, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  var _config$REGION = _environment.default.REGION,
    symbol = _config$REGION.symbol,
    decimal = _config$REGION.decimal,
    thousand = _config$REGION.thousand;
  function initialize() {
    _settings.currency.symbol = symbol;
    _settings.number.decimal = decimal;
    _settings.number.thousand = thousand;
  }
  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});