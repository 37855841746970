define("admin/compiled/modal/GeneralModal", ["exports", "react", "react-dom", "jquery", "react-redux", "admin/compiled/utils/useOutsideClick", "admin/compiled/redux/actions/modal", "classnames"], function (_exports, _react, _reactDom, _jquery, _reactRedux, _useOutsideClick, _modal, _classnames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var GeneralModal = function GeneralModal(_a) {
    var children = _a.children,
      dispatch = _a.dispatch,
      wider = _a.wider,
      preventOutsideClick = _a.preventOutsideClick,
      overflowInitial = _a.overflowInitial;
    var ref = (0, _react.useRef)(null);
    (0, _useOutsideClick.default)(ref, function () {
      if (!preventOutsideClick) {
        dispatch((0, _modal.hideModal)());
      }
    }, true);
    (0, _react.useEffect)(function () {
      var $body = (0, _jquery.default)('body');
      $body.addClass('with-modal');
      return function () {
        $body.removeClass('with-modal');
      };
    });
    var modalRoot = document.getElementById('modal-root');
    return _reactDom.default.createPortal(_react.default.createElement("div", {
      className: 'general-modal--overlay'
    }, _react.default.createElement("div", {
      ref: ref,
      className: (0, _classnames.default)('general-modal--container', {
        wider: wider,
        noOverflow: overflowInitial
      })
    }, children)), modalRoot);
  };
  var _default = (0, _reactRedux.connect)()(GeneralModal);
  _exports.default = _default;
});