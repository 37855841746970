define("admin/routes/client/applications/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function model(_ref) {
      var application_id = _ref.application_id;
      var _this$modelFor = this.modelFor('client.applications'),
        job = _this$modelFor.job;
      return Ember.RSVP.hash({
        application: this.store.findRecord('jobapplication', application_id),
        columns: this.store.query('column', {}),
        job: job
      });
    },
    renderTemplate: function renderTemplate() {
      this.render('client.applications.profile');
    },
    actions: {
      closeModal: function closeModal() {
        var _this$paramsFor = this.paramsFor('client.applications'),
          job_id = _this$paramsFor.job_id;
        var _this$paramsFor2 = this.paramsFor('client.applications.profile'),
          application_id = _this$paramsFor2.application_id;
        this.store.unloadAll('vianswer');
        if (this.get('currentSession.rememberPrevious') === 'videointerviews') {
          this.transitionTo('client.applications.videointerviews.detail', job_id, application_id);
        } else {
          this.transitionTo('client.applications.index', job_id);
        }
      }
    }
  });
  _exports.default = _default;
});