define("admin/templates/client/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "JocgJu/K",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"screen-loader\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"center\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/img/alfred-icon.png\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/templates/client/loading.hbs"
    }
  });
  _exports.default = _default;
});