define("admin/controllers/admin/messages/users/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      didSelectFiles: function didSelectFiles(model, files) {
        if (files.length) {
          var reader = new FileReader();
          reader.readAsDataURL(files[0]);
          reader.addEventListener('load', function () {
            model.set('image', reader.result);
          }, false);
        }
      }
    }
  });
  _exports.default = _default;
});