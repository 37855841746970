define("admin/helpers/page-title", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.pageTitle = pageTitle;
  function pageTitle(title) {
    (0, _jquery.default)('head').find('title').text(title);
  }
  var _default = Ember.Helper.helper(pageTitle);
  _exports.default = _default;
});