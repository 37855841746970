define("admin/components/compass/compass-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="item"
    id={{if @item.id @item.id @item._id}}>
    <div class="col-a">
      <span class="num">{{inc @item.weight}}.</span>
      <Compass::EditableText @value={{@item.name}}
        @edit={{@edit}}
        placeholder={{t "general.type_here"}} />
    </div>
    <div class="col-b">
      <Compass::EditableTextarea @value={{@item.description}}
        @edit={{@edit}}
        placeholder={{t "general.type_here"}} />
    </div>
    <div class="col-c">
      <div>
        <Compass::EditableText @value={{@item.value}}
          @edit={{@edit}}
          type="number" />
      </div>
      <div class="perc">
        %
      </div>
    </div>
    {{#if @edit}}
      <div class="col-action">
        <div class="sort-handle"></div>
        <button class="remove"
          tabindex="-1"
          {{on "click" @onRemove}}></button>
      </div>
    {{/if}}
  </div>
  
  */
  {
    "id": "2hjJNORq",
    "block": "{\"symbols\":[\"@onRemove\",\"@item\",\"@edit\"],\"statements\":[[10,\"div\"],[14,0,\"item\"],[15,1,[30,[36,1],[[32,2,[\"id\"]],[32,2,[\"id\"]],[32,2,[\"_id\"]]],null]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-a\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"num\"],[12],[1,[30,[36,2],[[32,2,[\"weight\"]]],null]],[2,\".\"],[13],[2,\"\\n    \"],[8,\"compass/editable-text\",[[16,\"placeholder\",[30,[36,3],[\"general.type_here\"],null]]],[[\"@value\",\"@edit\"],[[32,2,[\"name\"]],[32,3]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-b\"],[12],[2,\"\\n    \"],[8,\"compass/editable-textarea\",[[16,\"placeholder\",[30,[36,3],[\"general.type_here\"],null]]],[[\"@value\",\"@edit\"],[[32,2,[\"description\"]],[32,3]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-c\"],[12],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[8,\"compass/editable-text\",[[24,4,\"number\"]],[[\"@value\",\"@edit\"],[[32,2,[\"value\"]],[32,3]]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"perc\"],[12],[2,\"\\n      %\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,1],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"col-action\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"sort-handle\"],[12],[13],[2,\"\\n      \"],[11,\"button\"],[24,0,\"remove\"],[24,\"tabindex\",\"-1\"],[4,[38,0],[\"click\",[32,1]],null],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"if\",\"inc\",\"t\"]}",
    "meta": {
      "moduleName": "admin/components/compass/compass-item.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});