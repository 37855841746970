define("admin/compiled/spotlight/spotlight-header/spotlight-header", ["exports", "@alfred-is/alfred-design-system", "classnames", "react"], function (_exports, _alfredDesignSystem, _classnames, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SpotlightHeader = void 0;
  var SpotlightHeader = function SpotlightHeader(_a) {
    var onClose = _a.onClose,
      _b = _a.title,
      title = _b === void 0 ? 'Sviðsljósið' : _b,
      _c = _a.region,
      region = _c === void 0 ? _alfredDesignSystem.RegionT.IS : _c,
      logoOnClick = _a.logoOnClick;
    return _react.default.createElement(_alfredDesignSystem.HeaderBase2, {
      variant: _alfredDesignSystem.Theme.Orange
    }, _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-container tw-mx-auto')
    }, _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-flex tw-justify-between tw-relative')
    }, _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-flex-grow-0 tw-z-10')
    }), _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-text-center tw-text-md tw-font-700 tw-text-white tw-uppercase tw-hidden md:tw-flex tw-items-center tw-absolute tw-w-full tw-h-full tw-justify-center tw-z-0')
    }, title || 'Námskeið'), _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-flex tw-items-center tw-z-10')
    }, _react.default.createElement(_alfredDesignSystem.CircularIcon, {
      size: _alfredDesignSystem.SizeT.large,
      iconColor: _alfredDesignSystem.ColorT.white,
      bgColor: _alfredDesignSystem.ColorT.orange600,
      iconSize: _alfredDesignSystem.SizeT.medium,
      buttonProps: {
        onClick: function onClick() {
          return onClose();
        }
      },
      icon: _alfredDesignSystem.AlfredIconT.Exit
    })))));
  };
  _exports.SpotlightHeader = SpotlightHeader;
  var _default = SpotlightHeader;
  _exports.default = _default;
});