define("admin/components/jobs/job-item-menu", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var links = _environment.default.REGION.links;
  var _default = Ember.Component.extend({
    tagName: 'ul',
    isPhantom: Ember.computed.alias('currentSession.user.phantom'),
    classNames: ['job-item-menu'],
    classNameBindings: ['dialogShown:hide-menu'],
    actions: {
      stats: function stats() {
        this.showStats();
        this.toggle();
      },
      openWeb: function openWeb() {
        window.open("".concat(links.job).concat(this.job.slug ? this.job.slug : this.job.id), '_blank');
        this.toggle();
      },
      blacklist: function blacklist() {
        this.toggleBlacklist();
        this.toggle();
      },
      upsell: function upsell() {
        this.toggleUpsell();
        this.toggle();
      },
      delete: function _delete() {
        this.deleteJob();
        this.toggle();
      },
      closeJob: function closeJob() {
        this.toggleCloseJob();
        this.toggle();
      },
      clone: function clone() {
        this.clone();
        this.toggle();
      }
    }
  });
  _exports.default = _default;
});