define("admin/templates/components/crad/nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "f1EtjJ6W",
    "block": "{\"symbols\":[\"item\",\"index\"],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"filteredNavItems\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"crad/nav-item\",[],[[\"@route\",\"@complete\"],[[32,1,[\"route\"]],[32,1,[\"valid\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,1,[\"required\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"required\"],[12],[2,\"*\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[1,[30,[36,1],[[32,2]],null]],[2,\".\\n    \"],[1,[32,1,[\"name\"]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"inc\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/components/crad/nav.hbs"
    }
  });
  _exports.default = _default;
});