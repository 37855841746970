define("admin/compiled/alfrello-profile-invite/AlfrelloProfileInvite", ["exports", "react", "admin/compiled/ui/ContextMenu"], function (_exports, _react, _ContextMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AlfrelloProfileInviteSent = _exports.AlfrelloProfileInvite = void 0;
  var AlfrelloProfileInvite = function AlfrelloProfileInvite(_a) {
    var icon = _a.icon,
      onClick = _a.onClick,
      children = _a.children;
    return _react.default.createElement("div", {
      className: 'alfrello-profile-invite',
      onClick: onClick
    }, icon && _react.default.createElement("span", {
      className: "alfrello-profile-invite--icon ".concat(icon)
    }), children);
  };
  _exports.AlfrelloProfileInvite = AlfrelloProfileInvite;
  var AlfrelloProfileInviteSent = function AlfrelloProfileInviteSent(_a) {
    var title = _a.title,
      meta = _a.meta,
      isGreen = _a.isGreen,
      contextMenuItems = _a.contextMenuItems,
      children = _a.children;
    return _react.default.createElement("div", {
      className: 'alfrello-profile-invite--space-container'
    }, _react.default.createElement("div", {
      className: 'alfrello-profile-invite--container'
    }, _react.default.createElement("div", {
      className: "alfrello-profile-invite alfrello-profile-invite--sent ".concat(isGreen ? 'alfrello-profile-invite--green' : '', " ")
    }, _react.default.createElement("div", {
      className: 'alfrello-profile-invite--content'
    }, _react.default.createElement("div", {
      className: 'alfrello-profile-invite--title'
    }, title), _react.default.createElement("div", {
      className: 'alfrello-profile-invite--meta'
    }, meta)), children), contextMenuItems && _react.default.createElement(_ContextMenu.ContextMenu, {
      white: isGreen,
      items: contextMenuItems
    })));
  };
  _exports.AlfrelloProfileInviteSent = AlfrelloProfileInviteSent;
});