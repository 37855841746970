define("admin/templates/components/applications/ad-status-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "LIv5i+3P",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"quick-dialog\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"close\"],[4,[38,0],[[32,0],\"close\"],null],[12],[13],[2,\"\\n\"],[6,[37,3],[[35,2,[\"isActive\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"h2\"],[12],[1,[30,[36,1],[\"components.applications.ad_status.modal_title\"],null]],[13],[2,\"\\n\\n    \"],[10,\"p\"],[12],[1,[30,[36,1],[\"components.applications.ad_status.modal_p\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"h2\"],[12],[1,[30,[36,1],[\"components.applications.ad_status.start_review\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,1],[\"components.applications.ad_status.start_review_p\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"buttons\"],[12],[2,\"\\n\"],[6,[37,3],[[35,2,[\"isActive\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[11,\"button\"],[24,0,\"btn gray-brd red-hover\"],[4,[38,0],[[32,0],\"markAsCompleted\"],null],[12],[1,[30,[36,1],[\"components.applications.ad_status.mark_done\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[11,\"button\"],[24,0,\"btn gray-brd blue-hover\"],[4,[38,0],[[32,0],\"markAsCompleted\"],null],[12],[1,[30,[36,1],[\"components.applications.ad_status.start_review_action\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"job\",\"unless\"]}",
    "meta": {
      "moduleName": "admin/templates/components/applications/ad-status-modal.hbs"
    }
  });
  _exports.default = _default;
});