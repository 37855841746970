define("admin/serializers/jobapplication", ["exports", "admin/serializers/rest-serializer"], function (_exports, _restSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _restSerializer.default.extend({
    utils: Ember.inject.service(),
    attrs: {
      answers: {
        serialize: false,
        deserialize: 'records'
      },
      attachments: {
        serialize: false,
        deserialize: 'records'
      },
      notes: {
        serialize: false,
        deserialize: 'records'
      },
      profile: {
        serialize: false,
        deserialize: 'records'
      },
      interview: {
        serialize: false,
        deserialize: 'records'
      },
      videoInterview: {
        serialize: false,
        deserialize: 'records'
      },
      brand: {
        serialize: false,
        deserialize: 'records'
      }
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'jobapplication';
    }
  });
  _exports.default = _default;
});