define("admin/templates/components/background-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "eiPPWuwY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@overlayClass\"],[[30,[36,0],[[32,0],\"close\"],null],[34,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"default-dialog\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"top\"],[15,5,[31,[\"background-image: url('\",[34,2],\"');\"]]],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"title\"],[12],[2,[34,3]],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"gradient-layer\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"items\"],[12],[2,\"\\n      \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"className\",\"bg\",\"title\"]}",
    "meta": {
      "moduleName": "admin/templates/components/background-modal.hbs"
    }
  });
  _exports.default = _default;
});