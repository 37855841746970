define("admin/adapters/me", ["exports", "admin/adapters/rest-app"], function (_exports, _restApp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _restApp.default.extend({
    pathForType: function pathForType() {
      return 'users/me';
    }
  });
  _exports.default = _default;
});