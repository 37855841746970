define("admin/components/jobtags-react/jobtags", ["exports", "react", "admin/compiled/jobtags/JobTags", "admin/react-component"], function (_exports, _react, _JobTags, _reactComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{outlet}}
  
  */
  {
    "id": "QBtzQ7iX",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "admin/components/jobtags-react/jobtags.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _reactComponent.default.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    apiAdmin: Ember.inject.service(),
    store: Ember.inject.service(),
    renderComponent: function renderComponent() {
      this.reactRender(_react.default.createElement(_JobTags.Jobtags, null));
    }
  }));
  _exports.default = _default;
});