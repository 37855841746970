define("admin/compiled/jobs-list-filter/JobsListFilter", ["exports", "react", "admin/compiled/ui/filter-bar/FilterBar", "admin/compiled/ui/filter-container/FilterContainer", "admin/compiled/ui/filter-dropdown/FilterDropdown", "admin/compiled/ui/filter-separator/FilterSeparator", "admin/compiled/ui/search-bar/SearchBar", "admin/compiled/utils/emberContext"], function (_exports, _react, _FilterBar, _FilterContainer, _FilterDropdown, _FilterSeparator, _SearchBar, _emberContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.JobsListFilter = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var JobsListFilter = function JobsListFilter(_a) {
    var filter = _a.filter,
      updateFilter = _a.updateFilter,
      statuses = _a.statuses,
      users = _a.users,
      brands = _a.brands,
      gaCount = _a.gaCount;
    var _b = (0, _react.useContext)(_emberContext.default),
      intl = _b.intl,
      gtm = _b.gtm,
      features = _b.features,
      currentSession = _b.currentSession;
    var selectedStatus = statuses.find(function (s) {
      return s.id === (filter === null || filter === void 0 ? void 0 : filter.status);
    });
    var selectedUser = users.find(function (u) {
      return u.id === (filter === null || filter === void 0 ? void 0 : filter.user);
    });
    var selectedBrand = brands.find(function (b) {
      return b.id === (filter === null || filter === void 0 ? void 0 : filter.brand);
    });
    var selectedStatusFilterItem = selectedStatus ? {
      name: selectedStatus.description,
      val: selectedStatus.name
    } : undefined;
    var selectedUserFilterItem = selectedUser ? {
      name: selectedUser.name,
      val: selectedUser.id
    } : undefined;
    var selectedBrandFilterItem = selectedBrand ? {
      name: selectedBrand.name,
      val: selectedBrand.id
    } : undefined;
    var updateFilterValue = function updateFilterValue(filterName, item) {
      var _a;
      updateFilter(__assign(__assign({}, filter), (_a = {}, _a[filterName] = item === null || item === void 0 ? void 0 : item.val, _a)));
    };
    var allowViewGeneralApplication = features && features.job.generalApplications;
    return _react.default.createElement("div", {
      className: 'jobs-list-filter'
    }, _react.default.createElement(_FilterBar.FilterBar, null, _react.default.createElement("div", {
      className: 'jobs-list-filter--content'
    }, _react.default.createElement("div", {
      className: 'jobs-list-filter--left'
    }, _react.default.createElement(_SearchBar.SearchBar, {
      placeholder: intl.t('general.search'),
      search: filter === null || filter === void 0 ? void 0 : filter.search,
      setSearch: function setSearch(search) {
        return updateFilter(__assign(__assign({}, filter), {
          search: search
        }));
      }
    })), _react.default.createElement("div", {
      className: 'jobs-list-filter--right'
    }, allowViewGeneralApplication && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_FilterContainer.FilterContainer, {
      icon: 'eye',
      onClick: function onClick() {
        if (!(filter === null || filter === void 0 ? void 0 : filter.showGeneralApplications)) {
          gtm.initiateGeneralApplication();
        }
        updateFilter(__assign(__assign({}, filter), {
          showGeneralApplications: !(filter === null || filter === void 0 ? void 0 : filter.showGeneralApplications)
        }));
      },
      isActive: filter === null || filter === void 0 ? void 0 : filter.showGeneralApplications
    }, intl.t('job-item.general_applications'), ' ', !!gaCount && "(".concat(gaCount, ")")), _react.default.createElement(_FilterSeparator.FilterSeparator, null)), _react.default.createElement(_FilterDropdown.FilterDropdown, {
      placeholder: intl.t('string.all_ads'),
      items: statuses.map(function (status) {
        return {
          name: status.description,
          val: status.id
        };
      }),
      selected: selectedStatusFilterItem,
      onSelect: function onSelect(item) {
        return updateFilterValue('status', item);
      }
    }), _react.default.createElement(_FilterDropdown.FilterDropdown, {
      placeholder: intl.t('string.all_users'),
      items: users.map(function (user) {
        return {
          name: user.name,
          val: user.id
        };
      }),
      selected: selectedUserFilterItem,
      onSelect: function onSelect(item) {
        return updateFilterValue('user', item);
      }
    }), _react.default.createElement(_FilterDropdown.FilterDropdown, {
      placeholder: intl.t('string.all_brands'),
      items: brands.map(function (brand) {
        return {
          name: brand.name,
          val: brand.id
        };
      }),
      selected: selectedBrandFilterItem,
      onSelect: function onSelect(item) {
        return updateFilterValue('brand', item);
      }
    })))));
  };
  _exports.JobsListFilter = JobsListFilter;
});