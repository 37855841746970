define("admin/components/jobs-list-react/jobs-list", ["exports", "admin/config/environment", "moment", "react", "admin/compiled/jobs-list/JobsList", "admin/compiled/utils/emberContext", "admin/react-component"], function (_exports, _environment, _moment, _react, _JobsList, _emberContext, _reactComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  
  */
  {
    "id": "xQ5V/kvg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/components/jobs-list-react/jobs-list.hbs"
    }
  });
  var features = _environment.default.REGION.features;
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _reactComponent.default.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    apiClient: Ember.inject.service(),
    store: Ember.inject.service(),
    gtm: Ember.inject.service(),
    renderComponent: function renderComponent() {
      var _this = this;
      this.reactRender(_react.default.createElement(_emberContext.EmberProvider, {
        value: {
          intl: this.intl,
          gtm: this.gtm,
          config: _environment.default,
          currentSession: this.currentSession,
          moment: _moment.default,
          getHiringBoard: function getHiringBoard() {
            return _this.apiClient.get('/jobs/hired');
          },
          getStats: function getStats(jobId) {
            return _this.apiClient.get('/jobs/' + jobId + '/stats');
          },
          dismissAnnouncement: function dismissAnnouncement(id) {
            return _this.apiClient.patch("/announcements/".concat(id, "/dismiss"), {
              jobid: null
            });
          },
          getAnnouncements: function getAnnouncements() {
            return _this.apiClient.get('/announcements?placement=JOB_LIST');
          },
          removeJob: this.removeJob.bind(this),
          openModal: this.openModal.bind(this),
          cloneJob: this.duplicateJob.bind(this),
          isCloningJob: this.isDuplicatingJob,
          transitionTo: function transitionTo(route, model, opts) {
            // for some reason ember wants to explicitly omit opts object, even though it's undefined.
            if (!opts) {
              if (model) {
                _this.router.transitionTo(route, model);
              } else {
                _this.router.transitionTo(route);
              }
            } else {
              if (model) {
                _this.router.transitionTo(route, model, opts);
              } else {
                _this.router.transitionTo(route, opts);
              }
            }
          },
          filter: this.filter,
          setFilter: this.setFilter.bind(this),
          showGeneralApplications: this.showGeneralApplications,
          features: features
        }
      }, _react.default.createElement(_JobsList.JobsList, {
        skeletonLoading: this.skeletonLoading,
        jobs: this.jobs,
        brands: this.brands,
        jobStatuses: this.jobStatuses,
        users: this.users,
        onDismiss: this.onDismiss
      })));
    }
  }));
  _exports.default = _default;
});