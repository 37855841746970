define("admin/models/answer", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    answer: (0, _model.attr)('string'),
    date: (0, _model.attr)('moment'),
    optionId: (0, _model.attr)('string'),
    questionId: (0, _model.belongsTo)('question')
  });
  _exports.default = _default;
});