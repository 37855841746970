define("admin/components/forms/select-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['input-field'],
    classNameBindings: ['isValid:valid:'],
    isValid: Ember.computed.notEmpty('value'),
    actions: {}
  });
  _exports.default = _default;
});