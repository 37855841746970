define("admin/routes/client/applications/profile/interview", ["exports", "admin/mixins/model-loader"], function (_exports, _modelLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_modelLoader.default, {
    model: function model() {
      var _this$paramsFor = this.paramsFor('client.applications.profile'),
        application_id = _this$paramsFor.application_id;
      return Ember.RSVP.hash({
        application: this.store.peekRecord('jobapplication', application_id)
      });
    }
  });
  _exports.default = _default;
});