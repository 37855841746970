define("admin/components/jobs/step-three", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    allTags: Ember.computed('categories', function () {
      var allTags = [];
      this.categories.forEach(function (category) {
        allTags = allTags.concat(category.get('sortedTags').toArray());
      });
      return allTags;
    }),
    searchTags: Ember.computed('search', function () {
      var _this = this;
      return this.allTags.filter(function (tag) {
        return tag.get('name').flatten().search(_this.search.flatten()) >= 0;
      });
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('selectedCategory', this.get('categories.firstObject'));
    },
    actions: {
      clearSearch: function clearSearch() {
        this.set('search');
      },
      removeTag: function removeTag(jobtag) {
        this.get('job.tags').removeObject(jobtag);
      }
    }
  });
  _exports.default = _default;
});