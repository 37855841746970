define("admin/routes/admin/customers/imports", ["exports", "admin/mixins/model-loader"], function (_exports, _modelLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_modelLoader.default, {
    showFullscreenLoader: true,
    infinity: Ember.inject.service(),
    queryParams: {
      status: {
        refreshModel: true
      },
      locale: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      },
      filter: {
        refreshModel: true
      },
      district: {
        refreshModel: true
      },
      refresh: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var status = _ref.status,
        locale = _ref.locale,
        search = _ref.search,
        filter = _ref.filter,
        district = _ref.district;
      return this.infinity.model('imports/brand', {
        perPage: district ? 8000 : 80,
        startingPage: 0,
        status: status,
        locale: locale,
        perPageParam: 'size',
        search: search,
        filter: filter,
        district: district
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('refresh');
      if (!controller.get('areas.length')) {
        this.store.findAll('imports/area').then(function (response) {
          var districts = [];
          response.map(function (area) {
            var options = [];
            area.subAreas.map(function (sub) {
              return options.pushObject(sub.name);
            });
            districts.pushObject({
              groupName: area.name,
              options: options
            });
          });
          controller.set('areas', districts);
        });
      }
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});