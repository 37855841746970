define("admin/components/jobs/promo-modal", ["exports", "admin/components/edit-modal"], function (_exports, _editModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _editModal.default.extend({
    didRender: function didRender() {
      if (!localStorage.getItem("user:promoread")) {
        localStorage.setItem("user:promoread", true);
      }
    }
  });
  _exports.default = _default;
});