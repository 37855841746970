define("admin/templates/client/spotlight/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "u23b/ZlD",
    "block": "{\"symbols\":[],\"statements\":[[8,\"spotlight-react/spotlight-react\",[],[[],[]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/templates/client/spotlight/index.hbs"
    }
  });
  _exports.default = _default;
});