define("admin/templates/client/create-job/kjarni", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+PMpq3vE",
    "block": "{\"symbols\":[\"job\"],\"statements\":[[8,\"protect-route\",[],[[],[]],null],[2,\"\\n\"],[8,\"crad/crad-top-bar\",[],[[\"@title\"],[[30,[36,0],[\"crad.new_job.title\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"a\"],[15,6,[30,[36,1],[\"jobs\"],null]],[12],[1,[30,[36,0],[\"crad.close\"],null]],[2,\" \"],[10,\"span\"],[14,0,\"close-btn\"],[12],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"flex-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"tw-text-center tw-my-8\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"tw-font-bold tw-text-md\"],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"model\",\"jobs\",\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"jobs.create-from-kjarni.title\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"jobs.create-from-kjarni.no-jobs\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"sm:tw-px-14\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"model\",\"jobs\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"crad/kjarni/kjarni-item\",[],[[\"@job\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"new-admin\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/client/create-job/kjarni.hbs"
    }
  });
  _exports.default = _default;
});