define("admin/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "poV7kNiJ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"tw-flex sm:tw-flex-row tw-flex-col tw-bg-white tw-border tw-border-gray-300 tw-rounded-3xl tw-px-8 tw-py-6 tw-gap-8 tw-items-center tw-mt-32\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"tw-shrink-0\"],[12],[10,\"img\"],[14,\"src\",\"/img/alfred-icon.png\"],[14,\"alt\",\"\"],[14,0,\"tw-h-24\"],[12],[13],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"tw-flex-1 tw-text-lg sm:tw-text-left tw-text-center\"],[12],[1,[30,[36,0],[\"error.general_message\"],[[\"htmlSafe\"],[true]]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "admin/templates/error.hbs"
    }
  });
  _exports.default = _default;
});