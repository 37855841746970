define("admin/routes/client/users/new", ["exports", "admin/routes/client/_form/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    controllerName: 'client/users/form',
    renderTemplate: function renderTemplate() {
      this._super('users');
    },
    model: function model() {
      return this.store.createRecord('user');
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      Ember.RSVP.hash({
        roles: this.store.query('role', {})
        // brands: this.store.query('brand', {}),
      }).then(function (models) {
        return controller.setProperties(models);
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.user.rollbackAttributes();
      }
    },
    actions: {
      save: function save(user) {
        var _this = this;
        this.controller.set('loading', true);
        user.save().then(function () {
          _this.transitionTo('client.users');
        }).finally(function () {
          _this.controller.set('loading');
        });
      }
    }
  });
  _exports.default = _default;
});