define("admin/routes/client/users/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      if (!this.currentSession.get('user.isManager')) {
        return this.transitionTo('index');
      }
    },
    model: function model() {
      return this.store.query('user', {});
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var usersCtrl = this.controllerFor('client.users');
      usersCtrl.set('title', usersCtrl.get('name'));
      usersCtrl.set('showBtn', true);
    }
  });
  _exports.default = _default;
});