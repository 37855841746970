define("admin/components/formatter", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _config$REGION = _environment.default.REGION,
    symbol = _config$REGION.symbol,
    thousand = _config$REGION.thousand,
    decimal = _config$REGION.decimal,
    precision = _config$REGION.precision,
    format = _config$REGION.format;
  var _default = Ember.Component.extend({
    tagName: 'font',
    symbol: symbol,
    thousand: thousand,
    decimal: decimal,
    precision: precision,
    format: format
  });
  _exports.default = _default;
});