define("admin/templates/client/applications/videointerviews/detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+tMx1fmh",
    "block": "{\"symbols\":[],\"statements\":[[8,\"videointerview\",[],[[\"@vi\",\"@profile\",\"@showHeader\",\"@job\",\"@application\"],[[34,0,[\"application\",\"videoInterview\"]],[34,0,[\"application\",\"profile\"]],true,[34,0,[\"job\"]],[34,0,[\"application\"]]]],null]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "admin/templates/client/applications/videointerviews/detail.hbs"
    }
  });
  _exports.default = _default;
});