define("admin/compiled/ui/Avatar", ["exports", "classnames", "react"], function (_exports, _classnames, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Avatar = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };
  var Avatar = function Avatar(_a) {
    var image = _a.image,
      initials = _a.initials,
      size = _a.size,
      className = _a.className,
      rest = __rest(_a, ["image", "initials", "size", "className"]);
    var dpr = window && window.devicePixelRatio ? window.devicePixelRatio : 1;
    return _react.default.createElement("div", __assign({
      className: (0, _classnames.default)(className, 'tw-rounded-full tw-bg-cover tw-bg-gray-300 tw-font-500', {
        'tw-flex tw-items-center tw-justify-center': !image,
        'tw-h-[40px] tw-w-[40px]': size === 40,
        'tw-h-[50px] tw-w-[50px] tw-text-[20px]': size === 50 || !size,
        'tw-h-[60px] tw-w-[60px] tw-text-lg': size === 60,
        'tw-h-[80px] tw-w-[80px] tw-text-xl': size === 80,
        'tw-h-[120px] tw-w-[120px] tw-text-2xl': size === 120
      }),
      style: {
        backgroundImage: "url(\"".concat(image, "?dpr=").concat(dpr, "&h=").concat(size, "\")")
      }
    }, rest), !image && initials);
  };
  _exports.Avatar = Avatar;
});