define("admin/compiled/stats-graph/StatsGraphButton", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.StatsGraphButton = void 0;
  var StatsGraphButton = function StatsGraphButton(_a) {
    var children = _a.children,
      onClick = _a.onClick,
      disabled = _a.disabled;
    return _react.default.createElement("button", {
      className: "stats-graph-button ".concat(disabled ? 'disabled' : ''),
      onClick: onClick
    }, children);
  };
  _exports.StatsGraphButton = StatsGraphButton;
});