define("admin/components/skills/new-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    apiAdmin: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.category) {
        this.set('category', {});
      }
    },
    actions: {
      save: function save() {
        var _this = this;
        var category = this.category;
        if (this.category.id) {
          this.set('loading', true);
          this.apiAdmin.patch("/skillcategories/".concat(this.category.id), {
            category: category
          }).then(function () {
            return _this.set('newCategory');
          }).finally(function () {
            return _this.set('loading');
          });
        } else {
          this.set('loading', true);
          this.apiAdmin.post("/skillcategories", {
            category: category
          }).then(function (res) {
            _this.set('catId', res.category.id);
            _this.refreshData();
            _this.set('category');
            _this.set('newCategory');
          }).finally(function () {
            return _this.set('loading');
          });
        }
      }
    }
  });
  _exports.default = _default;
});