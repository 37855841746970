define("admin/compiled/alfrello-profile-header/ColumnSwitcher", ["exports", "react", "admin/compiled/utils/useOutsideClick"], function (_exports, _react, _useOutsideClick) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ColumnSwitcher = void 0;
  var ColumnSwitcher = function ColumnSwitcher(_a) {
    var columns = _a.columns,
      currentColumn = _a.currentColumn,
      switchColumn = _a.switchColumn;
    var _b = (0, _react.useState)(false),
      showList = _b[0],
      setShowList = _b[1];
    var ref = (0, _react.useRef)(null);
    (0, _useOutsideClick.default)(ref, function () {
      return setShowList(false);
    });
    return _react.default.createElement("div", {
      className: 'column-switcher',
      ref: ref
    }, _react.default.createElement("div", {
      className: 'column-switcher--selected',
      onClick: function onClick() {
        return setShowList(!showList);
      }
    }, _react.default.createElement("span", {
      className: 'column-switcher--circle',
      style: {
        backgroundColor: currentColumn.color
      }
    }), currentColumn.title), showList && _react.default.createElement("div", {
      className: 'column-switcher--dropdown'
    }, columns.filter(function (c) {
      return c.id !== currentColumn.id;
    }).map(function (c) {
      return _react.default.createElement("div", {
        key: c.id,
        className: 'column-switcher--dropdown-item',
        onClick: function onClick() {
          switchColumn(c);
          setShowList(false);
        }
      }, _react.default.createElement("div", {
        className: 'column-switcher--circle',
        style: {
          backgroundColor: c.color
        }
      }), _react.default.createElement("div", {
        className: 'column-switcher--title'
      }, c.title));
    })));
  };
  _exports.ColumnSwitcher = ColumnSwitcher;
});