define("admin/components/write-capacent-report-modal", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var web = _environment.default.REGION.links.web;
  var _default = Ember.Component.extend({
    apiClient: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      $('body').addClass('with-modal');
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);
      $('body').removeClass('with-modal');
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.applicationClassification && !this.applicationClassification.report && this.currentSession.client.premiumFeatures.indexOf('CONSULTANT') >= 0) {
        this.set('applicationClassification.report', 'Sæl/sæll,\n' + 'Nú hefur [nafn], ráðgjafi hjá Capacent lokið við flokkun umsókna vegna starfsauglýsingu: ' + this.jobTitle + '.\n\n' + 'Alls eru [x-fjöldi]  í VIÐTAL hópi. Viðtalshópurinn samstendur af einstaklingum með xx próf, mikla reynslu af X og Y.  Flestir hafa einnig Z // Flestir geta hafið störf fljótt // Allir tala íslensku // Allir tala ensku.\n\n' + 'Alls eru [x-fjöldi] í KANNSKI hópnum. Umsækjendur í KANNSKI hópnum eiga það sameiginlegt að vera með xx próf og einhverja reynslu af X og Y. Þessir einstaklingar hafa minni reynslu eða minni menntun en í viðtalshópnum // Einhverjar spurningar vöknuðu um xx ...\n\n' + 'Alls eru [x-fjöldi] í HAFNAÐ hópnum. HAFNAÐ hópurinn á það sameiginlegt að hafa ekki reynslu af sambærilegu starfi, stutta eða litla reynslu af sambærilegum störfum og voru ekki með xx próf.\n\n' + 'Takk fyrir samvinnuna og gangi ykkur vel,\n' + '[Nafn ráðgjafa]');
      }
    },
    actions: {
      saveReport: function saveReport() {
        var _this = this;
        this.set('loading', true);
        this.apiClient.patch("/jobs/".concat(this.jobId, "/applicationclassifications/").concat(this.applicationClassification.id), {
          applicationClassification: this.applicationClassification
        }).then(function () {
          _this.set('writeCapacentReport');
          _this.notifications.success('Samantekt hefur verið send á ' + _this.brandName + '.');
        }).finally(function () {
          return _this.set('loading');
        });
      },
      openJob: function openJob() {
        window.open(web + '/starf/' + this.slug, '_blank');
      }
    }
  });
  _exports.default = _default;
});