define("admin/components/nav-bar", ["exports", "jquery", "ember-local-storage"], function (_exports, _jquery, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    loginManager: Ember.inject.service(),
    store: Ember.inject.service(),
    cookies: (0, _emberLocalStorage.storageFor)('cookies'),
    consent: function consent() {
      this.get('currentSession.user').save();
      this.set('cookies.consent', true);
      this.set('cookiesSettings');
    },
    consentAll: function consentAll() {
      this.set('currentSession.user.cookiesoptions', true);
      this.set('currentSession.user.cookiesstatistics', true);
      this.set('currentSession.user.cookiespersonalization', true);
      this.get('currentSession.user').save();
      this.set('cookies.consent', true);
      this.set('cookiesSettings');
    },
    actions: {
      closeMenu: function closeMenu() {
        (0, _jquery.default)('.menu').removeClass('close');
        (0, _jquery.default)('header').slideToggle(300);
      },
      toggleMenu: function toggleMenu() {
        (0, _jquery.default)('.menu').toggleClass('close');
        (0, _jquery.default)('header').slideToggle(300);
      },
      getNotifications: function getNotifications(page, limit) {
        return Ember.RSVP.hash({
          messages: this.store.query('system-message', {
            page: page,
            limit: limit
          })
        });
      },
      clearCount: function clearCount() {
        Ember.run.next(this, function () {
          if (this.get('currentSession.isAuthenticated')) {
            this.set('currentSession.user.sysmsgcount');
          }
        });
      },
      logout: function logout() {
        this.loginManager.logout();
      },
      phantomLogout: function phantomLogout() {
        var _this = this;
        this.loginManager.exitPhantom().catch(function () {
          _this.notifications.error(_this.intl.t('string.generic_error'));
        }).finally(function () {
          if (_this.afterPhantomLogout) {
            _this.afterPhantomLogout();
          }
        });
      }
    }
  });
  _exports.default = _default;
});