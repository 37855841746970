define("admin/components/brand/info-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="brand-info-section">
    <div class="brand-info-section--content">
      {{yield}}
    </div>
  </div>
  
  */
  {
    "id": "LyO/wWmk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"brand-info-section\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"brand-info-section--content\"],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/components/brand/info-section.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});