define("admin/compiled/page-title-header/PageTitleHeader", ["exports", "@alfred-is/alfred-design-system", "react", "admin/compiled/header-button/header-button"], function (_exports, _alfredDesignSystem, _react, _headerButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PageTitleHeader = void 0;
  var PageTitleHeader = function PageTitleHeader(_a) {
    var title = _a.title,
      actionName = _a.actionName,
      onAction = _a.onAction,
      leftAction = _a.leftAction,
      menuItems = _a.menuItems,
      children = _a.children;
    return _react.default.createElement("div", {
      className: 'page-title-header'
    }, _react.default.createElement("div", {
      className: 'container'
    }, _react.default.createElement("div", {
      className: 'page-title-header--content md:tw-justify-end'
    }, !!leftAction && _react.default.createElement(_react.default.Fragment, null, leftAction, _react.default.createElement("div", {
      className: "md:tw-w-full tw-w-4"
    })), _react.default.createElement("div", {
      className: 'page-title-header--actions'
    }, children, actionName && onAction && (menuItems && menuItems.length > 1 ? _react.default.createElement(_alfredDesignSystem.DropdownRadix, {
      trigger: _react.default.createElement(_headerButton.HeaderButton, {
        variant: _headerButton.HeaderButtonVariant.PRIMARY
      }, actionName),
      align: 'center',
      menuItems: menuItems
    }) : _react.default.createElement(_headerButton.HeaderButton, {
      variant: _headerButton.HeaderButtonVariant.PRIMARY,
      onClick: onAction
    }, actionName))))));
  };
  _exports.PageTitleHeader = PageTitleHeader;
});