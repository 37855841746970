define("admin/compiled/ui/filter-container/FilterContainer", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FilterContainer = void 0;
  var FilterContainer = function FilterContainer(_a) {
    var isActive = _a.isActive,
      icon = _a.icon,
      children = _a.children,
      onClick = _a.onClick,
      className = _a.className;
    return _react.default.createElement("div", {
      className: "ui-filter-container ".concat(isActive ? 'active' : '', " ").concat(icon ? "with-icon ".concat(icon) : '', " ").concat(className ? className : ''),
      onClick: onClick
    }, children);
  };
  _exports.FilterContainer = FilterContainer;
});