define("admin/compiled/alfrello-profile-card/AlfrelloProfileCard", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AlfrelloProfileCard = void 0;
  var AlfrelloProfileCard = function AlfrelloProfileCard(_a) {
    var label = _a.label,
      className = _a.className,
      children = _a.children;
    return _react.default.createElement("div", {
      className: "alfrello-profile-card ".concat(className ? className : '')
    }, _react.default.createElement("div", {
      className: 'alfrello-profile-card--label'
    }, label), _react.default.createElement("div", {
      className: 'alfrello-profile-card--content'
    }, children));
  };
  _exports.AlfrelloProfileCard = AlfrelloProfileCard;
});