define("admin/routes/newuser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('button', this.intl.t("string.activate_account"));
      controller.set('title', this.intl.t("login.new_user"));
    }
  });
  _exports.default = _default;
});