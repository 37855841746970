define("admin/services/hsq-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this._hsq = window._hsq = window._hsq || [];
    },
    identify: function identify(id, email, name) {
      this._hsq.push(["identify", {
        id: id,
        email: email,
        name: name
      }]);
    },
    track: function track(url) {
      this._hsq.push(["setPath", url]);
      this._hsq.push(["trackPageView"]);
    },
    trackEvent: function trackEvent(id, value) {
      this._hsq.push(["trackEvent", {
        id: id,
        value: value
      }]);
    }
  });
  _exports.default = _default;
});