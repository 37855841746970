define("admin/compiled/jobs-list/jobs-context-menu", ["exports", "react", "admin/compiled/utils/emberContext", "@alfred-is/alfred-design-system"], function (_exports, _react, _emberContext, _alfredDesignSystem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getMenuItems = void 0;
  var getMenuItems = function getMenuItems(job, actions) {
    var _a = (0, _react.useContext)(_emberContext.default),
      intl = _a.intl,
      config = _a.config,
      currentSession = _a.currentSession,
      cloneJob = _a.cloneJob;
    var links = config.REGION.links;
    var menuItems = [];
    if (job.hasAccess && job.useAlfrello && !job.isDraft) {
      var appliedTitle = intl.t('string.applications');
      if (job.applied !== undefined && job.applied !== null) {
        appliedTitle += " (".concat(job.applied, ")");
      }
      menuItems.push({
        text: appliedTitle,
        onClick: function onClick() {
          actions.openApplications();
        },
        icon: _alfredDesignSystem.AlfredIconT.Profile,
        iconColor: _alfredDesignSystem.ColorT.blue500
      });
    }
    menuItems.push({
      text: intl.t('job.open_web'),
      onClick: function onClick() {
        window.open("".concat(links.job).concat(job.slug || job.id), '_blank');
      },
      icon: _alfredDesignSystem.AlfredIconT.Preview,
      iconColor: _alfredDesignSystem.ColorT.orange500
    });
    if (job.hasAccess) {
      menuItems.push({
        text: intl.t('job.edit_job'),
        onClick: function onClick() {
          return actions.editJob();
        },
        icon: _alfredDesignSystem.AlfredIconT.Edit,
        iconColor: _alfredDesignSystem.ColorT.black500
      });
    }
    if (job.isActive && job.hasAccess && !job.scheduled && (!job.generalApplication || currentSession.user.allowManageGa)) {
      menuItems.push({
        text: intl.t('general.additional_service'),
        onClick: function onClick() {
          return actions.triggerUpsell();
        },
        icon: _alfredDesignSystem.AlfredIconT.Plus,
        iconColor: _alfredDesignSystem.ColorT.blue500
      });
    }
    if (!job.isDraft && job.hasAccess && !job.scheduled && !job.generalApplication) {
      menuItems.push({
        text: intl.t('string.duplicate'),
        onClick: function onClick() {
          return cloneJob(job);
        },
        icon: _alfredDesignSystem.AlfredIconT.Duplicate,
        iconColor: _alfredDesignSystem.ColorT.green500
      });
    }
    if (job.hasAccess && (job.isActive || job.isProgress)) {
      var notGaOrAllowManageGa = !job.generalApplication || currentSession.user.allowManageGa;
      var preventClose = job.isActive && !job.nodeadline && !currentSession.user.phantom && !job.scheduled;
      if (notGaOrAllowManageGa) {
        menuItems.push({
          text: job.useAlfrello && !job.generalApplication && !job.scheduled ? job.isActive ? intl.t('general.start-review') : intl.t('ad-status-modal-review.end') : intl.t('string.close'),
          onClick: function onClick() {
            return actions.closeJob();
          },
          icon: _alfredDesignSystem.AlfredIconT.Shutdown,
          iconColor: _alfredDesignSystem.ColorT.red500,
          disabled: preventClose,
          tooltip: preventClose ? intl.t('job-card.close-disabled-on-deadline.tooltip') : undefined
        });
      }
    }
    if (job.hasAccess && (job.isDraft || job.isEnded) && (!job.generalApplication || currentSession.user.allowManageGa)) {
      menuItems.push({
        text: intl.t('string.remove'),
        onClick: function onClick() {
          return actions.remove();
        },
        icon: _alfredDesignSystem.AlfredIconT.TrashBin,
        iconColor: _alfredDesignSystem.ColorT.red500
      });
    }
    return menuItems;
  };
  _exports.getMenuItems = getMenuItems;
});