define("admin/components/capacent-report-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      $('body').addClass('with-modal');
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);
      $('body').removeClass('with-modal');
    }
  });
  _exports.default = _default;
});