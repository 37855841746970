define("admin/components/video-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    willDestroyElement: function willDestroyElement() {
      this.set('player');
      this.playing(false);
      this._super.apply(this, arguments);
    },
    actions: {
      videoIsPlaying: function videoIsPlaying() {
        this.playing(true);
      },
      ended: function ended() {
        this.playing(false);
        this.next();
      },
      pause: function pause() {
        this.playing(false);
      }
    }
  });
  _exports.default = _default;
});