define("admin/compiled/modal/ConfirmDialogModal", ["exports", "react", "admin/compiled/modal/AlfredModal", "admin/compiled/utils/emberContext", "react-redux", "admin/compiled/redux/actions/modal", "@alfred-is/alfred-design-system"], function (_exports, _react, _AlfredModal, _emberContext, _reactRedux, _modal, _alfredDesignSystem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ConfirmDialogModal = void 0;
  var ConfirmDialogModal = function ConfirmDialogModal(_a) {
    var title = _a.title,
      onAction = _a.onAction,
      children = _a.children;
    var intl = (0, _react.useContext)(_emberContext.default).intl;
    var dispatch = (0, _reactRedux.useDispatch)();
    return _react.default.createElement(_AlfredModal.AlfredModal, {
      title: title !== null && title !== void 0 ? title : intl.t('confirm-dialog.title')
    }, children ? _react.default.createElement("div", {
      className: "tw-mb-5 tw-text-center tw-text-md"
    }, children) : null, _react.default.createElement("div", {
      className: "tw-text-center tw-mt-6 tw-space-x-3"
    }, _react.default.createElement(_alfredDesignSystem.StandardButton, {
      size: 'medium',
      variant: 'gray',
      onClick: function onClick() {
        return dispatch((0, _modal.hideModal)());
      },
      color: 'gray'
    }, !!onAction ? intl.t('confirm-dialog.decline') : intl.t('string.close')), !!onAction && _react.default.createElement(_alfredDesignSystem.StandardButton, {
      size: 'medium',
      variant: 'green',
      onClick: function onClick() {
        if (onAction) {
          onAction();
          dispatch((0, _modal.hideModal)());
        }
      }
    }, intl.t('string.confirm'))));
  };
  _exports.ConfirmDialogModal = ConfirmDialogModal;
});