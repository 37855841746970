define("admin/serializers/rest-serializer", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _rest.default.extend(_rest.EmbeddedRecordsMixin, {
    extractMeta: function extractMeta(store, typeClass, payload) {
      if (payload) {
        if (payload.hasOwnProperty('totalCount') && payload.hasOwnProperty('totalPages')) {
          var meta = {
            count: payload.totalCount,
            total_pages: payload.totalPages
          };
          delete payload.totalCount, payload.totalPages;
          return meta;
        }
        if (payload.hasOwnProperty('total_pages')) {
          var _meta = {
            total_pages: payload.total_pages
          };
          delete payload.total_pages;
          return _meta;
        }
      }
      return this._super(store, typeClass, payload);
    }
  });
  _exports.default = _default;
});