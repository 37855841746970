define("admin/templates/admin/customers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0Ui01umc",
    "block": "{\"symbols\":[],\"statements\":[[8,\"nav-header\",[],[[\"@title\",\"@showBtn\",\"@submenu\",\"@newItem\",\"@routeLink\"],[[30,[36,0],[\"menu.customers\"],null],false,\"customers\",[30,[36,0],[\"menu.new_customer\"],null],\"admin.customers.new\"]],null],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "admin/templates/admin/customers.hbs"
    }
  });
  _exports.default = _default;
});