define("admin/controllers/client/edit-job/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    today: (0, _moment.default)().toDate(),
    selectDivision: function selectDivision(division) {
      this.set('model.job.division', division);
    },
    onSelectBrand: function onSelectBrand(brand) {
      var _this = this;
      if (!brand) {
        return;
      }
      this.set('model.job.division');
      if (brand.brandAddress) {
        this.store.query('address', {
          search: brand.brandAddress
        }).then(function (results) {
          if (_this.get('model.job.addresses.length')) {
            if (_this.get('model.job.addresses.length') === 1) {
              _this.get('model.job.addresses').clear();
            }
            _this.get('model.job.addresses').pushObject(results.firstObject);
          }
        });
      }
      if (this.get('model.job.brand.divisions.length') === 1) {
        this.selectDivision(this.get('model.job.brand.divisions.firstObject'));
      }
    },
    actions: {
      createBrand: function createBrand() {
        this.set('newBrand', this.store.createRecord('brand', {
          invoice: {},
          social: {}
        }));
        this.toggleProperty('isCreatingBrand');
      },
      onSaveBrand: function onSaveBrand(brand) {
        Ember.set(this, 'newBrand', null);
        Ember.set(this, 'model.job.brand', brand);
        this.onSelectBrand(brand);
        this.get('model.brands').pushObject(brand);
      },
      selectBrand: function selectBrand(brand) {
        Ember.set(this, 'model.job.brand', brand);
        this.onSelectBrand(brand);
      },
      selectDivision: function selectDivision(division) {
        this.selectDivision(division);
      }
    }
  });
  _exports.default = _default;
});