define("admin/components/crad/skills-search-results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="skills-search-results">
    <div class="skills-search-results--clear">
      <a href="#"
         data-test="clear-search"
        {{action @clearSearch}}>
        {{t "crad.clear_search"}}
      </a>
    </div>
    {{#each @searchResults as |category|}}
      <Layout::Space @all="20">
        <Layout::Space @bottom="10">
          <strong>
            {{category.name}}
          </strong>
        </Layout::Space>
        <div class="pre-wrap">
          {{#each category.skills as |skill|}}
            <Crad::Skill
              @skill={{skill}}
              @onClick={{fn @toggle skill}}
              @active={{array-contains @skillIds skill}} />
          {{/each}}
        </div>
      </Layout::Space>
    {{/each}}
  
    {{#unless @searchResults.length}}
      <Layout::Space @bottom="20">
        <p class="text-center top-space">
          {{t "crad.nothing_found"}}
        </p>
      </Layout::Space>
    {{/unless}}
  
  </div>
  
  */
  {
    "id": "lhHpDp8L",
    "block": "{\"symbols\":[\"category\",\"skill\",\"@toggle\",\"@skillIds\",\"@clearSearch\",\"@searchResults\"],\"statements\":[[10,\"div\"],[14,0,\"skills-search-results\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"skills-search-results--clear\"],[12],[2,\"\\n    \"],[11,\"a\"],[24,6,\"#\"],[24,\"data-test\",\"clear-search\"],[4,[38,5],[[32,0],[32,5]],null],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"crad.clear_search\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"layout/space\",[],[[\"@all\"],[\"20\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"layout/space\",[],[[\"@bottom\"],[\"10\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"strong\"],[12],[2,\"\\n          \"],[1,[32,1,[\"name\"]]],[2,\"\\n        \"],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[10,\"div\"],[14,0,\"pre-wrap\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,1,[\"skills\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"crad/skill\",[],[[\"@skill\",\"@onClick\",\"@active\"],[[32,2],[30,[36,1],[[32,3],[32,2]],null],[30,[36,2],[[32,4],[32,2]],null]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[6,[37,6],[[32,6,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"layout/space\",[],[[\"@bottom\"],[\"20\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"p\"],[14,0,\"text-center top-space\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"crad.nothing_found\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"fn\",\"array-contains\",\"-track-array\",\"each\",\"action\",\"unless\"]}",
    "meta": {
      "moduleName": "admin/components/crad/skills-search-results.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});