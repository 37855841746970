define("admin/mixins/build-url-with-prefix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var prefix;
      if (snapshot && snapshot.adapterOptions) {
        prefix = snapshot.adapterOptions.prefix;
      }
      if (query && query.prefix) {
        prefix = query.prefix;
        delete query.prefix;
      }
      modelName = prefix ? "".concat(prefix, "/").concat(modelName) : modelName;
      return this._super(modelName, id, snapshot, requestType, query);
    }
  });
  _exports.default = _default;
});