define("admin/compiled/utils/hrbContext", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.HRBProvider = void 0;
  var HRBContext = _react.default.createContext(false);
  var HRBProvider = HRBContext.Provider;
  _exports.HRBProvider = HRBProvider;
  var _default = HRBContext;
  _exports.default = _default;
});