define("admin/components/crad/empty-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="crad-empty-list">
    <img src="/img/icons/crad/empty-tags.svg"
         alt="" />
    {{yield}}
  </div>
  
  */
  {
    "id": "NX8YE6PM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"crad-empty-list\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,\"src\",\"/img/icons/crad/empty-tags.svg\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/components/crad/empty-list.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});