define("admin/storages/current-user", ["exports", "ember-local-storage/local/object", "admin/config/environment"], function (_exports, _object, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var locale = _environment.default.REGION.locale;
  var Storage = _object.default.extend();
  Storage.reopenClass({
    initialState: function initialState() {
      return {
        lang: locale,
        features: [],
        lastKnownAdCount: 0
      };
    }
  });
  var _default = Storage;
  _exports.default = _default;
});