define("admin/routes/admin/autotagging/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.findAll('autotag-category');
    }
  });
  _exports.default = _default;
});