define("admin/templates/components/region-locales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "aPlz7t8G",
    "block": "{\"symbols\":[\"loc\"],\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,0,[\"locales\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[16,0,[31,[\"flag \",[30,[36,2],[[30,[36,1],[[35,0,[\"locale\",\"firstObject\"]],[32,1]],null],\"active\"],null]]]],[4,[38,4],[[32,0],[35,3],[32,1]],null],[12],[2,\"\\n    \"],[10,\"img\"],[15,\"src\",[31,[\"/flags/\",[32,1],\".svg\"]]],[15,\"alt\",[32,1]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"intl\",\"eq\",\"if\",\"changeLocale\",\"action\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/components/region-locales.hbs"
    }
  });
  _exports.default = _default;
});