define("admin/templates/components/sales/confirm-export-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "c8aL2MAk",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@overlayClass\"],[[30,[36,0],[[32,0],\"close\"],null],\"modal-popup\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"default-dialog small text-center\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[1,[30,[36,1],[\"components.sales.confirm_export_modal.title\"],null]],[13],[2,\"\\n\\n    \"],[10,\"p\"],[12],[1,[30,[36,1],[\"components.sales.confirm_export_modal.confirm\"],null]],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"edit-form\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn gray-brd inline upper\"],[4,[38,0],[[32,0],\"close\"],null],[12],[1,[30,[36,1],[\"string.cancel\"],null]],[13],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn green inline upper\"],[4,[38,0],[[32,0],\"confirm\"],null],[12],[1,[30,[36,1],[\"components.sales.confirm_export_modal.export\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"t\"]}",
    "meta": {
      "moduleName": "admin/templates/components/sales/confirm-export-modal.hbs"
    }
  });
  _exports.default = _default;
});