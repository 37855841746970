define("admin/components/forms/input-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['input-field'],
    classNameBindings: ['isValid:valid:', 'invalid:invalid:', 'noTopRound:no-top-round'],
    length: 128,
    type: 'text',
    isValid: Ember.computed('value', 'invalid', 'disableValidation', function () {
      if (!this.disableValidation) {
        return this.invalid ? !this.invalid : this.readonly ? '' : this.value;
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.prelabel) {
        var pad = this['class'] === 'icon' ? 62 : 17;
        this.$('input').css({
          'padding-left': this.$('.prelabel').width() + pad
        });
      }
    }
  });
  _exports.default = _default;
});