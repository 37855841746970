define("admin/templates/client/statistics/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "JtsNNqvT",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"statistics-react/statistics-react\",[],[[\"@brands\"],[[32,1,[\"brands\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/templates/client/statistics/index.hbs"
    }
  });
  _exports.default = _default;
});