define("admin/compiled/job-item/RegularJobItem", ["exports", "@alfred-is/alfred-design-system", "moment", "react", "admin/compiled/job-card/job-card", "admin/compiled/jobs-list/jobs-context-menu", "admin/compiled/utils", "admin/compiled/utils/emberContext"], function (_exports, _alfredDesignSystem, _moment, _react, _jobCard, _jobsContextMenu, _utils, _emberContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RegularJobItem = void 0;
  var EditBtn = function EditBtn(_a) {
    var hasAccess = _a.hasAccess,
      _onClick = _a.onClick;
    var isHovered = (0, _jobCard.useJobCardContext)().isHovered;
    var t = (0, _alfredDesignSystem.useNxTranslation)().t;
    return _react.default.createElement(_alfredDesignSystem.CtaButton, {
      disabled: !hasAccess,
      variant: isHovered ? _alfredDesignSystem.CtaButtonVariant.GRAY : _alfredDesignSystem.CtaButtonVariant.WHITE_GRAY,
      size: _alfredDesignSystem.SizeT.medium,
      className: 'tw-rounded-sm',
      onClick: function onClick() {
        if (!hasAccess) {
          return;
        }
        _onClick();
      }
    }, t('string.change'));
  };
  var RegularJobItem = function RegularJobItem(_a) {
    var _b, _c;
    var job = _a.job,
      actions = _a.actions;
    var config = (0, _react.useContext)(_emberContext.default).config;
    var _d = config.REGION,
      thousand = _d.thousand,
      symbol = _d.symbol,
      features = _d.features;
    var format = (0, _alfredDesignSystem.useDateUtils)().format;
    var t = (0, _alfredDesignSystem.useNxTranslation)().t;
    var numberFormatter = function numberFormatter(number) {
      return (0, _utils.formatNumber)(number, thousand);
    };
    var price = "".concat(numberFormatter(Math.round(job.price)), " ").concat(symbol || t('string.currency'));
    var brand = job.brand;
    var jobProgress = [];
    if (!job.isDraft) {
      if (job.startdate < job.deadline) {
        // job startdate can be after job.deadline if the ad was scheduled and then closed before it went live.
        jobProgress.push({
          dateFrom: job.startdate,
          dateTo: job.deadline,
          color: _alfredDesignSystem.ColorT.green500,
          label: t('string.ad-status.label.active'),
          status: t('string.ad-status.label.active').toLowerCase()
        });
      }
      if (job.autoCloseDate && !job.isEnded) {
        jobProgress.push({
          dateFrom: job.deadline,
          dateTo: job.autoCloseDate,
          color: _alfredDesignSystem.ColorT.blue500,
          label: t('string.ad-status.label.in-progress'),
          status: t('string.ad-status.label.in-progress').toLowerCase()
        });
      } else if (!job.generalApplication && job.useAlfrello && job.isEnded && job.enddate) {
        // show review progress from deadline to the date when the review process has ended
        jobProgress.push({
          dateFrom: job.deadline,
          dateTo: job.enddate,
          color: _alfredDesignSystem.ColorT.blue500,
          label: t('string.ad-status.label.in-progress'),
          status: t('string.ad-status.label.in-progress').toLowerCase()
        });
      }
    }
    var infoItems = [{
      label: t('string.price'),
      content: price
    }, {
      label: t('string.opened'),
      content: (_b = job.opened) === null || _b === void 0 ? void 0 : _b.toString()
    }, {
      label: t('string.started_short'),
      content: format(job.startdate, 'd. MMM yy')
    }, {
      label: t('string.deadline'),
      content: format(job.deadline, 'd. MMM yy')
    }];
    if (job.useAlfrello) {
      var reviewWeeks = (0, _moment.default)(job.autoCloseDate).endOf('day').diff(job.deadline.toString(), 'weeks') || 6;
      infoItems.push({
        label: t('string.review'),
        content: reviewWeeks === 1 ? "1 ".concat(t('string.weeks_singular')) : "".concat(reviewWeeks, " ").concat(t('string.weeks_plural'))
      });
    }
    if (job.useAlfrello || job.applyemail) {
      infoItems.push({
        label: t('string.applied'),
        content: (_c = job.applied) === null || _c === void 0 ? void 0 : _c.toString()
      });
    }
    if (job.user.get('name')) {
      infoItems.push({
        label: t('string.posted_by'),
        content: job.user.get('name')
      });
    }
    return _react.default.createElement(_jobCard.JobCard, {
      variant: job.useAlfrello ? _jobCard.JobCardVariant.BLUE : _jobCard.JobCardVariant.GRAY
    }, function (_a) {
      var _b, _c, _d;
      var infoActive = _a.infoActive;
      return _react.default.createElement(_jobCard.JobCard.Container, null, _react.default.createElement(_jobCard.JobCard.Header, {
        brandName: brand.get('name'),
        logo: brand.get('logo')
      }, _react.default.createElement(_jobCard.JobCard.Header.Menu, {
        menuItems: (0, _jobsContextMenu.getMenuItems)(job, actions),
        withInfo: true
      })), !infoActive && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_jobCard.JobCard.Title, null, job.title), !job.isDraft ? _react.default.createElement(_jobCard.JobCard.Progress, {
        items: jobProgress,
        status: {
          color: "#".concat(job.status.color),
          description: job.status.description
        }
      }) : _react.default.createElement(_jobCard.JobCard.Status, {
        color: "#".concat(job.status.color)
      }, job.status.description), _react.default.createElement(_jobCard.JobCard.Spacer, null), _react.default.createElement(_jobCard.JobCard.CenteredContent, null, !job.isDraft && job.useAlfrello && _react.default.createElement(_jobCard.JobCard.ApplicationsBtn, {
        disabled: !job.hasAccess,
        applications: job.applied,
        onClick: function onClick() {
          return actions.openApplications();
        }
      }, job.applied === 1 ? t('menu.application') : t('string.applications')), !job.isDraft && !job.useAlfrello && _react.default.createElement(_jobCard.JobCard.ExternalBtn, {
        isEmail: !!job.applyemail,
        onClick: job.applyweb ? function () {
          if (job.applyweb) {
            window.open(job.applyweb);
          }
        } : features.alfrello.emailJobs ? actions.openApplications : undefined
      }, (0, _alfredDesignSystem.cutText)(job.applyweb || job.applyemail, 35), job.applyemail ? " (".concat(job.applied, ")") : null), job.isDraft && _react.default.createElement(EditBtn, {
        onClick: actions.editJob,
        hasAccess: job.hasAccess
      }), _react.default.createElement(_jobCard.JobCard.Actions, null, job.useAlfrello && _react.default.createElement(_jobCard.JobCard.Action, {
        onClick: function onClick() {
          return actions.openMessages();
        },
        metaType: 'MESSAGES',
        metaValue: (job.messages || 0).toString()
      }, t('string.messages')), job.isActive && !job.scheduled && job.hasAccess && _react.default.createElement(_jobCard.JobCard.Action, {
        metaType: 'ICON',
        metaCircleVariant: 'yellow',
        metaIcon: _alfredDesignSystem.AlfredIconT.Star,
        onClick: function onClick() {
          var _a;
          return (_a = actions.triggerUpsell) === null || _a === void 0 ? void 0 : _a.call(actions);
        }
      }, t('general.additional_service')))), ' ', _react.default.createElement(_jobCard.JobCard.Footer, null, _react.default.createElement(_jobCard.JobCard.FooterItem, {
        hideDot: !features.job.showClicks && !((_b = job.productPurchases) === null || _b === void 0 ? void 0 : _b.length),
        item: {
          icon: _alfredDesignSystem.AlfredIconT.Money,
          text: price
        }
      }), features.job.showClicks && _react.default.createElement(_jobCard.JobCard.FooterItem, {
        hideDot: !((_c = job.productPurchases) === null || _c === void 0 ? void 0 : _c.length),
        item: {
          icon: _alfredDesignSystem.AlfredIconT.Click,
          text: numberFormatter(job.opened || 0)
        }
      }), !!((_d = job.productPurchases) === null || _d === void 0 ? void 0 : _d.length) && _react.default.createElement(_jobCard.JobCard.FooterItem, {
        hideDot: true
      }, _react.default.createElement(_jobCard.JobCard.Services, {
        items: job.productPurchases.map(function (p) {
          return {
            name: p.tooltip,
            icon: p.icon,
            tooltip: "".concat(p.tooltip, " (").concat(numberFormatter(p.price), " ").concat(symbol, ")"),
            price: p.price
          };
        })
      })))), infoActive && _react.default.createElement(_jobCard.JobCard.InfoRow, {
        items: infoItems
      }));
    });
  };
  _exports.RegularJobItem = RegularJobItem;
});