define("admin/routes/client/edit-job", ["exports", "admin/mixins/model-loader", "admin/mixins/with-hidden-nav"], function (_exports, _modelLoader, _withHiddenNav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_withHiddenNav.WithHiddenNav, _modelLoader.default, {
    queryParams: {
      context: {
        refreshModel: false
      }
    },
    apiClient: Ember.inject.service(),
    model: function model(_ref) {
      var job_id = _ref.job_id,
        context = _ref.context;
      return Ember.RSVP.hash({
        context: context,
        job: this.store.find('job', job_id),
        brands: this.store.query('brand', {
          status: 'ACTIVE'
        }),
        jobstatuses: this.store.query('jobstatus', {}),
        jobtypes: this.store.query('jobtype', {}),
        questions: this.store.findAll('question'),
        jobTemplates: this.store.findAll('job-template'),
        compensationUnits: this.apiClient.get('/compensation/units').then(function (d) {
          return d.compensationUnits;
        }),
        compensationCurrencies: this.apiClient.get('/compensation/currencies').then(function (d) {
          return d.currencies;
        })
      });
    },
    activate: function activate() {
      this._super.apply(this, arguments);
      $('.chat-trigger').hide();
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);
      $('.chat-trigger').show();
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      if (model.job && model.job.generalApplication) {
        model.job.set('jobtype', model.jobtypes.get('firstObject'));
      }
    }
  });
  _exports.default = _default;
});