define("admin/compiled/alfrello-profile-invite/JobInterviewInvite", ["exports", "react", "moment", "admin/compiled/alfrello-profile-invite/AlfrelloProfileInvite", "admin/compiled/utils/languageContext", "admin/compiled/alfrello-profile-invite/EmptyInvite"], function (_exports, _react, _moment, _AlfrelloProfileInvite, _languageContext, _EmptyInvite) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.JobInterviewInvite = void 0;
  var JobInterviewInvite = function JobInterviewInvite(_a) {
    var interview = _a.interview,
      showInviteModal = _a.showInviteModal,
      showCancelModal = _a.showCancelModal,
      showRemoteMeetingModal = _a.showRemoteMeetingModal;
    var intl = (0, _react.useContext)(_languageContext.default);
    var isRemote = interview && !!interview.get('remoteInterview');
    var isRoomEnabled = interview && !!interview.get('remoteInterview.roomEnabled');
    if (!interview) {
      return _react.default.createElement(_EmptyInvite.EmptyInvite, {
        showInviteModal: showInviteModal,
        icon: 'calendar'
      }, intl.t('string.invite_interview'));
    }
    var meta = "".concat((0, _moment.default)(interview.get('from')).format('D. MMM @ H:mm'), " - ").concat((0, _moment.default)(interview.get('to')).format('H:mm'));
    if (!isRemote) {
      meta += ", ".concat(interview.get('address'));
    }
    var getStatus = function getStatus(status) {
      switch (status) {
        case 'SENT':
          return intl.t('string.invite_sent');
        case 'SEEN':
          return intl.t('string.seen');
        case 'ACCEPTED':
          return intl.t('string.accepted');
        case 'REQUEST_NEW':
          return intl.t('string.new_time_request');
        case 'DECLINED':
          return intl.t('string.declined');
        case 'CANCEL':
          return intl.t('string.canceled');
        default:
          return '';
      }
    };
    return _react.default.createElement(_AlfrelloProfileInvite.AlfrelloProfileInviteSent, {
      isGreen: interview.get('status') === 'ACCEPTED',
      title: "".concat(isRemote ? intl.t('remote-interviews.title') : intl.t('client.applications.profile.interview'), " - ").concat(getStatus(interview.get('status'))),
      contextMenuItems: [{
        title: intl.t('client.applications.profile.change_time'),
        onClick: showInviteModal
      }, {
        title: _react.default.createElement("span", {
          style: {
            color: '#F44E4E'
          }
        }, intl.t('client.applications.profile.cancel_interview')),
        onClick: showCancelModal
      }],
      meta: meta
    }, isRemote && isRoomEnabled && _react.default.createElement("div", {
      className: 'alfrello-profile-invite--remote'
    }, _react.default.createElement("span", {
      className: "interview-invite-status--link",
      onClick: showRemoteMeetingModal
    }, intl.t('remote-interviews.open'), " ", _react.default.createElement("span", {
      className: "arrow-right"
    }))));
  };
  _exports.JobInterviewInvite = JobInterviewInvite;
});