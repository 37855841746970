define("admin/compiled/jobtags-card/JobTagsCard", ["exports", "react", "admin/compiled/utils", "admin/compiled/utils/emberContext", "admin/compiled/types", "admin/compiled/redux/actions/modal", "react-redux", "admin/compiled/jobtags/hooks/useDeleteJobTag"], function (_exports, _react, _utils, _emberContext, _types, _modal, _reactRedux, _useDeleteJobTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.JobTagsCard = void 0;
  var JobTagsCard = function JobTagsCard(_a) {
    var _b;
    var id = _a.id,
      category = _a.category,
      name = _a.name,
      tags = _a.tags,
      jobCount = _a.jobCount,
      categoryId = _a.categoryId,
      _onClick = _a.onClick;
    var _c = (0, _react.useContext)(_emberContext.default),
      intl = _c.intl,
      config = _c.config;
    var thousand = config.REGION.thousand;
    var dispatch = (0, _reactRedux.useDispatch)();
    var deleteMutation = id ? (0, _useDeleteJobTag.default)(id, categoryId) : null;
    return _react.default.createElement("div", {
      className: 'jobtags-card',
      onClick: function onClick() {
        if (_onClick) {
          _onClick();
        }
      }
    }, _react.default.createElement("div", {
      className: 'jobtags-card--top'
    }, "".concat(category, " -> ").concat(name || (tags.length ? (_b = tags.find(function (t) {
      return !!t.name;
    })) === null || _b === void 0 ? void 0 : _b.name : ''), " | ").concat((0, _utils.formatNumber)(jobCount, thousand), " ").concat(intl.t('admin.jobtags.jobcount'))), _react.default.createElement("div", {
      className: 'jobtags-card--tags'
    }, tags.map(function (tag) {
      return tag.name ? _react.default.createElement("span", {
        className: 'jobtags-card-tag'
      }, _react.default.createElement("img", {
        src: tag.flag,
        alt: tag.name
      }), tag.name) : null;
    })), _react.default.createElement("span", {
      className: 'jobtags-card--remove',
      onClick: function onClick(e) {
        e.stopPropagation();
        dispatch((0, _modal.showModal)(_types.ModalType.CONFIRMATION_MODAL, {
          onAction: function onAction() {
            if (deleteMutation) {
              deleteMutation.mutate();
            }
          }
        }));
      }
    }));
  };
  _exports.JobTagsCard = JobTagsCard;
});