define("admin/controllers/client/applications/setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      addColumnTo: function addColumnTo(category, title) {
        this.send('createColumn', category, title);
      },
      sendDelete: function sendDelete(id) {
        this.send('deleteColumn', id);
      },
      save: function save() {
        this.transitionToRoute('client.applications');
      }
    }
  });
  _exports.default = _default;
});