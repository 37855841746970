define("admin/templates/client/applications/profile/interview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "tdIJ3Y10",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"interviews/remote-interview\",[],[[\"@profilePicture\",\"@name\",\"@toggle\",\"@roomUrl\"],[[32,1,[\"application\",\"profile\",\"image\"]],[32,1,[\"application\",\"profile\",\"name\"]],[30,[36,0],[[32,0],\"close\"],null],[32,1,[\"application\",\"interview\",\"roomUrl\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "admin/templates/client/applications/profile/interview.hbs"
    }
  });
  _exports.default = _default;
});