define("admin/adapters/customer", ["exports", "admin/adapters/rest-superadmin"], function (_exports, _restSuperadmin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _restSuperadmin.default.extend({
    pathForType: function pathForType() {
      return 'clients';
    },
    urlForQuery: function urlForQuery(query) {
      if (query._new) {
        delete query._new;
        return "".concat(this._super.apply(this, arguments), "/new");
      }
      return this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});