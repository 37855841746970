define("admin/templates/admin/autotagging/category/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "258nk5qd",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"text-center padded\"],[12],[10,\"strong\"],[12],[1,[30,[36,0],[\"autotagging.choose_tag_from_menu\"],null]],[13],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "admin/templates/admin/autotagging/category/index.hbs"
    }
  });
  _exports.default = _default;
});