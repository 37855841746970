define("admin/templates/components/crad/categories-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZuWGLVXL",
    "block": "{\"symbols\":[\"cat\"],\"statements\":[[6,[37,11],[[35,10]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[24,0,\"selected-category\"],[24,\"data-test\",\"selected-category\"],[4,[38,0],[[32,0],[30,[36,8],[\"active\",[32,0]],null]],null],[12],[2,\"\\n\"],[6,[37,9],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"strong\"],[12],[2,\"\\n        \"],[1,[35,7,[\"name\"]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"gray\"],[12],[2,\"\\n        \"],[1,[30,[36,6],[\"crad.choose_category\"],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[6,[37,9],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"click-outside\",[],[[\"@onClickOutside\"],[[30,[36,0],[[32,0],[30,[36,2],[[35,1]],null],false],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"categories-dropdown-options\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[11,\"div\"],[24,0,\"crad-categories-dropdown-item\"],[24,\"data-test\",\"crad-categories-item\"],[4,[38,0],[[32,0],\"set\",[32,1]],null],[12],[2,\"\\n            \"],[1,[32,1,[\"name\"]]],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"active\",\"mut\",\"categories\",\"-track-array\",\"each\",\"t\",\"selectedCategory\",\"toggle-action\",\"if\",\"hide\",\"unless\"]}",
    "meta": {
      "moduleName": "admin/templates/components/crad/categories-dropdown.hbs"
    }
  });
  _exports.default = _default;
});