define("admin/components/forms/text-field", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['input-field'],
    // classNameBindings: ['isValid:valid:invalid'],

    length: 45,
    isValid: Ember.computed.notEmpty('text'),
    didInsertElement: function didInsertElement() {
      var self = this;
      this.$('.teditable').html(this.text);
      this.$().on('focus', '[contenteditable]', function () {
        var $this = (0, _jquery.default)(this);
        $this.data('before', $this.html());
        return $this;
      }).on('blur keyup paste input', '[contenteditable]', function () {
        var $this = (0, _jquery.default)(this);
        var text = $this.html();
        if ($this.data('before') !== text) {
          $this.data('before', text);
          self.set('text', text);
          $this.trigger('change');
        }
        return $this;
      }).on('blur', '[contenteditable]', function () {
        (0, _jquery.default)(this).html((0, _jquery.default)(this).html().replace(/(#\w+#)/g, '<strong>$1</strong>'));
      });
    },
    actions: {}
  });
  _exports.default = _default;
});