define("admin/components/cookies-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['cookies-bar'],
    user: Ember.computed.alias('currentSession.user'),
    init: function init() {
      this._super.apply(this, arguments);
    },
    consent: function consent() {
      if (this.user) {
        this.saveCookies();
      }
      this.set('cookies.consent', true);
    },
    consentAll: function consentAll() {
      if (this.user) {
        this.set('user.cookies', {
          options: true,
          statistics: true,
          personalization: true
        });
        this.saveCookies();
      }
      this.set('cookies.consent', true);
    },
    saveCookies: function saveCookies() {
      this.user.saveCookies(this.user.cookies);
    }
  });
  _exports.default = _default;
});