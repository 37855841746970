define("admin/components/crad/nav", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var features = _environment.default.REGION.features;
  var _default = Ember.Component.extend({
    classNames: ['crad-nav'],
    intl: Ember.inject.service(),
    filteredNavItems: Ember.computed('navItems', function () {
      if (!this.navItems) {
        return [];
      }
      return this.navItems.filter(function (i) {
        return i.display;
      });
    }),
    navItems: Ember.computed('validation', 'validation.{index,description,tags,subCategories,skills,questions,deadline}', 'showQuestions', 'job.generalApplication', function () {
      return [{
        name: this.intl.t('crad.menu.general'),
        valid: this.validation.index,
        route: 'client.edit-job.index',
        display: true,
        required: true
      }, {
        name: this.intl.t('crad.menu.deadline'),
        valid: this.validation.deadline,
        route: 'client.edit-job.deadline',
        display: !this.job.generalApplication,
        required: true
      }, {
        name: this.intl.t('crad.menu.job_description'),
        valid: this.validation.description,
        route: 'client.edit-job.description',
        display: true,
        required: true
      }, {
        name: this.intl.t('crad.menu.tags'),
        valid: this.validation.tags,
        route: 'client.edit-job.tags',
        display: true,
        required: true
      }, {
        name: this.intl.t('crad.subcategories.title'),
        valid: this.validation.subCategories,
        route: 'client.edit-job.subcategories',
        display: features.job.subcategories,
        required: false
      }, {
        name: this.intl.t('crad.skills'),
        valid: this.validation.skills,
        route: 'client.edit-job.skills',
        display: features.job.skills || features.language_skills,
        required: false
      }, {
        name: this.intl.t('crad.menu.questions'),
        valid: this.validation.questions,
        route: 'client.edit-job.questions',
        display: this.showQuestions,
        required: false
      }];
    })
  });
  _exports.default = _default;
});