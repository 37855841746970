define("admin/controllers/client/applications/videointerviews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    revealRatings: Ember.computed.alias('model.job.revealratings'),
    sortBy: Ember.computed('sort.id', function () {
      if (this.get('sort.id') === 'rating') {
        return ['videoInterview.averageRating:desc', 'profile.name'];
      }
      if (this.get('sort.id') === 'status') {
        return ['videoInterview.status', 'profile.name'];
      }
      return ['profile.name'];
    }),
    applications: Ember.computed.filter('model.applications', function (application) {
      return application.get('status') !== 'CANCELED' && application.get('videoInterview.id') && application.get('videoInterview.status') !== 'EXPIRED';
    }),
    sortedApplications: Ember.computed.sort('applications', 'sortBy'),
    expiredApplications: Ember.computed.filterBy('model.applications', 'videoInterview.status', 'EXPIRED'),
    init: function init() {
      this._super.apply(this, arguments);
      this.sorts = [{
        id: 'rating',
        title: this.intl.t('applications.vi.sort_rating')
      }, {
        id: 'alpha',
        title: this.intl.t('applications.vi.sort_alphabet')
      }, {
        id: 'status',
        title: this.intl.t('applications.vi.sort_status')
      }];
      this.set('sort', this.sorts[1]);
    },
    actions: {
      sort: function sort(_sort) {
        this.set('sort', _sort);
      }
    }
  });
  _exports.default = _default;
});