define("admin/compiled/alfrello-profile-info/ProfileEducation", ["exports", "react", "admin/compiled/alfrello-profile-info/ProfileTimeline", "moment", "admin/compiled/utils/languageContext"], function (_exports, _react, _ProfileTimeline, _moment, _languageContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ProfileEducation = void 0;
  var ProfileEducation = function ProfileEducation(_a) {
    var educations = _a.educations,
      label = _a.label;
    var intl = (0, _react.useContext)(_languageContext.default);
    return _react.default.createElement(_ProfileTimeline.ProfileTimeline, {
      label: label,
      items: educations.map(function (education) {
        var _a, _b;
        return {
          id: education.id,
          title: education.name,
          place: education.school,
          description: education.description,
          from: education.fromdate ? (0, _moment.default)(education.fromdate, 'YYYY-MM-DD').format('MMM YYYY') : '',
          to: education.todate ? (0, _moment.default)(education.todate, 'YYYY-MM-DD').format('MMM YYYY') : intl.t('date.now'),
          degree: ((_a = education.degree) === null || _a === void 0 ? void 0 : _a.id) ? (_b = education.degree) === null || _b === void 0 ? void 0 : _b.name : null
        };
      })
    });
  };
  _exports.ProfileEducation = ProfileEducation;
});