define("admin/compiled/ui/Spinner", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Spinner = void 0;
  var Spinner = function Spinner() {
    return _react.default.createElement("div", {
      className: 'ui-spinner'
    }, _react.default.createElement("div", {
      className: 'ui-spinner--icon'
    }));
  };
  _exports.Spinner = Spinner;
});