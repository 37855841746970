define("admin/models/jobstatus", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    active: (0, _model.attr)('boolean'),
    description: (0, _model.attr)('string')

    // status: Ember.computed('name', function() {
    //     return `${this.get('name').toLowerCase().replace(/\s/g, '')}`;
    // })
  });
  _exports.default = _default;
});