define("admin/components/jobs/step-one", ["exports", "jquery", "moment"], function (_exports, _jquery, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    today: (0, _moment.default)().toDate(),
    // started_min: moment().toDate(),
    // deadline_min: moment().toDate(),
    isPhantom: Ember.computed.alias('currentSession.user.phantom'),
    bodyContains: Ember.computed('model.job.bodyhtml', function () {
      return this._createContainsObject(this.get('model.job.bodyhtml'));
    }),
    titleContains: Ember.computed('model.job.title', function () {
      return this._createContainsObject(this.get('model.job.title'));
    }),
    bodyWarningVisible: Ember.computed('bodyContains.{email,parttime,summerjobs}', 'model.job.adtype.id', 'model.job.jobtype.id', function () {
      return this.bodyContains.email && this.get('model.job.adtype.id') == 2 || this.bodyContains.summerjobs && this.get('model.job.jobtype.id') != 3 || this.bodyContains.parttime && this.get('model.job.jobtype.id') != 2;
    }),
    titleWarningVisible: Ember.computed('model.job.jobtype.id', 'titleContains.{email,parttime,summerjobs}', function () {
      return this.titleContains.summerjobs && this.get('model.job.jobtype.id') != 3 || this.titleContains.parttime && this.get('model.job.jobtype.id') != 2;
    }),
    _checkDeadlines: function _checkDeadlines() {
      var startdate = this.get('model.job.startdate');
      if (startdate) {
        this.set('deadline_min', startdate);
      }
      if (!this.get('model.job.nodeadline') && !this.isPhantom && this.model.job.isActive) {
        this.set('deadline_min', this.get('model.job.deadline'));
      }
      var max = (0, _moment.default)(startdate).add(6, 'weeks');
      if (!this.get('model.job.nodeadline') && max.isBefore((0, _moment.default)(this.get('model.job.deadline')), 'day')) {
        this.set('model.job.deadline', max.toDate());
      }
      if (!this.get('model.job.id')) {
        this.set('deadline_max', max.toDate());
        if ((0, _moment.default)(startdate).isAfter((0, _moment.default)(this.get('model.job.deadline')))) {
          this.set('model.job.deadline', startdate);
        }
      }
    },
    _createContainsObject: function _createContainsObject(string) {
      if (!string) {
        return false;
      }
      string = string.toLowerCase();
      return {
        email: string.match(/([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi),
        summerjobs: this._wordMatch(string, 'sumar'),
        parttime: this._wordMatch(string, 'hlutast')
      };
    },
    _wordMatch: function _wordMatch(string, word) {
      return string.match(new RegExp(word, 'g'));
    },
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.options = {
        menubar: false,
        toolbar: 'bold italic | bullist numlist',
        statusbar: false,
        plugins: ['lists', 'autoresize'],
        autoresize_bottom_margin: 0,
        autoresize_min_height: 645,
        autoresize_overflow_padding: 10,
        paste_as_text: true,
        content_style: '.mce-content-body {padding-top: 30px;font-family:"source-sans-pro",sans-serif;font-size:16px;}'
        // language: 'is_IS',
      };

      this.store.query('adtype', {}).then(function (data) {
        _this.set('adtypes', data.sortBy('name'));
        if (!_this.get('model.job.adtype.id')) {
          _this.set('model.job.adtype', data.findBy('id', '2'));
        }
      });
      this.set('disableDeadlineField', !this.isPhantom && this.model.job.isActive && !this.model.job.nodeadline);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var startdate = this.get('model.job.startdate');
      if ((0, _moment.default)().isAfter((0, _moment.default)(startdate), 'day')) {
        this.set('model.job.startdate', this.today);
        this.set('model.job.deadline');
        this.set('model.job.nodeadline', true);
      }

      // if (this.get('model.job.id')) {
      //   if (!this.get('model.job.nodeadline')) {
      //     if (this.isPhantom) {
      //       this.set('deadline_min', startdate);
      //     } else {
      //       this.set('deadline_min', this.get('model.job.deadline'));
      //     }
      //   } else {
      //     this.set('deadline_min', startdate);
      //   }
      //
      //   this.set('deadline_max', moment(startdate).add(6, 'weeks').toDate());
      //
      //   if (this.get('model.job.isDraft')) {
      //     this.set('started_min', this.today);
      //     if (moment().isAfter(moment(startdate), 'day')) {
      //       this.set('model.job.startdate', this.today);
      //       this.set('model.job.deadline');
      //       this.set('model.job.nodeadline', true);
      //     }
      //   } else {
      //     this.set('started_min', startdate);
      //   }
      //   this.set('started_max', this.deadline_max);
      // } else {
      //   this.set('started_min', this.today);
      //   this.set('deadline_min', this.today);
      //   this.set('deadline_max', moment().add(6, 'weeks').toDate());
      //   this.set('model.job.startdate', this.today)
      // }

      // this._checkDeadlines();
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;
      this._super.apply(this, arguments);
      (0, _jquery.default)(document).unbind('click').click(function (e) {
        var t = (0, _jquery.default)(e.target);
        if (t.parents('.deadline-cont').first().length == 0 && t.parents('.drop-pikaday').first().length == 0) {
          _this2.set('dropPikaday');
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)(document).unbind('click');
      this._super.apply(this, arguments);
    },
    actions: {
      checkBrand: function checkBrand(_ref) {
        var selected = _ref.selected;
        if (selected) {
          if (selected.get('address.id')) {
            this.set('model.job.address', this.get('model.job.brand.brandAddress'));
          }
          if (this.get('model.job.brand.divisions.length') === 1) {
            this.set('model.job.division', this.get('model.job.brand.divisions.firstObject'));
          }
        }
      },
      checkDivision: function checkDivision(_ref2) {
        var selected = _ref2.selected;
        if (selected && selected.get('address.id')) {
          this.set('model.job.address', this.get('model.job.division.divisionAddress'));
        }
      },
      searchAddress: function searchAddress(search) {
        return this.store.query('address', {
          search: search
        });
      },
      setStarted: function setStarted(startdate) {
        if (startdate) {
          this.set('model.job.startdate', startdate);
          // this._checkDeadlines();
        }
      },
      setDeadline: function setDeadline(deadline) {
        if (deadline) {
          this.set('model.job.deadline', deadline);
          this.set('model.job.nodeadline', false);
        }
        this.set('dropPikaday');
      },
      setNoDeadline: function setNoDeadline() {
        this.set('model.job.deadline');
        this.set('model.job.nodeadline', true);
        this.set('dropPikaday');
      },
      openDTool: function openDTool() {
        if (this.get('model.job.isActive')) {
          (0, _jquery.default)('.dtool').click();
        }
      }
    }
  });
  _exports.default = _default;
});