define("admin/compiled/utils", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.uuid = _exports.trunc = _exports.limitWords = _exports.fromNow = _exports.formatNumber = _exports.downloadFile = _exports.copyTextToClipboard = void 0;
  var fromNow = function fromNow(date, intl) {
    if (date) {
      var end = (0, _moment.default)(date);
      var duration = Math.round(_moment.default.duration((0, _moment.default)().diff(end)).asSeconds());
      if (duration < 120) {
        return '1 ' + intl.t('time.minutes');
      }
      if (duration < 60 * 60) {
        return Math.round(duration / 60) + ' ' + intl.t('time.minutes');
      }
      if (duration < 3600 * 24) {
        return Math.round(duration / 3600) + ' ' + intl.t('time.hours');
      }
      if (duration < 3600 * 24 * 30) {
        return Math.round(duration / (3600 * 24)) + ' ' + intl.t('time.days');
      }
      if (duration < 3600 * 24 * 30 * 12) {
        return Math.round(duration / (3600 * 24 * 30)) + ' ' + intl.t('time.months');
      }
      return Math.round(duration / (3600 * 24 * 30 * 12)) + ' ' + intl.t('time.years');
    }
    return '';
  };
  _exports.fromNow = fromNow;
  var uuid = function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // eslint-disable-next-line
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : r & 0x3 | 0x8;
      return v.toString(16);
    });
  };
  _exports.uuid = uuid;
  var trunc = function trunc(string, length) {
    if (!length) {
      length = 20;
    }
    if (string) {
      string = string.trim();
    }
    return string && string.trim().length > length ? "".concat(string.substring(0, length), "...") : string;
  };
  _exports.trunc = trunc;
  var limitWords = function limitWords(string, number) {
    if (!string) {
      return '';
    }
    return string.trim().split(' ').slice(0, number).join(' ');
  };
  _exports.limitWords = limitWords;
  var formatNumber = function formatNumber(number, symbol) {
    if (!number) {
      return number;
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, symbol);
  };
  _exports.formatNumber = formatNumber;
  var copyTextToClipboard = function copyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Copying text command was ' + msg);
    } catch (err) {
      console.log('Oops, unable to copy');
    }
    document.body.removeChild(textArea);
  };
  _exports.copyTextToClipboard = copyTextToClipboard;
  var downloadFile = function downloadFile(base64Prefix, contentType, fileName, downloadFunction) {
    // @ts-ignore
    var download = window.download;
    return new Promise(function (resolve, reject) {
      downloadFunction().then(function (data) {
        try {
          var file = data.value;
          download("".concat(base64Prefix, ";base64,").concat(file), fileName, contentType);
          resolve(true);
        } catch (err) {
          reject(err.message);
        }
      }).catch(function (err) {
        return reject(err.message);
      });
    });
  };
  _exports.downloadFile = downloadFile;
});