define("admin/adapters/customer-contact", ["exports", "admin/adapters/rest-superadmin"], function (_exports, _restSuperadmin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _restSuperadmin.default.extend({
    pathForType: function pathForType() {
      return this._super.apply(this, arguments).replace('customer', 'clients/');
    }
  });
  _exports.default = _default;
});