define("admin/components/sidebar/sidebar-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['sidebar-container']
  });
  _exports.default = _default;
});