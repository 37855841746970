define("admin/templates/client/create-job/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "d/GSrZZu",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"protect-route\",[],[[],[]],null],[2,\"\\n\"],[8,\"crad/crad-top-bar\",[],[[\"@title\"],[[30,[36,0],[\"client.jobs.form.edit\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"client.edit-job.description\",[32,1,[\"job\",\"id\"]]]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"crad.close\"],null]],[2,\" \"],[10,\"span\"],[14,0,\"close-btn\"],[12],[13]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"top-section\"],[12],[2,\"\\n    \"],[8,\"crad/select-ad-type\",[],[[\"@jobId\",\"@setUseAlfrello\",\"@useAlfrello\",\"@adTypes\",\"@isEditing\",\"@isSavingJob\",\"@generalApplication\",\"@toggleGeneralApplication\",\"@showGeneralApplication\",\"@disableGeneralApplication\",\"@saveJob\",\"@title\"],[[32,1,[\"job\",\"id\"]],[30,[36,2],[[30,[36,1],[[32,1,[\"job\",\"useAlfrello\"]]],null]],null],[32,1,[\"job\",\"useAlfrello\"]],[32,1,[\"adTypes\"]],true,[34,3,[\"isRunning\"]],[32,1,[\"job\",\"generalApplication\"]],[30,[36,2],[[30,[36,1],[[32,1,[\"job\",\"generalApplication\"]]],null]],null],[34,4,[\"user\",\"phantom\"]],[30,[36,5],[[32,1,[\"job\",\"isDraft\"]]],null],[30,[36,6],[[35,3]],null],[30,[36,0],[\"crad.new_job.edit\"],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"mut\",\"fn\",\"saveJob\",\"currentSession\",\"not\",\"perform\"]}",
    "meta": {
      "moduleName": "admin/templates/client/create-job/edit.hbs"
    }
  });
  _exports.default = _default;
});