define("admin/compiled/alfrello-column-switcher/AlfrelloColumnSwitcher", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AlfrelloColumnSwitcher = void 0;
  var AlfrelloColumnSwitcher = function AlfrelloColumnSwitcher(_a) {
    var columns = _a.columns,
      column = _a.column,
      onSwitch = _a.onSwitch,
      setShowColumnList = _a.setShowColumnList,
      showColumnList = _a.showColumnList;
    return _react.default.createElement("div", {
      style: {
        display: 'inline-block'
      }
    }, _react.default.createElement("div", {
      className: 'alfrello-column-switcher--icon',
      onClick: function onClick() {
        return setShowColumnList(!showColumnList);
      }
    }), _react.default.createElement("div", {
      className: "alfrello-column-switcher--dropdown ".concat(showColumnList ? 'active' : '')
    }, columns.map(function (c) {
      return _react.default.createElement("div", {
        key: c.id,
        className: "alfrello-column-switcher--item ".concat(column.id === c.id ? 'active' : ''),
        onClick: function onClick() {
          return onSwitch(c.id);
        }
      }, _react.default.createElement("div", {
        className: 'alfrello-column-switcher--circle',
        style: {
          backgroundColor: c.color
        }
      }), _react.default.createElement("div", {
        className: 'alfrello-column-switcher--title'
      }, c.title));
    })));
  };
  _exports.AlfrelloColumnSwitcher = AlfrelloColumnSwitcher;
});