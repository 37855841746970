define("admin/templates/components/api/new-key-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Hat6cwGt",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@overlayClass\"],[[30,[36,0],[[32,0],\"close\"],null],\"modal-popup previews\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"default-dialog key-dialog\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"Nýr veflykill\"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"edit-form\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-24\"],[12],[2,\"\\n          \"],[8,\"forms/input-field\",[],[[\"@value\",\"@length\",\"@placeholder\",\"@label\"],[[34,1],70,\"Heiti veflykils\",\"Heiti veflykils\"]],null],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn gray-brd inline\"],[4,[38,0],[[32,0],\"close\"],null],[12],[1,[30,[36,2],[\"string.cancel\"],null]],[13],[2,\"\\n        \"],[10,\"span\"],[15,0,[30,[36,3],[[35,1],\"disabled\"],null]],[12],[2,\"\\n          \"],[11,\"button\"],[24,0,\"btn green inline\"],[4,[38,0],[[32,0],\"save\"],null],[12],[1,[30,[36,2],[\"string.continue\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"credentialName\",\"t\",\"unless\"]}",
    "meta": {
      "moduleName": "admin/templates/components/api/new-key-modal.hbs"
    }
  });
  _exports.default = _default;
});