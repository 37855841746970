define("admin/templates/components/question-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "R+4babxP",
    "block": "{\"symbols\":[\"option\",\"index\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-24\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"lc\"],[12],[1,[30,[36,0],[\"questions.description\"],null]],[13],[2,\"\\n    \"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[10,\"br\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3,[\"options\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"col-md-8 removable\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[1,[30,[36,0],[\"questions.option\"],null]],[2,\" \"],[1,[30,[36,1],[[32,2]],null]],[13],[2,\"\\n      \"],[11,\"span\"],[24,0,\"remove\"],[4,[38,2],[[32,0],\"removeOption\",[32,1]],null],[12],[1,[30,[36,0],[\"string.remove\"],null]],[13],[2,\"\\n      \"],[8,\"input\",[],[[\"@value\"],[[32,1,[\"option\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"new-option\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn add\"],[24,4,\"button\"],[4,[38,2],[[32,0],\"addOption\",[35,6]],null],[12],[1,[30,[36,0],[\"string.add\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[18,3,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"inc\",\"action\",\"model\",\"-track-array\",\"each\",\"newOption\"]}",
    "meta": {
      "moduleName": "admin/templates/components/question-options.hbs"
    }
  });
  _exports.default = _default;
});