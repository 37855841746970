define("admin/components/ui/card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="ui-card">
    <div class="ui-card--title">
      {{#if @tooltip}}
        <span class="help-icon">
          <EmberTooltip @side="right">{{{@tooltip}}}</EmberTooltip>
        </span>
      {{/if}}
      <span class="upper">{{@title}}</span>
    </div>
    <div class="ui-card--content">
      {{yield}}
    </div>
  </div>
  
  */
  {
    "id": "w/txU+kF",
    "block": "{\"symbols\":[\"@tooltip\",\"@title\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"ui-card\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui-card--title\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"help-icon\"],[12],[2,\"\\n        \"],[8,\"ember-tooltip\",[],[[\"@side\"],[\"right\"]],[[\"default\"],[{\"statements\":[[2,[32,1]]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"span\"],[14,0,\"upper\"],[12],[1,[32,2]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui-card--content\"],[12],[2,\"\\n    \"],[18,3,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "admin/components/ui/card.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});