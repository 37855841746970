define("admin/serializers/feed", ["exports", "admin/serializers/rest-serializer"], function (_exports, _restSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _restSerializer.default.extend({
    attrs: {
      interview: {
        serialize: false,
        deserialize: 'records'
      },
      videoInterview: {
        serialize: false,
        deserialize: 'records'
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      if (payload.feeds) {
        payload.feeds.forEach(function (feed) {
          if (feed.videoInterview) {
            var videoInterview = feed.videoInterview;
            if (videoInterview.answers) {
              delete videoInterview.answers;
            }
          }
        });
      }
      return this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});