define("admin/compiled/ui/filter-dropdown/FilterDropdown", ["exports", "react", "admin/compiled/ui/filter-container/FilterContainer", "admin/compiled/utils/useOutsideClick"], function (_exports, _react, _FilterContainer, _useOutsideClick) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FilterDropdown = void 0;
  var JobsFilterItem = function JobsFilterItem(_a) {
    var item = _a.item,
      isActive = _a.isActive,
      _onClick = _a.onClick;
    return _react.default.createElement("div", {
      className: "ui-filter-dropdown--item ".concat(isActive ? 'active' : ''),
      onClick: function onClick() {
        if (isActive) {
          return;
        }
        _onClick();
      }
    }, item.name);
  };
  var FilterDropdown = function FilterDropdown(_a) {
    var selected = _a.selected,
      items = _a.items,
      placeholder = _a.placeholder,
      onSelect = _a.onSelect,
      isActive = _a.isActive,
      children = _a.children;
    var _b = (0, _react.useState)(false),
      open = _b[0],
      setOpen = _b[1];
    var ref = (0, _react.useRef)(null);
    (0, _useOutsideClick.default)(ref, function () {
      return setOpen(false);
    });
    return _react.default.createElement("div", {
      className: 'ui-filter-dropdown',
      ref: ref
    }, _react.default.createElement(_FilterContainer.FilterContainer, {
      icon: open ? 'arrow-up' : 'arrow-down',
      isActive: !!(selected === null || selected === void 0 ? void 0 : selected.name) || isActive,
      onClick: function onClick() {
        return setOpen(!open);
      }
    }, (selected === null || selected === void 0 ? void 0 : selected.name) || placeholder), open && !!items.length && _react.default.createElement("div", {
      className: "ui-filter-dropdown--items"
    }, _react.default.createElement(JobsFilterItem, {
      item: {
        name: placeholder,
        val: null
      },
      isActive: !selected,
      onClick: function onClick() {
        onSelect(null);
        setOpen(false);
      }
    }), items.map(function (item) {
      return _react.default.createElement(JobsFilterItem, {
        key: item.name,
        item: item,
        onClick: function onClick() {
          onSelect(item);
          setOpen(false);
        },
        isActive: (selected === null || selected === void 0 ? void 0 : selected.name) === item.name
      });
    })), open && children);
  };
  _exports.FilterDropdown = FilterDropdown;
});