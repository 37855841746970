define("admin/adapters/jobapplication", ["exports", "admin/adapters/rest-clientweb", "admin/mixins/job-application-namespace"], function (_exports, _restClientweb, _jobApplicationNamespace) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _restClientweb.default.extend(_jobApplicationNamespace.default, {
    pathForType: function pathForType() {
      return 'applications';
    }
  });
  _exports.default = _default;
});