define("admin/templates/components/crad/textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "yrdEyu07",
    "block": "{\"symbols\":[\"@title\",\"@tooltip\",\"@required\",\"@value\"],\"statements\":[[8,\"ui/icon-section\",[],[[\"@title\",\"@tooltip\",\"@required\",\"@icon\"],[[32,1],[32,2],[32,3],[30,[36,1],[[35,0],\"checkbox-default\",\"checkbox-complete\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"tinymce-editor\",[],[[\"@options\",\"@value\",\"@onValueChanged\"],[[34,2],[32,4],[30,[36,4],[[32,0],[30,[36,3],[[32,4]],null]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\",\"unless\",\"options\",\"mut\",\"action\"]}",
    "meta": {
      "moduleName": "admin/templates/components/crad/textarea.hbs"
    }
  });
  _exports.default = _default;
});