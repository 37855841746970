define("admin/models/social", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    facebook: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    twitter: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    instagram: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    linkedin: (0, _model.attr)('string', {
      defaultValue: ''
    })
    // youtube: attr('string', { defaultValue: '' }),
  });
  _exports.default = _default;
});