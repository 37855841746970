define("admin/components/settings/english-questions-modal", ["exports", "admin/components/edit-modal"], function (_exports, _editModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _editModal.default.extend({
    isValid: Ember.computed('model.enquestion', 'enoptions.@each.value', function () {
      var valueCheck = true;
      this.enoptions.forEach(function (opt) {
        if (!opt.value) {
          valueCheck = false;
        }
      });
      return this.get('model.enquestion') && valueCheck;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.enoptions = [];
      this.values = [];
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      this.enoptions.clear();
      this.values.clear();
      this.get('model.options_parsed').forEach(function (_, index) {
        _this.enoptions.addObject({
          value: _this.get('model.enoptions.length') ? _this.get('model.enoptions_parsed')[index] : ''
        });
      });
    },
    actions: {
      save: function save() {
        var _this2 = this;
        this.enoptions.forEach(function (opt) {
          _this2.values.addObject(opt.value);
        });
        this.set('model.enoptions', JSON.stringify(this.values));
        this.toggle();
      }
    }
  });
  _exports.default = _default;
});