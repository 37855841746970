define("admin/services/api-admin", ["exports", "admin/services/api-manager", "admin/config/environment"], function (_exports, _apiManager, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SUPERADMINSPACE = _environment.default.SUPERADMINSPACE;
  var _default = _apiManager.default.extend({
    namespace: SUPERADMINSPACE
  });
  _exports.default = _default;
});