define("admin/compiled/spotlight/Spotlight", ["exports", "@alfred-is/alfred-design-system", "@hookform/resolvers/zod", "classnames", "date-fns", "moment", "react", "react-hook-form", "react-infinite-scroll-component", "react-tooltip", "admin/compiled/form/Form", "admin/compiled/form/FormSelect", "admin/compiled/hooks/useBrands", "admin/compiled/hooks/useCreateSpotlight", "admin/compiled/hooks/useSpotlights", "admin/compiled/schemas", "admin/compiled/utils/emberContext", "admin/compiled/spotlight/section-spacer/section-spacer", "admin/compiled/spotlight/spotlight-header/spotlight-header", "admin/compiled/spotlight/spotlight-image/spotlight-image", "admin/compiled/spotlight/spotlight-list-item/spotlight-list-item", "admin/compiled/spotlight/spotlight-purchase-button/spotlight-purchase-button", "admin/compiled/spotlight/spotlight-purchase/spotlight-purchase"], function (_exports, _alfredDesignSystem, _zod, _classnames, _dateFns, _moment, _react, _reactHookForm, _reactInfiniteScrollComponent, _reactTooltip, _Form, _FormSelect, _useBrands, _useCreateSpotlight, _useSpotlights, _schemas, _emberContext, _sectionSpacer, _spotlightHeader, _spotlightImage, _spotlightListItem, _spotlightPurchaseButton, _spotlightPurchase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Spotlight = void 0;
  var __awaiter = void 0 && (void 0).__awaiter || function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P ? value : new P(function (resolve) {
        resolve(value);
      });
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }
      function rejected(value) {
        try {
          step(generator["throw"](value));
        } catch (e) {
          reject(e);
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };
  var __generator = void 0 && (void 0).__generator || function (thisArg, body) {
    var _ = {
        label: 0,
        sent: function sent() {
          if (t[0] & 1) throw t[1];
          return t[1];
        },
        trys: [],
        ops: []
      },
      f,
      y,
      t,
      g;
    return g = {
      next: verb(0),
      "throw": verb(1),
      "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
      return this;
    }), g;
    function verb(n) {
      return function (v) {
        return step([n, v]);
      };
    }
    function step(op) {
      if (f) throw new TypeError("Generator is already executing.");
      while (g && (g = 0, op[0] && (_ = 0)), _) try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];
        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;
          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };
          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;
          case 7:
            op = _.ops.pop();
            _.trys.pop();
            continue;
          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }
            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }
            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }
            if (t && _.label < t[2]) {
              _.label = t[2];
              _.ops.push(op);
              break;
            }
            if (t[2]) _.ops.pop();
            _.trys.pop();
            continue;
        }
        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
      if (op[0] & 5) throw op[1];
      return {
        value: op[0] ? op[1] : void 0,
        done: true
      };
    }
  };
  var Spotlight = function Spotlight() {
    var _a, _b;
    var maxDays = 7;
    var form = (0, _reactHookForm.useForm)({
      resolver: (0, _zod.zodResolver)(_schemas.spotlightFormSchema)
    });
    var watch = form.watch;
    var t = (0, _alfredDesignSystem.useNxTranslation)().t;
    var _c = (0, _react.useContext)(_emberContext.default),
      config = _c.config,
      transitionTo = _c.transitionTo,
      intl = _c.intl,
      notifications = _c.notifications;
    var _d = (0, _useBrands.useBrands)(),
      brands = _d.data,
      brandsLoading = _d.isLoading;
    var _e = (0, _useCreateSpotlight.useCreateSpotlight)(),
      mutate = _e.mutate,
      isSubmitting = _e.isLoading,
      isSuccess = _e.isSuccess;
    var _f = (0, _useSpotlights.useSpotlights)(),
      isLoading = _f.isLoading,
      pagedData = _f.data,
      fetchNextPage = _f.fetchNextPage;
    (0, _react.useEffect)(function () {
      if (isSuccess) {
        notifications.success(t('spotlight.success'));
        form.reset();
      }
    }, [isSuccess]);
    var spotlights = (pagedData === null || pagedData === void 0 ? void 0 : pagedData.pages.flatMap(function (p) {
      return p.spotlights;
    })) || [];
    if (brandsLoading || isLoading) {
      return _react.default.createElement("div", {
        className: "screen-loader"
      }, _react.default.createElement("div", {
        className: "center"
      }, _react.default.createElement("img", {
        src: "/img/alfred-icon.png",
        alt: ""
      })));
    }
    var spotlightsTotalCount = ((_b = (_a = pagedData === null || pagedData === void 0 ? void 0 : pagedData.pages) === null || _a === void 0 ? void 0 : _a[0].items) === null || _b === void 0 ? void 0 : _b.length) || 0;
    var spotlightStatus = {
      ACTIVE: t('spotlight.active'),
      PENDING: t('spotlight.pending'),
      FINISHED: t('spotlight.finished')
    };
    var newAdmin = function newAdmin(page, target) {
      if (target === void 0) {
        target = '_self';
      }
      window.open("".concat(config.REGION.links.new_site).concat(intl.primaryLocale, "/").concat(page), target);
    };
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_spotlightHeader.default, {
      logoOnClick: function logoOnClick() {
        return newAdmin('jobs');
      },
      onClose: function onClose() {
        return newAdmin('jobs');
      },
      title: t('spotlight.header_title')
    }), _react.default.createElement("div", {
      className: 'spotlight--container'
    }, _react.default.createElement("div", {
      className: 'tw-container tw-mx-auto'
    }, _react.default.createElement(_Form.Form, {
      form: form,
      onSubmit: function onSubmit(_a) {
        var brand = _a.brand,
          image = _a.image;
        return __awaiter(void 0, void 0, void 0, function () {
          return __generator(this, function (_b) {
            mutate({
              brandId: brand,
              image: image.value,
              start: new Date(),
              end: (0, _dateFns.addDays)(new Date(), maxDays)
            });
            return [2 /*return*/];
          });
        });
      }
    }, _react.default.createElement(_spotlightPurchase.SpotlightPurchaseContainer, null, _react.default.createElement(_spotlightPurchase.SpotlightPurchaseTop, {
      title: t('spotlight.title'),
      description: t('spotlight.description'),
      scheduleDays: maxDays
    }, _react.default.createElement(_spotlightImage.SpotlightImage, {
      imageLabel: _react.default.createElement("div", {
        className: 'tw-text-center'
      }, _react.default.createElement("div", null, t('crad.products.image.title')), _react.default.createElement("div", null, t('crad.products.image.min_size'), " 970x250px.")),
      setFile: function setFile(file) {
        form.setValue('image', file);
      },
      file: watch('image')
    })), _react.default.createElement(_spotlightPurchase.SpotlightPurchaseBottom, null, _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-flex', 'tw-flex-col', 'sm:tw-flex-row', 'tw-flex-1', 'tw-gap-4')
    }, _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-flex-1')
    }, _react.default.createElement(_FormSelect.FormSelect, {
      name: 'brand',
      placeholder: t('spotlight.brand_placeholder'),
      isSearchable: false,
      selectTheme: _alfredDesignSystem.Theme.Blue,
      options: brands === null || brands === void 0 ? void 0 : brands.map(function (b) {
        return {
          label: b.name,
          value: b,
          img: b.logo
        };
      })
    })), _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-flex-1'),
      "data-for": 'missing-image',
      "data-tip": true
    }, _react.default.createElement(_spotlightPurchaseButton.SpotlightPurchaseButton, {
      disabled: isSubmitting || !watch('image')
    }, isLoading ? t('spotlight.please_wait') : _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("strong", null, t('spotlight.purchase_spotlight')), " -", ' ', (0, _alfredDesignSystem.formatNumber)(config.REGION.spotlightPrice, intl.primaryLocale), ' ', t('spotlight.price_suffix'))), !watch('image') && _react.default.createElement(_reactTooltip.default, {
      id: 'missing-image'
    }, t('spotlight.missing_image'))))))), !!(spotlights === null || spotlights === void 0 ? void 0 : spotlights.length) && _react.default.createElement(_sectionSpacer.default, {
      title: t('spotlight.purchase_history')
    }), _react.default.createElement(_reactInfiniteScrollComponent.default, {
      scrollThreshold: 0.95,
      dataLength: spotlights.length,
      next: fetchNextPage,
      hasMore: spotlights.length < spotlightsTotalCount,
      loader: null
    }, spotlights === null || spotlights === void 0 ? void 0 : spotlights.map(function (spotlight) {
      var _a, _b;
      return _react.default.createElement("div", {
        key: spotlight.id,
        className: 'spotlight--item'
      }, _react.default.createElement(_spotlightListItem.default, {
        status: spotlightStatus[spotlight.status],
        variant: spotlight.status === 'ACTIVE' ? _spotlightListItem.SpotlightListItemVariant.GREEN : _spotlightListItem.SpotlightListItemVariant.PRIMARY,
        name: (_a = spotlight.brand) === null || _a === void 0 ? void 0 : _a.name,
        img: (_b = spotlight.brand) === null || _b === void 0 ? void 0 : _b.logo,
        items: [{
          icon: _alfredDesignSystem.AlfredIconT.CalendarClock,
          text: "".concat((0, _moment.default)(spotlight.fromDate).format('DD. MMM'), " - ").concat((0, _moment.default)(spotlight.toDate).format('DD. MMM')),
          showInMobile: true
        }, {
          text: "".concat((0, _alfredDesignSystem.formatNumber)(Math.round(spotlight.price), intl.primaryLocale), " ").concat(t('spotlight.price_suffix'))
        }]
      }));
    })))));
  };
  _exports.Spotlight = Spotlight;
});