define("admin/templates/components/crad/crad-jobtypes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "faEZ1OtF",
    "block": "{\"symbols\":[\"jobtype\",\"@selected\",\"@title\",\"@tooltip\"],\"statements\":[[8,\"ui/icon-section\",[],[[\"@title\",\"@tooltip\",\"@required\",\"@icon\",\"@data-test\"],[[32,3],[32,4],true,[30,[36,3],[[35,4],\"checkbox-complete\",\"checkbox-default\"],null],\"jobtypes-container\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"pre-wrap\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"crad/crad-tag-container\",[],[[\"@onClick\",\"@active\"],[[30,[36,1],[[32,0],\"setJobtype\",[32,1]],null],[30,[36,2],[[32,2],[32,1]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[32,1,[\"new\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"new\"],[12],[1,[30,[36,0],[\"crad.products.new\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"],[6,[37,3],[[32,1,[\"info\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"tooltipstered\"],[12],[2,\"\\n            \"],[8,\"ember-tooltip\",[],[[\"@side\"],[\"top\"]],[[\"default\"],[{\"statements\":[[2,\"\\n              \"],[2,[32,1,[\"info\"]]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"clearfix\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"array-contains\",\"if\",\"selected\",\"jobtypes\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/components/crad/crad-jobtypes.hbs"
    }
  });
  _exports.default = _default;
});