define("admin/routes/client/create-job/index", ["exports", "admin/mixins/model-loader", "admin/mixins/with-hidden-nav"], function (_exports, _modelLoader, _withHiddenNav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_withHiddenNav.WithHiddenNav, _modelLoader.default, {
    model: function model() {
      return Ember.RSVP.hash({
        job: this.store.createRecord('job', {
          useAlfrello: true
        }),
        adTypes: this.store.query('adtype', {})
      });
    }
  });
  _exports.default = _default;
});