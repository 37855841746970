define("admin/routes/client/settings/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      if (!this.get('currentSession.user.isManager')) {
        transition.abort();
        this.transitionTo('index');
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        client: this.currentSession.loadClient(),
        users: this.store.query('user', {})
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    },
    actions: {
      save: function save(client) {
        var _this = this;
        this.controller.set('loading', true);
        client.save().then(function () {
          _this.notifications.success(_this.intl.t('string.settings_saved'));
        }).finally(function () {
          return _this.controller.set('loading');
        });
      }
    }
  });
  _exports.default = _default;
});