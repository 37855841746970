define("admin/components/alfrello/board-actions-separator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span class="board-actions-separator"></span>
  
  */
  {
    "id": "neit0VsZ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"board-actions-separator\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/components/alfrello/board-actions-separator.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});