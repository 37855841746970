define("admin/models/invoice", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    contact: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    type: (0, _model.attr)('string', {
      defaultValue: 'ONE_INVOICE'
    }),
    status: (0, _model.attr)('string'),
    invoicestatus: (0, _model.attr)('string'),
    invoiceTitle: (0, _model.attr)('string'),
    invoiceDetail: (0, _model.attr)('string')
  });
  _exports.default = _default;
});