define("admin/components/client-blacklist-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    actions: {
      close: function close() {
        this.toggle();
      },
      openInvoices: function openInvoices() {
        this.toggle();
        this.router.transitionTo('client.settings.bills');
      }
    }
  });
  _exports.default = _default;
});