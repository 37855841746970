define("admin/components/crad/crad-top-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="crad-top-bar">
    <div class="container">
      <div class="col-lg-22 col-lg-offset-1 crad-top-bar--container">
        <div class="crad-top-bar--title">
          {{@title}}
          {{#if (and @linkRoute @linkTitle)}}
            <div class="crad-top-bar--link"><LinkTo @route={{@linkRoute}} @model={{@linkModel}}>{{@linkTitle}}</LinkTo></div>
          {{/if}}
        </div>
        <div class="crad-top-bar--logo">
          <img src={{region-asset "logo/small.svg"}} alt="" />
        </div>
        <div class="crad-top-bar--actions">
          {{yield}}
        </div>
      </div>
    </div>
  </div>
  
  */
  {
    "id": "MiCWahnc",
    "block": "{\"symbols\":[\"@linkRoute\",\"@linkModel\",\"@linkTitle\",\"@title\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"crad-top-bar\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-lg-22 col-lg-offset-1 crad-top-bar--container\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"crad-top-bar--title\"],[12],[2,\"\\n        \"],[1,[32,4]],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,1],[32,3]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"crad-top-bar--link\"],[12],[8,\"link-to\",[],[[\"@route\",\"@model\"],[[32,1],[32,2]]],[[\"default\"],[{\"statements\":[[1,[32,3]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"crad-top-bar--logo\"],[12],[2,\"\\n        \"],[10,\"img\"],[15,\"src\",[30,[36,2],[\"logo/small.svg\"],null]],[14,\"alt\",\"\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"crad-top-bar--actions\"],[12],[2,\"\\n        \"],[18,5,null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"and\",\"if\",\"region-asset\"]}",
    "meta": {
      "moduleName": "admin/components/crad/crad-top-bar.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});