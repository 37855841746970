define("admin/components/applications/message-all-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    apiClient: Ember.inject.service(),
    actions: {
      close: function close() {
        this.toggle();
      },
      sendMessage: function sendMessage() {
        var _this = this;
        this.set('loading', true);
        this.apiClient.post("/jobs/".concat(this.job.id, "/applications/message"), {
          jobId: this.job.id,
          applicationIds: this.applicationIds,
          message: this.message
        }, false).then(function () {
          _this.toggle();
          _this.notifications.success(_this.intl.t('string.message_sent'));
        }).catch(function () {
          _this.notifications.error(_this.intl.t('string.message_sending_failed'));
        }).finally(function () {
          return _this.set('loading');
        });
      }
    }
  });
  _exports.default = _default;
});