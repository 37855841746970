define("admin/templates/client/users-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3QNAPJ8u",
    "block": "{\"symbols\":[],\"statements\":[[8,\"page-header-react/page-header\",[],[[\"@title\",\"@actionName\",\"@actionRoute\"],[[30,[36,0],[\"menu.users\"],null],[30,[36,0],[\"menu.new_user\"],null],\"client.users.new\"]],null],[2,\"\\n\"],[10,\"div\"],[14,0,\"screen-loader\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"center\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/img/alfred-icon.png\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "admin/templates/client/users-loading.hbs"
    }
  });
  _exports.default = _default;
});