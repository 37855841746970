define("admin/routes/client/brands/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    infinity: Ember.inject.service(),
    queryParams: {
      search: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var search = _ref.search;
      return this.store.query('brand', {
        search: search,
        status: 'ACTIVE'
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var brandsCtrl = this.controllerFor('client.brands');
      brandsCtrl.set('title', brandsCtrl.get('name'));
      brandsCtrl.set('showBtn', true);
    },
    actions: {
      clearSearch: function clearSearch() {
        this.controller.set('search');
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});