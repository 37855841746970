define("admin/components/api/new-key-modal", ["exports", "admin/components/edit-modal"], function (_exports, _editModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _editModal.default.extend({
    actions: {
      save: function save() {
        this.saveCredential();
      }
    }
  });
  _exports.default = _default;
});