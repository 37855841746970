define("admin/routes/admin/customers/registrations", ["exports", "admin/mixins/model-loader"], function (_exports, _modelLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_modelLoader.default, {
    infinity: Ember.inject.service(),
    limit: 25,
    queryParams: {
      status: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var status = _ref.status;
      return this.infinity.model('customer', {
        _new: true,
        statuses: status,
        perPageParam: 'limit',
        startingPage: 0
      });
    }
  });
  _exports.default = _default;
});