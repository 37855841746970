define("admin/routes/client/edit-job/deadline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    activate: function activate() {
      this._super.apply(this, arguments);
      window.scrollTo(0, 0);
    },
    model: function model() {
      var job = this.modelFor('client.edit-job').job;
      return Ember.RSVP.hash({
        autoCloseOptions: job.getAutoCloseOptions().then(function (data) {
          return data.autoCloseOptions;
        }),
        job: job,
        initialNoDeadline: !!job.nodeadline
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      if (!model.job.autoCloseOption) {
        model.job.autoCloseOption = model.autoCloseOptions.lastObject;
      }
    }
  });
  _exports.default = _default;
});