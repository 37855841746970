define("admin/serializers/client", ["exports", "admin/serializers/rest-serializer"], function (_exports, _restSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _restSerializer.default.extend({
    attrs: {
      address: {
        serialize: 'records',
        deserialize: 'records'
      }
    },
    extractId: function extractId() {
      return 'me';
    }
  });
  _exports.default = _default;
});