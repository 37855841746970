define("admin/compiled/types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ModalType = _exports.ModalActionType = void 0;
  var ModalActionType;
  _exports.ModalActionType = ModalActionType;
  (function (ModalActionType) {
    ModalActionType["SHOW_MODAL"] = "SHOW_MODAL";
    ModalActionType["HIDE_MODAL"] = "HIDE_MODAL";
  })(ModalActionType || (_exports.ModalActionType = ModalActionType = {}));
  var ModalType;
  _exports.ModalType = ModalType;
  (function (ModalType) {
    ModalType["CREATE_GENERAL_APPLICATION"] = "CREATE_GENERAL_APPLICATION";
    ModalType["GA_VISIBILITY"] = "GA_VISIBILITY";
    ModalType["JOBS_LIST_ANNOUNCEMENTS"] = "JOBS_LIST_ANNOUNCEMENTS";
    ModalType["CONFIRMATION_MODAL"] = "CONFIRMATION_MODAL";
    ModalType["JOB_TAGS_MODAL"] = "JOB_TAGS_MODAL";
    ModalType["DOWNLOAD_FILES_MODAL"] = "DOWNLOAD_FILES_MODAL";
  })(ModalType || (_exports.ModalType = ModalType = {}));
});