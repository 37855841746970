define("admin/components/statistics-react/statistics-react", ["exports", "admin/react-component", "admin/config/environment", "admin/compiled/utils/emberContext", "react", "admin/compiled/modal/ModalRoot", "admin/compiled/stats-page/StatsPage", "moment"], function (_exports, _reactComponent, _environment, _emberContext, _react, _ModalRoot, _StatsPage, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  
  */
  {
    "id": "PaKG8ixo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/components/statistics-react/statistics-react.hbs"
    }
  });
  var features = _environment.default.REGION.features;
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _reactComponent.default.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    apiClient: Ember.inject.service(),
    store: Ember.inject.service(),
    gtm: Ember.inject.service(),
    renderComponent: function renderComponent() {
      this.reactRender(_react.default.createElement(_emberContext.EmberProvider, {
        value: {
          apiClient: this.apiClient,
          intl: this.intl,
          moment: _moment.default
        }
      }, _react.default.createElement(_StatsPage.StatsPage, {
        brands: this.brands.toArray()
      })));
    }
  }));
  _exports.default = _default;
});