define("admin/components/brand/brand-award", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="brand-award" id="{{@id}}">
    <Ui::RemovableField @remove={{@remove}}>
      <Ui::IconSection @sortable={{true}} @title={{@title}} @icon="checkbox-complete">
        <div class="brand-award--description">
        {{@description}}
        </div>
      </Ui::IconSection>
      <span class="brand-award--icon" style="background-image: url('{{@icon}}');"></span>
    </Ui::RemovableField>
  </div>
  
  */
  {
    "id": "QMDGnj2E",
    "block": "{\"symbols\":[\"@id\",\"@remove\",\"@title\",\"@description\",\"@icon\"],\"statements\":[[10,\"div\"],[14,0,\"brand-award\"],[15,1,[31,[[32,1]]]],[12],[2,\"\\n  \"],[8,\"ui/removable-field\",[],[[\"@remove\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"ui/icon-section\",[],[[\"@sortable\",\"@title\",\"@icon\"],[true,[32,3],\"checkbox-complete\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"brand-award--description\"],[12],[2,\"\\n      \"],[1,[32,4]],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"span\"],[14,0,\"brand-award--icon\"],[15,5,[31,[\"background-image: url('\",[32,5],\"');\"]]],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/components/brand/brand-award.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});