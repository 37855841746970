define("admin/compiled/spotlight/spotlight-purchase-button/spotlight-purchase-button", ["exports", "classnames", "react"], function (_exports, _classnames, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SpotlightPurchaseButton = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };
  var SpotlightPurchaseButton = function SpotlightPurchaseButton(_a) {
    var children = _a.children,
      disabled = _a.disabled,
      className = _a.className,
      rest = __rest(_a, ["children", "disabled", "className"]);
    return _react.default.createElement("button", __assign({}, rest, {
      className: (0, _classnames.default)(className, 'tw-w-full', 'tw-bg-green-500', 'tw-text-white', 'tw-p-4', 'tw-rounded-lg', 'hover:tw-bg-green-600', {
        'tw-pointer-events-none tw-opacity-60 tw-select-none': !!disabled
      })
    }), children);
  };
  _exports.SpotlightPurchaseButton = SpotlightPurchaseButton;
});