define("admin/adapters/_mockup", ["exports", "admin/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    host: 'https://19e3521e-14f0-4516-bac5-2f6472ebebc7.mock.pstmn.io'
  });
  _exports.default = _default;
});