define("admin/components/jobs/e-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    productKey: 'ELECTRONIC_VERIFICATION',
    apiClient: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['e-sign'],
    selected: Ember.computed('job.verificationRequired', 'choices', function () {
      if (this.choices) {
        if (this.get('job.verificationRequired') === false) {
          return this.choices.filter(function (c) {
            return !c.verificationRequired;
          })[0];
        }
        if (this.get('job.verificationRequired') === true) {
          return this.choices.filter(function (c) {
            return c.verificationRequired;
          })[0];
        }
        return null;
      }
    }),
    isDisabled: Ember.computed('job.{useAlfrello,id,verificationRequired,jobstatus.id}', function () {
      var _this = this;
      if (this.job.id && this.get('job.isActive')) {
        return true;
      }
      if (!this.get('job.useAlfrello')) {
        Ember.run.schedule('afterRender', function () {
          _this.set('job.verificationRequired');
          _this.removeEsign();
        });
        return true;
      }
      return this.get('job.isActive') && this.get('job.verificationRequired') === true;
    }),
    init: function init() {
      var _this2 = this;
      this._super.apply(this, arguments);
      this.apiClient.get("/products?productkey=".concat(this.productKey)).then(function (res) {
        _this2.store.pushPayload('product', res);
        var product = _this2.store.peekRecord('product', res.product.id);
        _this2.set('productpurchase', _this2.store.createRecord('productpurchase', {
          product: product
        }));
        _this2.set('choices', [{
          text: 'Já',
          price: res.product.price,
          verificationRequired: true
        }, {
          text: 'Nei',
          verificationRequired: false
        }]);
      });
    },
    removeEsign: function removeEsign() {
      this.job.productPurchases.removeObject(this.productpurchase);
    },
    addEsign: function addEsign() {
      this.job.productPurchases.pushObject(this.productpurchase);
    },
    actions: {
      choose: function choose(choice) {
        this.set('job.verificationRequired', choice.verificationRequired);
        if (choice.verificationRequired) {
          this.addEsign();
        } else {
          this.removeEsign();
        }
      }
    }
  });
  _exports.default = _default;
});