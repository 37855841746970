define("admin/compiled/e-sign/ESign", ["exports", "classnames", "react", "admin/compiled/hooks/useESign", "admin/compiled/utils/languageContext", "admin/compiled/e-sign/Edit", "admin/compiled/e-sign/Icons", "admin/compiled/e-sign/Previews", "admin/compiled/e-sign/Summary", "admin/compiled/e-sign/types"], function (_exports, _classnames, _react, _useESign, _languageContext, _Edit, _Icons, _Previews, _Summary, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ESign = void 0;
  var ESign = function ESign(_a) {
    var application = _a.application,
      jobId = _a.jobId,
      hireAnswers = _a.hireAnswers,
      systems = _a.systems,
      onClose = _a.onClose,
      intl = _a.intl;
    var _b = (0, _react.useState)(_types.PageType.Reviews),
      page = _b[0],
      setPage = _b[1];
    var _c = (0, _react.useState)({
        contracts: [],
        preview: null,
        filter: _types.FilterType.Templates
      }),
      eState = _c[0],
      setEState = _c[1];
    var _d = (0, _react.useState)(0),
      editContract = _d[0],
      setEditContract = _d[1];
    var _e = (0, _useESign.useContracts)(application.id, jobId),
      data = _e.data,
      isLoading = _e.isLoading;
    var sent = data ? data.contracts : [];
    var removeContract = function removeContract(id) {
      setEState({
        contracts: eState.contracts.filter(function (dd) {
          return dd.id !== id;
        }),
        preview: eState.preview,
        filter: eState.filter
      });
      setEditContract(editContract - 1);
    };
    (0, _react.useEffect)(function () {
      if (sent && sent.length > 0) {
        setEState({
          contracts: eState.contracts,
          preview: eState.preview,
          filter: _types.FilterType.Sent
        });
      }
    }, [sent]);
    if (isLoading) {
      return _react.default.createElement(_react.default.Fragment, null);
    }
    var Page = function Page() {
      if (page === _types.PageType.Edit) {
        return _react.default.createElement(_Edit.Edit, {
          applicationId: application.id,
          jobId: jobId,
          contracts: eState.contracts,
          setPage: setPage,
          editContract: editContract,
          setEditContract: setEditContract
        });
      }
      if (page === _types.PageType.Summary) {
        return _react.default.createElement(_Summary.Summary, {
          application: application,
          jobId: jobId,
          contracts: eState.contracts,
          removeContract: removeContract,
          setPage: setPage,
          onClose: onClose
        });
      }
      return _react.default.createElement(_Previews.Previews, {
        application: application,
        jobId: jobId,
        hireAnswers: hireAnswers,
        systems: systems,
        filter: eState.filter,
        preview: eState.preview,
        contracts: eState.contracts,
        sent: sent,
        setEState: setEState,
        setPage: setPage
      });
    };
    return _react.default.createElement(_languageContext.LanguageProvider, {
      value: intl
    }, _react.default.createElement("div", {
      className: "tw-fixed tw-inset-0 tw-z-[1000]"
    }, _react.default.createElement("div", {
      className: "tw-flex tw-flex-col tw-h-full tw-bg-gray-100 tw-overflow-y-scroll"
    }, _react.default.createElement("div", {
      className: "tw-bg-orange-500"
    }, _react.default.createElement("div", {
      className: "tw-container tw-mx-auto tw-p-4 tw-flex tw-items-center tw-justify-between"
    }, _react.default.createElement("img", {
      src: "/region/is/logo/white/small.svg",
      alt: "alfred",
      className: "tw-h-[32px]"
    }), _react.default.createElement("div", {
      className: "tw-text-white tw-font-700 tw-text-lg"
    }, intl.t('esign.title')), _react.default.createElement("button", {
      onClick: function onClick() {
        return onClose();
      }
    }, _react.default.createElement(_Icons.Close, null)))), _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-container tw-mx-auto md:tw-py-14 tw-py-4 tw-px-4', 'tw-flex-1 tw-flex md:tw-flex-row tw-flex-col')
    }, _react.default.createElement(Page, null)))));
  };
  _exports.ESign = ESign;
});