define("admin/compiled/e-sign/Previews", ["exports", "@alfred-is/alfred-design-system", "classnames", "react", "admin/compiled/hooks/useESign", "admin/compiled/ui/Avatar", "admin/compiled/utils/languageContext", "admin/compiled/e-sign/Icons", "admin/compiled/e-sign/types"], function (_exports, _alfredDesignSystem, _classnames, _react, _useESign, _Avatar, _languageContext, _Icons, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TemplateItem = _exports.SmallButton = _exports.Previews = _exports.FilterButton = _exports.ContractItem = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };
  var __spreadArray = void 0 && (void 0).__spreadArray || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
      if (ar || !(i in from)) {
        if (!ar) ar = Array.prototype.slice.call(from, 0, i);
        ar[i] = from[i];
      }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
  };
  var Previews = function Previews(_a) {
    var application = _a.application,
      hireAnswers = _a.hireAnswers,
      systems = _a.systems,
      filter = _a.filter,
      preview = _a.preview,
      contracts = _a.contracts,
      sent = _a.sent,
      setEState = _a.setEState,
      setPage = _a.setPage;
    var intl = (0, _react.useContext)(_languageContext.default);
    var _b = (0, _react.useState)(''),
      search = _b[0],
      setSearch = _b[1];
    var data = (0, _useESign.useTemplates)().data;
    var templates = data ? data.templates : [];
    var items = filter === _types.FilterType.Sent ? sent : templates;
    var selectDoc = function selectDoc(doc) {
      if (isSelected(doc)) {
        return setEState({
          contracts: contracts.filter(function (dd) {
            return dd.id !== doc.id;
          }),
          preview: null,
          filter: filter
        });
      }
      return setEState({
        contracts: __spreadArray(__spreadArray([], contracts, true), [doc], false).sort(function (a, b) {
          return Number(a.id) - Number(b.id);
        }),
        preview: doc,
        filter: filter
      });
    };
    var setFilter = function setFilter(item) {
      setEState({
        contracts: contracts,
        preview: preview,
        filter: item
      });
    };
    var setPreview = function setPreview(doc) {
      setEState({
        contracts: contracts,
        preview: doc,
        filter: filter
      });
    };
    var isSelected = function isSelected(doc) {
      return contracts.some(function (dd) {
        return dd.id === doc.id;
      });
    };
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-flex tw-flex-col tw-gap-y-5', {
        'md:tw-max-w-[530px]': filter === _types.FilterType.Sent,
        'md:tw-w-[430px]': filter === _types.FilterType.Templates
      })
    }, _react.default.createElement("div", {
      className: "tw-border tw-border-gray-200 tw-rounded-sm tw-p-5"
    }, _react.default.createElement("div", {
      className: "tw-flex tw-items-center"
    }, _react.default.createElement(_Avatar.Avatar, {
      image: application.image,
      initials: application.initials,
      size: 40,
      className: "tw-mr-3"
    }), _react.default.createElement("div", {
      className: "tw-flex-1 tw-font-600"
    }, application.name), _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-flex tw-items-center tw-gap-x-1', 'tw-border tw-border-green-500 tw-rounded-sm tw-px-3 tw-py-1.5', 'tw-text-xs tw-font-700 tw-text-green-500')
    }, _react.default.createElement(_Icons.Check, {
      size: 11
    }), intl.t('components.markashired.hired'))), _react.default.createElement("div", {
      className: "tw-bg-gray-200 tw-h-px tw-my-4"
    }), _react.default.createElement("div", {
      className: "tw-flex tw-flex-col tw-gap-y-3"
    }, _react.default.createElement("div", {
      className: "tw-flex tw-gap-x-2 tw-items-center tw-text-sm"
    }, hireAnswers ? _react.default.createElement(_Icons.Check, null) : _react.default.createElement(_Icons.Alert, null), hireAnswers ? intl.t('esign.questions.answered') : intl.t('esign.questions.missing')), systems.map(function (system) {
      if (system.configured) {
        return _react.default.createElement("div", {
          key: system.id,
          className: "tw-flex tw-gap-x-2 tw-items-center tw-text-left tw-text-sm"
        }, system.exported ? _react.default.createElement(_Icons.Check, null) : _react.default.createElement(_Icons.Alert, null), _react.default.createElement("div", {
          className: "tw-flex-1"
        }, system.exported ? intl.t('esign.system.active', {
          name: system.name
        }) : intl.t('esign.system.missing', {
          name: system.name
        })));
      }
    }))), _react.default.createElement("div", {
      className: "tw-flex tw-flex-col tw-flex-1"
    }, _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-border tw-border-gray-200 tw-rounded-t-sm tw-bg-white', 'tw-flex tw-items-center tw-gap-x-4 tw-p-5')
    }, _react.default.createElement(FilterButton, {
      active: filter === _types.FilterType.Sent,
      onClick: function onClick() {
        return setFilter(_types.FilterType.Sent);
      }
    }, intl.t('esign.filter.sent'), " (", sent.length, ")"), _react.default.createElement(FilterButton, {
      active: filter === _types.FilterType.Templates,
      onClick: function onClick() {
        return setFilter(_types.FilterType.Templates);
      }
    }, intl.t('esign.filter.templates'))), (items.length > 2 || !sent.length) && _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-border-x tw-border-b tw-border-gray-200 tw-bg-gray-100', 'tw-flex tw-gap-x-4 tw-px-5 tw-py-3')
    }, items.length > 2 && _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-flex-1 tw-px-4 tw-py-2', 'tw-bg-white tw-border tw-border-gray-200 tw-rounded-sm')
    }, _react.default.createElement("input", {
      type: "text",
      placeholder: intl.t('esign.search.placeholder'),
      className: "tw-bg-transparent tw-w-full",
      name: "search",
      value: search,
      onChange: function onChange(e) {
        return setSearch(e.target.value);
      }
    })), filter === _types.FilterType.Sent && !sent.length && _react.default.createElement("div", {
      className: "tw-flex-1 tw-text-center"
    }, intl.t('esign.sent.empty'))), _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-flex-auto tw-h-40 tw-overflow-y-scroll', 'tw-bg-white tw-border tw-border-t-0 tw-border-gray-200 tw-rounded-b-sm')
    }, items.filter(function (doc) {
      return doc ? doc.name.match(new RegExp(search, 'i')) : true;
    }).map(function (document) {
      if (filter === _types.FilterType.Sent) {
        return _react.default.createElement(ContractItem, {
          key: document.id,
          name: document.name,
          signed: document.signed,
          url: document.url
        });
      }
      return _react.default.createElement(TemplateItem, {
        key: document.id,
        name: document.name,
        selected: isSelected(document),
        onSelect: function onSelect(e) {
          e.stopPropagation();
          selectDoc(document);
        },
        active: preview ? document.id === preview.id : false,
        onClick: function onClick() {
          if (preview && preview.id === document.id) {
            return setPreview(null);
          }
          setPreview(document);
        }
      });
    }))), _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-p-5 tw-flex tw-flex-col tw-text-center tw-gap-y-4', 'tw-bg-white tw-border tw-border-gray-200 tw-rounded-sm')
    }, _react.default.createElement("div", null, intl.t('esign.selected.info', {
      count: contracts.length
    })), _react.default.createElement("button", {
      className: (0, _classnames.default)('tw-flex tw-items-center tw-justify-center tw-gap-x-1.5', 'tw-bg-orange-500 hover:tw-bg-orange-400 disabled:tw-opacity-50', 'tw-text-white tw-p-3 tw-rounded-sm tw-font-700'),
      disabled: contracts.length <= 0,
      onClick: function onClick() {
        return setPage(_types.PageType.Edit);
      }
    }, intl.t('esign.continue.edit'), _react.default.createElement(_alfredDesignSystem.Icon, {
      kind: _alfredDesignSystem.AlfredIconT.Arrow,
      size: 13,
      color: '#fff'
    })))), _react.default.createElement("div", {
      className: "tw-w-14 tw-h-14"
    }), _react.default.createElement("div", {
      className: "tw-flex-1 tw-flex tw-flex-col"
    }, !!preview && _react.default.createElement("div", {
      className: "tw-flex tw-items-center tw-justify-between tw-mb-4"
    }, _react.default.createElement("div", null, preview.name), _react.default.createElement(SmallButton, {
      color: isSelected(preview) ? 'red' : 'blue',
      onClick: function onClick() {
        return selectDoc(preview);
      }
    }, isSelected(preview) ? intl.t('esign.unselect') : intl.t('esign.select'))), _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-flex-1 tw-min-h-[600px] tw-p-5 tw-flex tw-items-center tw-justify-center', 'tw-bg-white tw-border tw-border-gray-200 tw-rounded-sm')
    }, !!preview && _react.default.createElement("img", {
      src: preview.preview,
      alt: "no preview"
    }), !preview && _react.default.createElement("div", {
      className: "tw-text-center tw-max-w-xs"
    }, _react.default.createElement("div", {
      className: "tw-text-lg tw-font-600"
    }, intl.t('esign.preview.empty.title')), _react.default.createElement("div", null, intl.t('esign.preview.empty.description'))))));
  };
  _exports.Previews = Previews;
  var ContractItem = function ContractItem(_a) {
    var name = _a.name,
      _b = _a.signed,
      signed = _b === void 0 ? false : _b,
      url = _a.url,
      rest = __rest(_a, ["name", "signed", "url"]);
    var intl = (0, _react.useContext)(_languageContext.default);
    return _react.default.createElement("div", __assign({
      className: (0, _classnames.default)('tw-flex tw-items-center tw-justify-between tw-gap-x-4 tw-p-5', 'tw-group tw-border-b tw-border-gray-200 hover:tw-bg-gray-100')
    }, rest), _react.default.createElement("div", {
      className: "tw-flex-1 tw-text-sm"
    }, name), _react.default.createElement("div", {
      className: "tw-flex tw-gap-x-1"
    }, signed ? _react.default.createElement(SmallButton, {
      color: "green-border"
    }, intl.t('esign.signed')) : _react.default.createElement(SmallButton, {
      color: "blue-border"
    }, intl.t('esign.sent')), _react.default.createElement(SmallButton, {
      color: signed ? 'red' : 'gray',
      onClick: function onClick() {
        return window.open(url);
      },
      disabled: !signed
    }, "PDF ", _react.default.createElement(_Icons.Download, null))));
  };
  _exports.ContractItem = ContractItem;
  var TemplateItem = function TemplateItem(_a) {
    var name = _a.name,
      selected = _a.selected,
      onSelect = _a.onSelect,
      active = _a.active,
      onClick = _a.onClick,
      rest = __rest(_a, ["name", "selected", "onSelect", "active", "onClick"]);
    var intl = (0, _react.useContext)(_languageContext.default);
    return _react.default.createElement("div", __assign({
      className: (0, _classnames.default)('tw-flex tw-items-center tw-justify-between tw-p-5 tw-cursor-pointer', 'tw-group tw-border-b tw-border-gray-200 hover:tw-bg-gray-100', {
        'tw-font-700 tw-bg-blue-100': active
      }),
      onClick: onClick
    }, rest), _react.default.createElement("div", {
      className: "tw-flex tw-items-center tw-gap-x-4"
    }, selected ? _react.default.createElement(_Icons.Selected, null) : _react.default.createElement(_Icons.Select, null), name), _react.default.createElement(SmallButton, {
      color: selected ? 'red' : 'blue',
      onClick: onSelect,
      className: "tw-hidden group-hover:tw-block"
    }, selected ? intl.t('esign.unselect') : intl.t('esign.select')));
  };
  _exports.TemplateItem = TemplateItem;
  var FilterButton = function FilterButton(_a) {
    var active = _a.active,
      children = _a.children,
      rest = __rest(_a, ["active", "children"]);
    return _react.default.createElement("button", __assign({
      className: (0, _classnames.default)('tw-flex-auto tw-rounded-sm tw-px-3 tw-py-2', {
        'tw-bg-orange-500 tw-text-white tw-font-600': active,
        'tw-bg-white tw-border tw-border-gray-200 hover:tw-border-gray-300': !active
      })
    }, rest), children);
  };
  _exports.FilterButton = FilterButton;
  var SmallButton = function SmallButton(_a) {
    var color = _a.color,
      className = _a.className,
      children = _a.children,
      rest = __rest(_a, ["color", "className", "children"]);
    return _react.default.createElement("button", __assign({
      className: (0, _classnames.default)(className, 'tw-flex tw-items-center tw-justify-center tw-gap-x-1 tw-px-2 tw-py-1', 'tw-rounded-sm tw-font-700 tw-text-xs tw-uppercase ', 'disabled:tw-opacity-40', {
        'tw-bg-orange-500  hover:tw-bg-orange-600 tw-text-white': color === 'blue',
        'tw-bg-red-600 hover:tw-bg-red-700 tw-text-white': color === 'red',
        'tw-bg-gray-400 tw-text-white': color === 'gray',
        'tw-border tw-border-green-500 tw-text-green-500': color === 'green-border',
        'tw-border tw-border-orange-500 tw-text-orange-500': color === 'blue-border'
      })
    }, rest), children);
  };
  _exports.SmallButton = SmallButton;
});