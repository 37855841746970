define("admin/components/applications/filter-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // didReceiveAttrs() {
    //   this._super(...arguments);
    //
    //   let optionId = this.questionoption;
    //   let questionId = this.set('questionId', this.question + 1);
    //   let array = this.get('filter.question' + questionId) ? this.get('filter.question' + questionId) : [];
    //
    //   let inarray = -1;
    //
    //   array.forEach(function(item, index) {
    //     if (item && item === optionId) {
    //       inarray = index;
    //       return;
    //     }
    //   });
    //
    //   if (inarray >= 0) {
    //     this.set('isSelected', true);
    //   } else {
    //     this.set('isSelected', false);
    //   }
    // },

    actions: {}
  });
  _exports.default = _default;
});