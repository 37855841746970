define("admin/components/videointerview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_obj = {
    utils: Ember.inject.service(),
    classNames: ['videointerview'],
    clip: 0,
    filteredAnswers: Ember.computed('vi.answers.@each.video', 'vi.answers.length', 'application.id', function () {
      if (this.get('vi.answers.length')) {
        return this.get('vi.answers');
      }
    }),
    observeApplication: Ember.observer('application.id', function () {
      this.set('playing');
      this.set('clip', 0);
    }),
    isPlaying: function isPlaying(bool) {
      this.set('playing', bool);
    },
    toggleClip: function toggleClip(clip) {
      if (this.clip !== clip) {
        this.set('clip', clip);
        Ember.run.schedule('afterRender', function () {
          return $('.vjs-play-control').click();
        });
      } else {
        $('.vjs-play-control').click();
      }
    },
    next: function next() {
      if (this.clip < this.get('filteredAnswers.length') - 1) {
        this.incrementProperty('clip');
        Ember.run.schedule('afterRender', function () {
          return $('.vjs-play-control').click();
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "isPlaying", [_dec], Object.getOwnPropertyDescriptor(_obj, "isPlaying"), _obj), _applyDecoratedDescriptor(_obj, "toggleClip", [_dec2], Object.getOwnPropertyDescriptor(_obj, "toggleClip"), _obj), _applyDecoratedDescriptor(_obj, "next", [_dec3], Object.getOwnPropertyDescriptor(_obj, "next"), _obj)), _obj)));
  _exports.default = _default;
});