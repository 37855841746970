define("admin/compiled/job-actions/JobActions", ["exports", "@alfred-is/alfred-admin-lib", "@alfred-is/alfred-design-system", "moment", "react", "react-redux", "admin/compiled/redux/actions/modal", "admin/compiled/types", "admin/compiled/utils/emberContext"], function (_exports, _alfredAdminLib, _alfredDesignSystem, _moment, _react, _reactRedux, _modal, _types, _emberContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.JobActions = void 0;
  var __awaiter = void 0 && (void 0).__awaiter || function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P ? value : new P(function (resolve) {
        resolve(value);
      });
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }
      function rejected(value) {
        try {
          step(generator["throw"](value));
        } catch (e) {
          reject(e);
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };
  var __generator = void 0 && (void 0).__generator || function (thisArg, body) {
    var _ = {
        label: 0,
        sent: function sent() {
          if (t[0] & 1) throw t[1];
          return t[1];
        },
        trys: [],
        ops: []
      },
      f,
      y,
      t,
      g;
    return g = {
      next: verb(0),
      "throw": verb(1),
      "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
      return this;
    }), g;
    function verb(n) {
      return function (v) {
        return step([n, v]);
      };
    }
    function step(op) {
      if (f) throw new TypeError("Generator is already executing.");
      while (g && (g = 0, op[0] && (_ = 0)), _) try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];
        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;
          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };
          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;
          case 7:
            op = _.ops.pop();
            _.trys.pop();
            continue;
          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }
            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }
            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }
            if (t && _.label < t[2]) {
              _.label = t[2];
              _.ops.push(op);
              break;
            }
            if (t[2]) _.ops.pop();
            _.trys.pop();
            continue;
        }
        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
      if (op[0] & 5) throw op[1];
      return {
        value: op[0] ? op[1] : void 0,
        done: true
      };
    }
  };
  var JobActions = function JobActions(_a) {
    var _b;
    var job = _a.job;
    var _c = (0, _react.useState)(0),
      setValue = _c[1]; // used for re-renders
    var _d = (0, _react.useState)(false),
      isLoading = _d[0],
      setIsLoading = _d[1];
    var _e = (0, _react.useContext)(_emberContext.default),
      config = _e.config,
      intl = _e.intl,
      transitionTo = _e.transitionTo,
      notifications = _e.notifications,
      currentSession = _e.currentSession;
    var dispatch = (0, _reactRedux.useDispatch)();
    var status = {
      description: job.status.description,
      color: "#".concat(job.status.color)
    };
    var autoClose = job.autoCloseOption ? {
      weeks: job.autoCloseOption.weeks,
      date: job.autoCloseDate
    } : undefined;
    var canExtendReview = job.autoCloseDate && (0, _moment.default)(job.autoCloseDate).startOf('day').subtract(3, 'days').isBefore((0, _moment.default)());
    var rerender = function rerender() {
      setValue(function (value) {
        return value + 1;
      });
    };
    var t = (0, _alfredDesignSystem.useNxTranslation)().t;
    var notificationType = job.notificationType;
    var notificationSettings = {
      NEVER: t('applications.email_notif.NEVER'),
      WEEKLY: t('applications.email_notif.WEEKLY'),
      DAILY: t('applications.email_notif.DAILY')
    };
    var performAsyncAction = function performAsyncAction(action) {
      return __awaiter(void 0, void 0, void 0, function () {
        var _1;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              _a.trys.push([0, 2,, 3]);
              setIsLoading(true);
              return [4 /*yield*/, action()];
            case 1:
              _a.sent();
              setIsLoading(false);
              return [3 /*break*/, 3];
            case 2:
              _1 = _a.sent();
              setIsLoading(false);
              return [3 /*break*/, 3];
            case 3:
              return [2 /*return*/];
          }
        });
      });
    };

    var preventClose = job.isActive && !job.nodeadline && !((_b = currentSession === null || currentSession === void 0 ? void 0 : currentSession.user) === null || _b === void 0 ? void 0 : _b.phantom) && !job.scheduled;
    return _react.default.createElement(_react.default.Fragment, null, isLoading ? _react.default.createElement(_alfredDesignSystem.LoadingScreen, {
      variant: "blue"
    }) : null, _react.default.createElement("div", {
      className: "tw-space-x-2"
    }, !job.generalApplication ? _react.default.createElement(_react.default.Fragment, null, job.isActive && !job.scheduled && _react.default.createElement(_alfredAdminLib.AdStatusModalActive, {
      fromDate: job.startdate,
      toDate: job.deadline,
      status: status,
      autoClose: autoClose,
      edit: function edit() {
        window.open("".concat(config.REGION.links.new_site).concat(intl.primaryLocale, "/create-job/").concat(job.id, "/review?context=board"), 'self');
        // transitionTo('client.edit-job.deadline', job.id, {
        //   queryParams: { context: 'board' },
        // })
      },
      startReview: !preventClose ? function () {
        dispatch((0, _modal.showModal)(_types.ModalType.CONFIRMATION_MODAL, {
          title: t('components.applications.ad_status.start_review'),
          children: _react.default.createElement(_react.default.Fragment, null, t('components.applications.ad_status.start_review_p')),
          onAction: function onAction() {
            return performAsyncAction(function () {
              return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                  switch (_a.label) {
                    case 0:
                      return [4 /*yield*/, job.close()];
                    case 1:
                      _a.sent();
                      return [4 /*yield*/, job.reload()];
                    case 2:
                      _a.sent();
                      notifications.success(t('components.applications.ad_status.start_review_success'));
                      return [2 /*return*/];
                  }
                });
              });
            });
          }
        }));
      } : undefined
    }), job.isProgress && _react.default.createElement(_alfredAdminLib.AdStatusModalReview, {
      fromDate: job.deadline,
      status: status,
      autoClose: autoClose,
      extendReview: function extendReview() {
        dispatch((0, _modal.showModal)(_types.ModalType.CONFIRMATION_MODAL, canExtendReview ? {
          title: t('ad-status-modal.extend-confirm'),
          children: _react.default.createElement(_react.default.Fragment, null, t('ad-status-modal.extend-confirm.body')),
          onAction: function onAction() {
            return performAsyncAction(function () {
              return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                  switch (_a.label) {
                    case 0:
                      return [4 /*yield*/, job.extendReview()];
                    case 1:
                      _a.sent();
                      return [4 /*yield*/, job.reload()];
                    case 2:
                      _a.sent();
                      notifications.success(t('ad-status-modal.extend-confirm.success'));
                      return [2 /*return*/];
                  }
                });
              });
            });
          }
        } : {
          title: t('ad-status-modal.extend.title'),
          children: _react.default.createElement(_react.default.Fragment, null, t('ad-status-modal.extend-too-soon'))
        }));
      },
      endReview: function endReview() {
        return dispatch((0, _modal.showModal)(_types.ModalType.CONFIRMATION_MODAL, {
          title: t('ad-status-modal.end'),
          children: _react.default.createElement(_react.default.Fragment, null, t('ad-status-modal.end.body')),
          onAction: function onAction() {
            return performAsyncAction(function () {
              return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                  switch (_a.label) {
                    case 0:
                      return [4 /*yield*/, job.ended()];
                    case 1:
                      _a.sent();
                      return [4 /*yield*/, job.reload()];
                    case 2:
                      _a.sent();
                      notifications.success(t('ad-status-modal.end.success'));
                      return [2 /*return*/];
                  }
                });
              });
            });
          }
        }));
      },

      previewMessage: undefined
    }), job.isEnded && _react.default.createElement(_alfredAdminLib.AdStatusModalEnded, {
      openUntil: (0, _moment.default)(job.deadline).add(1, 'year'),
      status: status
    })) : null, _react.default.createElement(_alfredAdminLib.AdNotifications, {
      triggerLabel: notificationSettings[notificationType],
      triggerText: t('string.notifications'),
      notificationSettings: Object.keys(notificationSettings).map(function (objKey) {
        var key = objKey;
        return {
          label: notificationSettings[key],
          onClick: function onClick() {
            return __awaiter(void 0, void 0, void 0, function () {
              return __generator(this, function (_a) {
                switch (_a.label) {
                  case 0:
                    job.set('notificationType', key);
                    rerender();
                    setIsLoading(true);
                    return [4 /*yield*/, job.saveEmailNotification({
                      notificationType: key
                    })];
                  case 1:
                    _a.sent();
                    return [4 /*yield*/, job.reload()];
                  case 2:
                    _a.sent();
                    setIsLoading(false);
                    return [2 /*return*/];
                }
              });
            });
          },

          isActive: notificationType == key
        };
      })
    })));
  };
  _exports.JobActions = JobActions;
});