define("admin/helpers/filter-utils/hired-date-filter", ["exports", "moment/moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hiredDateFilter = void 0;
  var hiredDateFilter = function hiredDateFilter(hiredDate, filterPeriod, fit) {
    var resetToMidnight = function resetToMidnight(date) {
      date.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      });
      return date;
    };
    if (hiredDate != null) {
      if (filterPeriod && filterPeriod.length > 1) {
        var submitted = resetToMidnight((0, _moment.default)(hiredDate.toISOString()));
        var from = resetToMidnight(filterPeriod[0]);
        var to = resetToMidnight(filterPeriod[1]);
        if (!(submitted.isSameOrBefore(to) && submitted.isSameOrAfter(from))) {
          fit = false;
        }
      }
    } else {
      fit = false;
    }
    return fit;
  };
  _exports.hiredDateFilter = hiredDateFilter;
});