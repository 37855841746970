define("admin/models/videointerview", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    applicationId: (0, _model.attr)('string'),
    averageRating: (0, _model.attr)('number'),
    deadline: (0, _model.attr)('moment'),
    rating: (0, _model.attr)('number'),
    sent: (0, _model.attr)('moment'),
    status: (0, _model.attr)('string'),
    templateId: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    seen: (0, _model.attr)('boolean'),
    questioncount: (0, _model.attr)('number'),
    lastused: (0, _model.attr)('string'),
    added: (0, _model.attr)('string'),
    inuse: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    active: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    answers: (0, _model.hasMany)('videointerview-answer'),
    ratingcolor: Ember.computed('rating', function () {
      return this._getRatingColor(this.rating);
    }),
    avgratingcolor: Ember.computed('averageRating', function () {
      return this._getRatingColor(this.averageRating);
    }),
    translated_vistatus: Ember.computed('status', function () {
      return this.status ? this.intl.t("vi_status.".concat(this.status), {
        default: 'vi_status.SENT'
      }) : this.status;
    }),
    _getRatingColor: function _getRatingColor(rating) {
      if (rating > 8) {
        return 'green';
      }
      if (rating > 6) {
        return 'blue';
      }
      if (rating > 4) {
        return 'orange';
      }
      if (rating > 0) {
        return 'red';
      }
      return 'gray';
    }
  });
  _exports.default = _default;
});