define("admin/components/crad/crad-close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    notifications: Ember.inject.service(),
    router: Ember.inject.service(),
    newAdmin: Ember.inject.service(),
    intl: Ember.inject.service(),
    classNames: ['crad-close'],
    actions: {
      save: function save() {
        if (this.isActive && !this.isValid) {
          return;
        }
        this.saveJob();
      },
      cancel: function cancel() {
        this.cancel();
        this.newAdmin.transitionTo('jobs');
      },
      delete: function _delete() {
        this.delete();
      },
      closeAction: function closeAction() {
        if (this.hasChanges) {
          this.toggleProperty('isClosing');
        } else {
          this.newAdmin.transitionTo('jobs');
        }
      }
    }
  });
  _exports.default = _default;
});