define("admin/compiled/alfrello-profile-info/ProfileLinks", ["exports", "react", "admin/compiled/alfrello-profile-items/AlfrelloProfileItems"], function (_exports, _react, _AlfrelloProfileItems) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ProfileLinks = void 0;
  var ProfileLink = function ProfileLink(_a) {
    var link = _a.link,
      color = _a.color;
    return _react.default.createElement("div", {
      className: 'alfrello-profile-link'
    }, _react.default.createElement("div", {
      className: 'alfrello-profile-link--title'
    }, link.title), _react.default.createElement("a", {
      href: link.url,
      target: '_blank',
      title: link.title
    }, link.url));
  };
  var ProfileLinks = function ProfileLinks(_a) {
    var links = _a.links,
      label = _a.label,
      color = _a.color;
    if (!links || !links.length) {
      return null;
    }
    return _react.default.createElement(_AlfrelloProfileItems.AlfrelloProfileItems, {
      label: label,
      items: links.map(function (l) {
        return _react.default.createElement(ProfileLink, {
          key: l.id,
          link: l,
          color: color
        });
      })
    });
  };
  _exports.ProfileLinks = ProfileLinks;
});