define("admin/routes/client/edit-job/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    editJob: Ember.inject.service(),
    model: function model() {
      var job = this.modelFor('client.edit-job').job;
      return Ember.RSVP.hash({
        job: job,
        productData: job.getProducts()
      });
    },
    afterModel: function afterModel(model) {
      this.editJob.productData = model.productData;
    }
  });
  _exports.default = _default;
});