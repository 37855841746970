define("admin/react-component", ["exports", "react-dom", "admin/config/environment", "admin/compiled/configureStore", "admin/compiled/modal/ModalRoot", "react-redux", "moment", "moment/locale/is", "moment/locale/cs", "admin/compiled/utils/emberContext", "react-tooltip", "admin/compiled/error-map", "react-query", "@alfred-is/alfred-admin-lib", "@alfred-is/alfred-design-system", "react", "zod"], function (_exports, _reactDom, _environment, _configureStore, _ModalRoot, _reactRedux, _moment, _is, _cs, _emberContext, _reactTooltip, _errorMap, _reactQuery, _alfredAdminLib, _alfredDesignSystem, _react, _zod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _config$REGION = _environment.default.REGION,
    locale = _config$REGION.locale,
    links = _config$REGION.links;
  var queryClient = new _reactQuery.QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false
      }
    }
  });
  _moment.default.locale(locale);
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    apiClient: Ember.inject.service(),
    apiManager: Ember.inject.service(),
    apiAdmin: Ember.inject.service(),
    router: Ember.inject.service(),
    notifications: Ember.inject.service(),
    gtm: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    /**
     * We don't need a template since we're only creating a
     * wrapper for our React component
     **/
    layout: '',
    /**
     * Renders a react component as the current ember element
     * @param {React.Component} reactComponent. e.g., <HelloWorld />
     */
    reactRender: function reactRender(reactComponent) {
      var _this = this;
      var store = (0, _configureStore.default)({});
      _zod.default.setErrorMap((0, _errorMap.getZodErrorMap)(function (val) {
        return _this.intl.t(val);
      }));
      _reactDom.default.render(_react.default.createElement(_reactQuery.QueryClientProvider, {
        client: queryClient
      }, _react.default.createElement(_reactRedux.Provider, {
        store: store
      }, _react.default.createElement(_alfredDesignSystem.TranslationProvider, {
        value: {
          t: function t(val, opts) {
            return _this.intl.t(val, opts);
          },
          lang: this.intl.locale
        }
      }, _react.default.createElement(_alfredAdminLib.AdminConfigContextProvider, {
        value: _environment.default
      }, _react.default.createElement(_alfredDesignSystem.DesignSystemContextProvider, {
        value: {
          locale: this.intl.primaryLocale
        }
      }, _react.default.createElement(_emberContext.EmberProvider, {
        value: {
          transitionTo: function transitionTo(route, model, opts) {
            // for some reason ember wants to explicitly omit opts object, even though it's undefined.
            if (!opts) {
              if (model) {
                _this.router.transitionTo(route, model);
              } else {
                _this.router.transitionTo(route);
              }
            } else {
              if (model) {
                _this.router.transitionTo(route, model, opts);
              } else {
                _this.router.transitionTo(route, opts);
              }
            }
          },
          intl: this.intl,
          notifications: this.notifications,
          apiManager: this.apiManager,
          apiClient: this.apiClient,
          apiAdmin: this.apiAdmin,
          currentSession: this.currentSession,
          links: links,
          locale: locale,
          config: _environment.default,
          moment: _moment.default,
          gtm: this.gtm
        }
      }, reactComponent, _react.default.createElement(_ModalRoot.ModalRoot, null), _react.default.createElement(_reactTooltip.default, null))))))), this.element);
    },
    /**
     * Removes a mounted React component from the DOM and
     * cleans up its event handlers and state.
     */
    unmountReactElement: function unmountReactElement() {
      _reactDom.default.unmountComponentAtNode(this.element);
    },
    /**
     * Cleans up the rendered react component as the ember
     * component gets destroyed
     */
    willDestroyComponent: function willDestroyComponent() {
      this._super();
      this.unmountReactElement();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.renderComponent();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.renderComponent();
    }
  });
  _exports.default = _default;
});