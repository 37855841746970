define("admin/templates/components/videointerview-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0z1BDYTD",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"intro\"],[12],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"h3\"],[12],[1,[30,[36,0],[\"client.applications.profile.video.no_videointerview\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"client.applications.profile.video.no_videointerview_p\"],null]],[13],[2,\"\\n    \"],[8,\"applications/video-invite\",[],[[\"@application\",\"@job\"],[[34,1],[34,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"button\"],[14,0,\"btn\"],[12],[1,[30,[36,0],[\"string.invite_video\"],null]],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"h3\"],[12],[1,[30,[36,0],[\"client.applications.profile.video.unfinished\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"client.applications.profile.video.unfinished_p\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"application\",\"job\",\"vi\",\"unless\"]}",
    "meta": {
      "moduleName": "admin/templates/components/videointerview-empty.hbs"
    }
  });
  _exports.default = _default;
});