define("admin/templates/components/applications/filter-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "was9Iqp8",
    "block": "{\"symbols\":[],\"statements\":[[10,\"li\"],[15,0,[30,[36,1],[[35,0,[\"isSelected\"]],\"active\"],null]],[12],[2,\"\\n  \"],[11,\"a\"],[24,6,\"#\"],[4,[38,3],[[32,0],[30,[36,2],[\"questionoption.isSelected\",[32,0]],null]],null],[12],[1,[35,0,[\"option\"]]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"questionoption\",\"if\",\"toggle\",\"action\"]}",
    "meta": {
      "moduleName": "admin/templates/components/applications/filter-option.hbs"
    }
  });
  _exports.default = _default;
});