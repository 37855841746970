define("admin/components/jobs/publish-modal", ["exports", "admin/components/edit-modal"], function (_exports, _editModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _editModal.default.extend({
    router: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.getCategories) {
        this.set('categories', this.getCategories());
      }
    },
    actions: {
      goToJobs: function goToJobs() {
        this.goToJobs();
      },
      publish: function publish() {
        this.publish();
      },
      publishRandom: function publishRandom() {
        this.publishRandom();
      }
    }
  });
  _exports.default = _default;
});