define("admin/routes/admin/customers/profiles", ["exports", "admin/mixins/model-loader"], function (_exports, _modelLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_modelLoader.default, {
    apiAdmin: Ember.inject.service(),
    queryParams: {
      search: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var search = _ref.search;
      return this.apiAdmin.get("/profiles?username=".concat(search)).then(function (response) {
        return response || [];
      }).catch(function () {
        return [];
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('query', controller.search);
    },
    actions: {
      blockProfile: function blockProfile(id) {
        var _this = this;
        this.apiAdmin.put("/profiles/".concat(id, "/block")).then(function () {
          return _this.refresh();
        });
      },
      unblockProfile: function unblockProfile(id) {
        var _this2 = this;
        this.apiAdmin.delete("/profiles/".concat(id, "/block")).then(function () {
          return _this2.refresh();
        });
      }
    }
  });
  _exports.default = _default;
});