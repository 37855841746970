define("admin/compiled/date-picker/date-picker", ["exports", "@alfred-is/alfred-design-system", "@datepicker-react/styled", "@floating-ui/react-dom", "react", "styled-components"], function (_exports, _alfredDesignSystem, _styled, _reactDom, _react, _styledComponents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DatePicker = DatePicker;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };
  function DatePicker(_a) {
    var _b;
    var placeholder = _a.placeholder,
      _c = _a.theme,
      theme = _c === void 0 ? _alfredDesignSystem.Theme.Orange : _c,
      minDate = _a.minDate,
      maxDate = _a.maxDate,
      date = _a.date,
      setDate = _a.setDate,
      rest = __rest(_a, ["placeholder", "theme", "minDate", "maxDate", "date", "setDate"]);
    var t = (0, _alfredDesignSystem.useNxTranslation)().t;
    var format = (0, _alfredDesignSystem.useDateUtils)().format;
    var _d = (0, _react.useState)(false),
      visible = _d[0],
      setVisible = _d[1];
    var inputId = (0, _react.useMemo)(function () {
      return (0, _alfredDesignSystem.uuid)();
    }, []);
    var ref = (0, _react.useRef)(null);
    (0, _react.useLayoutEffect)(function () {}, [ref]);
    var _e = (0, _reactDom.useFloating)({
        placement: 'bottom-start',
        middleware: [(0, _reactDom.offset)({
          mainAxis: 15,
          crossAxis: 10
        }), (0, _reactDom.shift)()],
        strategy: 'absolute'
      }),
      x = _e.x,
      y = _e.y,
      reference = _e.reference,
      floating = _e.floating,
      strategy = _e.strategy;
    var colorMap = (_b = {}, _b[_alfredDesignSystem.Theme.Orange] = _alfredDesignSystem.ColorT.orange500, _b[_alfredDesignSystem.Theme.Blue] = _alfredDesignSystem.ColorT.blue500, _b[_alfredDesignSystem.Theme.Green] = _alfredDesignSystem.ColorT.green500, _b);
    (0, _react.useLayoutEffect)(function () {
      var input = document === null || document === void 0 ? void 0 : document.getElementById(inputId);
      if (input) {
        input.style.display = 'none';
        input.style.border = 'none';
      }
      reference(ref.current);
    }, [inputId, visible]);
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("div", {
      ref: ref
    }, _react.default.createElement(_alfredDesignSystem.TextInput, __assign({
      placeholder: placeholder || t('string.choose_date'),
      value: date ? format(date, 'dd. MMM yyyy') : '',
      onClick: function onClick() {
        return setVisible(true);
      },
      readOnly: true,
      theme: theme
    }, rest))), visible && _react.default.createElement("div", {
      ref: floating,
      style: {
        position: strategy,
        top: y !== null && y !== void 0 ? y : '',
        left: x !== null && x !== void 0 ? x : ''
      }
    }, _react.default.createElement(_styledComponents.ThemeProvider, {
      theme: {
        reactDatepicker: {
          colors: {
            accessibility: (0, _alfredDesignSystem.getHexColor)(colorMap[theme]),
            selectDateDateColor: (0, _alfredDesignSystem.getHexColor)(colorMap[theme]),
            selectedDayHover: (0, _alfredDesignSystem.getHexColor)(_alfredDesignSystem.ColorT.gray100),
            primaryColor: (0, _alfredDesignSystem.getHexColor)(colorMap[theme])
          },
          navButtonBorder: '0',
          inputLabelDisplay: 'none'
        }
      }
    }, _react.default.createElement(_styled.DateSingleInput, {
      minBookingDate: minDate,
      maxBookingDate: maxDate,
      onFocusChange: function onFocusChange(focusedInput) {
        setVisible(false);
      },
      onDateChange: function onDateChange(date) {
        setDate(date.date);
        setVisible(false);
      },
      date: date,
      showDatepicker: true,
      dayLabelFormat: function dayLabelFormat(date) {
        return format(date, 'dd');
      },
      weekdayLabelFormat: function weekdayLabelFormat(date) {
        return format(date, 'eeeeee');
      },
      monthLabelFormat: function monthLabelFormat(date) {
        return format(date, 'MMMM yyyy');
      },
      showClose: false,
      showResetDate: false,
      phrases: {
        datepickerStartDatePlaceholder: '',
        datepickerStartDateLabel: '',
        datepickerEndDatePlaceholder: '',
        datepickerEndDateLabel: '',
        resetDates: '',
        dateAriaLabel: '',
        datePlaceholder: '',
        close: t('string.close')
      },
      inputId: inputId
    }))));
  }
});