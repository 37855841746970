define("admin/templates/components/applications/cancel-invite-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "CA16uVZU",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@overlayClass\"],[[30,[36,0],[[32,0],\"close\"],null],\"modal-popup\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"default-dialog\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[1,[30,[36,1],[\"components.applications.cancel_invite_modal.title\"],null]],[13],[2,\"\\n\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"components.applications.cancel_invite_modal.p\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"edit-form\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[1,[30,[36,1],[\"string.message\"],null]],[13],[2,\"\\n      \"],[8,\"textarea\",[],[[\"@value\"],[[34,2]]],null],[2,\"\\n      \"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn gray-brd inline\"],[4,[38,0],[[32,0],\"close\"],null],[12],[1,[30,[36,1],[\"components.applications.cancel_invite_modal.close\"],null]],[13],[2,\"\\n        \"],[11,\"button\"],[16,0,[31,[\"btn red inline upper \",[30,[36,3],[[35,2],\"disabled\"],null]]]],[4,[38,0],[[32,0],\"cancelInvitation\"],null],[12],[1,[30,[36,1],[\"components.applications.cancel_invite_modal.confirm\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"cancelmessage\",\"unless\"]}",
    "meta": {
      "moduleName": "admin/templates/components/applications/cancel-invite-modal.hbs"
    }
  });
  _exports.default = _default;
});