define("admin/models/brand", ["exports", "@ember-data/model", "ember-api-actions"], function (_exports, _model, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    isdefault: (0, _model.attr)('boolean'),
    name: (0, _model.attr)('string'),
    logo: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    web: (0, _model.attr)('string'),
    ssn: (0, _model.attr)('string'),
    body: (0, _model.attr)('string'),
    enbody: (0, _model.attr)('string'),
    domain: (0, _model.attr)('string'),
    status: (0, _model.attr)('string', {
      defaultValue: 'ACTIVE'
    }),
    cover: (0, _model.attr)('string'),
    slogan: (0, _model.attr)('string'),
    slug: (0, _model.attr)('string'),
    color: (0, _model.attr)('string'),
    brandAddress: (0, _model.attr)('string'),
    invoiceemail: (0, _model.attr)('string'),
    invoicecontact: (0, _model.attr)('string'),
    facebook: (0, _model.attr)('string'),
    twitter: (0, _model.attr)('string'),
    instagram: (0, _model.attr)('string'),
    linkedin: (0, _model.attr)('string'),
    foundingYear: (0, _model.attr)('number'),
    companySize: (0, _model.attr)(),
    malePercentage: (0, _model.attr)('number'),
    femalePercentage: (0, _model.attr)('number'),
    facts: (0, _model.attr)(),
    images: (0, _model.attr)(),
    paragraphs: (0, _model.attr)(),
    figures: (0, _model.attr)(),
    benefits: (0, _model.attr)(),
    awards: (0, _model.attr)(),
    address: (0, _model.belongsTo)('address'),
    divisions: (0, _model.attr)(),
    // no need to have it as a model, it's only causing headache
    childBrands: (0, _model.attr)(),
    generalApplicationJob: (0, _model.belongsTo)('ga-job'),
    generalApplicationSubscription: (0, _model.attr)(),
    purchaseGa: (0, _emberApiActions.memberAction)({
      path: 'generalapplications',
      type: 'post'
    }),
    icon: Ember.computed('logo', function () {
      if (this.logo !== 'https://alfred.imgix.net/null' && this.logo !== 'https://alfredprod.imgix.net/null') {
        return "".concat(this.logo, "?w=200");
      }
      return null;
    })
  });
  _exports.default = _default;
});