define("admin/models/sales-invoice", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    address: (0, _model.attr)('string'),
    clientId: (0, _model.attr)('string'),
    contact: (0, _model.attr)('string'),
    division: (0, _model.attr)('string'),
    exportDate: (0, _model.attr)('string'),
    graceDays: (0, _model.attr)('string'),
    invoiceDate: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    ssn: (0, _model.attr)('string'),
    sum: (0, _model.attr)('number'),
    zip: (0, _model.attr)('string'),
    zipCode: (0, _model.attr)('string'),
    lines: (0, _model.hasMany)('invoiceline')
  });
  _exports.default = _default;
});