define("admin/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xssifJDQ",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[30,[36,0],[\"cee\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"title\"],[12],[2,\"Alfréd administrace\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[\"is\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"title\"],[12],[2,\"Alfreð umsjón\"],[13],[2,\"\\n  \"],[10,\"meta\"],[14,3,\"apple-itunes-app-name\"],[14,\"content\",\"app-id=1434414290\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"title\"],[12],[2,\"Alfred admin\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"region\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/head.hbs"
    }
  });
  _exports.default = _default;
});