define("admin/templates/components/skills/manage-skills", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+W20Oxvb",
    "block": "{\"symbols\":[\"skill\",\"@refreshData\"],\"statements\":[[10,\"div\"],[14,0,\"top\"],[12],[2,\"\\n  \"],[10,\"strong\"],[12],[1,[34,6]],[2,\":\"],[13],[2,\" \"],[1,[34,7]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn\"],[4,[38,2],[[32,0],[30,[36,8],[\"newSkill\",[32,0]],null]],null],[12],[2,\"Ný hæfni\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,11],[[30,[36,10],[[30,[36,10],[[35,9]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"ui/icon-input\",[],[[\"@icon\",\"@value\",\"@onSave\",\"@actionText\",\"@loading\",\"@onDelete\",\"@deleteActionText\",\"@saveIcon\",\"@disabled\"],[[30,[36,3],[[32,1,[\"loadingIcon\"]],\"/img/icons/loader.svg\",[32,1,[\"icon\"]]],null],[32,1,[\"name\"]],[30,[36,2],[[32,0],\"saveSkill\",[32,1]],null],[30,[36,4],[\"string.save\"],null],[32,1,[\"loading\"]],[30,[36,2],[[32,0],\"deleteSkill\",[32,1]],null],[30,[36,4],[\"string.remove\"],null],[30,[36,2],[[32,0],\"saveIcon\",[32,1]],null],[30,[36,5],[[32,1,[\"name\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[6,[37,3],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"skills/new-skill\",[],[[\"@newSkill\",\"@subcategoryId\",\"@refreshData\"],[[34,0],[34,1],[30,[36,2],[[32,0],[32,2]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"newSkill\",\"subcategoryId\",\"action\",\"if\",\"t\",\"not\",\"categoryName\",\"subcategoryName\",\"toggle-action\",\"skills\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/components/skills/manage-skills.hbs"
    }
  });
  _exports.default = _default;
});