define("admin/compiled/ui/filter-bar/FilterBar", ["exports", "react", "admin/compiled/utils/useStickyFilter"], function (_exports, _react, _useStickyFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FilterBar = void 0;
  var FilterBar = function FilterBar(_a) {
    var children = _a.children;
    // make sticky filter bar
    (0, _useStickyFilter.default)();
    return _react.default.createElement("div", {
      id: 'ui-filter-bar',
      className: 'ui-filter-bar'
    }, _react.default.createElement("div", {
      className: 'container'
    }, _react.default.createElement("div", {
      className: 'ui-filter-bar--content'
    }, children)));
  };
  _exports.FilterBar = FilterBar;
});