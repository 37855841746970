define("admin/components/ui/dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div id={{@id}} class="ui-dropdown">
    <div
      class="ui-dropdown--selected
        {{if @active 'active'}}
        {{if @label 'with-label'}}
        {{if @required 'required'}}
        {{if @selected 'selected'}}"
      {{on "click" @onTrigger}}
    >
      <div class="{{if @withCheckmark 'tw-flex'}}">
        {{#if @withCheckmark}}
          <div
            class="ui-dropdown--checkmark
              {{if @selected 'selected'}}
              tw-mr-4 tw-flex-none"
          >
          </div>
        {{/if}}
        <div>
          {{#if @label}}
            <div class="ui-dropdown--label">{{@label}}</div>
          {{/if}}
          {{#unless @selected}}
            <div class="ui-dropdown--placeholder">{{@placeholder}}</div>
          {{else}}
            {{{@selected}}}
          {{/unless}}
        </div>
      </div>
    </div>
    {{#if @active}}
      <div class="ui-dropdown--content">
        {{yield}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "3mEkTP5n",
    "block": "{\"symbols\":[\"&default\",\"@selected\",\"@placeholder\",\"@label\",\"@id\",\"@required\",\"@active\",\"@onTrigger\",\"@withCheckmark\"],\"statements\":[[10,\"div\"],[15,1,[32,5]],[14,0,\"ui-dropdown\"],[12],[2,\"\\n  \"],[11,\"div\"],[16,0,[31,[\"ui-dropdown--selected\\n      \",[30,[36,0],[[32,7],\"active\"],null],\"\\n      \",[30,[36,0],[[32,4],\"with-label\"],null],\"\\n      \",[30,[36,0],[[32,6],\"required\"],null],\"\\n      \",[30,[36,0],[[32,2],\"selected\"],null]]]],[4,[38,1],[\"click\",[32,8]],null],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[[32,9],\"tw-flex\"],null]]]],[12],[2,\"\\n\"],[6,[37,0],[[32,9]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[15,0,[31,[\"ui-dropdown--checkmark\\n            \",[30,[36,0],[[32,2],\"selected\"],null],\"\\n            tw-mr-4 tw-flex-none\"]]],[12],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,0],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"ui-dropdown--label\"],[12],[1,[32,4]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"ui-dropdown--placeholder\"],[12],[1,[32,3]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[2,[32,2]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,0],[[32,7]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"ui-dropdown--content\"],[12],[2,\"\\n      \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"on\",\"unless\"]}",
    "meta": {
      "moduleName": "admin/components/ui/dropdown.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});