define("admin/components/crad/dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['crad-dropdown'],
    classNameBindings: ['active'],
    attributeBindings: ['data-test'],
    click: function click() {
      this.toggleProperty('active');
    }
  });
  _exports.default = _default;
});