define("admin/controllers/client", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    loginManager: Ember.inject.service(),
    cookies: (0, _emberLocalStorage.storageFor)('cookies'),
    currentUser: (0, _emberLocalStorage.storageFor)('current-user'),
    browserSupported: Ember.computed.not('userAgent.browser.isIE'),
    body: 'Dear Clients,<br> We are thrilled to share some exciting news with you – Alfred is undergoing a transformation! We have been hard at work behind the scenes to bring you a fresh and revitalized experience.<br><br>We are embracing a vibrant palette of brand colors that resonate with our values and aspirations. These colors will infuse a sense of energy and authenticity into every interaction you have with Alfred.<br><br>Thank you for your continued trust and partnership with Alfred.<br>We look forward to this exciting journey ahead!',
    actions: {
      afterPhantomLogout: function afterPhantomLogout() {
        this.transitionToRoute('admin.customers');
        Ember.run.next(this, function () {
          this.loginManager.cleanAfterPhantom();
        });
      },
      toggleEmployeeCountModal: function toggleEmployeeCountModal() {
        this.currentSession.user.set('showEmployeeCount', false);
        this.currentSession.user.set('employeeCount', 'NO_ANSWER');
        this.currentSession.user.submitEmployeeCount({
          employeeCount: 'NO_ANSWER'
        });
      },
      saveEmployeeCount: function saveEmployeeCount(employeeCount) {
        var _this = this;
        this.currentSession.user.submitEmployeeCount({
          employeeCount: employeeCount
        }).then(function () {
          _this.toggleProperty('showEmployeeCount');
          _this.notifications.success("Takk fyrir!");
        });
      }
    }
  });
  _exports.default = _default;
});