define("admin/templates/components/intro-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fp95VJ8d",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"intro\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"edit-form clear-form\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"row text-center\"],[12],[2,\"\\n      \"],[10,\"img\"],[14,\"src\",\"/img/alfred-icon.png\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n\\n      \"],[10,\"h1\"],[12],[1,[34,0]],[13],[2,\"\\n\\n      \"],[10,\"p\"],[12],[2,\"\\n        \"],[1,[34,1]],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[8,\"link-to\",[[24,0,\"btn inline\"]],[[\"@route\"],[[34,2]]],[[\"default\"],[{\"statements\":[[1,[34,3]]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"title\",\"description\",\"link\",\"btn\"]}",
    "meta": {
      "moduleName": "admin/templates/components/intro-page.hbs"
    }
  });
  _exports.default = _default;
});