define("admin/components/ui/icon-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="icon-button {{@icon}}" {{on "click" @onClick}}>
    {{yield}}
  </div>
  
  */
  {
    "id": "lP0wuzBQ",
    "block": "{\"symbols\":[\"@icon\",\"@onClick\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"icon-button \",[32,1]]]],[4,[38,0],[\"click\",[32,2]],null],[12],[2,\"\\n  \"],[18,3,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "meta": {
      "moduleName": "admin/components/ui/icon-button.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});