define("admin/compiled/jobs-list/JobsList", ["exports", "react", "react-redux", "admin/compiled/alfrello-column/LoadingBar", "admin/compiled/header-button/header-button", "admin/compiled/hooks/useHrBoard", "admin/compiled/job-item/GaInactiveJob", "admin/compiled/job-item/GaJobItem", "admin/compiled/job-item/RegularJobItem", "admin/compiled/jobs-list-filter/JobsListFilter", "admin/compiled/page-title-header/PageTitleHeader", "admin/compiled/redux/actions/modal", "admin/compiled/types", "admin/compiled/utils/emberContext", "admin/compiled/utils/empty-screens", "admin/compiled/utils/useForceUpdate", "admin/compiled/jobs-list/jobs-actions", "admin/compiled/jobs-list/jobs-filter", "admin/compiled/jobs-list/jobs-ga-visiblity", "date-fns"], function (_exports, _react, _reactRedux, _LoadingBar, _headerButton, _useHrBoard, _GaInactiveJob, _GaJobItem, _RegularJobItem, _JobsListFilter, _PageTitleHeader, _modal, _types, _emberContext, _emptyScreens, _useForceUpdate, _jobsActions, _jobsFilter, _jobsGaVisiblity, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.JobsList = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var __awaiter = void 0 && (void 0).__awaiter || function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P ? value : new P(function (resolve) {
        resolve(value);
      });
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }
      function rejected(value) {
        try {
          step(generator["throw"](value));
        } catch (e) {
          reject(e);
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };
  var __generator = void 0 && (void 0).__generator || function (thisArg, body) {
    var _ = {
        label: 0,
        sent: function sent() {
          if (t[0] & 1) throw t[1];
          return t[1];
        },
        trys: [],
        ops: []
      },
      f,
      y,
      t,
      g;
    return g = {
      next: verb(0),
      "throw": verb(1),
      "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
      return this;
    }), g;
    function verb(n) {
      return function (v) {
        return step([n, v]);
      };
    }
    function step(op) {
      if (f) throw new TypeError("Generator is already executing.");
      while (g && (g = 0, op[0] && (_ = 0)), _) try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];
        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;
          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };
          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;
          case 7:
            op = _.ops.pop();
            _.trys.pop();
            continue;
          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }
            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }
            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }
            if (t && _.label < t[2]) {
              _.label = t[2];
              _.ops.push(op);
              break;
            }
            if (t[2]) _.ops.pop();
            _.trys.pop();
            continue;
        }
        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
      if (op[0] & 5) throw op[1];
      return {
        value: op[0] ? op[1] : void 0,
        done: true
      };
    }
  };
  var JobsList = function JobsList(_a) {
    var jobs = _a.jobs,
      brands = _a.brands,
      jobStatuses = _a.jobStatuses,
      users = _a.users,
      skeletonLoading = _a.skeletonLoading,
      onDismiss = _a.onDismiss;
    var _b = (0, _react.useContext)(_emberContext.default),
      intl = _b.intl,
      transitionTo = _b.transitionTo,
      setFilter = _b.setFilter,
      filter = _b.filter,
      showGeneralApplications = _b.showGeneralApplications,
      config = _b.config,
      currentSession = _b.currentSession,
      getAnnouncements = _b.getAnnouncements,
      dismissAnnouncement = _b.dismissAnnouncement;
    // const showGeneralApplications = true;
    var _c = config.REGION,
      links = _c.links,
      region = _c.region,
      locale = _c.locale;
    var forceUpdate = (0, _useForceUpdate.useForceUpdate)();
    var jobActions = (0, _jobsActions.getJobActions)(forceUpdate);
    var filteredGaJobs = brands.filter((0, _jobsFilter.filterGaJob)(filter));
    // filteredGaJobs.sort(sortGaJobs);
    var _d = (0, _useHrBoard.useHrBoard)(),
      isLoading = _d.isLoading,
      data = _d.data;
    var featureHR = config.REGION.features.systems.hr;
    var isFilterOn = (filter === null || filter === void 0 ? void 0 : filter.user) || (filter === null || filter === void 0 ? void 0 : filter.brand) || (filter === null || filter === void 0 ? void 0 : filter.status) || (filter === null || filter === void 0 ? void 0 : filter.status) === '0' || (filter === null || filter === void 0 ? void 0 : filter.search);
    var getLang = function getLang() {
      var _a, _b;
      return (((_b = (_a = intl.primaryLocale) === null || _a === void 0 ? void 0 : _a.split('-')) === null || _b === void 0 ? void 0 : _b[0]) || '') + '/';
    };
    var openNewJobPage = function openNewJobPage() {
      window.open("".concat(links.new_site).concat(getLang(), "create-job?").concat(new URLSearchParams(window.location.search).toString()), '_self');
    };
    var openBrandsPage = function openBrandsPage(brandId) {
      window.open("".concat(links.new_site).concat(getLang(), "/brands/").concat(brandId, "/spotlight"), '_self');
    };
    var emptyScreens = {
      jobsEmptyFilterOn: (0, _emptyScreens.jobsEmptyFilterOn)(),
      jobsEmpty: (0, _emptyScreens.jobsEmpty)(openNewJobPage),
      brandsEmpty: (0, _emptyScreens.jobsEmpty)(openNewJobPage)
    };
    var renderRegularJobItems = function renderRegularJobItems() {
      if (!jobs.length) {
        return isFilterOn ? emptyScreens.jobsEmptyFilterOn : emptyScreens.jobsEmpty;
      }
      return _react.default.createElement("div", {
        className: 'jobs-list'
      }, jobs.map(function (job) {
        return _react.default.createElement(_RegularJobItem.RegularJobItem, {
          key: job.id,
          job: job,
          actions: jobActions(job)
        });
      }));
    };
    var dispatch = (0, _reactRedux.useDispatch)();
    var renderGaJobItems = function renderGaJobItems() {
      if (!(filteredGaJobs === null || filteredGaJobs === void 0 ? void 0 : filteredGaJobs.length)) {
        return emptyScreens.jobsEmptyFilterOn;
      }
      return _react.default.createElement("div", {
        className: 'jobs-list'
      }, filteredGaJobs.map(function (brand) {
        var job = brand.get('generalApplicationJob.content');
        if (!job) {
          return _react.default.createElement(_GaInactiveJob.GaCreateJob, {
            key: 'no-job-' + brand.id,
            brand: brand
          });
        }
        if (job.isEnded) {
          return _react.default.createElement(_GaInactiveJob.GaEnableJob, {
            key: job.id,
            brand: brand,
            activate: function activate() {
              return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                  switch (_a.label) {
                    case 0:
                      return [4 /*yield*/, job.activateGa()];
                    case 1:
                      _a.sent();
                      return [4 /*yield*/, job.reload()];
                    case 2:
                      _a.sent();
                      return [4 /*yield*/, brand.reload()];
                    case 3:
                      _a.sent();
                      forceUpdate();
                      return [2 /*return*/];
                  }
                });
              });
            }
          });
        }

        if (job.isActive || job.isDraft) {
          var actions = __assign(__assign({}, jobActions(job)), {
            remove: function remove() {
              return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                  switch (_a.label) {
                    case 0:
                      return [4 /*yield*/, jobActions(job).remove()];
                    case 1:
                      _a.sent();
                      brand.set('generalApplicationJob', null);
                      forceUpdate();
                      return [2 /*return*/];
                  }
                });
              });
            },

            triggerGaVisibility: function triggerGaVisibility() {
              return dispatch((0, _jobsGaVisiblity.showGaVisibilityModal)(intl, "".concat(links.job).concat(job.slug || job.id), region, brand.id, brand.slug ? "https://".concat(brand.slug, ".").concat(links.webShort) : null, brand.color || null));
            }
          });
          return _react.default.createElement(_GaJobItem.GaJobItem, {
            key: job.id,
            job: job,
            actions: actions,
            subscription: brand.generalApplicationSubscription
          });
        }
        return null;
      }));
    };
    (0, _react.useLayoutEffect)(function () {
      var _a, _b;
      if ((_a = currentSession === null || currentSession === void 0 ? void 0 : currentSession.user) === null || _a === void 0 ? void 0 : _a.phantom) {
        return;
      }
      if (((_b = currentSession === null || currentSession === void 0 ? void 0 : currentSession.client) === null || _b === void 0 ? void 0 : _b.status) === 'BLACKLIST') {
        transitionTo === null || transitionTo === void 0 ? void 0 : transitionTo('locked');
      }
      var getData = function getData() {
        return __awaiter(void 0, void 0, void 0, function () {
          var announcementsData, announcements;
          return __generator(this, function (_a) {
            switch (_a.label) {
              case 0:
                return [4 /*yield*/, getAnnouncements()];
              case 1:
                announcementsData = _a.sent();
                announcements = announcementsData === null || announcementsData === void 0 ? void 0 : announcementsData.announcements;
                if (announcements && announcements.length) {
                  setTimeout(function () {
                    dispatch((0, _modal.showModal)(_types.ModalType.JOBS_LIST_ANNOUNCEMENTS, {
                      announcements: announcements.map(function (a) {
                        return __assign(__assign({}, a), {
                          label: null,
                          dismiss: function dismiss() {
                            return dismissAnnouncement(a.announcementType);
                          }
                        });
                      }),
                      onDismiss: onDismiss
                    }));
                  }, 1000);
                }
                return [2 /*return*/];
            }
          });
        });
      };

      getData();
    }, []);
    var renderContent = function renderContent() {
      if (!brands.length) {
        return _react.default.createElement("div", {
          className: 'container'
        }, emptyScreens.brandsEmpty);
      }
      return _react.default.createElement(_react.default.Fragment, null, (!skeletonLoading || showGeneralApplications) && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("div", {
        className: 'container'
      }, _react.default.createElement("div", {
        className: 'tw-bg-orange-200 tw-h-0.5 tw-mt-4'
      })), _react.default.createElement(_JobsListFilter.JobsListFilter, {
        filter: __assign(__assign({}, filter), {
          showGeneralApplications: showGeneralApplications
        }),
        updateFilter: setFilter,
        gaCount: brands === null || brands === void 0 ? void 0 : brands.filter(function (b) {
          return b.get('generalApplicationJob.id');
        }).length,
        brands: brands,
        statuses: jobStatuses,
        users: users
      }), _react.default.createElement("div", {
        className: 'container'
      }, showGeneralApplications ? renderGaJobItems() : renderRegularJobItems())));
    };
    var renderHrButton = function renderHrButton() {
      if (!isLoading && data && featureHR && (currentSession.user.isManager || currentSession.user.isAgent && currentSession.user.allowViewHrBoard)) {
        return _react.default.createElement(_headerButton.HeaderButton, {
          variant: _headerButton.HeaderButtonVariant.DARK_BLUE,
          onClick: function onClick() {
            return transitionTo('client.applications.index', data.id);
          }
        }, intl.t('hr.board.title'));
      }
      if (featureHR && isLoading) {
        return _react.default.createElement(_LoadingBar.LoadingBar, null);
      }
    };
    var menuItems = (!currentSession.user.isAgent || currentSession.user.allowCreateJob) && brands.length ? [{
      text: intl.t('menu.new_ad'),
      onClick: openNewJobPage
    }] : [];
    if (config.REGION.features.systems.hr && currentSession.client.hrSystems && currentSession.client.hrSystems.includes('KJARNI') && brands.length > 0) {
      menuItems.push({
        text: intl.t('menu.create-from-kjarni'),
        onClick: function onClick() {
          return transitionTo('client.create-job.kjarni');
        }
      });
    }
    // can be removed anytime after the date has passed
    var isVotingBoardNew = (0, _react.useMemo)(function () {
      return (0, _dateFns.addDays)((0, _dateFns.parseISO)('2023-10-17T00:00:00'), 20) > new Date();
    }, []);
    return _react.default.createElement(_react.default.Fragment, null, brands.length > 0 && _react.default.createElement(_PageTitleHeader.PageTitleHeader, {
      title: intl.t('menu.ads'),
      actionName: !currentSession.user.isAgent || currentSession.user.allowCreateJob ? intl.t('menu.new_ad') : '',
      leftAction: renderHrButton(),
      onAction: (!currentSession.user.isAgent || currentSession.user.allowCreateJob) && brands.length ? openNewJobPage : undefined,
      menuItems: menuItems
    }, config.REGION.features.ideaBoard && _react.default.createElement(_headerButton.HeaderButton, {
      variant: _headerButton.HeaderButtonVariant.DARK_BLUE,
      onClick: function onClick() {
        return window.open(config.REGION.links.votingBoard, '_blank');
      }
    }, intl.t('client.voting-board'), isVotingBoardNew && _react.default.createElement("strong", {
      className: 'tw-bg-yellow-500 tw-px-2 tw-py-1 tw-text-white tw-ml-2 tw--mr-1 tw-rounded-sm tw-text-xs'
    }, intl.t('components.upsell.new'))), config.REGION.features.spotlight && brands.length > 0 && _react.default.createElement(_headerButton.HeaderButton, {
      onClick: function onClick() {
        openBrandsPage(brands.firstObject.get('id'));
      },
      variant: _headerButton.HeaderButtonVariant.DARK_BLUE
    }, intl.t('spotlight.name'))), renderContent());
  };
  _exports.JobsList = JobsList;
});