define("admin/templates/components/settings/english-questions-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mp4YhggQ",
    "block": "{\"symbols\":[\"option\",\"index\",\"enoption\"],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@overlayClass\"],[[30,[36,3],[[32,0],\"close\"],null],\"modal-popup english-modal\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"default-dialog wide\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[1,[30,[36,4],[\"components.settings.english_question_modal.title\"],null]],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"edit-form\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-14\"],[12],[2,\"\\n          \"],[8,\"forms/input-field\",[],[[\"@value\",\"@length\",\"@placeholder\",\"@label\"],[[34,5,[\"enquestion\"]],70,[30,[36,4],[\"string.question\"],null],[34,5,[\"question\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5,[\"options_parsed\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,2],[35,0]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"forms/input-field\",[],[[\"@value\",\"@length\",\"@label\"],[[32,3,[\"value\"]],70,[32,1]]],null],[2,\"\\n\"]],\"parameters\":[3]}]]]],\"parameters\":[1,2]}]]],[2,\"\\n      \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn gray-brd inline\"],[4,[38,3],[[32,0],\"close\"],null],[12],[1,[30,[36,4],[\"string.cancel\"],null]],[13],[2,\"\\n        \"],[10,\"span\"],[15,0,[30,[36,9],[[35,8],\"disabled\"],null]],[12],[2,\"\\n          \"],[11,\"button\"],[24,0,\"btn green inline\"],[4,[38,3],[[32,0],\"save\"],null],[12],[1,[30,[36,4],[\"string.continue\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"enoptions\",\"object-at\",\"with\",\"action\",\"t\",\"model\",\"-track-array\",\"each\",\"isValid\",\"unless\"]}",
    "meta": {
      "moduleName": "admin/templates/components/settings/english-questions-modal.hbs"
    }
  });
  _exports.default = _default;
});