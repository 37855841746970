define("admin/templates/components/applications/alfrello-excel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "JZNrFPhE",
    "block": "{\"symbols\":[],\"statements\":[[11,\"button\"],[16,0,[31,[\"btn gray-brd \",[30,[36,1],[[35,0],\"loading\"],null]]]],[4,[38,2],[[32,0],\"download\"],null],[12],[2,\"Excel\"],[13]],\"hasEval\":false,\"upvars\":[\"isLoading\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "admin/templates/components/applications/alfrello-excel.hbs"
    }
  });
  _exports.default = _default;
});