define("admin/components/jobs/suggest-tags-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    hasUpsellTags: Ember.computed('suggestedTags.@each.upsell', function () {
      return this.suggestedTags.some(function (t) {
        return t.upsell;
      });
    }),
    store: Ember.inject.service(),
    apiClient: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    actions: {
      continue: function _continue() {
        this.set('suggestTags');
        this.set('publishModal', true);
      },
      toggleTag: function toggleTag(tag) {
        if (this.get('job.tags').indexOf(tag) >= 0) {
          tag.set('upsell');
          this.job.tags.removeObject(tag);
        } else {
          tag.set('upsell', true);
          this.job.tags.pushObject(tag);
        }
      }
    }
  });
  _exports.default = _default;
});