define("admin/compiled/form/Form", ["exports", "react", "react-hook-form"], function (_exports, _react, _reactHookForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Form = Form;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  function Form(_a) {
    var children = _a.children,
      form = _a.form,
      onSubmit = _a.onSubmit,
      onError = _a.onError;
    var defaultForm = (0, _reactHookForm.useForm)();
    var computedForm = form || defaultForm;
    return _react.default.createElement(_reactHookForm.FormProvider, __assign({}, computedForm), _react.default.createElement("form", {
      onSubmit: computedForm.handleSubmit(onSubmit, onError)
    }, children));
  }
});