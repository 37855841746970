define("admin/models/questionoption", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    active: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    option: (0, _model.attr)('string'),
    weight: (0, _model.attr)('number'),
    questionid: (0, _model.attr)('string'),
    name: Ember.computed('option', function () {
      return this.option;
    })
  });
  _exports.default = _default;
});