define("admin/templates/components/applications/video-invite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Mh9qaxV6",
    "block": "{\"symbols\":[\"@job\",\"@application\",\"@applicationIds\",\"@quantity\",\"@column\",\"@done\",\"&default\"],\"statements\":[[18,7,null],[2,\"\\n\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"applications/video-invite-modal\",[],[[\"@application\",\"@applicationIds\",\"@job\",\"@quantity\",\"@column\",\"@toggle\",\"@done\"],[[32,2],[32,3],[32,1],[32,4],[32,5],[30,[36,0],[\"videoInviteModal\",[32,0]],null],[32,6]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"applications/video-purchase-modal\",[],[[\"@job\",\"@toggle\",\"@done\"],[[32,1],[30,[36,0],[\"purchaseVideoModal\",[32,0]],null],[30,[36,0],[\"videoInviteModal\",[32,0]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"applications/confirm-reinvite-modal\",[],[[\"@answers\",\"@toggle\",\"@done\"],[[32,0,[\"answers\"]],[30,[36,0],[\"confirmReinviteModal\",[32,0]],null],[30,[36,0],[\"videoInviteModal\",[32,0]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"toggle-action\",\"videoInviteModal\",\"if\",\"purchaseVideoModal\",\"confirmReinviteModal\"]}",
    "meta": {
      "moduleName": "admin/templates/components/applications/video-invite.hbs"
    }
  });
  _exports.default = _default;
});