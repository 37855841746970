define("admin/compiled/embed-ga-button/EmbedGaButton", ["exports", "react", "admin/compiled/utils/emberContext"], function (_exports, _react, _emberContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EmbedGaButton = void 0;
  var EmbedGaButton = function EmbedGaButton() {
    var intl = (0, _react.useContext)(_emberContext.default).intl;
    return _react.default.createElement("div", {
      className: 'embed-ga-button'
    }, _react.default.createElement("div", {
      className: 'embed-ga-button--left'
    }, _react.default.createElement("img", {
      src: 'https://storage.googleapis.com/alfredstatic/alfred-ga-button/alfred.svg'
    })), _react.default.createElement("div", {
      className: 'embed-ga-button--spacer'
    }), _react.default.createElement("div", {
      className: 'embed-ga-button--right'
    }, _react.default.createElement("img", {
      src: 'https://storage.googleapis.com/alfredstatic/alfred-ga-button/profile.svg'
    }), _react.default.createElement("span", null, intl.t('general-application.title'))));
  };
  _exports.EmbedGaButton = EmbedGaButton;
});