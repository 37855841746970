define("admin/serializers/question-template", ["exports", "admin/serializers/rest-serializer"], function (_exports, _restSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _restSerializer.default.extend({
    attrs: {
      type: {
        embedded: 'always'
      }
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'question-template';
    },
    payloadKeyFromModelName: function payloadKeyFromModelName() {
      return 'question';
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      if (payload.question) {
        if (payload.question.options === null) {
          delete payload.options;
        }
      }
      return this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});