define("admin/compiled/configureStore", ["exports", "admin/compiled/redux/reducers", "redux-thunk", "redux"], function (_exports, _reducers, _reduxThunk, _redux) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = configureStore;
  function configureStore(persistedState) {
    return (0, _redux.createStore)(_reducers.rootReducer, persistedState, (0, _redux.applyMiddleware)(_reduxThunk.default));
  }
});