define("admin/compiled/alfrello-profile-header/AlfrelloProfileHeader", ["exports", "react", "admin/compiled/alfrello-profile-invite/JobInterviewInvite", "admin/compiled/alfrello-profile-invite/VideoInterviewInvite", "admin/compiled/utils/languageContext"], function (_exports, _react, _JobInterviewInvite, _VideoInterviewInvite, _languageContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AlfrelloProfileHeader = void 0;
  var AlfrelloProfileHeader = function AlfrelloProfileHeader(_a) {
    var name = _a.name,
      email = _a.email,
      phonenumber = _a.phonenumber,
      age = _a.age,
      color = _a.color,
      image = _a.image,
      intl = _a.intl,
      interview = _a.interview,
      videoInterview = _a.videoInterview,
      close = _a.close,
      showModal = _a.showModal,
      application = _a.application,
      hasPurchasedVi = _a.hasPurchasedVi,
      isCopied = _a.isCopied,
      jobIsHRB = _a.jobIsHRB;
    return _react.default.createElement(_languageContext.LanguageProvider, {
      value: intl
    }, _react.default.createElement("div", {
      className: 'alfrello-profile-header'
    }, _react.default.createElement("div", {
      className: "alfrello-profile-header--left"
    }, image && _react.default.createElement("div", {
      className: 'alfrello-profile-header--image',
      style: {
        border: "4px ".concat(color, " solid"),
        backgroundImage: "url('".concat(image, "?dpr=1&h=200')")
      }
    }), _react.default.createElement("div", {
      className: "alfrello-profile-header--info ".concat(!image ? 'no-image' : '')
    }, _react.default.createElement("div", {
      className: "alfrello-profile-header--name ".concat(isCopied && !jobIsHRB ? '--copied' : '')
    }, name), _react.default.createElement("div", {
      className: 'alfrello-profile-header--infoline'
    }, email), _react.default.createElement("div", {
      className: 'alfrello-profile-header--infoline'
    }, phonenumber && _react.default.createElement("span", null, intl.t('general.phone'), ": ", phonenumber), ' ', age && _react.default.createElement("span", null, intl.t('string.age'), ": ", age, " ", intl.t('string.years'))))), !jobIsHRB && _react.default.createElement("div", {
      className: 'alfrello-profile-header--right'
    }, _react.default.createElement(_JobInterviewInvite.JobInterviewInvite, {
      interview: interview,
      showInviteModal: function showInviteModal() {
        return showModal('interviewInviteModal', {
          application: application
        });
      },
      showCancelModal: function showCancelModal() {
        return showModal('interviewCancelModal', {
          application: application
        });
      },
      showRemoteMeetingModal: function showRemoteMeetingModal() {
        return showModal('remoteMeetingModal', {
          application: application
        });
      }
    }), _react.default.createElement(_VideoInterviewInvite.VideoInterviewInvite, {
      interview: videoInterview,
      showInviteModal: function showInviteModal() {
        showModal('videoInviteModal', {
          applicationIds: [application.id]
        });
      }
    })), _react.default.createElement("div", {
      className: "profile-modal--close",
      onClick: close
    })));
  };
  _exports.AlfrelloProfileHeader = AlfrelloProfileHeader;
});