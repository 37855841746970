define("admin/routes/consult", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    loginManager: Ember.inject.service(),
    queryParams: {
      jobid: {
        refreshModel: true
      },
      email: {
        refreshModel: true
      },
      code: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var _this = this;
      var jobid = _ref.jobid,
        email = _ref.email,
        code = _ref.code;
      this.loginManager.goCapacent(email, code).then(function () {
        _this.transitionTo('client.applications.index', jobid);
      });
    }
  });
  _exports.default = _default;
});