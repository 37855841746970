define("admin/compiled/alfrello-profile-invite/EmptyInvite", ["exports", "react", "admin/compiled/alfrello-profile-invite/AlfrelloProfileInvite"], function (_exports, _react, _AlfrelloProfileInvite) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EmptyInvite = void 0;
  var EmptyInvite = function EmptyInvite(_a) {
    var showInviteModal = _a.showInviteModal,
      icon = _a.icon,
      children = _a.children;
    return _react.default.createElement("div", {
      className: 'alfrello-profile-invite--space-container'
    }, _react.default.createElement("div", {
      className: "alfrello-profile-invite--container"
    }, _react.default.createElement(_AlfrelloProfileInvite.AlfrelloProfileInvite, {
      icon: icon,
      onClick: showInviteModal
    }, _react.default.createElement("div", {
      className: 'alfrello-profile-invite--content'
    }, children))));
  };
  _exports.EmptyInvite = EmptyInvite;
});