define("admin/compiled/jobs-list/jobs-actions", ["exports", "react", "admin/compiled/utils/emberContext"], function (_exports, _react, _emberContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getJobActions = void 0;
  var __awaiter = void 0 && (void 0).__awaiter || function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P ? value : new P(function (resolve) {
        resolve(value);
      });
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }
      function rejected(value) {
        try {
          step(generator["throw"](value));
        } catch (e) {
          reject(e);
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };
  var __generator = void 0 && (void 0).__generator || function (thisArg, body) {
    var _ = {
        label: 0,
        sent: function sent() {
          if (t[0] & 1) throw t[1];
          return t[1];
        },
        trys: [],
        ops: []
      },
      f,
      y,
      t,
      g;
    return g = {
      next: verb(0),
      "throw": verb(1),
      "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
      return this;
    }), g;
    function verb(n) {
      return function (v) {
        return step([n, v]);
      };
    }
    function step(op) {
      if (f) throw new TypeError("Generator is already executing.");
      while (g && (g = 0, op[0] && (_ = 0)), _) try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];
        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;
          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };
          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;
          case 7:
            op = _.ops.pop();
            _.trys.pop();
            continue;
          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }
            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }
            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }
            if (t && _.label < t[2]) {
              _.label = t[2];
              _.ops.push(op);
              break;
            }
            if (t[2]) _.ops.pop();
            _.trys.pop();
            continue;
        }
        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
      if (op[0] & 5) throw op[1];
      return {
        value: op[0] ? op[1] : void 0,
        done: true
      };
    }
  };
  var getJobActions = function getJobActions(forceUpdate) {
    var _a = (0, _react.useContext)(_emberContext.default),
      intl = _a.intl,
      transitionTo = _a.transitionTo,
      config = _a.config,
      currentSession = _a.currentSession,
      openModal = _a.openModal,
      removeJob = _a.removeJob;
    var _b = config.REGION,
      links = _b.links,
      locale = _b.locale;
    return function (job) {
      var preventBlacklisted = function preventBlacklisted(func) {
        return function () {
          if (currentSession.client.blacklisted) {
            console.log('Client blacklisted.');
          } else {
            func();
          }
        };
      };
      return {
        triggerUpsell: preventBlacklisted(function () {
          return openModal('upsellModal', {
            job: job
          });
        }),
        editJob: preventBlacklisted(function () {
          var _a, _b;
          var lang = (((_b = (_a = intl.primaryLocale) === null || _a === void 0 ? void 0 : _a.split('-')) === null || _b === void 0 ? void 0 : _b[0]) || '') + '/';
          if (job.generalApplication) {
            transitionTo('client.edit-job', job.id);
          } else {
            window.open("".concat(links.new_site).concat(lang, "create-job/").concat(job.id, "?").concat(new URLSearchParams(window.location.search).toString()), '_self');
          }
        }),
        closeJob: function closeJob() {
          return openModal('closeJobModal', {
            job: job,
            refresh: function refresh() {
              return forceUpdate();
            }
          });
        },
        remove: function remove() {
          return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
              switch (_a.label) {
                case 0:
                  // this function is overridden in GA job
                  return [4 /*yield*/, removeJob(job)];
                case 1:
                  // this function is overridden in GA job
                  _a.sent();
                  forceUpdate();
                  return [2 /*return*/];
              }
            });
          });
        },

        openApplications: preventBlacklisted(function () {
          return transitionTo('client.applications.index', job.id, {
            queryParams: {
              messages: false
            }
          });
        }),
        openMessages: preventBlacklisted(function () {
          return transitionTo('client.applications.index', job.id, {
            queryParams: {
              messages: true
            }
          });
        })
      };
    };
  };
  _exports.getJobActions = getJobActions;
});