define("admin/components/check-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'a',
    classNames: ['checkbox'],
    classNameBindings: ['disabled', 'checked'],
    click: function click() {
      this.toggleProperty('checked');
    }
  });
  _exports.default = _default;
});