define("admin/compiled/job-card/job-card-header", ["exports", "@alfred-is/alfred-design-system", "classnames", "react", "admin/compiled/job-card/job-card"], function (_exports, _alfredDesignSystem, _classnames, _react, _jobCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.JobCardHeader = void 0;
  var __spreadArray = void 0 && (void 0).__spreadArray || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
      if (ar || !(i in from)) {
        if (!ar) ar = Array.prototype.slice.call(from, 0, i);
        ar[i] = from[i];
      }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
  };
  var JobCardHeader = function JobCardHeader(_a) {
    var brandName = _a.brandName,
      logo = _a.logo,
      hideInfo = _a.hideInfo,
      children = _a.children;
    var logoUrl = "".concat(logo, "?h=60");
    var _b = (0, _jobCard.useJobCardContext)(),
      isHovered = _b.isHovered,
      infoActive = _b.infoActive;
    return _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-flex', 'tw-justify-between', 'tw-items-center', 'tw-p-5', 'tw-rounded-t-sm', {
        'tw-shadow-md': infoActive
      })
    }, _react.default.createElement("div", {
      className: "tw-flex tw-items-center"
    }, logo && _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-bg-white', 'tw-w-[60px]', 'tw-h-[40px]', 'tw-rounded-lg', 'tw-border', 'tw-border-gray-200', 'tw-flex', 'tw-items-center', 'tw-justify-center', 'tw-overflow-hidden')
    }, _react.default.createElement("img", {
      className: "tw-max-h-[30px] tw-inline-block",
      src: logoUrl,
      alt: ''
    })), brandName && _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-ml-4', {
        'tw-text-gray-500': !isHovered,
        'tw-text-black-500': isHovered
      })
    }, brandName)), _react.default.createElement("div", {
      className: "tw-flex tw-items-center"
    }, isHovered && !hideInfo && _react.default.createElement(InfoBtn, null), children));
  };
  _exports.JobCardHeader = JobCardHeader;
  var InfoBtn = function InfoBtn() {
    var _a = (0, _jobCard.useJobCardContext)(),
      setInfoActive = _a.setInfoActive,
      infoActive = _a.infoActive,
      isHovered = _a.isHovered;
    return _react.default.createElement(_alfredDesignSystem.CircularIcon, {
      buttonProps: {
        onClick: function onClick() {
          setInfoActive(function (old) {
            return !old;
          });
        }
      },
      icon: infoActive ? _alfredDesignSystem.AlfredIconT.DropDown : _alfredDesignSystem.AlfredIconT.Info,
      iconSize: infoActive ? _alfredDesignSystem.SizeT.medium : _alfredDesignSystem.SizeT.large,
      bgColor: infoActive ? isHovered ? _alfredDesignSystem.ColorT.black : _alfredDesignSystem.ColorT.gray400 : isHovered ? _alfredDesignSystem.ColorT.black : _alfredDesignSystem.ColorT.gray400,
      iconColor: _alfredDesignSystem.ColorT.white,
      size: _alfredDesignSystem.SizeT.medium
    });
  };
  var Menu = function Menu(_a) {
    var withInfo = _a.withInfo,
      _b = _a.menuItems,
      menuItems = _b === void 0 ? [] : _b;
    var _c = (0, _jobCard.useJobCardContext)(),
      isHovered = _c.isHovered,
      setInfoActive = _c.setInfoActive;
    var t = (0, _alfredDesignSystem.useNxTranslation)().t;
    var infoItem = {
      text: t('job-card.info'),
      icon: _alfredDesignSystem.AlfredIconT.Info,
      iconColor: _alfredDesignSystem.ColorT.black,
      onClick: function onClick() {
        return setInfoActive(true);
      }
    };
    var additionalMenuItems = withInfo ? [infoItem] : [];
    return _react.default.createElement(_alfredDesignSystem.DropdownMenu, {
      menuItems: __spreadArray(__spreadArray([], additionalMenuItems, true), menuItems, true),
      trigger: _react.default.createElement(_alfredDesignSystem.PlainIcon, {
        className: 'tw-ml-5',
        icon: _alfredDesignSystem.AlfredIconT.SpacedDots,
        color: isHovered ? _alfredDesignSystem.ColorT.black : _alfredDesignSystem.ColorT.gray400,
        size: 28
      })
    });
  };
  JobCardHeader.Menu = Menu;
});