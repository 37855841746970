define("admin/models/customer", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    adsCount: (0, _model.attr)('number'),
    contact: (0, _model.attr)('string'),
    contactEmail: (0, _model.attr)('string'),
    discount: (0, _model.attr)('number'),
    graceDays: (0, _model.attr)('string'),
    brandsCount: (0, _model.attr)('number'),
    lastDisplayed: (0, _model.attr)('moment'),
    logo: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    registered: (0, _model.attr)('moment'),
    ssn: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    usersCount: (0, _model.attr)('number'),
    phone: (0, _model.attr)('string'),
    vat: (0, _model.attr)('string'),
    address: (0, _model.belongsTo)('address')
  });
  _exports.default = _default;
});