define("admin/routes/client/applications/setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    model: function model() {
      var applications = this.modelFor('client.applications');
      return this.store.query('alfrellocategory', {
        jobid: applications.job.get('id')
      });
    },
    actions: {
      createColumn: function createColumn(category, title) {
        var _this = this;
        var newColumn = this.store.createRecord('alfrello-column', {
          title: title,
          ordinal: category.get('alfrello-columns.length'),
          rand: Math.floor(Math.random() * 100 + 99),
          alfrellocategory: category
        });

        // category.get('alfrello-columns').pushObject(newColumn);

        var model = this.modelFor('client.applications');
        model.job.newColumn(newColumn.serialize()).then(function (column) {
          newColumn.rollbackAttributes();
          category.get('alfrello-columns').pushObject(_this.store.push(column));
        });
      },
      deleteColumn: function deleteColumn(id) {
        var column = this.store.peekRecord('alfrello-column', id);
        if (!column.isdefault) {
          column.destroyRecord().then(function () {
            if (column.get('alfrellocategory.alfrello-columns')) {
              column.get('alfrellocategory.alfrello-columns').removeObject(column);
            }
          }).catch(function (error) {
            console.log(error);
            // this.notifications.error(`This column contains active applicants.`);
          });
        }
      }
    }
  });
  _exports.default = _default;
});