define("admin/components/capacent-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    apiClient: Ember.inject.service(),
    unitPrice: Ember.computed('capacentProduct.{price,offerPrice}', function () {
      var product = this.capacentProduct;
      if (product) {
        return product.offerPrice ? product.offerPrice : product.price;
      }
    }),
    totalPrice: Ember.computed('capacentProduct.{count,price,offerPrice}', function () {
      var product = this.capacentProduct;
      if (product) {
        var price = product.offerPrice ? product.offerPrice : product.price;
        return price * product.count;
      }
    }),
    canPurchase: Ember.computed('capacentProduct.{canPurchase}', 'jobStatusId', function () {
      if (Number(this.jobStatusId) !== 3 || !this.capacentProduct) {
        return false;
      }
      return this.capacentProduct.canPurchase;
    }),
    actions: {
      purchase: function purchase() {
        var _this = this;
        this.set('isPurchasing', true);
        this.apiClient.post("/jobs/".concat(this.jobId, "/purchase"), {
          productKey: 'APPLICATION_CLASSIFICATION',
          comment: this.comment ? this.comment : null
        }).then(function (res) {
          _this.set('capacentProduct', res);
          _this.set('showCapacentModal');
          if (!_this.get('currentSession.user.phantom')) {
            _this.columns.forEach(function (c) {
              return c.set('dragLocked', true);
            });
          }
          _this.refreshJob();
          _this.notifications.success('Flokkun hjá Capacent hefur verið keypt.');
        }).finally(function () {
          _this.set('isPurchasing');
        });
      }
    }
  });
  _exports.default = _default;
});