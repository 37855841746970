define("admin/compiled/ga-select-plan/GaSelectPlanItem", ["exports", "react", "admin/compiled/utils/emberContext", "admin/compiled/utils"], function (_exports, _react, _emberContext, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GaSelectPlanItem = void 0;
  var GaSelectPlanItem = function GaSelectPlanItem(_a) {
    var title = _a.title,
      price = _a.price,
      selected = _a.selected,
      subtitle = _a.subtitle,
      discount = _a.discount,
      onClick = _a.onClick;
    var config = (0, _react.useContext)(_emberContext.default).config;
    var _b = config.REGION,
      symbol = _b.symbol,
      thousand = _b.thousand;
    return _react.default.createElement("div", {
      "data-testid": 'select-plan-item',
      className: "ga-select-plan-item ".concat(selected ? 'selected' : ''),
      onClick: onClick
    }, _react.default.createElement("div", {
      className: 'ga-select-plan-item--title'
    }, title), _react.default.createElement("div", {
      className: 'ga-select-plan-item--content'
    }, _react.default.createElement("div", {
      className: 'ga-select-plan-item--price'
    }, "".concat((0, _utils.formatNumber)(price, thousand), " ").concat(symbol)), subtitle && _react.default.createElement("div", {
      className: 'ga-select-plan-item--subtitle'
    }, subtitle)));
  };
  _exports.GaSelectPlanItem = GaSelectPlanItem;
});