define("admin/helpers/filter-utils/language-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.languageFilter = void 0;
  var languageFilter = function languageFilter(profileLanguages, filter, fit) {
    if (filter != null && filter.length) {
      var desiredLanguages = filter.filter(function (f) {
        return f.language != null && f.value != null;
      });
      desiredLanguages.forEach(function (a) {
        if (!profileLanguages.toArray().some(function (b) {
          return a.language.id == b.get('language.id') && b.get('languageSkill.weight') >= a.value.weight;
        })) {
          fit = false;
        }
      });
    }
    return fit;
  };
  _exports.languageFilter = languageFilter;
});