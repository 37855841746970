define("admin/helpers/filter-utils/period-filter", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.periodFilter = void 0;
  var periodFilter = function periodFilter(dateSubmitted, filterPeriod, fit) {
    var resetToMidnight = function resetToMidnight(date) {
      date.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      });
      return date;
    };
    if (filterPeriod && filterPeriod.length > 1) {
      var submitted = resetToMidnight((0, _moment.default)(dateSubmitted.toISOString()));
      var from = resetToMidnight(filterPeriod[0]);
      var to = resetToMidnight(filterPeriod[1]);
      if (!(submitted.isSameOrBefore(to) && submitted.isSameOrAfter(from))) {
        fit = false;
      }
    }
    return fit;
  };
  _exports.periodFilter = periodFilter;
});