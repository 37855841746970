define("admin/templates/components/brands/departments-info-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5eJ7+F2d",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@overlayClass\"],[[30,[36,0],[[32,0],\"close\"],null],\"modal-popup info-modal\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"default-dialog\"],[12],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"text-center\"],[12],[1,[30,[36,1],[\"components.brands.departments_info_modal.divisions\"],null]],[13],[2,\"\\n\\n    \"],[10,\"p\"],[12],[1,[30,[36,1],[\"components.brands.departments_info_modal.p\"],null]],[13],[2,\"\\n\\n    \"],[10,\"p\"],[12],[10,\"strong\"],[14,0,\"blue\"],[12],[2,\"1.\"],[13],[2,\" \"],[1,[30,[36,1],[\"components.brands.departments_info_modal.value_one\"],null]],[13],[2,\"\\n\\n    \"],[10,\"p\"],[12],[10,\"strong\"],[14,0,\"blue\"],[12],[2,\"2.\"],[13],[2,\" \"],[1,[30,[36,1],[\"components.brands.departments_info_modal.two\"],null]],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"t\"]}",
    "meta": {
      "moduleName": "admin/templates/components/brands/departments-info-modal.hbs"
    }
  });
  _exports.default = _default;
});