define("admin/helpers/filter-utils/education-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.educationFilter = void 0;
  var educationFilter = function educationFilter(profileEducations, filter, fit) {
    if (filter != null && filter.length) {
      var desiredEducationIds = filter.filter(function (f) {
        return f.education != null;
      }).map(function (f) {
        return f.education.id;
      });
      var profileEducationIds = profileEducations.map(function (edu) {
        var _edu$degree;
        return (_edu$degree = edu.degree) === null || _edu$degree === void 0 ? void 0 : _edu$degree.id;
      });
      if (desiredEducationIds.length && !desiredEducationIds.some(function (a) {
        return profileEducationIds.some(function (b) {
          return a == b;
        });
      })) {
        fit = false;
      }
    }
    return fit;
  };
  _exports.educationFilter = educationFilter;
});