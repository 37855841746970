define("admin/compiled/spotlight/section-spacer/section-spacer", ["exports", "classnames", "react"], function (_exports, _classnames, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SectionSpacer = void 0;
  var SectionSpacer = function SectionSpacer(_a) {
    var _b;
    var title = _a.title,
      noUpperCase = _a.noUpperCase;
    return _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-flex', 'tw-gap-4', 'tw-items-center', 'tw-font-medium', 'tw-my-10', (_b = {}, _b['tw-uppercase'] = !noUpperCase, _b))
    }, _react.default.createElement("div", {
      className: "tw-h-px tw-bg-gray-200 tw-flex-grow"
    }), _react.default.createElement("div", {
      className: "tw-text-gray-400 tw-text-md"
    }, title), _react.default.createElement("div", {
      className: "tw-h-px tw-bg-gray-200 tw-flex-grow"
    }));
  };
  _exports.SectionSpacer = SectionSpacer;
  var _default = SectionSpacer;
  _exports.default = _default;
});