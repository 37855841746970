define("admin/components/skills/new-skill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    apiAdmin: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.skill) {
        this.set('skill', {
          subCategories: [{
            id: this.subcategoryId
          }]
        });
      }
    },
    didRender: function didRender() {
      $('#new-skill-input').focus();
    },
    actions: {
      save: function save() {
        var _this = this;
        var skill = this.skill;
        this.set('loading', true);
        this.apiAdmin.post("/skills", {
          skill: skill
        }).then(function () {
          _this.refreshData();
          _this.set('newSkill');
        }).finally(function () {
          return _this.set('loading');
        });
      }
    }
  });
  _exports.default = _default;
});