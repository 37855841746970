define("admin/templates/components/applications/email-notification-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "uG/kCOdA",
    "block": "{\"symbols\":[\"type\",\"@job\"],\"statements\":[[10,\"div\"],[14,0,\"quick-dialog\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"close\"],[4,[38,2],[[32,0],\"close\"],null],[12],[13],[2,\"\\n  \"],[10,\"h2\"],[12],[1,[30,[36,3],[\"components.applications.email_notifications.title\"],null]],[13],[2,\"\\n\\n  \"],[10,\"p\"],[12],[1,[30,[36,3],[\"components.applications.email_notifications.p\"],null]],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"buttons\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"button\"],[16,0,[31,[\"btn \",[30,[36,1],[[30,[36,0],[[32,1,[\"notificationType\"]],[32,2,[\"notificationType\"]]],null],\"blue\",\"gray-brd\"],null]]]],[4,[38,2],[[32,0],\"setNotifications\",[32,1]],null],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"action\",\"t\",\"notificationTypes\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/components/applications/email-notification-modal.hbs"
    }
  });
  _exports.default = _default;
});