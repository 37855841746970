define("admin/templates/components/format-numeric", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1WHvifBD",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,4],[[35,3]],[[\"thousand\",\"format\",\"precision\",\"symbol\",\"decimal\"],[[35,2],\"%v\",[35,1],\".\",[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"decimal\",\"precision\",\"thousand\",\"value\",\"format-money\"]}",
    "meta": {
      "moduleName": "admin/templates/components/format-numeric.hbs"
    }
  });
  _exports.default = _default;
});