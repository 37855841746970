define("admin/components/ui/context-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['context-menu'],
    actions: {
      hide: function hide() {
        this.set('showMenu');
      }
    }
  });
  _exports.default = _default;
});