define("admin/compiled/alfrello-board/AlfrelloBoard", ["exports", "react", "react-beautiful-dnd", "admin/compiled/alfrello-column-add/AlfrelloColumnAdd", "admin/compiled/utils/hrbContext", "admin/compiled/utils/languageContext", "admin/compiled/utils/useRenderColumn"], function (_exports, _react, _reactBeautifulDnd, _AlfrelloColumnAdd, _hrbContext, _languageContext, _useRenderColumn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AlfrelloBoard = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var AlfrelloBoard = function AlfrelloBoard(_a) {
    var columns = _a.columns,
      filter = _a.filter,
      emberUtils = _a.emberUtils,
      columnActions = _a.columnActions,
      intl = _a.intl,
      showInactiveApplications = _a.showInactiveApplications,
      allApplications = _a.allApplications,
      moveApplication = _a.moveApplication,
      reorderApplication = _a.reorderApplication,
      moveColumn = _a.moveColumn,
      isLoading = _a.isLoading,
      showNewMessages = _a.showNewMessages,
      hasPurchasedVi = _a.hasPurchasedVi,
      jobIsHRB = _a.jobIsHRB;
    var onDragEnd = function onDragEnd(res) {
      var source = res.source,
        destination = res.destination;
      if (res.type === 'column') {
        return moveColumn(source.index, destination.index);
      }
      if (!destination) {
        return;
      }
      var sId = source.droppableId;
      var dId = destination.droppableId;
      if (sId == dId) {
        reorderApplication(sId, source.index, destination.index);
      } else {
        moveApplication(sId, dId, source.index, destination.index);
      }
    };
    var getRenderColumn = function getRenderColumn(column, index, provided, isDragging) {
      var applications = emberUtils.getSortedColumnApplications(column, showInactiveApplications, allApplications, filter, showNewMessages);
      var renderColumn = (0, _useRenderColumn.useRenderColumn)(columns, applications, columnActions, hasPurchasedVi, isLoading, 170 + (window.innerWidth - document.documentElement.clientWidth), index, intl);
      return renderColumn(column, provided, isDragging);
    };
    return _react.default.createElement(_languageContext.LanguageProvider, {
      value: intl
    }, _react.default.createElement(_hrbContext.HRBProvider, {
      value: jobIsHRB
    }, _react.default.createElement("div", {
      className: 'alfrello-board',
      id: 'alfrello-board'
    }, _react.default.createElement(_reactBeautifulDnd.DragDropContext, {
      onDragEnd: onDragEnd
    }, _react.default.createElement(_reactBeautifulDnd.Droppable, {
      droppableId: 'all-droppables',
      direction: 'horizontal',
      type: 'column'
    }, function (provided) {
      return _react.default.createElement("div", __assign({
        className: 'alfrello-board--columns'
      }, provided.droppableProps, {
        ref: provided.innerRef
      }), columns.sort(function (a, b) {
        return emberUtils.sortOrdinalFunction(a, b, false);
      }).map(function (column, index) {
        return _react.default.createElement("div", {
          key: column.id
        }, _react.default.createElement(_reactBeautifulDnd.Draggable, {
          draggableId: column.id,
          index: index
        }, function (provided, snapshot) {
          return _react.default.createElement("div", __assign({}, provided.draggableProps, {
            ref: provided.innerRef
          }), getRenderColumn(column, index, provided, snapshot.isDragging));
        }));
      }), provided.placeholder, _react.default.createElement(_AlfrelloColumnAdd.AlfrelloColumnAdd, {
        persistColumn: function persistColumn(name) {
          return columnActions.addColumn(name);
        }
      }));
    })))));
  };
  _exports.AlfrelloBoard = AlfrelloBoard;
});