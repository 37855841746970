define("admin/compiled/redux/reducers/index", ["exports", "redux", "admin/compiled/redux/reducers/modal"], function (_exports, _redux, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rootReducer = void 0;
  var rootReducer = (0, _redux.combineReducers)({
    modal: _modal.modalReducer
  });
  _exports.rootReducer = rootReducer;
});