define("admin/compiled/utils/languageContext", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LanguageProvider = void 0;
  var LanguageContext = _react.default.createContext({});
  var LanguageProvider = LanguageContext.Provider;
  _exports.LanguageProvider = LanguageProvider;
  var _default = LanguageContext;
  _exports.default = _default;
});