define("admin/routes/client/spotlight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },
    deactivate: function deactivate() {
      this.controllerFor('client').set('hideNav');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('client').set('hideNav', true);
    }
  });
  _exports.default = _default;
});