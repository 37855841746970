define("admin/models/language-level", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    language: (0, _model.belongsTo)('language'),
    languageSkill: (0, _model.belongsTo)('language-skill')
  });
  _exports.default = _default;
});