define("admin/compiled/stats-item/StatsItem", ["exports", "react", "react-tooltip", "admin/compiled/utils"], function (_exports, _react, _reactTooltip, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.StatsItem = void 0;
  var StatsItem = function StatsItem(_a) {
    var stat = _a.stat,
      title = _a.title,
      tooltipItems = _a.tooltipItems;
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("div", {
      className: 'stats-item',
      "data-for": title,
      "data-tip": tooltipItems ? tooltipItems.join('<br />') : ''
    }, _react.default.createElement("div", {
      className: 'stats-item--stat'
    }, (0, _utils.formatNumber)(stat, '.')), _react.default.createElement("div", {
      className: 'stats-item--title'
    }, title)), _react.default.createElement(_reactTooltip.default, {
      id: title,
      html: true
    }));
  };
  _exports.StatsItem = StatsItem;
});