define("admin/models/question-template", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    active: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    clientId: (0, _model.attr)('string'),
    created: (0, _model.attr)('moment'),
    inuse: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    lastused: (0, _model.attr)('moment'),
    question: (0, _model.attr)('string'),
    type: (0, _model.belongsTo)('questiontype'),
    options: (0, _model.attr)()
  });
  _exports.default = _default;
});