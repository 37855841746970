define("admin/routes/client/create-job/edit", ["exports", "admin/mixins/with-hidden-nav", "admin/mixins/model-loader"], function (_exports, _withHiddenNav, _modelLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_withHiddenNav.WithHiddenNav, _modelLoader.default, {
    model: function model(_ref) {
      var job_id = _ref.job_id;
      return Ember.RSVP.hash({
        job: this.store.find('job', job_id),
        adTypes: this.store.query('adtype', {})
      });
    }
  });
  _exports.default = _default;
});