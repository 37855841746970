define("admin/components/applications/alfrello-excel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global download */
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ['alfrello-excel'],
    actions: {
      download: function (_download) {
        function download() {
          return _download.apply(this, arguments);
        }
        download.toString = function () {
          return _download.toString();
        };
        return download;
      }(function () {
        var _this = this;
        this.set('isLoading', true);
        this.job.excelExport().then(function (data) {
          var excelFile = data.value;
          download("data:application/vnd.ms-excel;base64,".concat(excelFile), "".concat(_this.intl.t('string.applications'), " - ").concat(_this.job.title, ".xlsx"), 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        }).catch(function (err) {
          console.log(err);
          _this.notifications.error(_this.intl.t('applications.excel.error'));
        }).finally(function () {
          return _this.set('isLoading');
        });
      })
    }
  });
  _exports.default = _default;
});