define("admin/components/customers/customer-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['row'],
    actions: {
      manage: function manage(client) {
        this.manage(client);
      },
      closeClient: function closeClient(client) {
        client.set('status', 'BLACKLIST');
        this.toggleProperty('editCustomer');
      },
      loadJobs: function loadJobs(client) {
        client.getJobs().then(function (res) {
          client.set('jobs', res.jobs);
        });
      }
    }
  });
  _exports.default = _default;
});