define("admin/routes/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    newAdmin: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      transition.abort();
      this.newAdmin.transitionTo('');
    }
  });
  _exports.default = _default;
});