define("admin/components/applications/video-invite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['video-invite'],
    quantity: 1,
    answers: Ember.computed.filter('application.videoInterview.answers', function (a) {
      return a.get('video');
    }),
    inviteVideoCheck: Ember.computed('answers.length', 'application.videoInterview.{id,status}', 'job.videoInterviewPurchased', function () {
      if (this.get('application.videoInterview.id') && this.get('application.videoInterview.status') !== 'SENT' && this.get('answers.length')) {
        return 'confirmReinviteModal';
      }
      return 'videoInviteModal';
    }),
    click: function click() {
      this.toggleProperty(this.inviteVideoCheck);
    }
  });
  _exports.default = _default;
});