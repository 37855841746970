define("admin/compiled/e-sign/Icons", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Warning = _exports.Trash = _exports.Selected = _exports.Select = _exports.Download = _exports.Close = _exports.Check = _exports.ArrowR = _exports.Alert = _exports.Add = void 0;
  var Close = function Close() {
    return _react.default.createElement("svg", {
      width: "32",
      height: "32",
      fill: "none"
    }, _react.default.createElement("rect", {
      width: "32",
      height: "32",
      rx: "16",
      fill: "#e66700"
    }), _react.default.createElement("path", {
      d: "M17.265 16.1a.138.138 0 0 1 0-.195l5.094-5.094a.825.825 0 0 0-1.168-1.166l-5.094 5.091a.137.137 0 0 1-.195 0l-5.094-5.091a.825.825 0 0 0-1.167 1.166l5.094 5.094a.137.137 0 0 1 0 .195L9.64 21.194a.825.825 0 0 0 1.167 1.166l5.094-5.094a.138.138 0 0 1 .195 0l5.094 5.095a.825.825 0 0 0 1.167-1.167L17.265 16.1z",
      fill: "#fff"
    }));
  };
  _exports.Close = Close;
  var Add = function Add() {
    return _react.default.createElement("svg", {
      width: "32",
      height: "32",
      fill: "none"
    }, _react.default.createElement("rect", {
      width: "32",
      height: "32",
      rx: "16",
      fill: "#7E7E7E"
    }), _react.default.createElement("path", {
      d: "M15.842 22.846a1.222 1.222 0 0 0 1.224-1.224V17.48c.001-.134.11-.244.245-.244h4.142a1.224 1.224 0 1 0 0-2.449H17.31a.245.245 0 0 1-.245-.246v-4.14a1.225 1.225 0 1 0-2.449-.001v4.142c0 .135-.11.244-.244.245H10.23a1.225 1.225 0 0 0 0 2.448h4.141a.245.245 0 0 1 .245.245l.001 4.142a1.224 1.224 0 0 0 1.224 1.224z",
      fill: "#fff"
    }));
  };
  _exports.Add = Add;
  var Check = function Check(_a) {
    var _b = _a.size,
      size = _b === void 0 ? 16 : _b;
    return _react.default.createElement("svg", {
      width: size,
      height: size,
      viewBox: "0 0 16 12",
      fill: "none"
    }, _react.default.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M5.458 11.405h-.045a1.23 1.23 0 0 1-.87-.41L.975 6.967a1.221 1.221 0 0 1 .104-1.726 1.22 1.22 0 0 1 1.726.103l2.72 3.067L13.257.88a1.223 1.223 0 1 1 1.705 1.751l-8.651 8.427a1.22 1.22 0 0 1-.854.347z",
      fill: "#32AA05"
    }));
  };
  _exports.Check = Check;
  var Alert = function Alert() {
    return _react.default.createElement("svg", {
      width: "18",
      height: "16",
      fill: "none"
    }, _react.default.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M17.616 12.705L11.04 1.115A2.179 2.179 0 0 0 9.12 0c-.805 0-1.523.417-1.92 1.116L.624 12.706a2.186 2.186 0 0 0 .014 2.198A2.184 2.184 0 0 0 2.543 16h13.156c.792 0 1.505-.41 1.905-1.097.399-.687.404-1.508.012-2.198z",
      fill: "#FFB400"
    }), _react.default.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M9.803 12.975a.992.992 0 0 1-.677.257c-.26 0-.49-.086-.684-.254-.198-.172-.299-.413-.299-.718 0-.266.095-.496.282-.684a.943.943 0 0 1 .69-.281c.27 0 .503.094.694.28a.928.928 0 0 1 .29.685c0 .3-.1.54-.296.715M10.057 6.482l-.25 2.856c-.027.355-.088.623-.184.819a.559.559 0 0 1-.531.329c-.248 0-.43-.112-.528-.323-.087-.188-.147-.462-.183-.836l-.186-2.78c-.035-.549-.052-.932-.052-1.17 0-.347.094-.623.28-.821a.969.969 0 0 1 .738-.302c.373 0 .628.135.758.402.12.246.18.586.18 1.039 0 .256-.015.521-.042.787",
      fill: "#fff"
    }));
  };
  _exports.Alert = Alert;
  var ArrowR = function ArrowR() {
    return _react.default.createElement("svg", {
      width: "8",
      height: "12",
      fill: "none"
    }, _react.default.createElement("path", {
      d: "M7.05 6.58l-5.057 5.045a.825.825 0 0 1-1.165 0 .82.82 0 0 1 0-1.162L5.303 6 .828 1.536a.82.82 0 0 1 0-1.162.825.825 0 0 1 1.165 0L7.05 5.419a.818.818 0 0 1 0 1.161z",
      fill: "#7E7E7E"
    }));
  };
  _exports.ArrowR = ArrowR;
  var Select = function Select() {
    return _react.default.createElement("svg", {
      width: "20",
      height: "20",
      viewBox: "0 0 22 22",
      fill: "none"
    }, _react.default.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M.583 10.999C.583 5.246 5.247.582 11 .582s10.417 4.664 10.417 10.417S16.753 21.415 11 21.415.583 16.752.583 11zM11 1.415a9.583 9.583 0 1 0 0 19.167 9.583 9.583 0 0 0 0-19.167z",
      fill: "#ECECEC"
    }));
  };
  _exports.Select = Select;
  var Selected = function Selected() {
    return _react.default.createElement("svg", {
      width: "20",
      height: "20",
      fill: "none"
    }, _react.default.createElement("rect", {
      width: "20",
      height: "20",
      rx: "10",
      fill: "#ff7200"
    }), _react.default.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8.265 13.243h-.03a.838.838 0 0 1-.593-.28L5.209 10.22a.832.832 0 1 1 1.247-1.106l1.853 2.09 5.271-5.132a.833.833 0 1 1 1.163 1.193l-5.896 5.743a.831.831 0 0 1-.582.236",
      fill: "#FFFFFE"
    }));
  };
  _exports.Selected = Selected;
  var Trash = function Trash() {
    return _react.default.createElement("svg", {
      width: "24",
      height: "24",
      fill: "none"
    }, _react.default.createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M7.627 20.8a.905.905 0 0 1-.904-.868L6.12 7.2h11.46l-.603 12.733a.905.905 0 0 1-.904.867H7.627zm6.501-10.805a.368.368 0 0 0-.367.367v7.275c0 .203.165.367.367.367h.588a.367.367 0 0 0 .368-.367v-7.275a.368.368 0 0 0-.368-.367h-.588zm-2.94.367c0-.203.165-.367.368-.367h.588c.203 0 .368.164.368.367v7.275a.367.367 0 0 1-.368.367h-.588a.367.367 0 0 1-.367-.367v-7.275zm-2.204-.367a.368.368 0 0 0-.367.367v7.275c0 .203.164.367.367.367h.588a.367.367 0 0 0 .368-.367v-7.275a.367.367 0 0 0-.368-.367h-.588z",
      fill: "#D2D2D2"
    }), _react.default.createElement("path", {
      d: "M17.951 4.125h-3.896V3.39a.19.19 0 0 0-.19-.19H9.774a.19.19 0 0 0-.19.19v.736H5.688a.568.568 0 0 0-.568.568v1.784h13.4V4.693a.568.568 0 0 0-.569-.568z",
      fill: "#D2D2D2"
    }));
  };
  _exports.Trash = Trash;
  var Warning = function Warning() {
    return _react.default.createElement("svg", {
      width: "5",
      height: "14"
    }, _react.default.createElement("path", {
      d: "M.8 3.604L.692.94h3.096L3.68 3.604l-.432 5.004H1.232L.8 3.604zm-.36 7.74c0-.528.168-.972.504-1.332.336-.36.768-.54 1.296-.54s.96.18 1.296.54c.336.36.504.804.504 1.332s-.168.972-.504 1.332c-.336.36-.768.54-1.296.54s-.96-.18-1.296-.54c-.336-.36-.504-.804-.504-1.332z",
      fill: "#F5524F",
      fillRule: "evenodd"
    }));
  };
  _exports.Warning = Warning;
  var Download = function Download() {
    return _react.default.createElement("svg", {
      width: "8",
      height: "8",
      viewBox: "0 0 8 8",
      fill: "none"
    }, _react.default.createElement("path", {
      d: "M7.10025 5.15979L4.43354 7.82649C4.20213 8.05783 3.82701 8.05783 3.5956 7.82649L0.928895 5.15979C0.704316 4.92726 0.707527 4.55765 0.936114 4.32906C1.1647 4.10048 1.53431 4.09727 1.76683 4.32185L3.16952 5.72394C3.21205 5.76517 3.27505 5.77711 3.32972 5.75429C3.38438 5.73147 3.42019 5.67828 3.42079 5.61905L3.42079 0.592602C3.42079 0.265317 3.6861 0 4.01339 0C4.34067 0 4.60599 0.265317 4.60599 0.592602L4.60599 5.61905C4.60588 5.67906 4.64198 5.7332 4.69741 5.75618C4.75284 5.77915 4.81666 5.76643 4.85903 5.72394L6.26231 4.32185C6.49484 4.09727 6.86445 4.10048 7.09303 4.32906C7.32162 4.55765 7.32483 4.92726 7.10025 5.15979Z",
      fill: "white"
    }));
  };
  _exports.Download = Download;
});