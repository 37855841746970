define("admin/templates/admin/messages/users/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ySxaxwPc",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n\\n  \"],[10,\"br\"],[12],[13],[2,\"\\n  \"],[10,\"br\"],[12],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"edit-form\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"table\"],[12],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"head clearfix visible-lg\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-lg-2\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-lg-7\"],[12],[1,[30,[36,1],[\"admin.messages.page_employees.employee\"],null]],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-lg-7\"],[12],[1,[30,[36,1],[\"admin.messages.page_employees.division\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"col-lg-2 col-sm-3\"],[12],[2,\"\\n              \"],[8,\"profile-thumb\",[],[[\"@bg\",\"@initials\"],[[30,[36,0],[[32,1,[\"image\"]]],[[\"h\"],[100]]],[32,1,[\"thumb_initials\"]]]],null],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"col-lg-7 col-sm-8\"],[12],[10,\"span\"],[12],[1,[32,1,[\"name\"]]],[13],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"col-lg-14 col-sm-7\"],[12],[10,\"span\"],[12],[1,[32,1,[\"title\"]]],[13],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"col-sm-1 icon\"],[12],[2,\"\\n              \"],[8,\"link-to\",[[24,0,\"edit\"]],[[\"@route\",\"@model\"],[\"admin.messages.users.edit\",[32,1,[\"id\"]]]],null],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"br\"],[12],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"imgix\",\"t\",\"model\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/admin/messages/users/index.hbs"
    }
  });
  _exports.default = _default;
});