define("admin/compiled/ui/MaterialIcon", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MaterialIcon = void 0;
  var MaterialIcon = function MaterialIcon(_a) {
    var onClick = _a.onClick,
      noPointer = _a.noPointer,
      children = _a.children;
    return _react.default.createElement("div", {
      className: "ui-material-icon ".concat(noPointer ? 'no-pointer' : ''),
      onClick: onClick
    }, children);
  };
  _exports.MaterialIcon = MaterialIcon;
});