define("admin/models/user", ["exports", "@ember-data/model", "ember-api-actions"], function (_exports, _model, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    initials: (0, _model.attr)('string'),
    jobtitle: (0, _model.attr)('string'),
    status: (0, _model.attr)('string', {
      defaultValue: 'CREATED'
    }),
    clientId: (0, _model.attr)('number'),
    image: (0, _model.attr)('string'),
    updated: (0, _model.attr)('moment'),
    // field: attr('string'),
    // department: attr('string'),
    ssn: (0, _model.attr)('string'),
    canSign: (0, _model.attr)('boolean'),
    redirectToHrBoard: (0, _model.attr)('boolean'),
    statusdescription: (0, _model.attr)('string'),
    ads: (0, _model.attr)('number'),
    lastActive: (0, _model.attr)('moment'),
    premium: (0, _model.attr)('boolean'),
    confirmationLink: (0, _model.attr)('string'),
    canedit: (0, _model.attr)('boolean'),
    accessapplications: (0, _model.attr)('boolean'),
    oldpass: (0, _model.attr)('string'),
    newpass1: (0, _model.attr)('string'),
    newpass2: (0, _model.attr)('string'),
    role: (0, _model.belongsTo)('role'),
    brandaccess: (0, _model.hasMany)('brand'),
    changePassword: (0, _emberApiActions.memberAction)({
      path: 'changepassword',
      type: 'post'
    }),
    changePasswordPhantom: (0, _emberApiActions.memberAction)({
      path: 'changepasswordphantom',
      type: 'post'
    }),
    resetPassword: (0, _emberApiActions.memberAction)({
      path: 'newpassword',
      type: 'post'
    }),
    isAgent: Ember.computed.equal('role.roleName', 'AGENT'),
    isManager: Ember.computed.equal('role.roleName', 'MANAGER'),
    isHr: Ember.computed.equal('role.roleName', 'HR'),
    isHelpdesk: Ember.computed.equal('role.roleName', 'HELPDESK'),
    isAdmin: Ember.computed.equal('role.roleName', 'ADMIN'),
    isTranslator: Ember.computed.equal('role.roleName', 'TRANSLATOR'),
    isTagger: Ember.computed.equal('jobtitle', 'Tagging'),
    allowCreateJob: (0, _model.attr)('boolean'),
    allowViewAllJobs: (0, _model.attr)('boolean'),
    allowViewHrBoard: (0, _model.attr)('boolean'),
    allowManageGa: Ember.computed.or('isManager', 'isHelpdesk', 'isAdmin'),
    thumb_initials: Ember.computed('image', 'initials', function () {
      if (!this.image) {
        return this.initials;
      }
    }),
    hasPhantomEmail: Ember.computed('email', function () {
      return this.email && this.email.startsWith('phantom');
    })
  });
  _exports.default = _default;
});