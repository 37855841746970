define("admin/components/sales/confirm-export-modal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    apiAdmin: Ember.inject.service(),
    actions: {
      close: function close() {
        this.toggle();
      },
      confirm: function confirm() {
        var _this = this;
        var year = (0, _moment.default)(this.date, 'YYYY-MM').format('YYYY');
        var month = (0, _moment.default)(this.date, 'YYYY-MM').format('M');
        this.apiAdmin.get("/sales/invoices/export?year=".concat(year, "&month=").concat(month)).then(function () {
          _this.notifications.success("Successfully exported");
          _this.toggle();
        });
      }
    }
  });
  _exports.default = _default;
});