define("admin/components/alfrello-react/job-actions", ["exports", "admin/react-component", "admin/compiled/job-actions/JobActions"], function (_exports, _reactComponent, _JobActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  
  */
  {
    "id": "1FYwo32Q",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/components/alfrello-react/job-actions.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _reactComponent.default.extend({
    renderComponent: function renderComponent() {
      this.reactRender(React.createElement(_JobActions.JobActions, {
        id: this.key,
        job: this.job
      }));
    }
  }));
  _exports.default = _default;
});