define("admin/components/jobs/step-two", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      selectQuestion: function selectQuestion(index, event) {
        var id = event.target.value;
        if (this.get('model.job.questions').objectAt(index) || !id) {
          this.get('model.job.questions').removeAt(index);
        }
        if (id) {
          var question = this.get('model.questions').findBy('id', id);
          this.get('model.job.questions').insertAt(index, question);
        }
      }
    }
  });
  _exports.default = _default;
});