define("admin/routes/client/brands/edit", ["exports", "admin/routes/client/_form/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    controllerName: 'client/brands/form',
    apiClient: Ember.inject.service(),
    renderTemplate: function renderTemplate() {
      this._super('brands');
    },
    model: function model(_ref) {
      var brand_id = _ref.brand_id;
      return Ember.RSVP.hash({
        brands: this.apiClient.get('/brands?status=ACTIVE').then(function (res) {
          return res.brands;
        }),
        brand: this.store.findRecord('brand', brand_id),
        companySizes: this.apiClient.get('/brands/companysizes').then(function (res) {
          return res.companySizes;
        }),
        benefitTypes: this.apiClient.get('/brands/benefittypes').then(function (res) {
          return res.benefitTypes;
        }),
        awards: this.apiClient.get('/brands/awards').then(function (res) {
          return res.awards;
        })
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.model.brand.rollbackAttributes();
      }
    }
  });
  _exports.default = _default;
});