define("admin/controllers/client/users/form", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    user: Ember.computed.alias('model'),
    cookies: (0, _emberLocalStorage.storageFor)('cookies'),
    isValid: Ember.computed('user.{name,phone,email,jobtitle,role.id,brandaccess.length}', function () {
      return this.get('user.name') && this.get('user.phone') && this.get('user.email') && this.get('user.email').email() && this.get('user.jobtitle') && this.get('user.role.id');
    }),
    saveTooltip: Ember.computed('user.{name,image,phone,email,jobtitle,role.id,brandaccess.length}', function () {
      return "<table class=\"adChecks\">\n        <tr><td>".concat(this.intl.t('string.name'), ":</td><td class=\"").concat(this.get('user.name') ? 'ok' : 'no', "\"></td></tr>\n        <tr><td>").concat(this.intl.t('string.job_title'), ":</td><td class=\"").concat(this.get('user.jobtitle') ? 'ok' : 'no', "\"></td></tr>\n        <tr><td>").concat(this.intl.t('string.phone'), ":</td><td class=\"").concat(this.get('user.phone') ? 'ok' : 'no', "\"></td></tr>\n        <tr><td>").concat(this.intl.t('string.email'), ":</td><td class=\"").concat(this.get('user.email') ? 'ok' : 'no', "\"></td></tr>\n        <tr><td>").concat(this.intl.t('string.role'), ":</td><td class=\"").concat(this.get('user.role.id') ? 'ok' : 'no', "\"></td></tr>\n        </table>");
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.statuses = ['ACTIVE'];
    },
    actions: {
      close: function close() {
        this.transitionToRoute('client.users');
      },
      didSelectFiles: function didSelectFiles(user, files) {
        var _this = this;
        if (files.length) {
          var reader = new FileReader();
          reader.readAsDataURL(files[0]);
          reader.addEventListener('load', function () {
            _this.set('ignoreImgix', true);
            user.set('image', reader.result);
          }, false);
        }
      },
      resetPsw: function resetPsw(user) {
        var _this2 = this;
        user.resetPassword().then(function () {
          _this2.notifications.success(_this2.intl.t('alerts.email_sent'));
        });
      },
      deleteUser: function deleteUser(user) {
        var _this3 = this;
        user.set('status', 'DELETED');
        user.destroyRecord().then(function () {
          _this3.transitionToRoute('client.users');
        });
      }
    }
  });
  _exports.default = _default;
});