define("admin/templates/components/ui/context-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "MVbhzbMu",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"click-outside\",[],[[\"@onClickOutside\"],[[30,[36,0],[[32,0],\"hide\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[11,\"div\"],[16,0,[31,[\"icon \",[34,1]]]],[4,[38,0],[[32,0],[30,[36,2],[\"showMenu\",[32,0]],null]],null],[12],[13],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"ul\"],[14,0,\"items\"],[12],[2,\"\\n      \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"clearfix\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"icon\",\"toggle\",\"showMenu\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/ui/context-menu.hbs"
    }
  });
  _exports.default = _default;
});