define("admin/routes/client/applications", ["exports", "admin/mixins/protected"], function (_exports, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_protected.default, {
    apiClient: Ember.inject.service(),
    model: function model(_ref) {
      var job_id = _ref.job_id;
      this.set('currentSession.jobId', job_id);
      return Ember.RSVP.hash({
        job: this.store.findRecord('job', job_id),
        questions: this.store.query('question', {
          jobid: job_id
        }),
        languages: this.store.findAll('language'),
        languageskills: this.store.findAll('language-skill'),
        degrees: this.store.findAll('degree'),
        compasses: this.apiClient.get("/jobs/".concat(job_id, "/compasses")),
        job_id: job_id
      });
    },
    deactivate: function deactivate() {
      this.controllerFor('client').set('hideNav', false);
      var applicationsIndexController = this.controllerFor('client.applications.index');
      if (applicationsIndexController) {
        applicationsIndexController.set('filter', {
          age: null,
          skills: null,
          questions: null,
          languages: null,
          period: null,
          education: null,
          compass: null,
          search: null
        });
      }
      this._super();
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      if (!this.get('currentSession.user.redirectToHrBoard')) {
        this.controllerFor('client').set('hideNav', true);
      }
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});