define("admin/controllers/client/edit-job/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    gtm: Ember.inject.service(),
    validator: Ember.inject.service(),
    productsPrice: Ember.computed('products.@each.selected', function () {
      var totalPrice = 0;
      this.products.forEach(function (product) {
        if (product.selected) {
          totalPrice += product.offerPrice || product.offerPrice == 0 ? product.offerPrice : product.price;
        }
      });
      return totalPrice;
    }),
    products: Ember.computed('model.productData.jobProductCategories', function () {
      var products = [];
      if (this.model.productData && this.model.productData.jobProductCategories) {
        this.model.productData.jobProductCategories.forEach(function (cat) {
          cat.products.forEach(function (product) {
            return products.push(product);
          });
        });
      }
      return products;
    }),
    selectedProductsCount: Ember.computed('products.@each.selected', function () {
      var count = 0;
      if (this.products) {
        this.products.forEach(function (product) {
          if (product.selected) {
            count++;
          }
        });
      }
      return count;
    })
  });
  _exports.default = _default;
});