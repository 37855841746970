define("admin/templates/components/autotags-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gBJSlDTX",
    "block": "{\"symbols\":[\"jobtag\"],\"statements\":[[11,\"div\"],[24,0,\"title\"],[4,[38,3],[[32,0],\"toggleCategory\"],null],[12],[1,[35,0,[\"title\"]]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"category-content\"],[12],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"ul\"],[14,0,\"jobtags\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0,[\"jobtags\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"li\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"cat\",\"-track-array\",\"each\",\"action\",\"open\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/autotags-category.hbs"
    }
  });
  _exports.default = _default;
});