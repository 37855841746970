define("admin/compiled/alfrello-profile-sidebar/AlfrelloProfileSidebar", ["exports", "react", "react-beautiful-dnd", "admin/compiled/utils/hrbContext", "admin/compiled/utils/languageContext", "admin/compiled/utils/useRenderColumn"], function (_exports, _react, _reactBeautifulDnd, _hrbContext, _languageContext, _useRenderColumn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AlfrelloProfileSidebar = void 0;
  var AlfrelloProfileSidebar = function AlfrelloProfileSidebar(_a) {
    var initialColumnId = _a.initialColumnId,
      columns = _a.columns,
      allApplications = _a.allApplications,
      filter = _a.filter,
      showInactiveApplications = _a.showInactiveApplications,
      reorderApplication = _a.reorderApplication,
      showNewMessages = _a.showNewMessages,
      emberUtils = _a.emberUtils,
      columnActions = _a.columnActions,
      hasPurchasedVi = _a.hasPurchasedVi,
      intl = _a.intl,
      activeApplication = _a.activeApplication,
      jobIsHRB = _a.jobIsHRB;
    var onDragEnd = function onDragEnd(res) {
      var source = res.source,
        destination = res.destination;
      if (!destination) {
        return;
      }
      var sId = source.droppableId;
      var dId = destination.droppableId;
      if (sId == dId) {
        reorderApplication(sId, source.index, destination.index);
      }
    };
    var _b = (0, _react.useState)(columns.find(function (c) {
        return c.id === initialColumnId;
      })),
      column = _b[0],
      setColumn = _b[1];
    var applications = emberUtils.getSortedColumnApplications(column, showInactiveApplications, allApplications, filter, showNewMessages);
    var renderColumn = (0, _useRenderColumn.useRenderColumn)(columns, applications, columnActions, hasPurchasedVi, false, 118, 0, intl, function (columnId) {
      setColumn(columns.find(function (c) {
        return c.id == columnId;
      }));
    }, activeApplication, true);
    return _react.default.createElement(_languageContext.LanguageProvider, {
      value: intl
    }, _react.default.createElement(_hrbContext.HRBProvider, {
      value: jobIsHRB
    }, _react.default.createElement(_reactBeautifulDnd.DragDropContext, {
      onDragEnd: onDragEnd
    }, _react.default.createElement("div", {
      className: 'alfrello-profile-sidebar'
    }, _react.default.createElement("div", {
      className: 'alfrello-profile-sidebar--color',
      style: {
        backgroundColor: column.color
      }
    }), _react.default.createElement(_reactBeautifulDnd.Droppable, {
      droppableId: 'droppable-sidebar',
      direction: 'horizontal',
      type: 'column'
    }, function (provided) {
      return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("div", {
        key: column.id
      }, renderColumn(column, provided)), provided.placeholder);
    })))));
  };
  _exports.AlfrelloProfileSidebar = AlfrelloProfileSidebar;
});