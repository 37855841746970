define("admin/compiled/e-sign/types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PageType = _exports.FilterType = void 0;
  var PageType;
  _exports.PageType = PageType;
  (function (PageType) {
    PageType[PageType["Reviews"] = 0] = "Reviews";
    PageType[PageType["Edit"] = 1] = "Edit";
    PageType[PageType["Summary"] = 2] = "Summary";
  })(PageType || (_exports.PageType = PageType = {}));
  var FilterType;
  _exports.FilterType = FilterType;
  (function (FilterType) {
    FilterType[FilterType["Sent"] = 0] = "Sent";
    FilterType[FilterType["Templates"] = 1] = "Templates";
  })(FilterType || (_exports.FilterType = FilterType = {}));
});