define("admin/compiled/alfrello-profile-info/ProfileVerification", ["exports", "react", "admin/compiled/utils/languageContext", "admin/compiled/alfrello-profile-items/AlfrelloProfileItems"], function (_exports, _react, _languageContext, _AlfrelloProfileItems) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ProfileVerification = void 0;
  var ProfileVerification = function ProfileVerification(_a) {
    var verification = _a.verification;
    if (!verification) {
      return null;
    }
    var intl = (0, _react.useContext)(_languageContext.default);
    var items = [{
      label: intl.t('components.esign.full-name'),
      value: verification.name
    }, {
      label: intl.t('components.esign.ssn'),
      value: verification.ssn
    }, {
      label: intl.t('components.esign.phone'),
      value: verification.phone
    }];
    return _react.default.createElement(_AlfrelloProfileItems.AlfrelloProfileItems, {
      label: intl.t('components.esign.title'),
      items: items.map(function (item) {
        return _react.default.createElement("div", null, item.label, ": ", _react.default.createElement("strong", null, item.value));
      })
    });
  };
  _exports.ProfileVerification = ProfileVerification;
});