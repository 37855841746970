define("admin/compiled/job-item/GaInactiveJob", ["exports", "@alfred-is/alfred-design-system", "react", "react-redux", "admin/compiled/cta-button/cta-button", "admin/compiled/job-card/job-card", "admin/compiled/redux/actions/modal", "admin/compiled/types", "admin/compiled/utils/emberContext"], function (_exports, _alfredDesignSystem, _react, _reactRedux, _ctaButton, _jobCard, _modal, _types, _emberContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GaEnableJob = _exports.GaCreateJob = void 0;
  var __awaiter = void 0 && (void 0).__awaiter || function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P ? value : new P(function (resolve) {
        resolve(value);
      });
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }
      function rejected(value) {
        try {
          step(generator["throw"](value));
        } catch (e) {
          reject(e);
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };
  var __generator = void 0 && (void 0).__generator || function (thisArg, body) {
    var _ = {
        label: 0,
        sent: function sent() {
          if (t[0] & 1) throw t[1];
          return t[1];
        },
        trys: [],
        ops: []
      },
      f,
      y,
      t,
      g;
    return g = {
      next: verb(0),
      "throw": verb(1),
      "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
      return this;
    }), g;
    function verb(n) {
      return function (v) {
        return step([n, v]);
      };
    }
    function step(op) {
      if (f) throw new TypeError("Generator is already executing.");
      while (g && (g = 0, op[0] && (_ = 0)), _) try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];
        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;
          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };
          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;
          case 7:
            op = _.ops.pop();
            _.trys.pop();
            continue;
          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }
            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }
            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }
            if (t && _.label < t[2]) {
              _.label = t[2];
              _.ops.push(op);
              break;
            }
            if (t[2]) _.ops.pop();
            _.trys.pop();
            continue;
        }
        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
      if (op[0] & 5) throw op[1];
      return {
        value: op[0] ? op[1] : void 0,
        done: true
      };
    }
  };
  var GaEnableJob = function GaEnableJob(_a) {
    var brand = _a.brand,
      activate = _a.activate;
    var _b = (0, _react.useContext)(_emberContext.default),
      intl = _b.intl,
      currentSession = _b.currentSession;
    var _c = (0, _react.useState)(false),
      isLoading = _c[0],
      setIsLoading = _c[1];
    return _react.default.createElement(_jobCard.JobCard, {
      variant: _jobCard.JobCardVariant.GRAY
    }, function (_a) {
      var infoActive = _a.infoActive;
      return _react.default.createElement(_jobCard.JobCard.Container, null, _react.default.createElement(_jobCard.JobCard.Header, {
        brandName: brand.name,
        logo: brand.logo,
        hideInfo: true
      }), _react.default.createElement(_jobCard.JobCard.Title, null, intl.t('general-application.title')), _react.default.createElement(_jobCard.JobCard.Status, {
        color: _alfredDesignSystem.colors.gray500
      }, intl.t('general-application.disabled')), _react.default.createElement(_jobCard.JobCard.Spacer, null), _react.default.createElement(_jobCard.JobCard.CenteredContent, null, _react.default.createElement(_ctaButton.CtaButton, {
        disabled: isLoading || !currentSession.user.allowManageGa,
        size: _alfredDesignSystem.SizeT.medium,
        variant: _ctaButton.CtaButtonVariant.GREEN,
        onClick: function onClick() {
          return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
              switch (_a.label) {
                case 0:
                  if (!currentSession.user.allowManageGa && !isLoading) {
                    return [2 /*return*/];
                  }

                  setIsLoading(true);
                  return [4 /*yield*/, activate()];
                case 1:
                  _a.sent();
                  return [2 /*return*/];
              }
            });
          });
        },

        className: 'tw-rounded-sm'
      }, isLoading ? intl.t('general.loading') : null, !isLoading && _react.default.createElement(_alfredDesignSystem.TextWithIcon, {
        icon: _alfredDesignSystem.AlfredIconT.ArrowRight,
        iconPosition: _alfredDesignSystem.TextWithIconIconPosition.RIGHT,
        color: _alfredDesignSystem.ColorT.white,
        size: _alfredDesignSystem.SizeT.large
      }, intl.t('general-application.enable'))), _react.default.createElement("div", {
        className: 'tw-text-center tw-mt-4 tw-text-gray-500'
      }, currentSession.user.allowManageGa ? intl.t('general-application.disabled-disclaimer') : intl.t('general-application.disabled-text'))));
    });
  };
  _exports.GaEnableJob = GaEnableJob;
  var GaCreateJob = function GaCreateJob(_a) {
    var brand = _a.brand;
    var dispatch = (0, _reactRedux.useDispatch)();
    var _b = (0, _react.useContext)(_emberContext.default),
      intl = _b.intl,
      gtm = _b.gtm,
      currentSession = _b.currentSession;
    return _react.default.createElement(_jobCard.JobCard, {
      variant: _jobCard.JobCardVariant.GRAY
    }, function (_a) {
      var infoActive = _a.infoActive;
      return _react.default.createElement(_jobCard.JobCard.Container, null, _react.default.createElement(_jobCard.JobCard.Header, {
        brandName: brand.name,
        logo: brand.logo,
        hideInfo: true
      }), _react.default.createElement(_jobCard.JobCard.Title, null, intl.t('general-application.title')), _react.default.createElement(_jobCard.JobCard.Status, {
        color: _alfredDesignSystem.colors.gray500
      }, intl.t('general-application.disabled')), _react.default.createElement(_jobCard.JobCard.Spacer, null), _react.default.createElement(_jobCard.JobCard.CenteredContent, null, _react.default.createElement(_ctaButton.CtaButton, {
        size: _alfredDesignSystem.SizeT.medium,
        disabled: !currentSession.user.allowManageGa,
        variant: _ctaButton.CtaButtonVariant.GREEN,
        onClick: function onClick() {
          if (!currentSession.user.allowManageGa) {
            return;
          }
          gtm.continueGeneralApplication();
          dispatch((0, _modal.showModal)(_types.ModalType.CREATE_GENERAL_APPLICATION, {
            brand: brand
          }));
        }
      }, _react.default.createElement(_alfredDesignSystem.TextWithIcon, {
        icon: _alfredDesignSystem.AlfredIconT.ArrowRight,
        iconPosition: _alfredDesignSystem.TextWithIconIconPosition.RIGHT,
        color: _alfredDesignSystem.ColorT.white,
        size: _alfredDesignSystem.SizeT.large
      }, intl.t('crad.new_job.continue'))), _react.default.createElement("div", {
        className: 'tw-text-center tw-mt-4 tw-text-gray-500'
      }, currentSession.user.allowManageGa ? intl.t('general-application.description') : intl.t('general-application.disabled-text'))));
    });
  };
  _exports.GaCreateJob = GaCreateJob;
});