define("admin/helpers/imgix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.imgix = imgix;
  function imgix(src, _ref) {
    var h = _ref.h,
      w = _ref.w,
      crop = _ref.crop,
      face = _ref.face,
      ignoreImgix = _ref.ignoreImgix;
    if (ignoreImgix) return src;
    if (!src || !src[0]) return src;
    var dpr = window && window.devicePixelRatio ? window.devicePixelRatio : 1;
    var url = "".concat(src, "?dpr=").concat(dpr);
    if (h) {
      url += "&h=".concat(h);
    }
    if (w) {
      url += "&w=".concat(w);
    }
    if (crop) {
      url += '&fit=crop';
    }
    if (face) {
      url += '&fit=facearea&facepad=10';
    }
    return url;
  }
  var _default = Ember.Helper.helper(imgix);
  _exports.default = _default;
});