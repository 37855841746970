define("admin/mixins/prefix-model-path", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    pathForType: function pathForType(modelName) {
      return (0, _emberInflector.pluralize)(modelName.replace(/-/g, '/'));
    }
  });
  _exports.default = _default;
});