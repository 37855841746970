define("admin/compiled/spotlight/image-input/image-input", ["exports", "@alfred-is/alfred-design-system", "classnames", "react", "react-easy-crop", "react-modal", "admin/compiled/utils/emberContext", "admin/compiled/utils/useWindowSize", "admin/compiled/spotlight/image-input/utils"], function (_exports, _alfredDesignSystem, _classnames, _react, _reactEasyCrop, _reactModal, _emberContext, _useWindowSize, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ImageInput = ImageInput;
  _exports.default = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var __awaiter = void 0 && (void 0).__awaiter || function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P ? value : new P(function (resolve) {
        resolve(value);
      });
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }
      function rejected(value) {
        try {
          step(generator["throw"](value));
        } catch (e) {
          reject(e);
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };
  var __generator = void 0 && (void 0).__generator || function (thisArg, body) {
    var _ = {
        label: 0,
        sent: function sent() {
          if (t[0] & 1) throw t[1];
          return t[1];
        },
        trys: [],
        ops: []
      },
      f,
      y,
      t,
      g;
    return g = {
      next: verb(0),
      "throw": verb(1),
      "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
      return this;
    }), g;
    function verb(n) {
      return function (v) {
        return step([n, v]);
      };
    }
    function step(op) {
      if (f) throw new TypeError("Generator is already executing.");
      while (g && (g = 0, op[0] && (_ = 0)), _) try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];
        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;
          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };
          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;
          case 7:
            op = _.ops.pop();
            _.trys.pop();
            continue;
          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }
            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }
            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }
            if (t && _.label < t[2]) {
              _.label = t[2];
              _.ops.push(op);
              break;
            }
            if (t[2]) _.ops.pop();
            _.trys.pop();
            continue;
        }
        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
      if (op[0] & 5) throw op[1];
      return {
        value: op[0] ? op[1] : void 0,
        done: true
      };
    }
  };
  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };
  function ImageInput(_a) {
    var _b;
    var _this = this;
    var setFile = _a.setFile,
      file = _a.file,
      errorMessage = _a.errorMessage,
      charLimit = _a.charLimit,
      aspectRatio = _a.aspectRatio,
      cropperAspectRatio = _a.cropperAspectRatio,
      minWidth = _a.minWidth,
      minHeight = _a.minHeight,
      warn = _a.warn,
      _c = _a.theme,
      theme = _c === void 0 ? _alfredDesignSystem.Theme.Orange : _c,
      customContainer = _a.customContainer,
      customButton = _a.customButton,
      ref = _a.ref,
      rest = __rest(_a, ["setFile", "file", "errorMessage", "charLimit", "aspectRatio", "cropperAspectRatio", "minWidth", "minHeight", "warn", "theme", "customContainer", "customButton", "ref"]);
    var notifications = (0, _emberContext.useEmberContext)().notifications;
    var minZoom = 1;
    var _d = (0, _react.useState)(''),
      upImg = _d[0],
      setUpImg = _d[1];
    var _e = (0, _react.useState)(''),
      fileName = _e[0],
      setFileName = _e[1];
    var _f = (0, _react.useState)({
        x: 0,
        y: 0
      }),
      crop = _f[0],
      setCrop = _f[1];
    var _g = (0, _react.useState)(1),
      zoom = _g[0],
      setZoom = _g[1];
    var _h = (0, _react.useState)(null),
      croppedAreaPixels = _h[0],
      setCroppedAreaPixels = _h[1];
    var _j = (0, _react.useState)(false),
      modalIsOpen = _j[0],
      setModalIsOpen = _j[1];
    var imgFile = file && typeof file !== 'string' ? file : undefined;
    var imgUrl = file && typeof file === 'string' ? file : '';
    var t = (0, _alfredDesignSystem.useNxTranslation)().t;
    var width = (0, _useWindowSize.useWindowSize)()[0];
    var fileInputRef = (0, _react.useRef)(null);
    var closeModal = function closeModal() {
      setModalIsOpen(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    };
    var selectFile = function selectFile() {
      var _a;
      (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    var clonedCustomContainer;
    if (customContainer) {
      clonedCustomContainer = _react.default.cloneElement(customContainer, {
        onClick: function onClick(e) {
          e.stopPropagation();
          e.preventDefault();
          selectFile();
        }
      });
    }
    var cropperWidth = width < 1000 ? width : 1000;
    var onModalButtonClick = function onModalButtonClick() {
      return __awaiter(_this, void 0, void 0, function () {
        var base64;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              if (!croppedAreaPixels) return [3 /*break*/, 2];
              return [4 /*yield*/, (0, _utils.default)(upImg, croppedAreaPixels)];
            case 1:
              base64 = _a.sent();
              setFile({
                name: fileName,
                value: base64
              });
              closeModal();
              setZoom(1);
              setCrop({
                x: 0,
                y: 0
              });
              _a.label = 2;
            case 2:
              return [2 /*return*/];
          }
        });
      });
    };

    return _react.default.createElement(_react.default.Fragment, null, !customContainer ? _react.default.createElement(_alfredDesignSystem.TextInput, __assign({}, rest, {
      value: imgFile === null || imgFile === void 0 ? void 0 : imgFile.name,
      variant: errorMessage ? 'error' : 'primary',
      theme: theme,
      onClick: selectFile,
      errorMessage: errorMessage,
      readOnly: true
    }), !imgFile && !imgUrl ? _react.default.createElement("button", {
      className: (0, _classnames.default)('tw-text-sm', 'tw-py-[6px]', 'tw-px-4', 'tw-rounded-3xl', 'tw-border', (_b = {}, _b[(0, _classnames.default)('tw-bg-red-500', 'tw-text-white', 'tw-border-red-500')] = !!errorMessage, _b[(0, _classnames.default)('tw-bg-gray-100', 'tw-text-gray-600', 'tw-border-gray-200')] = !errorMessage, _b)),
      onClick: function onClick(e) {
        e.stopPropagation();
        e.preventDefault();
        selectFile();
      }
    }, t('string.choose')) : _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-h-9 tw-overflow-hidden tw-rounded')
    }, _react.default.createElement("img", {
      className: (0, _classnames.default)('tw-w-full tw-h-full'),
      src: (imgFile === null || imgFile === void 0 ? void 0 : imgFile.value) || "".concat(imgUrl, "?h=36"),
      alt: ''
    }))) : clonedCustomContainer, _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-w-0', 'tw-h-0', 'tw-hidden')
    }, _react.default.createElement("input", {
      ref: fileInputRef,
      type: 'file',
      accept: 'image/*',
      onChange: function onChange(e) {
        var files = e.target.files;
        if (files !== null && files.length > 0) {
          var reader_1 = new FileReader();
          reader_1.addEventListener('load', function () {
            var img = new Image();
            img.src = reader_1.result;
            img.onload = function () {
              if (minWidth && img.width < minWidth) {
                notifications.error("".concat(t('image.min_size'), " ").concat(minWidth, "x").concat(minHeight, "px."));
                return;
              }
              if (minHeight && img.height < minHeight) {
                notifications.error("".concat(t('image.min_size'), " ").concat(minWidth, "x").concat(minHeight, "px."));
              } else {
                setUpImg(reader_1.result);
                setModalIsOpen(true);
              }
            };
          });
          reader_1.readAsDataURL(files[0]);
          setFileName(files[0].name);
        }
      }
    })), _react.default.createElement(_reactModal.default, {
      isOpen: modalIsOpen,
      onRequestClose: closeModal,
      style: {
        overlay: {
          zIndex: 999
        },
        content: {
          zIndex: 9999,
          borderColor: _alfredDesignSystem.colors.gray200,
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          width: cropperWidth,
          height: aspectRatio ? cropperWidth / aspectRatio : cropperWidth,
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          position: 'relative',
          maxHeight: '80vh',
          minHeight: '60vh'
        }
      }
    }, _react.default.createElement("div", {
      className: 'tw-min-h-[250px]'
    }, _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-absolute', 'tw-w-full', 'tw-h-[calc(100%_-_120px)]', 'tw-top-0', 'tw-left-0', 'tw-bottom-[120px]')
    }, _react.default.createElement(_reactEasyCrop.default, {
      minZoom: minZoom,
      image: upImg,
      crop: crop,
      zoom: zoom,
      aspect: cropperAspectRatio || aspectRatio || 1,
      restrictPosition: true,
      onCropChange: function onCropChange(crop) {
        setCrop({
          x: zoom < 1 ? 0 : crop.x,
          y: crop.y
        });
      },
      onCropComplete: function onCropComplete(croppedArea, croppedAreaPixels) {
        setCroppedAreaPixels(croppedAreaPixels);
      },
      onZoomChange: setZoom
    }))), _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-absolute', 'tw-bottom-0', 'tw-left-0', 'tw-w-full', 'tw-h-[120px]', 'tw-flex', 'tw-flex-col', 'tw-gap-4', 'tw-items-center', 'tw-px-4', 'tw-justify-center')
    }, _react.default.createElement("input", {
      className: (0, _classnames.default)('tw-cursor-pointer', 'tw-w-full', 'tw-border-0', 'tw-bg-orange-500'),
      type: "range",
      min: minZoom,
      max: 3,
      value: zoom,
      step: 0.01,
      onChange: function onChange(e) {
        var _a;
        return setZoom(Number((_a = e.target) === null || _a === void 0 ? void 0 : _a.value));
      }
    }), !customButton && _react.default.createElement(_alfredDesignSystem.StandardButton, {
      size: 'medium',
      onClick: onModalButtonClick,
      variant: 'orange'
    }, t('string.save')), customButton && customButton(onModalButtonClick))));
  }
  var _default = ImageInput;
  _exports.default = _default;
});