define("admin/models/cookies", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    options: (0, _model.attr)('boolean'),
    statistics: (0, _model.attr)('boolean'),
    personalization: (0, _model.attr)('boolean'),
    consent: Ember.computed.and('options', 'statistics', 'personalization')
  });
  _exports.default = _default;
});