define("admin/routes/client/applications/videointerviews/detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function model(_ref) {
      var application_id = _ref.application_id;
      var _this$modelFor = this.modelFor('client.applications'),
        job = _this$modelFor.job;
      return Ember.RSVP.hash({
        job: job,
        application: this.store.findRecord('jobapplication', application_id)
      });
    },
    actions: {
      videoInviteModal: function videoInviteModal() {
        this.controllerFor('client.applications.profile').toggleProperty('videoInviteModal');
      }
    }
  });
  _exports.default = _default;
});