define("admin/components/forms/eng-txt-btn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['btn', 'gray-brd', 'tran-btn'],
    classNameBindings: ['valid:valid:'],
    click: function click() {
      this.action();
    }
  });
  _exports.default = _default;
});