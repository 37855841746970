define("admin/mixins/query-me", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    urlForQueryRecord: function urlForQueryRecord(query) {
      if (query.me) {
        delete query.me;
        return "".concat(this._super.apply(this, arguments), "/me");
      }
      return this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});