define("admin/templates/components/question-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "DibBRUPR",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@overlayClass\"],[[30,[36,0],[[32,0],\"close\"],null],\"modal-popup question-popup\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"question-popup-modal default-dialog\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"alfred-logo\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"bar\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"close-btn\"],[4,[38,0],[[32,0],\"close\"],null],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"body\"],[12],[2,\"\\n      \"],[10,\"h1\"],[12],[1,[34,1]],[13],[2,\"\\n      \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"title\"]}",
    "meta": {
      "moduleName": "admin/templates/components/question-popup.hbs"
    }
  });
  _exports.default = _default;
});