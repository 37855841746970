define("admin/transforms/model", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _transform.default.extend({
    store: Ember.inject.service(),
    deserialize: function deserialize(data, modelName) {
      return this.store.push(this.store.normalize(modelName, data));
    },
    serialize: function serialize(model) {
      var data = model.serialize();
      Object.keys(data).forEach(function (key) {
        return data[key] === null && delete data[key];
      });
      return data;
    }
  });
  _exports.default = _default;
});