define("admin/templates/components/format-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+Ub9ab3Z",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,6],[[35,5]],[[\"thousand\",\"format\",\"precision\",\"symbol\",\"decimal\"],[[35,4],[35,3],[35,2],[35,1],[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"decimal\",\"symbol\",\"precision\",\"format\",\"thousand\",\"value\",\"format-money\"]}",
    "meta": {
      "moduleName": "admin/templates/components/format-currency.hbs"
    }
  });
  _exports.default = _default;
});