define("admin/serializers/me", ["exports", "admin/serializers/user"], function (_exports, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _user.default.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'me';
    }
  });
  _exports.default = _default;
});