define("admin/components/textarea-auto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'textarea',
    classNames: ['textarea-auto'],
    attributeBindings: ['placeholder'],
    placeholder: null,
    maxHeight: 150,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$().bind('keydown paste', this.autosize.bind(this));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.$()) {
        this.$().val(this.getWithDefault('value', null));
      }
    },
    autosize: function autosize() {
      Ember.run.next(this, function () {
        this.set('value', this.$().val());
        // this.$().css('height', 'auto');
        // this.$().css('padding-bottom', '20px');
        //
        // if (this.$().prop('scrollHeight') < this.maxHeight) {
        //   this.$().css('height', this.$().prop('scrollHeight'));
        // } else if (this.$().prop('scrollHeight') > this.maxHeight) {
        //   this.$().css('height', this.maxHeight);
        // }
      });
    }
  });
  _exports.default = _default;
});