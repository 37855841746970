define("admin/routes/client/edit-job/questions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    activate: function activate() {
      this._super.apply(this, arguments);
      window.scrollTo(0, 0);
    },
    model: function model() {
      var job = this.modelFor('client.edit-job').job;
      return Ember.RSVP.hash({
        job: job,
        questions: this.store.findAll('question')
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('model.questions', model.questions.toArray().filter(function (q) {
        return q.active;
      })); // make it mutable to be able to add records later
      var editJobcontroller = this.controllerFor('client.edit-job');
      controller.set('validation', editJobcontroller.get('validation'));
    }
  });
  _exports.default = _default;
});