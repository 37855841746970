define("admin/components/alfred-modal", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'span',
    didInsertElement: function didInsertElement() {
      (0, _jquery.default)('body').addClass('with-modal');
    },
    didDestroyElement: function didDestroyElement() {
      (0, _jquery.default)('body').removeClass('with-modal');
    },
    actions: {
      close: function close() {
        this.toggle();
      }
    }
  });
  _exports.default = _default;
});