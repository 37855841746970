define("admin/templates/client/settings/video/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "H+c4mpnC",
    "block": "{\"symbols\":[],\"statements\":[[8,\"forms/video-questions\",[],[[\"@template\",\"@toggle\"],[[34,0],[30,[36,1],[[32,0],\"close\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"action\"]}",
    "meta": {
      "moduleName": "admin/templates/client/settings/video/form.hbs"
    }
  });
  _exports.default = _default;
});