define("admin/routes/client/_form/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    renderTemplate: function renderTemplate(route) {
      this.render("client/".concat(route, "/form"), {
        into: 'client',
        outlet: 'main'
      });
    },
    activate: function activate() {
      this._super.apply(this, arguments);
      window.scrollTo(0, 0);
    },
    deactivate: function deactivate() {
      this.controllerFor('client').set('hideNav');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('client').set('hideNav', true);
    }
  });
  _exports.default = _default;
});