define("admin/serializers/sales-invoice", ["exports", "admin/serializers/rest-serializer"], function (_exports, _restSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _restSerializer.default.extend({
    attrs: {
      lines: {
        serialize: false,
        deserialize: 'records'
      }
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'sales-invoice';
    },
    payloadKeyFromModelName: function payloadKeyFromModelName() {
      return 'invoice';
    }
  });
  _exports.default = _default;
});