define("admin/templates/components/videointerviews-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "TyyPHcPq",
    "block": "{\"symbols\":[],\"statements\":[[8,\"link-to\",[],[[\"@route\",\"@model\",\"@class\"],[\"client.applications.videointerviews.detail\",[34,0,[\"id\"]],[30,[36,4],[[30,[36,3],[[35,0,[\"videoInterview\",\"status\"]],\"FINISHED\"],null],\"\",\"disabled\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"cel c1\"],[12],[2,\"\\n    \"],[8,\"profile-thumb\",[],[[\"@bg\",\"@initials\"],[[30,[36,5],[[35,0,[\"profile\",\"image\"]]],[[\"h\"],[100]]],[34,0,[\"profile\",\"thumb_initials\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"cel c2 hidden-m hidden-sm hidden-xs\"],[12],[2,\"\\n    \"],[10,\"div\"],[12],[1,[35,0,[\"profile\",\"name\"]]],[13],[2,\"\\n    \"],[10,\"strong\"],[15,0,[34,0,[\"videoInterview\",\"status\"]]],[12],[1,[35,0,[\"videoInterview\",\"translated_vistatus\"]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"avgrating\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,6],[[35,0,[\"videoInterview\",\"rating\"]],0],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,2],[\"components.applications.video_rating.your_rating\"],null]],[2,\": \"],[1,[30,[36,1],[[35,0,[\"videoInterview\",\"rating\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"cel c3 hidden-m hidden-sm hidden-xs\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,6],[[35,0,[\"videoInterview\",\"averageRating\"]],0],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[15,0,[31,[\"virating \",[34,0,[\"videoInterview\",\"avgratingcolor\"]]]]],[12],[1,[30,[36,1],[[35,0,[\"videoInterview\",\"averageRating\"]]],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"virating gray\"],[12],[2,\"-\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"application\",\"round\",\"t\",\"eq\",\"if\",\"imgix\",\"gt\"]}",
    "meta": {
      "moduleName": "admin/templates/components/videointerviews-list-item.hbs"
    }
  });
  _exports.default = _default;
});