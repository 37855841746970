define("admin/serializers/imports/job", ["exports", "admin/serializers/rest-serializer"], function (_exports, _restSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _restSerializer.default.extend({
    attrs: {
      address: {
        serialize: 'records',
        deserialize: 'records'
      },
      adtype: {
        serialize: 'id',
        deserialize: 'id'
      },
      division: {
        serialize: 'id',
        deserialize: 'id'
      },
      jobstatus: {
        serialize: 'id',
        deserialize: 'id'
      },
      jobtype: {
        serialize: 'id',
        deserialize: 'id'
      },
      tags: {
        embedded: 'always'
      },
      questions: {
        serialize: 'ids',
        deserialize: 'ids'
      },
      productPurchases: {
        embedded: 'always'
      },
      user: 'userId',
      skillIds: {
        serialize: 'id',
        deserialize: 'id'
      },
      skills: {
        serialize: 'records',
        deserialize: 'records'
      },
      jobTemplate: {
        embedded: 'always'
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      if (payload.job && payload.job.tags) {
        payload.job.tags.forEach(function (t) {
          delete t.count;
        });
      }
      return this._super.apply(this, arguments);
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'imports/job';
    },
    payloadKeyFromModelName: function payloadKeyFromModelName() {
      return 'job';
    }
  });
  _exports.default = _default;
});