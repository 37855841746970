define("admin/components/sales/edit-invoice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'a',
    apiAdmin: Ember.inject.service(),
    click: function click() {
      this.toggleProperty('showForm');
    },
    save: function save() {
      var _this = this;
      this.apiAdmin.patch("/sales/invoices/".concat(this.invoice.id), this.invoice.serialize({
        includeId: true
      })).then(function () {
        _this.click();
      });
    },
    actions: {
      toggleForm: function toggleForm() {
        this.click();
      },
      save: function save() {
        this.save();
      }
    }
  });
  _exports.default = _default;
});