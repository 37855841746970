define("admin/transforms/moment", ["exports", "@ember-data/serializer/transform", "moment"], function (_exports, _transform, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _transform.default.extend({
    deserialize: function deserialize(date, options) {
      return date ? (0, _moment.default)(date, options.format || null).toDate() : null;
    },
    serialize: function serialize(date, options) {
      return date ? (0, _moment.default)(date).utc().format(options.format || null) : null;
    }
  });
  _exports.default = _default;
});