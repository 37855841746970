define("admin/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "h1cz4cuH",
    "block": "{\"symbols\":[],\"statements\":[[8,\"head-layout\",[],[[],[]],null],[2,\"\\n\\n\"],[8,\"env-strip\",[],[[],[]],null],[2,\"\\n\"],[8,\"notification-container\",[],[[\"@position\"],[\"top\"]],null],[2,\"\\n\"],[6,[37,0],[[30,[36,4],[[35,1],[35,1,[\"client\"]],[30,[36,3],[[30,[36,2],[[35,1,[\"user\",\"role\",\"name\"]],\"ADMIN\"],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"invoice-warning\",[],[[\"@invoice\"],[[34,1,[\"client\",\"invoice\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,6],[[30,[36,5],null,null]],null]],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"modelLoading\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"screen-loader\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"center\"],[12],[2,\"\\n      \"],[10,\"img\"],[14,\"src\",\"/img/alfred-icon.png\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[30,[36,2],[[32,0,[\"loaderType\"]],\"pageTransition\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"page-transition \",[30,[36,0],[[32,0,[\"pageTransition\"]],\"fadein\",\"fadeout\"],null]]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"center\"],[12],[2,\"\\n      \"],[10,\"img\"],[14,\"src\",\"/img/alfred-icon.png\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"currentSession\",\"eq\",\"not\",\"and\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "admin/templates/application.hbs"
    }
  });
  _exports.default = _default;
});