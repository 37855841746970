define("admin/compiled/utils/StatsUtils", ["exports", "moment", "react", "admin/compiled/utils/emberContext"], function (_exports, _moment, _react, _emberContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var StatsUtils = /** @class */function () {
    function StatsUtils(jobs, categories, applications) {
      this.jobs = jobs;
      this.categories = categories;
      this.applications = applications;
    }
    StatsUtils.prototype.applicationsCount = function () {
      return this.applications.reduce(function (a, b) {
        return a + b.count;
      }, 0);
    };
    StatsUtils.prototype.averageApplications = function () {
      var jobsCount = this.jobs.length;
      if (!jobsCount) {
        return 0;
      }
      return Math.round(this.applicationsCount() / jobsCount);
    };
    StatsUtils.prototype.averageAdLength = function () {
      if (!this.jobs.length) {
        return 0;
      }
      var days = this.jobs.reduce(function (a, b) {
        return a + (0, _moment.default)(b.deadline, 'YYYY-MM-DD').diff((0, _moment.default)(b.startdate, 'YYYY-MM-DD'), 'days');
      }, 0);
      return Math.round(days / this.jobs.length);
    };
    StatsUtils.prototype.adsForPeriod = function (granularity, from, to) {
      var intl = (0, _react.useContext)(_emberContext.default).intl;
      if (!this.jobs) {
        return [];
      }
      var timeValues = [];
      if (granularity === 'months') {
        while (to > from || from.format('M') === to.format('M')) {
          var jobs = this.jobs.filter(function (j) {
            return (0, _moment.default)(j.startdate).format('YYYY-MM') === from.format('YYYY-MM');
          });
          timeValues.push({
            name: from.format('MMM YYYY'),
            value: jobs.length,
            tooltipList: jobs.map(function (j) {
              return "".concat(j.title, " (").concat(j.applied, " ").concat(j.applied === 1 ? intl.t('statistics.application') : intl.t('statistics.applications'), ")");
            })
          });
          from.add(1, 'month');
        }
      } else if (granularity === 'days') {
        while (to > from || from.format('DD YY') === to.format('DD YY')) {
          var jobs = this.jobs.filter(function (j) {
            return (0, _moment.default)(j.startdate).format('YYYY-MM-DD') === from.format('YYYY-MM-DD');
          });
          timeValues.push({
            name: from.format('DD/MM/YY'),
            value: jobs.length,
            tooltipList: jobs.map(function (j) {
              return j.title;
            })
          });
          from.add(1, 'days');
        }
      }
      return timeValues;
    };
    StatsUtils.prototype.applicationsForPeriod = function (granularity, from, to) {
      if (!this.applications) {
        return [];
      }
      var timeValues = [];
      if (granularity === 'months') {
        while (to > from || from.format('M') === to.format('M')) {
          var applications = this.applications.filter(function (a) {
            return (0, _moment.default)(a.date, 'YYYY-MM-DD').format('YYYY-MM') === from.format('YYYY-MM');
          });
          timeValues.push({
            name: from.format('MMM YYYY'),
            tooltipList: [],
            value: applications.reduce(function (a, b) {
              return a + b.count;
            }, 0)
          });
          from.add(1, 'month');
        }
      } else if (granularity === 'days') {
        while (to > from || from.format('DD YY') === to.format('DD YY')) {
          var applications = this.applications.filter(function (a) {
            return a.date === from.format('YYYY-MM-DD');
          });
          timeValues.push({
            name: from.format('DD/MM/YY'),
            tooltipList: [],
            value: applications.reduce(function (a, b) {
              return a + b.count;
            }, 0)
          });
          from.add(1, 'days');
        }
      }
      return timeValues;
    };
    return StatsUtils;
  }();
  var _default = StatsUtils;
  _exports.default = _default;
});