define("admin/routes/client/settings/video/new", ["exports", "admin/routes/client/_form/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    controllerName: 'client/settings/video/form',
    renderTemplate: function renderTemplate() {
      this._super('settings/video');
    },
    model: function model() {
      return this.store.peekAll('videointerviews-template').findBy('isNew', true) || this.store.createRecord('videointerviews-template');
    }
  });
  _exports.default = _default;
});