define("admin/templates/admin/sales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Ct7I42Zy",
    "block": "{\"symbols\":[],\"statements\":[[8,\"nav-header\",[],[[\"@title\",\"@showBtn\"],[[30,[36,0],[\"menu.sales\"],null],false]],null],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"t\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "admin/templates/admin/sales.hbs"
    }
  });
  _exports.default = _default;
});