define("admin/routes/admin/autotagging/category/jobtag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function model(_ref) {
      var jobtag_id = _ref.jobtag_id;
      var _this$modelFor = this.modelFor('admin.autotagging.category'),
        jobTags = _this$modelFor.jobTags;
      var tag = jobTags.filter(function (tag) {
        return tag.id == jobtag_id;
      })[0];
      return Ember.RSVP.hash({
        keywords: tag.getKeywords(),
        tag: tag
      });
    }
  });
  _exports.default = _default;
});