define("admin/helpers/filter-utils/search-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.searchFilter = void 0;
  var searchFilter = function searchFilter(query, name, fit) {
    if (query && !name || query && name && !name.toLowerCase().includes(query.toLowerCase())) {
      fit = false;
    }
    return fit;
  };
  _exports.searchFilter = searchFilter;
});