define("admin/compiled/utils/empty-screens", ["exports", "react", "admin/compiled/ui/empty-screen/EmptyScreen", "admin/compiled/utils/emberContext"], function (_exports, _react, _EmptyScreen, _emberContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.jobsEmptyFilterOn = _exports.jobsEmpty = _exports.brandsEmpty = void 0;
  var jobsEmptyFilterOn = function jobsEmptyFilterOn() {
    var intl = (0, _react.useContext)(_emberContext.default).intl;
    return _react.default.createElement(_EmptyScreen.EmptyScreen, {
      title: intl.t('client.jobs.empty-filter-on.title'),
      content: intl.t('client.jobs.empty-filter-on.description')
    });
  };
  _exports.jobsEmptyFilterOn = jobsEmptyFilterOn;
  var jobsEmpty = function jobsEmpty(action) {
    var _a = (0, _react.useContext)(_emberContext.default),
      intl = _a.intl,
      currentSession = _a.currentSession;
    var allowCreateJob = !currentSession.user.isAgent || currentSession.user.allowCreateJob;
    return _react.default.createElement(_EmptyScreen.EmptyScreen, {
      title: intl.t('index.jobs.title'),
      content: allowCreateJob ? intl.t('index.jobs.description') : '',
      action: allowCreateJob ? {
        title: intl.t('crad.new_job.title'),
        onClick: action
      } : null
    });
  };
  _exports.jobsEmpty = jobsEmpty;
  var brandsEmpty = function brandsEmpty(action) {
    var intl = (0, _react.useContext)(_emberContext.default).intl;
    return _react.default.createElement(_EmptyScreen.EmptyScreen, {
      title: intl.t('index.title'),
      content: intl.t('index.description'),
      action: {
        title: intl.t('index.add_brand'),
        onClick: action
      }
    });
  };
  _exports.brandsEmpty = brandsEmpty;
});