define("admin/models/jobtype", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    new: (0, _model.attr)('boolean'),
    info: (0, _model.attr)('string')
    // slug: attr('string'),
  });
  _exports.default = _default;
});