define("admin/compiled/jobs-list-header-placeholder/JobsListHeaderPlaceholder", ["exports", "react", "admin/compiled/page-title-header/PageTitleHeader"], function (_exports, _react, _PageTitleHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.JobsListHeaderPlaceholder = void 0;
  // Used while loading the jobs list initially
  var JobsListHeaderPlaceholder = function JobsListHeaderPlaceholder() {
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_PageTitleHeader.PageTitleHeader, {
      leftAction: _react.default.createElement("button", null, "hello"),
      title: "",
      actionName: "",
      onAction: function onAction() {},
      menuItems: [{
        text: 'ff',
        onClick: function onClick() {}
      }]
    }));
  };
  _exports.JobsListHeaderPlaceholder = JobsListHeaderPlaceholder;
});