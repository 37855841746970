define("admin/components/crad/textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['crad-textarea'],
    attributeBindings: ['data-test'],
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.options = {
        language: this.intl.locale.firstObject,
        menubar: false,
        toolbar: 'bold italic | bullist numlist | link emoticons',
        statusbar: false,
        plugins: ['paste', 'autoresize', 'lists', 'advlist', 'link', 'emoticons'],
        autoresize_bottom_margin: 5,
        paste_as_text: false,
        valid_elements: 'a[href|target=_blank],strong/b,br,ul,li,ol,italic/i,em,p',
        content_style: ".mce-content-body {font-family:\"source-sans-pro\",sans-serif;font-size:16px;border:none;}"
      };
    }
  });
  _exports.default = _default;
});