define("admin/components/alfrello-profile/video-interview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (and @application.videoInterview @application.videoInterview.answers.length (not (eq @application.videoInterview.status "EXPIRED")))}}
    <Videointerview
      @vi={{@application.videoInterview}}
      @profile={{@application.profile}}
      @application={{@application}}
      @job={{@job}}
      @showHeader={{false}}
      @questionStyle="profile" />
  {{else}}
    <VideointerviewEmpty @vi={{@application.videoInterview}} @application={{@application}} @job={{@job}}/>
  {{/if}}
  
  */
  {
    "id": "2ZzrpC4H",
    "block": "{\"symbols\":[\"@application\",\"@job\"],\"statements\":[[6,[37,3],[[30,[36,2],[[32,1,[\"videoInterview\"]],[32,1,[\"videoInterview\",\"answers\",\"length\"]],[30,[36,1],[[30,[36,0],[[32,1,[\"videoInterview\",\"status\"]],\"EXPIRED\"],null]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"videointerview\",[],[[\"@vi\",\"@profile\",\"@application\",\"@job\",\"@showHeader\",\"@questionStyle\"],[[32,1,[\"videoInterview\"]],[32,1,[\"profile\"]],[32,1],[32,2],false,\"profile\"]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"videointerview-empty\",[],[[\"@vi\",\"@application\",\"@job\"],[[32,1,[\"videoInterview\"]],[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"not\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "admin/components/alfrello-profile/video-interview.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});