define("admin/compiled/ui/large-list-item/LargeListItem", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LargeListItem = void 0;
  var LargeListItem = function LargeListItem(_a) {
    var title = _a.title,
      content = _a.content,
      action = _a.action,
      icon = _a.icon,
      preview = _a.preview;
    return _react.default.createElement("div", {
      className: 'large-list-item'
    }, _react.default.createElement("div", {
      className: 'large-list-item--flex-container'
    }, icon && _react.default.createElement("div", {
      className: "large-list-item--icon ".concat(icon)
    }), _react.default.createElement("div", {
      className: 'large-list-item--middle'
    }, _react.default.createElement("div", {
      className: 'large-list-item--title'
    }, title), _react.default.createElement("div", {
      className: 'large-list-item--content'
    }, content)), action && _react.default.createElement("div", {
      className: 'large-list-item--right'
    }, action)), preview && _react.default.createElement("div", {
      className: 'large-list-item--preview'
    }, preview));
  };
  _exports.LargeListItem = LargeListItem;
});