define("admin/components/applications/new-note", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['new-note'],
    apiClient: Ember.inject.service(),
    classNameBindings: ['defaultLabelId:contracted'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.run.schedule('afterRender', function () {
        (0, _jquery.default)('#note-field').focus();
      });
    },
    actions: {
      saveNewNote: function saveNewNote(note) {
        var _this = this;
        var url = "/jobs/".concat(this.jobId, "/applications/").concat(this.applicationId, "/notes");
        var data = {
          note: note.serialize()
        };
        this.set('loading', true);
        this.apiClient.post(url, data).then(function () {
          _this.addNewNote();
        }).finally(function () {
          return _this.set('loading');
        });
      }
    }
  });
  _exports.default = _default;
});