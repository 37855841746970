define("admin/components/jobs/upsell-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['upsell-item'],
    classNameBindings: ['selected:active', 'disabled:disabled', 'alreadyPurchased:purchased', 'skeleton:no-flex', 'unselectable:unselectable'],
    click: function click() {
      if (!this.unselectable) {
        this.toggleProperty('selected');
      }
    }
  });
  _exports.default = _default;
});