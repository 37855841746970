define("admin/mixins/with-hidden-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.WithHiddenNav = void 0;
  var WithHiddenNav = Ember.Mixin.create({
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('client').set('hideNav', true);
    },
    deactivate: function deactivate() {
      this.controllerFor('client').set('hideNav');
    }
  });
  _exports.WithHiddenNav = WithHiddenNav;
});