define("admin/compiled/utils/useStickyFilter", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var useStickyFilter = function useStickyFilter() {
    (0, _react.useLayoutEffect)(function () {
      var makeOrRemoveSticky = function makeOrRemoveSticky() {
        var _a;
        var headerBounding = (_a = document.querySelector('.page-title-header')) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        var jobsList = document.querySelector('.jobs-list');
        var filter = document.getElementById('ui-filter-bar');
        if (!filter || !jobsList || !headerBounding) {
          return;
        }
        if (headerBounding.bottom < 5 && !filter.classList.contains('sticky')) {
          filter.classList.add('sticky');
          jobsList.classList.add('with-sticky-bar');
        } else if (headerBounding.bottom >= 5 && filter.classList.contains('sticky')) {
          filter.classList.remove('sticky');
          jobsList.classList.remove('with-sticky-bar');
        }
      };
      window.addEventListener('scroll', makeOrRemoveSticky);
      return function () {
        return window.removeEventListener('scroll', makeOrRemoveSticky);
      };
    }, []);
  };
  var _default = useStickyFilter;
  _exports.default = _default;
});