define("admin/templates/client/brands/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jQ7g1s6J",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"brand/edit\",[],[[\"@brand\",\"@brands\",\"@model\",\"@onClose\"],[[32,1,[\"brand\"]],[32,1,[\"brands\"]],[32,1],[32,0,[\"close\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/templates/client/brands/form.hbs"
    }
  });
  _exports.default = _default;
});