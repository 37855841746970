define("admin/components/alfrello/alfrello-upsell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['alfrello-upsell'],
    classNameBindings: ['dismissed'],
    actions: {
      dismiss: function dismiss() {
        if (this.dismiss) {
          this.dismiss();
        } else {
          this.set('dismissed', true);
        }
      },
      readMore: function readMore() {
        var upsellModal = ['BOOST', 'VIDEOINTERVIEWS'];
        if (upsellModal.indexOf(this.announcementType) >= 0) {
          return this.set('upsellModal', true);
        }
        return this.set('showDetail', true);
      }
    }
  });
  _exports.default = _default;
});