define("admin/components/jobs/job-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'li',
    actions: {
      selectTag: function selectTag() {
        var _this = this;
        var jobtags = this.jobtags;
        var jobtag = this.jobtag;
        if (jobtags.get('length') === 0) {
          jobtags.pushObject(jobtag);
        } else {
          jobtags.map(function (tag) {
            if (tag) {
              if (tag.id === _this.get('jobtag.id')) {
                jobtags.removeObject(jobtag);
              } else {
                if (jobtags.get('length') < 10) {
                  jobtags.pushObject(jobtag);
                }
              }
            }
          });
        }

        // this.rerender()
      }
    }
  });
  _exports.default = _default;
});