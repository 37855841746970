define("admin/routes/locked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    newAdmin: Ember.inject.service(),
    model: function model() {
      var _this = this;
      return this.store.findRecord('client', 'me').then(function (client) {
        if (client.status !== 'BLACKLIST') {
          _this.newAdmin.transitionTo('jobs');
        }
      });
    }
  });
  _exports.default = _default;
});