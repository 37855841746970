define("admin/serializers/job", ["exports", "admin/serializers/rest-serializer"], function (_exports, _restSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _restSerializer.default.extend({
    attrs: {
      address: {
        serialize: 'records',
        deserialize: 'records'
      },
      addresses: {
        serialize: 'records',
        deserialize: 'records'
      },
      adtype: {
        serialize: 'id',
        deserialize: 'id'
      },
      division: {
        serialize: 'id',
        deserialize: 'id'
      },
      jobstatus: {
        serialize: 'id',
        deserialize: 'id'
      },
      jobtype: {
        serialize: 'id',
        deserialize: 'id'
      },
      jobtypes: {
        serialize: 'id',
        deserialize: 'id'
      },
      tags: {
        embedded: 'always'
      },
      questions: {
        serialize: 'ids',
        deserialize: 'ids'
      },
      productPurchases: {
        embedded: 'always'
      },
      user: 'userId',
      skillIds: {
        serialize: 'id',
        deserialize: 'id'
      },
      skills: {
        serialize: 'records',
        deserialize: 'records'
      },
      jobTemplate: {
        embedded: 'always'
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      if (payload.job && payload.job.tags) {
        payload.job.tags.forEach(function (t) {
          delete t.count;
        });
      }
      return this._super.apply(this, arguments);
    },
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload) {
      if (payload.job) {
        var _payload$job$status;
        // otherwise these properties would overwrite the jobs list properties. In some cases they can vary, e.g. due to application expirations.
        delete payload.job.price;
        delete payload.job.watchers;
        delete payload.job.applied;
        if (payload.job.addresses === null && ((_payload$job$status = payload.job.status) === null || _payload$job$status === void 0 ? void 0 : _payload$job$status.value) !== 'DRAFT') {
          payload.job.noaddress = true;
        }
      }
      return this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});