define("admin/models/experience", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    company: (0, _model.attr)('string'),
    current: (0, _model.attr)('boolean'),
    fromdate: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    todate: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    sortableDate: Ember.computed('fromdate', function () {
      if (this.fromdate) {
        return this.fromdate;
      }
      return '';
    })
  });
  _exports.default = _default;
});