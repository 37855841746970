define("admin/routes/client/settings/compass/new", ["exports", "admin/routes/client/_form/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    controllerName: 'client/settings/compass/form',
    renderTemplate: function renderTemplate() {
      this._super('settings/compass');
    },
    model: function model() {
      return {
        compassTemplate: {
          name: '',
          color: '#FF8A5B',
          compassTemplateItems: []
        }
      };
    }
  });
  _exports.default = _default;
});