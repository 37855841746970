define("admin/models/system-message", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    messagetype: (0, _model.attr)('string', {
      defaultValue: 'GENERAL'
    }),
    messagetitle: (0, _model.attr)('string'),
    messagetext: (0, _model.attr)('string'),
    messagestatus: (0, _model.attr)('string', {
      defaultValue: 'PUBLISHED'
    }),
    published: (0, _model.attr)('string'),
    unread: (0, _model.attr)('boolean'),
    messageuser: (0, _model.belongsTo)('sysmsg/messageuser'),
    client: (0, _model.belongsTo)('client'),
    published_format: Ember.computed('published', function () {
      return this.published ? (0, _moment.default)(this.published, 'DD.MM.YYYY HH:mm').fromNow() : '-';
    }),
    fromNow: Ember.computed('published', function () {
      return this.published ? (0, _moment.default)(this.published, 'DD.MM.YYYY HH:mm').fromNow(true) : '-';
    })
  });
  _exports.default = _default;
});