define("admin/compiled/alfrello-column/AlfrelloStandardColumnContainer", ["exports", "react", "react-beautiful-dnd", "admin/compiled/alfrello-card/AlfrelloCard", "admin/compiled/utils", "admin/compiled/utils/useWindowSize", "admin/compiled/alfrello-column/AlfrelloColumn", "admin/compiled/alfrello-column/LoadingBar"], function (_exports, _react, _reactBeautifulDnd, _AlfrelloCard, _utils, _useWindowSize, _AlfrelloColumn, _LoadingBar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AlfrelloStandardColumnContainer = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var getListStyle = function getListStyle(columnHeightReduce, windowHeight, width, isDragging) {
    return {
      marginTop: 0,
      width: width,
      height: windowHeight - columnHeightReduce,
      paddingBottom: '20px',
      overflowY: 'scroll',
      overflowX: 'hidden',
      backgroundColor: isDragging ? '#ddd' : 'none'
    };
  };
  var getItemStyle = function getItemStyle(_a) {
    var draggableStyle = _a.draggableStyle,
      isDragging = _a.isDragging;
    var combined = __assign({}, draggableStyle);
    return __assign(__assign({}, combined), {
      marginLeft: "8px",
      marginRight: "8px",
      marginBottom: 8,
      marginTop: 0,
      outline: 'none'
    });
  };
  var InnerItemList = _react.default.memo(function InnerItemList(props) {
    return props.items.map(function (item, index) {
      if (index > props.maxEls) {
        return null;
      }
      var isActive = props.activeApplication && item.id == props.activeApplication.id;
      return _react.default.createElement(_reactBeautifulDnd.Draggable, {
        key: item.id,
        draggableId: item.id,
        index: index
      }, function (provided, snapshot) {
        return _react.default.createElement("div", __assign({
          ref: provided.innerRef
        }, provided.draggableProps, provided.dragHandleProps, {
          style: getItemStyle({
            draggableStyle: provided.draggableProps.style,
            isDragging: snapshot.isDragging
          }),
          onClick: item.status !== 'CANCELED' ? item.onClick : function () {
            console.log('Application canceled. Prevent open modal.');
          }
        }), _react.default.createElement(_AlfrelloCard.AlfrelloCard, {
          id: item.id,
          isActive: isActive,
          initials: item.get('profile.initials'),
          image: item.get('profile.image'),
          name: item.get('profile.name'),
          experience: item.get('profile.latestWorkOrEducation'),
          submitted: item.submitted,
          isDragging: snapshot.isDragging,
          isFadedOut: item.status === 'CANCELED' || item.status === 'REJECTED',
          videoInterview: item.get('videoInterview'),
          interview: item.get('interview'),
          status: item.status,
          isHired: item.get('hired'),
          isCopied: item.get('copied'),
          compasses: item.get('applicationCompasses'),
          unreadCount: item.get('unreadCount'),
          color: props.color,
          menuItems: item.menuItems,
          orgJobTitle: item.orgJobTitle,
          contracts: item.contracts
        }));
      });
    });
  });
  var ItemList = function ItemList(_a) {
    var column = _a.column,
      items = _a.items,
      columnHeightReduce = _a.columnHeightReduce,
      isSidebar = _a.isSidebar,
      activeApplication = _a.activeApplication;
    var batchSize = Math.floor(window.innerHeight / 50);
    var activeApplicationIndex = items.indexOf(activeApplication) || 0;
    var _b = (0, _react.useState)(batchSize + activeApplicationIndex),
      maxEls = _b[0],
      setMaxEls = _b[1];
    var handleScroll = function handleScroll(e) {
      var bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 100;
      if (bottom) {
        setMaxEls(maxEls + batchSize);
      }
    };
    var _c = (0, _useWindowSize.useWindowSize)(),
      width = _c[0],
      height = _c[1];
    var listId = (0, _utils.uuid)();
    // make sure active application is in the list and create buffer of batchSize.
    (0, _react.useLayoutEffect)(function () {
      if (activeApplication && activeApplication.columnId === column.id) {
        var activeApplicationIndex_1 = items.indexOf(activeApplication);
        var cardElement = document.getElementById("card-".concat(activeApplication.id));
        var listElement_1 = document.getElementById(listId);
        if (cardElement && listElement_1) {
          var offset_1 = cardElement.offsetTop;
          setTimeout(function () {
            return listElement_1.scrollTop = offset_1 - 55;
          }, 200);
        }
      }
    }, []);
    return isSidebar ? _react.default.createElement(_reactBeautifulDnd.Droppable, {
      droppableId: "".concat(column.id),
      renderClone: function renderClone(provided, snapshot, rubric) {
        var item = items[rubric.source.index];
        var isActive = activeApplication && item.id == activeApplication.id;
        return _react.default.createElement("div", __assign({
          ref: provided.innerRef
        }, provided.draggableProps, provided.dragHandleProps, {
          style: getItemStyle({
            draggableStyle: provided.draggableProps.style,
            isDragging: snapshot.isDragging
          }),
          onClick: item.onClick
        }), _react.default.createElement(_AlfrelloCard.AlfrelloCard, {
          isActive: isActive,
          image: item.get('profile.image'),
          name: item.get('profile.name'),
          experience: item.get('profile.latestWorkOrEducation'),
          submitted: item.submitted,
          isDragging: snapshot.isDragging,
          isFadedOut: false,
          videoInterview: item.get('videoInterview'),
          interview: item.get('interview'),
          status: item.status,
          isHired: item.get('hired'),
          compasses: item.get('applicationCompasses'),
          unreadCount: item.get('unreadCount'),
          color: column.color,
          menuItems: item.menuItems,
          initials: item.get('profile.initials')
        }));
      }
    }, function (provided) {
      return _react.default.createElement("div", __assign({
        id: listId,
        onScroll: handleScroll
      }, provided.droppableProps, {
        ref: provided.innerRef,
        style: getListStyle(columnHeightReduce, height, width < 1400 ? 290 : 320)
      }), _react.default.createElement(InnerItemList, {
        activeApplication: activeApplication,
        isSidebar: isSidebar,
        items: items,
        maxEls: maxEls,
        color: column.color
      }));
    }) : _react.default.createElement(_reactBeautifulDnd.Droppable, {
      droppableId: column.id
    }, function (provided) {
      return _react.default.createElement("div", __assign({
        onScroll: handleScroll
      }, provided.droppableProps, {
        ref: provided.innerRef,
        style: getListStyle(columnHeightReduce, height, width < 1400 ? 290 : 320)
      }), _react.default.createElement(InnerItemList, {
        isSidebar: isSidebar,
        items: items,
        maxEls: maxEls,
        color: column.color
      }), provided.placeholder);
    });
  };
  var AlfrelloStandardColumnContainer = _react.default.memo(function Column(_a) {
    var column = _a.column,
      applications = _a.applications,
      provided = _a.provided,
      menuItems = _a.menuItems,
      columnHeightReduce = _a.columnHeightReduce,
      isLoading = _a.isLoading,
      isSidebar = _a.isSidebar,
      switchColumn = _a.switchColumn,
      columns = _a.columns,
      activeApplication = _a.activeApplication,
      isDragging = _a.isDragging;
    return _react.default.createElement(_AlfrelloColumn.AlfrelloColumn, {
      menuItems: menuItems,
      column: column,
      applications: applications,
      provided: provided,
      switchColumn: switchColumn,
      columns: columns,
      isSidebar: isSidebar,
      isDragging: isDragging
    }, isLoading && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_LoadingBar.LoadingBar, null)), _react.default.createElement(ItemList, {
      isSidebar: isSidebar,
      column: column,
      items: applications,
      columnHeightReduce: columnHeightReduce,
      activeApplication: activeApplication
    }));
  });
  _exports.AlfrelloStandardColumnContainer = AlfrelloStandardColumnContainer;
});