define("admin/templates/components/forms/eng-txt-btn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "u+U0XAdS",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"components.forms.eng_txt_btn.english_text\"],null]],[2,\"\\n\"],[10,\"div\"],[14,0,\"stat\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"components.forms.eng_txt_btn.done\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"components.forms.eng_txt_btn.missing\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"valid\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/forms/eng-txt-btn.hbs"
    }
  });
  _exports.default = _default;
});