define("admin/models/message-template", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    template: (0, _model.attr)('string'),
    locale: (0, _model.attr)('string'),
    type: (0, _model.attr)() // title, toolTip, type
  });
  _exports.default = _default;
});