define("admin/templates/admin/skills/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "92BzEuI8",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"skills\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[8,\"sidebar/sidebar-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"left-side\"],[12],[2,\"\\n      \"],[8,\"ui/search-input\",[],[[\"@placeholder\",\"@value\"],[[30,[36,2],[\"skills.search\"],null],[34,3]]],null],[2,\"\\n      \"],[10,\"div\"],[14,0,\"categories-container\"],[12],[2,\"\\n        \"],[8,\"skills/categories\",[],[[\"@categories\",\"@selectedSubcat\",\"@subcatid\",\"@refreshData\"],[[34,4],[34,0],[34,5],[30,[36,1],[[32,0],\"refresh\"],null]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,6],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"sidebar/sidebar-content-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"skills/manage-skills\",[],[[\"@categoryName\",\"@subcategoryName\",\"@subcategoryId\",\"@refreshData\",\"@skills\"],[[34,0,[\"category\",\"name\"]],[34,0,[\"name\"]],[34,0,[\"id\"]],[30,[36,1],[[32,0],\"refresh\"],null],[34,0,[\"skills\"]]]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"selectedSubcat\",\"action\",\"t\",\"searchQuery\",\"filteredCategories\",\"subcatid\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/admin/skills/index.hbs"
    }
  });
  _exports.default = _default;
});