define("admin/templates/admin/library", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Mm2RzIMe",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"library\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[8,\"layout/space\",[],[[\"@top\"],[20]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.library.inputs\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        Inputs\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.library.sidebar\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        Sidebar\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.library.crad\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        Create Job\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.library.crad-index\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        Create Job Index\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.library.crad-description\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        Create Job Description\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.library.buttons\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        Buttons\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "admin/templates/admin/library.hbs"
    }
  });
  _exports.default = _default;
});