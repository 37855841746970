define("admin/templates/client/brands", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wjTxrPNd",
    "block": "{\"symbols\":[],\"statements\":[[8,\"page-header-react/page-header\",[],[[\"@title\",\"@actionName\",\"@actionRoute\"],[[30,[36,0],[\"menu.brands\"],null],[30,[36,0],[\"menu.new_brand\"],null],\"client.brands.new\"]],null],[2,\"\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "admin/templates/client/brands.hbs"
    }
  });
  _exports.default = _default;
});