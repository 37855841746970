define("admin/helpers/mult", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.mult = mult;
  function mult(numbers) {
    return numbers.reduce(function (a, b) {
      return Number(a) * Number(b);
    });
  }
  var _default = Ember.Helper.helper(mult);
  _exports.default = _default;
});