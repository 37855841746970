define("admin/compiled/ui/SvgIcon", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SvgMessageIcon = void 0;
  var SvgMessageIcon = function SvgMessageIcon(_a) {
    var color = _a.color;
    return _react.default.createElement("svg", {
      width: "20",
      height: "20",
      xmlns: "http://www.w3.org/2000/svg"
    }, _react.default.createElement("path", {
      d: "M14.375 8.125h-8.75a.625.625 0 1 1 0-1.25h8.75a.625.625 0 0 1 0 1.25zm-1.25 3.75h-6.25a.625.625 0 1 1 0-1.25h6.25a.625.625 0 1 1 0 1.25zM10 0C4.478 0 0 3.918 0 8.75c0 2.762 1.466 5.221 3.75 6.824V20l4.38-2.658c.607.1 1.23.158 1.87.158 5.523 0 10-3.918 10-8.75S15.523 0 10 0z",
      fill: color,
      fillRule: "evenodd"
    }));
  };
  _exports.SvgMessageIcon = SvgMessageIcon;
});