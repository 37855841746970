define("admin/templates/components/terms-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "K/VQH+t7",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-dialog\",[],[[\"@overlayClass\"],[\"modal-popup terms-modal\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"default-dialog small text-center\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[1,[30,[36,0],[\"components.terms_modal.title\"],null]],[13],[2,\"\\n\\n    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"components.terms_modal.p\"],null]],[10,\"br\"],[12],[13],[10,\"a\"],[15,6,[30,[36,1],[\"data\"],null]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[1,[30,[36,0],[\"components.terms_modal.read_more\"],null]],[13],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"edit-form\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n          \"],[11,\"button\"],[24,0,\"btn gray-brd upper\"],[4,[38,3],[[32,0],[35,2]],null],[12],[1,[30,[36,0],[\"components.terms_modal.no_consent\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n          \"],[11,\"button\"],[24,0,\"btn upper\"],[4,[38,3],[[32,0],[35,4]],null],[12],[1,[30,[36,0],[\"components.terms_modal.consent\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"get-link\",\"disagree\",\"action\",\"agree\"]}",
    "meta": {
      "moduleName": "admin/templates/components/terms-modal.hbs"
    }
  });
  _exports.default = _default;
});