define("admin/components/new/float-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    tagName: 'div',
    classNames: ['float-header'],
    actions: {
      close: function close() {
        if (this.onClose) {
          this.onClose();
        } else if (this.returnRoute) {
          this.router.transitionTo(this.returnRoute);
        }
      }
    }
  });
  _exports.default = _default;
});