define("admin/templates/admin/autotagging/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "FbP+sJPS",
    "block": "{\"symbols\":[\"category\"],\"statements\":[[10,\"div\"],[14,0,\"container\"],[14,1,\"autotags\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"edit-form top-space\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"categories\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"link-to\",[],[[\"@route\",\"@model\",\"@class\",\"@tagName\"],[\"admin.autotagging.category\",[32,1,[\"id\"]],\"category\",\"div\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[10,\"div\"],[14,0,\"title\"],[12],[2,\"\\n            \"],[1,[32,1,[\"name\"]]],[2,\"\\n          \"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/admin/autotagging/index.hbs"
    }
  });
  _exports.default = _default;
});