define("admin/compiled/alfrello-profile-info/ProfileTimeline", ["exports", "react", "admin/compiled/alfrello-profile-card/AlfrelloProfileCard"], function (_exports, _react, _AlfrelloProfileCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ProfileTimeline = void 0;
  var ProfileTimelineItem = function ProfileTimelineItem(_a) {
    var title = _a.title,
      place = _a.place,
      description = _a.description,
      from = _a.from,
      to = _a.to,
      degree = _a.degree;
    return _react.default.createElement("div", {
      className: 'alfrello-profile-timeline'
    }, _react.default.createElement("div", {
      className: 'alfrello-profile-timeline--top'
    }, _react.default.createElement("div", {
      className: 'alfrello-profile-timeline--left'
    }, _react.default.createElement("div", {
      className: 'alfrello-profile-timeline--title'
    }, title), _react.default.createElement("div", {
      className: 'alfrello-profile-timeline--place'
    }, place), degree && _react.default.createElement("div", {
      className: 'alfrello-profile-timeline--level'
    }, _react.default.createElement("strong", {
      className: 'blue'
    }, degree))), _react.default.createElement("div", {
      className: 'alfrello-profile-timeline--right'
    }, _react.default.createElement("strong", null, from), " - ", _react.default.createElement("strong", null, to))), description && _react.default.createElement("div", {
      className: 'alfrello-profile-timeline--description'
    }, description));
  };
  var ProfileTimeline = function ProfileTimeline(_a) {
    var items = _a.items,
      label = _a.label;
    return _react.default.createElement(_AlfrelloProfileCard.AlfrelloProfileCard, {
      label: label
    }, items.map(function (i) {
      return _react.default.createElement(ProfileTimelineItem, {
        key: i.id,
        id: i.id,
        title: i.title,
        place: i.place,
        description: i.description,
        from: i.from,
        to: i.to,
        degree: i.degree
      });
    }));
  };
  _exports.ProfileTimeline = ProfileTimeline;
});