define("admin/compiled/e-sign/Summary", ["exports", "@alfred-is/alfred-design-system", "classnames", "react", "admin/compiled/hooks/useESign", "admin/compiled/utils/emberContext", "admin/compiled/utils/languageContext", "admin/compiled/e-sign/Icons", "admin/compiled/e-sign/Previews", "admin/compiled/e-sign/types"], function (_exports, _alfredDesignSystem, _classnames, _react, _useESign, _emberContext, _languageContext, _Icons, _Previews, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Summary = _exports.Item = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var __awaiter = void 0 && (void 0).__awaiter || function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P ? value : new P(function (resolve) {
        resolve(value);
      });
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }
      function rejected(value) {
        try {
          step(generator["throw"](value));
        } catch (e) {
          reject(e);
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };
  var __generator = void 0 && (void 0).__generator || function (thisArg, body) {
    var _ = {
        label: 0,
        sent: function sent() {
          if (t[0] & 1) throw t[1];
          return t[1];
        },
        trys: [],
        ops: []
      },
      f,
      y,
      t,
      g;
    return g = {
      next: verb(0),
      "throw": verb(1),
      "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
      return this;
    }), g;
    function verb(n) {
      return function (v) {
        return step([n, v]);
      };
    }
    function step(op) {
      if (f) throw new TypeError("Generator is already executing.");
      while (g && (g = 0, op[0] && (_ = 0)), _) try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];
        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;
          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };
          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;
          case 7:
            op = _.ops.pop();
            _.trys.pop();
            continue;
          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }
            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }
            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }
            if (t && _.label < t[2]) {
              _.label = t[2];
              _.ops.push(op);
              break;
            }
            if (t[2]) _.ops.pop();
            _.trys.pop();
            continue;
        }
        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
      if (op[0] & 5) throw op[1];
      return {
        value: op[0] ? op[1] : void 0,
        done: true
      };
    }
  };
  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };
  var Summary = function Summary(_a) {
    var application = _a.application,
      jobId = _a.jobId,
      contracts = _a.contracts,
      removeContract = _a.removeContract,
      setPage = _a.setPage,
      onClose = _a.onClose;
    var intl = (0, _react.useContext)(_languageContext.default);
    var _b = (0, _react.useContext)(_emberContext.default),
      apiManager = _b.apiManager,
      notifications = _b.notifications;
    var _c = (0, _react.useState)(''),
      selectedUser = _c[0],
      setSelectedUser = _c[1];
    var _d = (0, _react.useState)(false),
      validEmployee = _d[0],
      setValidEmployee = _d[1];
    var _e = (0, _react.useState)({
        name: application.name,
        ssn: '',
        phone: '',
        email: ''
      }),
      employee = _e[0],
      setEmployee = _e[1];
    var _f = (0, _react.useState)(false),
      loading = _f[0],
      setLoading = _f[1];
    var users = (0, _useESign.useUsers)().data;
    var employeeData = (0, _useESign.useProfile)(application.id, jobId).data;
    (0, _react.useEffect)(function () {
      setEmployee({
        name: application.name,
        ssn: employeeData ? employeeData.ssn || '' : '',
        phone: employeeData ? employeeData.phone || '' : '',
        email: employeeData ? employeeData.email || '' : ''
      });
    }, [employeeData]);
    var sendToSign = function sendToSign() {
      return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
          setLoading(true);
          apiManager.post("/systems/hr/elsign/contracts/".concat(jobId, "/").concat(application.id, "/sign"), {
            contracts: contracts.map(function (_a) {
              var contractId = _a.contractId;
              return contractId;
            }),
            signingUser: selectedUser,
            signingEmployee: employee
          }).then(function () {
            notifications.success(intl.t('esign.sent.success'));
            onClose();
          }).catch(function (e) {
            console.log(e);
          }).finally(function () {
            return setLoading(false);
          });
          return [2 /*return*/];
        });
      });
    };

    var removeDoc = function removeDoc(id) {
      removeContract(id);
      apiManager.delete("/systems/hr/elsign/templates/".concat(id, "/use/").concat(jobId, "/").concat(application.id));
    };
    var validateProfile = function validateProfile() {
      if (!employee.ssn || !employee.phone) {
        return setValidEmployee(false);
      }
      return setValidEmployee(employee.ssn.length === 10 && employee.phone.length >= 7);
    };
    var checkSSN = function checkSSN(value) {
      return value.replace(/\D/g, '');
    };
    var checkPhone = function checkPhone(value) {
      return value.replace(/\s/g, '');
    };
    (0, _react.useEffect)(function () {
      validateProfile();
    }, [employee]);
    var selectedUserDropdownObject = (0, _react.useMemo)(function () {
      var user = users === null || users === void 0 ? void 0 : users.find(function (u) {
        return u.id == Number(selectedUser);
      });
      if (user) {
        return {
          label: user.name,
          value: user.id
        };
      }
    }, [selectedUser]);
    console.log(selectedUser.length > 0);
    console.log(selectedUser);
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("div", {
      className: "tw-flex-1 tw-flex tw-flex-col"
    }, _react.default.createElement("div", {
      className: "tw-flex tw-items-center tw-justify-between tw-mb-4"
    }, _react.default.createElement(_Previews.SmallButton, {
      color: "blue",
      onClick: function onClick() {
        return setPage(contracts.length ? _types.PageType.Edit : _types.PageType.Reviews);
      }
    }, _react.default.createElement(_alfredDesignSystem.Icon, {
      kind: _alfredDesignSystem.AlfredIconT.ArrowLeft,
      size: 11,
      color: '#fff'
    }), intl.t('esign.back')), _react.default.createElement("div", {
      className: "tw-text-lg tw-font-700"
    }, intl.t('esign.summary.title')), _react.default.createElement("div", null)), _react.default.createElement("div", {
      className: "tw-flex md:tw-flex-row tw-flex-col tw-mt-5 tw-gap-x-14 tw-gap-y-8"
    }, _react.default.createElement("div", {
      className: "tw-flex-1 tw-flex tw-flex-col tw-gap-y-5"
    }, _react.default.createElement("div", {
      className: "tw-border tw-border-gray-200 tw-rounded-2xl tw-p-5"
    }, intl.t('esign.summary.description')), _react.default.createElement("div", {
      className: "tw-bg-white tw-border tw-border-gray-200 tw-rounded-2xl tw-overflow-hidden"
    }, contracts.map(function (document) {
      return _react.default.createElement(Item, {
        key: document.id,
        selected: true
      }, _react.default.createElement("div", {
        className: "tw-flex-1 tw-flex tw-justify-between"
      }, document.name, _react.default.createElement("button", {
        onClick: function onClick() {
          return removeDoc(document.id);
        }
      }, _react.default.createElement(_Icons.Trash, null))));
    }), contracts.length <= 0 && _react.default.createElement("div", {
      className: "tw-p-5 tw-flex tw-gap-x-4"
    }, _react.default.createElement(_Icons.Warning, null), intl.t('esign.summary.empty')))), _react.default.createElement("div", {
      className: "tw-flex-1 tw-flex tw-flex-col tw-gap-y-5"
    }, _react.default.createElement("div", {
      className: "tw-bg-white tw-border tw-border-gray-200 tw-rounded-2xl tw-overflow-hidden"
    }, _react.default.createElement(Item, {
      className: "tw-flex-1 hover:tw-bg-white",
      selected: validEmployee
    }, _react.default.createElement("div", {
      className: "tw-flex tw-flex-col tw-flex-1 tw-gap-y-2"
    }, _react.default.createElement("div", {
      className: "tw-flex tw-gap-x-4"
    }, _react.default.createElement("div", {
      className: "tw-flex-1"
    }, _react.default.createElement("div", {
      className: "tw-font-700"
    }, intl.t('esign.summary.employee')), _react.default.createElement("div", null, employee.name)), _react.default.createElement("div", {
      className: "tw-w-40"
    }, _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-font-700', {
        'tw-text-red-500': !employee.ssn
      })
    }, intl.t('string.ssn')), _react.default.createElement("div", null, _react.default.createElement("input", {
      type: "text",
      placeholder: intl.t('general.type_here'),
      className: "tw-bg-transparent tw-w-full",
      name: "ssn",
      value: employee.ssn,
      onChange: function onChange(e) {
        return setEmployee(__assign(__assign({}, employee), {
          ssn: checkSSN(e.target.value)
        }));
      }
    })))), _react.default.createElement("div", {
      className: "tw-h-px tw-bg-gray-200"
    }), _react.default.createElement("div", {
      className: "tw-flex tw-gap-x-4"
    }, _react.default.createElement("div", {
      className: "tw-flex-1"
    }, _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-font-700', {
        'tw-text-red-500': !employee.email
      })
    }, intl.t('string.email')), _react.default.createElement("div", null, _react.default.createElement("input", {
      type: "email",
      placeholder: intl.t('general.type_here'),
      className: "tw-bg-transparent tw-w-full",
      name: "email",
      value: employee.email,
      onChange: function onChange(e) {
        return setEmployee(__assign(__assign({}, employee), {
          email: e.target.value
        }));
      }
    }))), _react.default.createElement("div", {
      className: "tw-w-40"
    }, _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-font-700', {
        'tw-text-red-500': !employee.phone
      })
    }, intl.t('string.phone')), _react.default.createElement("div", null, _react.default.createElement("input", {
      type: "text",
      placeholder: intl.t('general.type_here'),
      className: "tw-bg-transparent tw-w-full",
      name: "phone",
      value: employee.phone,
      onChange: function onChange(e) {
        return setEmployee(__assign(__assign({}, employee), {
          phone: checkPhone(e.target.value)
        }));
      }
    }))))))), _react.default.createElement("div", {
      className: "tw-w-20 tw-h-1 tw-self-center tw-rounded-md tw-bg-gray-300"
    }), _react.default.createElement("div", {
      className: "tw-bg-white tw-border tw-border-gray-200 tw-rounded-2xl tw-overflow-hidden"
    }, _react.default.createElement(Item, {
      className: "tw-flex-1 hover:tw-bg-white",
      selected: !!selectedUser
    }, _react.default.createElement("div", {
      className: "tw-flex tw-flex-col tw-flex-1"
    }, _react.default.createElement("div", {
      className: "tw-font-700 tw-pl-1"
    }, intl.t('esign.summary.company_user')), _react.default.createElement("div", {
      className: "tw-mt-4"
    }, _react.default.createElement(_alfredDesignSystem.SelectInput, {
      name: "users",
      selectTheme: _alfredDesignSystem.Theme.Blue,
      onChange: function onChange(val) {
        setSelectedUser(val === null || val === void 0 ? void 0 : val.value);
      },
      value: selectedUserDropdownObject,
      options: users === null || users === void 0 ? void 0 : users.map(function (u) {
        return {
          label: u.name,
          value: u.id
        };
      }),
      placeholder: intl.t('esign.summary.select_user')
    }))))), _react.default.createElement("button", {
      className: (0, _classnames.default)('tw-flex tw-items-center tw-justify-center tw-gap-x-1.5', 'tw-bg-green-500 hover:tw-bg-green-600 disabled:tw-opacity-50', 'tw-text-white tw-p-3 tw-rounded-sm tw-font-700'),
      disabled: loading || contracts.length <= 0 || selectedUser.length <= 0 || !validEmployee,
      onClick: sendToSign
    }, intl.t('esign.continue.sent'), _react.default.createElement(_alfredDesignSystem.Icon, {
      kind: _alfredDesignSystem.AlfredIconT.Arrow,
      size: 13,
      color: '#fff'
    }))))), loading && _react.default.createElement(_alfredDesignSystem.LoadingScreen, null));
  };
  _exports.Summary = Summary;
  var Item = function Item(_a) {
    var selected = _a.selected,
      children = _a.children,
      className = _a.className,
      rest = __rest(_a, ["selected", "children", "className"]);
    return _react.default.createElement("div", __assign({
      className: (0, _classnames.default)(className, 'tw-flex tw-items-center tw-gap-x-4 tw-p-5 last:tw-border-b-0', 'tw-group tw-border-b tw-border-gray-200 hover:tw-bg-gray-100')
    }, rest), selected ? _react.default.createElement(_Icons.Selected, null) : _react.default.createElement(_Icons.Select, null), children);
  };
  _exports.Item = Item;
});