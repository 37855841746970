define("admin/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XDtxqmJ+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"login\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"overlay\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n\\n        \"],[10,\"img\"],[15,0,[31,[\"logo \",[30,[36,1],[[32,0,[\"loading\"]],\"shakeit\"],null],\" \",[30,[36,1],[[30,[36,0],[[32,0,[\"loading\"]],\"hide\"],null],\"hideit\"],null]]]],[14,\"src\",\"/img/alfred-icon.png\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n\\n        \"],[8,\"login-form\",[],[[\"@loading\",\"@success\"],[[32,0,[\"loading\"]],[30,[36,3],[[32,0],[35,2]],null]]],null],[2,\"\\n\\n        \"],[8,\"region-locales\",[],[[],[]],null],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"foot\"],[12],[2,\"\\n          \"],[1,[30,[36,4],[\"login.info\"],null]],[2,\" \"],[10,\"a\"],[15,6,[30,[36,5],[\"terms\"],null]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[1,[30,[36,4],[\"login.terms\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"successLogin\",\"action\",\"t\",\"get-link\"]}",
    "meta": {
      "moduleName": "admin/templates/login.hbs"
    }
  });
  _exports.default = _default;
});