define("admin/templates/components/forms/text-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Kvb4ZrTS",
    "block": "{\"symbols\":[],\"statements\":[[10,\"label\"],[12],[1,[34,1]],[13],[2,\"\\n\\n\"],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"tooltipstered\"],[12],[2,\"\\n    \"],[8,\"ember-tooltip\",[],[[\"@text\",\"@event\",\"@side\"],[[34,0],\"click\",\"right\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[15,0,[31,[\"teditable \",[34,3]]]],[14,\"contenteditable\",\"\"],[12],[13]],\"hasEval\":false,\"upvars\":[\"tooltip\",\"label\",\"if\",\"class\"]}",
    "meta": {
      "moduleName": "admin/templates/components/forms/text-field.hbs"
    }
  });
  _exports.default = _default;
});