define("admin/templates/admin/autotagging/category/jobtag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "SUvplBrE",
    "block": "{\"symbols\":[],\"statements\":[[8,\"autotag/keywords-list\",[],[[\"@tag\",\"@keywords\"],[[34,0,[\"tag\"]],[34,0,[\"keywords\",\"keywords\"]]]],null]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "admin/templates/admin/autotagging/category/jobtag.hbs"
    }
  });
  _exports.default = _default;
});