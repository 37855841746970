define("admin/compiled/alfrello-card/AlfrelloCard", ["exports", "classnames", "react", "react-tooltip", "admin/compiled/ui/SvgIcon", "admin/compiled/utils", "admin/compiled/utils/hrbContext", "admin/compiled/utils/languageContext", "admin/compiled/utils/useOutsideClick", "admin/compiled/alfrello-card/AlfrelloCardAttachment", "admin/compiled/alfrello-card/AlfrelloCardDotMenu", "admin/compiled/alfrello-card/signed"], function (_exports, _classnames, _react, _reactTooltip, _SvgIcon, _utils, _hrbContext, _languageContext, _useOutsideClick, _AlfrelloCardAttachment, _AlfrelloCardDotMenu, _signed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AlfrelloCard = void 0;
  var AlfrelloCard = function AlfrelloCard(_a) {
    var image = _a.image,
      name = _a.name,
      experience = _a.experience,
      submitted = _a.submitted,
      isDragging = _a.isDragging,
      isFadedOut = _a.isFadedOut,
      videoInterview = _a.videoInterview,
      interview = _a.interview,
      status = _a.status,
      isHired = _a.isHired,
      compasses = _a.compasses,
      unreadCount = _a.unreadCount,
      color = _a.color,
      menuItems = _a.menuItems,
      isActive = _a.isActive,
      isCopied = _a.isCopied,
      id = _a.id,
      initials = _a.initials,
      orgJobTitle = _a.orgJobTitle,
      contracts = _a.contracts;
    var intl = (0, _react.useContext)(_languageContext.default);
    var jobIsHRB = (0, _react.useContext)(_hrbContext.default);
    var style = unreadCount || isActive ? {
      borderColor: color
    } : {};
    if (isActive) {
      style.borderWidth = '2px';
    }
    var _b = (0, _react.useState)(false),
      showDotMenu = _b[0],
      setShowDotMenu = _b[1];
    var ref = (0, _react.useRef)(null);
    (0, _useOutsideClick.default)(ref, function () {
      setShowDotMenu(false);
    });
    // const hideApplication = utils;
    return _react.default.createElement("div", {
      id: "card-".concat(id),
      style: style,
      //@ts-ignore
      className: "alfrello-card ".concat(isDragging ? 'is-dragging' : '', " ").concat(isFadedOut ? 'is-faded-out' : '', " ").concat(unreadCount ? 'unread' : '')
    }, _react.default.createElement("div", {
      className: "alfrello-card--top ".concat(showDotMenu ? 'hover-state' : '')
    }, !!unreadCount && _react.default.createElement("div", {
      className: "alfrello-card--message-icon"
    }, _react.default.createElement(_SvgIcon.SvgMessageIcon, {
      color: color
    })), _react.default.createElement("span", {
      ref: ref
    }, _react.default.createElement(_AlfrelloCardDotMenu.AlfrelloCardDotMenu, {
      items: menuItems,
      visible: showDotMenu,
      toggleVisible: function toggleVisible() {
        return setShowDotMenu(!showDotMenu);
      }
    })), _react.default.createElement("div", {
      "data-initials": image ? '' : initials,
      className: 'alfrello-card--profile-thumb',
      style: {
        backgroundImage: image ? "url('".concat(image, "?dpr=1&h=100')") : ''
      }
    }), _react.default.createElement("div", {
      className: (0, _classnames.default)('alfrello-card--content', {
        'tw-flex-1': jobIsHRB
      })
    }, _react.default.createElement("div", {
      className: "alfrello-card--name ".concat(isCopied && !jobIsHRB ? '--copied' : '')
    }, name), isHired && !jobIsHRB && _react.default.createElement("div", {
      className: 'alfrello-card--hire'
    }, _react.default.createElement("p", {
      className: "green"
    }, _react.default.createElement("span", {
      className: "green-checkmark"
    }), intl.t('profile.hire.title'))), _react.default.createElement("div", {
      className: 'alfrello-card--experience'
    }, jobIsHRB ? orgJobTitle : experience), !jobIsHRB && _react.default.createElement("div", {
      className: 'alfrello-card--meta'
    }, _react.default.createElement("span", {
      className: 'gray-check'
    }), " ", (0, _utils.fromNow)(submitted, intl))), jobIsHRB && contracts && contracts.length && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactTooltip.default, {
      html: true
    }), _react.default.createElement("div", {
      "data-tip": contracts.map(function (contract) {
        return contract.fileName;
      }).join('<br>')
    }, _react.default.createElement(_signed.SignedIcon, null)))), _react.default.createElement(_AlfrelloCardAttachment.AlfrelloCardAttachment, {
      compasses: compasses,
      videoInterview: videoInterview.get('id') ? videoInterview : null,
      interview: interview.get('id') ? interview : null,
      status: status
    }));
  };
  _exports.AlfrelloCard = AlfrelloCard;
});