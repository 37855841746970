define("admin/templates/components/forms/select-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Kx/Ke6fG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"label\"],[15,0,[30,[36,2],[[35,1],\"disabled\"],null]],[12],[1,[34,3]],[13],[2,\"\\n\"],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"tooltipstered enabled\"],[12],[2,\"\\n    \"],[8,\"ember-tooltip\",[],[[\"@text\",\"@event\",\"@side\"],[[34,0],\"click\",\"right\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,null]],\"hasEval\":false,\"upvars\":[\"tooltip\",\"disableLabel\",\"if\",\"label\"]}",
    "meta": {
      "moduleName": "admin/templates/components/forms/select-field.hbs"
    }
  });
  _exports.default = _default;
});