define("admin/compiled/ga-select-plan/GaSelectPlan", ["exports", "react", "admin/compiled/ga-select-plan/GaSelectPlanItem"], function (_exports, _react, _GaSelectPlanItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GaSelectPlan = void 0;
  var GaSelectPlan = function GaSelectPlan(_a) {
    var plans = _a.plans,
      selectedPlan = _a.selectedPlan,
      setSelectedPlan = _a.setSelectedPlan;
    return _react.default.createElement("div", {
      className: 'ga-select-plan'
    }, plans.map(function (plan) {
      return _react.default.createElement(_GaSelectPlanItem.GaSelectPlanItem, {
        key: plan.title,
        title: plan.title,
        subtitle: plan.subtitle,
        price: plan.price,
        discount: plan.discount,
        selected: (selectedPlan === null || selectedPlan === void 0 ? void 0 : selectedPlan.title) === plan.title,
        onClick: function onClick() {
          return setSelectedPlan(plan);
        },
        type: plan.type
      });
    }));
  };
  _exports.GaSelectPlan = GaSelectPlan;
});