define("admin/services/api-manager", ["exports", "ember-local-storage", "admin/config/environment"], function (_exports, _emberLocalStorage, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var resturl = _environment.default.REGION.resturl,
    APPSPACE = _environment.default.APPSPACE;
  var _default = Ember.Service.extend({
    resturl: resturl,
    namespace: APPSPACE,
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    loginManager: Ember.inject.service(),
    notifications: Ember.inject.service('notifications'),
    storage: (0, _emberLocalStorage.storageFor)('current-user'),
    headers: Ember.computed('session.data.authenticated.access_token', 'intl.locale', function () {
      return {
        'Accept-Language': this.intl.locale,
        'Content-Type': 'application/json'
      };
    }),
    _checkStatus: function _checkStatus(response) {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      return response.json().then(function (json) {
        throw json.message;
      });
    },
    _parseJSON: function _parseJSON(response) {
      return response.json().catch(function () {
        return Ember.RSVP.resolve();
      });
    },
    _plainFetch: function _plainFetch(_ref) {
      var url = _ref.url,
        body = _ref.body,
        _ref$method = _ref.method,
        method = _ref$method === void 0 ? 'GET' : _ref$method;
      if (body) {
        body = JSON.stringify(body);
      }
      return fetch("".concat(this.resturl, "/").concat(this.namespace).concat(url), {
        method: method,
        headers: this.headers,
        body: body,
        credentials: 'include'
      });
    },
    _fetch: function _fetch(_ref2) {
      var _this = this;
      var url = _ref2.url,
        body = _ref2.body,
        _ref2$method = _ref2.method,
        method = _ref2$method === void 0 ? 'GET' : _ref2$method,
        _ref2$errorMessage = _ref2.errorMessage,
        errorMessage = _ref2$errorMessage === void 0 ? true : _ref2$errorMessage;
      if (body) {
        body = JSON.stringify(body);
      }
      return fetch("".concat(this.resturl, "/").concat(this.namespace).concat(url), {
        method: method,
        headers: this.headers,
        body: body,
        credentials: 'include'
      }).then(this._checkStatus).then(this._parseJSON).catch(function (error) {
        if (error.status === 401) {
          return _this.loginManager.logout();
        }
        if (errorMessage && !_this.notifications.content.length) {
          _this.notifications.clearAll().error(error || _this.intl.t('string.generic_error'));
        }
        throw error;
      });
    },
    get: function get(url, errorMessage) {
      return this._fetch({
        url: url,
        errorMessage: errorMessage
      });
    },
    post: function post(url, body, errorMessage) {
      return this._fetch({
        url: url,
        body: body,
        method: 'POST',
        errorMessage: errorMessage
      });
    },
    patch: function patch(url, body, errorMessage) {
      return this._fetch({
        url: url,
        body: body,
        method: 'PATCH',
        errorMessage: errorMessage
      });
    },
    put: function put(url, body, errorMessage) {
      return this._fetch({
        url: url,
        body: body,
        method: 'PUT',
        errorMessage: errorMessage
      });
    },
    delete: function _delete(url, errorMessage) {
      return this._fetch({
        url: url,
        method: 'DELETE',
        errorMessage: errorMessage
      });
    }
  });
  _exports.default = _default;
});