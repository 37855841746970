define("admin/components/crad/crad-jobtypes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['crad-jobtypes'],
    actions: {
      setJobtype: function setJobtype(jobtype) {
        if (this.selected.includes(jobtype)) {
          this.selected.removeObject(jobtype);
        } else {
          this.selected.pushObject(jobtype);
        }
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  });
  _exports.default = _default;
});