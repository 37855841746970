define("admin/compiled/logo/logo", ["exports", "classnames", "react"], function (_exports, _classnames, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Logo = Logo;
  _exports.default = void 0;
  function Logo(_a) {
    var _b = _a.variant,
      variant = _b === void 0 ? 'orange' : _b,
      text = _a.text,
      region = _a.region,
      size = _a.size,
      _onClick = _a.onClick;
    var sizes = {
      small: {
        width: 115,
        height: 32
      },
      medium: {
        width: 217,
        height: 60
      },
      large: {
        width: 282,
        height: 78
      }
    };
    var logo = text ? "/region/".concat(region, "/logo/").concat(text, "/").concat(size, ".svg") : "/region/".concat(region, "/logo/").concat(size, ".svg");
    return _react.default.createElement("img", {
      src: logo,
      alt: '',
      width: sizes[size].width,
      height: sizes[size].height,
      className: (0, _classnames.default)('tw-inline-block', {
        'tw-cursor-pointer': !!_onClick
      }),
      onClick: function onClick() {
        if (_onClick) {
          _onClick();
        }
      }
    });
  }
  var _default = Logo;
  _exports.default = _default;
});