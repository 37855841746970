define("admin/compiled/hiring-board/HiringBoard", ["exports", "@alfred-is/alfred-design-system", "classnames", "react", "admin/compiled/hooks/useHrBoard", "admin/compiled/utils/emberContext"], function (_exports, _alfredDesignSystem, _classnames, _react, _useHrBoard, _emberContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Profile = _exports.Num = _exports.Item = _exports.HiringBoard = void 0;
  var HiringBoard = function HiringBoard() {
    var _a = (0, _react.useContext)(_emberContext.default),
      intl = _a.intl,
      transitionTo = _a.transitionTo;
    var _b = (0, _react.useState)(false),
      isOpen = _b[0],
      setIsOpen = _b[1];
    var _c = (0, _useHrBoard.useHrBoard)(),
      isLoading = _c.isLoading,
      data = _c.data;
    if (isLoading || !data) {
      return _react.default.createElement(_react.default.Fragment, null);
    }
    var applicationsTotal = data.applicationsTotal,
      id = data.id,
      users = data.users,
      columns = data.columns;
    return _react.default.createElement("div", {
      className: "tw-mt-2 tw-p-5 tw-bg-white tw-border tw-border-gray-200 tw-rounded-2xl"
    }, _react.default.createElement("div", {
      className: "tw-flex tw-justify-between"
    }, _react.default.createElement("div", {
      className: "tw-flex tw-gap-x-10 tw-gap-y-5 tw-items-center tw-flex-wrap"
    }, _react.default.createElement("div", {
      className: "tw-text-md tw-font-600"
    }, intl.t('hr.board.title')), _react.default.createElement("div", {
      className: "tw-flex tw-items-center tw-gap-x-2 tw-text-blue-500 tw-font-500"
    }, _react.default.createElement(_alfredDesignSystem.PlainIcon, {
      icon: _alfredDesignSystem.AlfredIconT.Profile,
      color: _alfredDesignSystem.ColorT.blue500,
      size: 21
    }), applicationsTotal, " ", intl.t('hr.board.applicants')), _react.default.createElement("div", {
      className: "tw-flex tw-items-center tw-flex-wrap tw-gap-x-2 tw-text-gray-500"
    }, intl.t('hr.board.access'), ":", _react.default.createElement("div", {
      className: "tw-flex tw-items-center tw-gap-x-1 tw-flex-wrap"
    }, users && users.map(function (user) {
      return _react.default.createElement(Profile, {
        key: user.id,
        user: user
      });
    })))), _react.default.createElement("div", {
      className: "tw-flex tw-gap-x-5 tw-items-center tw-flex-shrink-0"
    }, _react.default.createElement("button", {
      className: (0, _classnames.default)('tw-bg-blue-500 tw-font-600 tw-text-white tw-text-sm', 'tw-px-3 tw-py-2 tw-rounded-3xl hover:tw-bg-blue-600'),
      onClick: function onClick() {
        return transitionTo('client.applications.index', id);
      }
    }, intl.t('hr.board.open')), _react.default.createElement("button", {
      onClick: function onClick() {
        return setIsOpen(!isOpen);
      }
    }, _react.default.createElement(_alfredDesignSystem.PlainIcon, {
      icon: isOpen ? _alfredDesignSystem.AlfredIconT.ArrowUp : _alfredDesignSystem.AlfredIconT.DropDown,
      color: _alfredDesignSystem.ColorT.gray400,
      size: 18
    })))), isOpen && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("div", {
      className: "tw-bg-gray-200 tw-h-px tw-my-5"
    }), _react.default.createElement("div", {
      className: "tw-flex tw-flex-wrap tw-gap-x-10 tw-gap-y-5"
    }, columns.map(function (column) {
      return _react.default.createElement(Item, {
        key: column.columnName
      }, _react.default.createElement(Num, {
        color: column.color
      }, column.applicationsCount), column.columnName);
    }))));
  };
  _exports.HiringBoard = HiringBoard;
  var Item = function Item(_a) {
    var children = _a.children;
    return _react.default.createElement("div", {
      className: "tw-flex tw-items-center tw-gap-x-2.5"
    }, children);
  };
  _exports.Item = Item;
  var Num = function Num(_a) {
    var color = _a.color,
      children = _a.children;
    return _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-font-600 tw-text-sm tw-text-center', 'tw-min-w-[24px] tw-rounded-sm tw-px-1 tw-py-1'),
      style: {
        backgroundColor: "".concat(color, "26"),
        color: color
      }
    }, children);
  };
  _exports.Num = Num;
  var Profile = function Profile(_a) {
    var user = _a.user;
    return _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-flex tw-items-center tw-justify-center', 'tw-h-[32px] tw-w-[32px] tw-rounded-full tw-bg-gray-300', 'tw-text-sm tw-font-600 tw-bg-cover tw-text-white'),
      title: user.name,
      style: {
        backgroundImage: "url(\"".concat(user.image, "\")")
      }
    }, user.image ? '' : user.initials);
  };
  _exports.Profile = Profile;
});