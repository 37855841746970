define("admin/compiled/stats-container/StatsContainer", ["exports", "react", "admin/compiled/stats-item/StatsItem"], function (_exports, _react, _StatsItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.StatsContainer = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var StatsContainer = function StatsContainer(_a) {
    var stats = _a.stats;
    return _react.default.createElement("div", {
      className: 'stats-container'
    }, stats.map(function (stat) {
      return _react.default.createElement(_StatsItem.StatsItem, __assign({
        key: stat.title
      }, stat));
    }));
  };
  _exports.StatsContainer = StatsContainer;
});