define("admin/compiled/ui/filter-separator/FilterSeparator", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FilterSeparator = void 0;
  var FilterSeparator = function FilterSeparator(_a) {
    var space = _a.space;
    return _react.default.createElement("span", {
      className: 'ui-filter-separator',
      style: {
        margin: "0 ".concat(space || 15, "px")
      }
    });
  };
  _exports.FilterSeparator = FilterSeparator;
});