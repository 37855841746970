define("admin/compiled/job-card/job-card-container", ["exports", "classnames", "react", "admin/compiled/job-card/job-card"], function (_exports, _classnames, _react, _jobCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.JobCardContainer = void 0;
  var JobCardContainer = _react.default.forwardRef(function (_a, ref) {
    var children = _a.children;
    var _b = (0, _jobCard.useJobCardContext)(),
      isHovered = _b.isHovered,
      infoActive = _b.infoActive,
      variant = _b.variant;
    return _react.default.createElement("div", {
      ref: ref,
      className: (0, _classnames.default)('tw-h-[370px]', 'tw-flex', 'tw-flex-col', 'tw-rounded-sm', 'tw-transition', 'tw-transition-shadow', 'tw-transition-colors', 'tw-border-2', 'tw-bg-white', {
        'tw-border-orange-200': !isHovered || variant !== _jobCard.JobCardVariant.GRAY,
        'tw-border-orange-400': isHovered && variant === _jobCard.JobCardVariant.BLUE,
        'tw-bg-white': isHovered && variant === _jobCard.JobCardVariant.GRAY || infoActive
      })
    }, children);
  });
  _exports.JobCardContainer = JobCardContainer;
});