define("admin/templates/client/settings/hr/system", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "rwjOz3y+",
    "block": "{\"symbols\":[],\"statements\":[[8,\"settings/hr-edit\",[],[[\"@auth\",\"@system\",\"@brands\",\"@brand\",\"@setBrand\"],[[32,0,[\"model\",\"auth\"]],[32,0,[\"model\",\"system\"]],[32,0,[\"model\",\"brands\"]],[32,0,[\"brand\"]],[32,0,[\"setBrand\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/templates/client/settings/hr/system.hbs"
    }
  });
  _exports.default = _default;
});