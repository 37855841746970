define("admin/routes/client/settings/video/edit", ["exports", "admin/routes/client/_form/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    controllerName: 'client/settings/video/form',
    renderTemplate: function renderTemplate() {
      this._super('settings/video');
    },
    model: function model(_ref) {
      var videointerview_id = _ref.videointerview_id;
      return this.store.findRecord('videointerviews-template', videointerview_id);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.model.rollbackAttributes();
      }
    }
  });
  _exports.default = _default;
});