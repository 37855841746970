define("admin/templates/components/skills/new-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KMR2ALh3",
    "block": "{\"symbols\":[],\"statements\":[[8,\"alfred-modal\",[],[[\"@alfred\",\"@toggle\"],[true,[30,[36,0],[\"newCategory\",[32,0]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,1,\"new-category\"],[14,0,\"body\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1,[\"id\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[10,\"h1\"],[12],[2,\"Nýr flokkur\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[10,\"h1\"],[12],[2,\"Breyta flokk\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[8,\"layout/space\",[],[[\"@horizontal\",\"@bottom\"],[\"40\",\"30\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"ui/labeled-input\",[],[[\"@inputId\",\"@label\",\"@placeholder\",\"@onEnter\",\"@value\"],[\"new-category-input\",\"Nafn\",\"Skrifaðu hér...\",[30,[36,3],[[32,0],\"save\"],null],[34,1,[\"name\"]]]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n            \"],[11,\"button\"],[24,0,\"btn\"],[4,[38,3],[[32,0],[30,[36,0],[\"newCategory\",[32,0]],null]],null],[12],[2,\"Loka\"],[13],[2,\"\\n            \"],[11,\"button\"],[16,0,[31,[\"btn blue \",[30,[36,7],[[30,[36,6],[[30,[36,5],[[35,1,[\"name\"]]],null],[35,4]],null],\"disabled\"],null]]]],[4,[38,3],[[32,0],\"save\"],null],[12],[2,\"\\n              \"],[6,[37,7],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"Hinkraðu...\"]],\"parameters\":[]},{\"statements\":[[2,\"Vista\"]],\"parameters\":[]}]]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"toggle-action\",\"category\",\"unless\",\"action\",\"loading\",\"not\",\"or\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/skills/new-category.hbs"
    }
  });
  _exports.default = _default;
});