define("admin/templates/components/ui/search-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "dQ1YofYz",
    "block": "{\"symbols\":[],\"statements\":[[8,\"input\",[[16,\"placeholder\",[34,1]]],[[\"@value\",\"@autocomplete\"],[[34,0],\"off\"]],null]],\"hasEval\":false,\"upvars\":[\"value\",\"placeholder\"]}",
    "meta": {
      "moduleName": "admin/templates/components/ui/search-input.hbs"
    }
  });
  _exports.default = _default;
});