define("admin/routes/admin/messages/new", ["exports", "admin/routes/admin/_form/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    controllerName: 'admin.messages.form',
    renderTemplate: function renderTemplate() {
      this._super('messages');
    },
    model: function model() {
      return Ember.RSVP.hash({
        sysmessage: this.store.createRecord('system-message'),
        messageusers: this.store.findAll('system-user')
      });
    },
    actions: {
      save: function save() {
        var _this = this;
        var application = this.controllerFor('application');
        application.set('loading', true);
        this.controller.get('model.sysmessage').save().then(function () {
          _this.transitionTo('admin.messages.index');
        }).finally(function () {
          application.set('loading', false);
        });
      }
    }
  });
  _exports.default = _default;
});