define("admin/templates/components/info-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "PNw+Slex",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"span\"],[14,0,\"info-icon\"],[12],[13],[2,\"\\n\"],[10,\"ul\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"info-bg\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"triangle\"],[12],[13],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/templates/components/info-bubble.hbs"
    }
  });
  _exports.default = _default;
});