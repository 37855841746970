define("admin/compiled/modal/AlfredModal", ["exports", "react", "react-redux", "admin/compiled/redux/actions/modal", "admin/compiled/modal/GeneralModal"], function (_exports, _react, _reactRedux, _modal, _GeneralModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AlfredModal = void 0;
  var AlfredModal = function AlfredModal(_a) {
    var children = _a.children,
      title = _a.title,
      wider = _a.wider,
      preventOutsideClick = _a.preventOutsideClick,
      overflowInitial = _a.overflowInitial;
    var dispatch = (0, _reactRedux.useDispatch)();
    return _react.default.createElement(_GeneralModal.default, {
      wider: wider,
      preventOutsideClick: preventOutsideClick,
      overflowInitial: overflowInitial
    }, _react.default.createElement("div", {
      className: 'react-modals-alfred-modal'
    }, _react.default.createElement("div", {
      className: "react-modals-alfred-modal--alfred-logo"
    }, _react.default.createElement("img", {
      src: '/img/alfred-icon.svg',
      style: {
        height: '48px'
      },
      alt: ''
    })), _react.default.createElement("div", {
      className: "react-modals-alfred-modal--header"
    }, _react.default.createElement("span", {
      className: "react-modals-alfred-modal--close-btn",
      onClick: function onClick() {
        return dispatch((0, _modal.hideModal)());
      }
    })), _react.default.createElement("div", {
      className: 'react-modals-alfred-modal--content'
    }, title && _react.default.createElement("div", {
      className: 'react-modals-alfred-modal--title'
    }, title), children)));
  };
  _exports.AlfredModal = AlfredModal;
});