define("admin/utils/intl/missing-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = missingMessage;
  function missingMessage(key, locales) {
    return "".concat(key, ":").concat(locales);
  }
});