define("admin/templates/components/write-capacent-report-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Hb9S3y0R",
    "block": "{\"symbols\":[],\"statements\":[[8,\"background-modal\",[],[[\"@bg\",\"@title\",\"@overlayClass\",\"@close\"],[\"/img/capacent.jpg\",\"<span class='capacent'></span> Samantekt\",\"capacent-report-modal write-capacent-report-modal\",[30,[36,1],[[32,0],[30,[36,0],[\"writeCapacentReport\",[32,0]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"body\"],[12],[2,\"\\n    \"],[8,\"textarea\",[[24,0,\"comment\"],[24,\"placeholder\",\"Skrifaðu hér\"]],[[\"@value\"],[[34,2,[\"report\"]]]],null],[2,\"    \"],[10,\"div\"],[14,0,\"button-container\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn\"],[4,[38,1],[[32,0],\"openJob\"],null],[12],[2,\"Opna starfsauglýsingu\"],[13],[2,\"\\n      \"],[11,\"button\"],[16,0,[31,[\"btn green \",[30,[36,5],[[35,2,[\"report\"]],\"disabled\"],null],\" \",[30,[36,4],[[35,3],\"disabled\"],null]]]],[4,[38,1],[[32,0],\"saveReport\"],null],[12],[2,\"\\n        \"],[6,[37,5],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"Senda samantekt\\n        \"]],\"parameters\":[]},{\"statements\":[[2,\"Hinkraðu...\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"toggle-action\",\"action\",\"applicationClassification\",\"loading\",\"if\",\"unless\"]}",
    "meta": {
      "moduleName": "admin/templates/components/write-capacent-report-modal.hbs"
    }
  });
  _exports.default = _default;
});