define("admin/routes/client/settings/msg-templates/new", ["exports", "admin/routes/client/_form/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    controllerName: 'client/settings/msg-templates/form',
    renderTemplate: function renderTemplate() {
      this._super('settings/msg-templates');
    },
    model: function model() {
      return {
        messageTemplate: {}
      };
    }
  });
  _exports.default = _default;
});