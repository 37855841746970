define("admin/templates/components/background-modal-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0x6Mts3R",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"icon\"],[15,5,[31,[\"background-image: url('\",[34,0],\"');\"]]],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"text\"],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"icon\"]}",
    "meta": {
      "moduleName": "admin/templates/components/background-modal-item.hbs"
    }
  });
  _exports.default = _default;
});