define("admin/components/background-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    className: Ember.computed('overlayClass', function () {
      if (this.overlayClass) {
        return 'modal-popup background-modal ' + this.overlayClass;
      }
      return 'modal-popup background-modal';
    }),
    actions: {
      close: function close() {
        this.close();
      }
    }
  });
  _exports.default = _default;
});