define("admin/components/applications/video-rating-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    apiClient: Ember.inject.service(),
    classNames: ['video-rating-profile'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('ratings', [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    },
    actions: {
      virate: function virate(rating) {
        var _this = this;
        this.set('vi.rating', rating);
        this.apiClient.post("/jobs/".concat(this.job.id, "/applications/").concat(this.application.id, "/videointerviews/").concat(this.get('vi.id'), "/rate"), {
          rating: rating
        }).then(function () {
          _this.application.reload();
        });
      }
    }
  });
  _exports.default = _default;
});