define("admin/compiled/form/FormCheckbox", ["exports", "@alfred-is/alfred-design-system", "react", "react-hook-form"], function (_exports, _alfredDesignSystem, _react, _reactHookForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FormCheckbox = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };
  var FormCheckbox = function FormCheckbox(_a) {
    var name = _a.name,
      props = __rest(_a, ["name"]);
    var _b = (0, _reactHookForm.useFormContext)(),
      watch = _b.watch,
      register = _b.register;
    return _react.default.createElement(_alfredDesignSystem.CheckboxInput, __assign({}, props, register(name), {
      checked: watch(name)
    }));
  };
  _exports.FormCheckbox = FormCheckbox;
});