define("admin/components/jobs/job-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global toMarkdown */
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    utils: Ember.inject.service(),
    store: Ember.inject.service(),
    job: Ember.computed.alias('model.job'),
    isValid: Ember.computed('job.adtype.id', 'job.brand.id', 'job.jobtype.id', 'job.tags.length', 'job.title.length', 'job.{applyemail,applyweb,bodyhtml}', function () {
      var hasMandatoryFields = this.get('job.title') && this.get('job.title.length') <= 45 && this.get('job.bodyhtml') && this.get('job.brand.id') && this.get('job.jobtype.id') && this.get('job.adtype.id') && this.get('job.tags.length');
      var isProfileJob = this.get('job.adtype.id') === '2';
      var hasEmailOrWebsite = !isProfileJob && (this.get('job.applyemail') || this.get('job.applyweb'));
      return hasMandatoryFields && (isProfileJob || hasEmailOrWebsite);
    }),
    enValid: Ember.computed.and('job.entitle', 'job.enbodyhtml'),
    draftValid: Ember.computed.and('job.title', 'job.brand.id'),
    saveTooltip: Ember.computed('job.{title,brand.id,address.placeId,bodyhtml,adtype.id,applyemail,applyweb,tags.length}', function () {
      return "<table class=\"adChecks\">\n        <tr><td>".concat(this.intl.t('string.job_title'), ":</td><td class=\"").concat(this.get('job.title') ? 'ok' : 'no', "\"></td></tr>\n        <tr><td>").concat(this.intl.t('string.brand'), ":</td><td class=\"").concat(this.get('job.brand.id') ? 'ok' : 'no', "\"></td></tr>\n        <tr><td>").concat(this.intl.t('string.job_address'), ":</td><td class=\"").concat(this.get('job.address.placeId') ? 'ok' : 'no', "\"></td></tr>\n        <tr><td>").concat(this.intl.t('string.job_text'), ":</td><td class=\"").concat(this.get('job.bodyhtml') ? 'ok' : 'no', "\"></td></tr>\n        <tr><td>").concat(this.intl.t('string.job_tag'), ":</td><td class=\"").concat(this.get('job.tags.length') ? 'ok' : 'no', "\"></td></tr>\n        </table>");
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.get('model.brands.length') === 1) {
        this.set('job.brand', this.get('model.brands.firstObject'));
        if (!this.get('model.job.id') && !this.clone) {
          this.set('job.address', this.get('job.brand.address'));
        }
      }
    },
    save: function save(random, isDraft) {
      var _this = this;
      var job = this.job;
      if (isDraft) {
        this.set('loadingDraft', true);
      } else {
        this.set('loading', true);
      }
      if (job.get('adtype.id') === 2) {
        job.set('applyweb');
        job.set('applyemail');
      } else if (job.get('applyweb')) {
        job.set('applyweb', this.utils.checkWeb(job.get('applyweb')));
      }
      if (job.get('bodyhtml')) {
        job.set('body', toMarkdown(job.get('bodyhtml')).replace(/<\/?[^>]+(>|$)/g, ''));
      }
      if (job.get('enbodyhtml')) {
        job.set('enbody', toMarkdown(job.get('enbodyhtml')).replace(/<\/?[^>]+(>|$)/g, ''));
      }
      job.save().then(function () {
        if (random) {
          // For taggers
          _this.getRandom();
        } else if (_this.showUpsell) {
          _this.set('upsellModal', true);
        } else {
          _this.job.reload();
          _this.onClose();
        }
      }).finally(function () {
        _this.set('loading');
        _this.set('loadingDraft');
      });
    },
    actions: {
      saveAsDraft: function saveAsDraft() {
        this.set('showUpsell');
        var jobstatus = this.store.peekRecord('jobstatus', '1');
        this.job.set('jobstatus', jobstatus);
        this.save(false, true);
      },
      publish: function publish() {
        var jobstatus = this.store.peekRecord('jobstatus', '2');
        this.set('job.jobstatus', jobstatus);
        if (this.job.id) {
          this.set('showUpsell');
        }
        this.set('publishModal', this.showUpsell);
        this.save();
      },
      publishRandom: function publishRandom() {
        var jobstatus = this.store.peekRecord('jobstatus', '2');
        this.set('job.jobstatus', jobstatus);
        this.set('publishModal');
        this.save(true);
      },
      closeJob: function closeJob() {
        var _this2 = this;
        this.job.destroyRecord().then(function () {
          _this2.onClose();
          _this2.refresh();
        }).catch(function () {});
      },
      getCategories: function getCategories() {
        return this.store.peekAll('category');
      },
      togglePromo: function togglePromo() {
        this.toggleProperty('isShowingPromo');
      }
    }
  });
  _exports.default = _default;
});