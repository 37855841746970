define("admin/compiled/job-card/job-card-progress", ["exports", "@alfred-is/alfred-design-system", "classnames", "date-fns", "react", "react-tooltip", "admin/compiled/job-card/job-card"], function (_exports, _alfredDesignSystem, _classnames, _dateFns, _react, _reactTooltip, _jobCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.JobCardProgress = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var JobCardProgress = function JobCardProgress(_a) {
    var items = _a.items,
      status = _a.status;
    var isHovered = (0, _jobCard.useJobCardContext)().isHovered;
    var opacity = isHovered ? 1 : 0.6;
    var fromNow = (0, _alfredDesignSystem.useDateUtils)().fromNow;
    var t = (0, _alfredDesignSystem.useNxTranslation)().t;
    var orderedItems = (0, _react.useMemo)(function () {
      return items.map(function (item) {
        return __assign(__assign({}, item), {
          dateFrom: item.dateFrom,
          dateTo: item.dateTo
        });
      }).sort(function (a, b) {
        return a.dateFrom.valueOf() - b.dateFrom.valueOf();
      });
    }, [items]);
    var currentItem = orderedItems.find(function (i) {
      var today = new Date();
      return today >= i.dateFrom && today < i.dateTo;
    });
    return _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-px-5', {
        'tw-mt-3': isHovered && !!(orderedItems === null || orderedItems === void 0 ? void 0 : orderedItems.length),
        'tw-mt-4': !isHovered || !(orderedItems === null || orderedItems === void 0 ? void 0 : orderedItems.length)
      })
    }, (!isHovered || !(orderedItems === null || orderedItems === void 0 ? void 0 : orderedItems.length)) && _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-text-sm', 'tw-text-gray-500', 'tw-flex', 'tw-justify-center', 'tw-items-center', 'tw-gap-0.5')
    }, _react.default.createElement(_alfredDesignSystem.Circle, {
      hexColor: status.color,
      size: 10
    }), status.description, currentItem ? " (".concat(fromNow(currentItem.dateTo, true), " ").concat(t('job-card-progress.status.time-left'), ")") : ''), isHovered && (orderedItems === null || orderedItems === void 0 ? void 0 : orderedItems.length) > 0 && _react.default.createElement("div", {
      style: {
        opacity: opacity
      },
      className: (0, _classnames.default)('tw-flex', 'tw-space-x-2')
    }, orderedItems.map(function (item) {
      return _react.default.createElement(DateProgress, {
        tooltip: item.label,
        dateFrom: item.dateFrom,
        dateTo: item.dateTo,
        color: item.color
      });
    })));
  };
  _exports.JobCardProgress = JobCardProgress;
  function DateProgress(_a) {
    var dateFrom = _a.dateFrom,
      dateTo = _a.dateTo,
      color = _a.color,
      tooltip = _a.tooltip;
    var format = (0, _alfredDesignSystem.useDateUtils)().format;
    dateFrom.setSeconds(0);
    dateTo.setSeconds(0);
    var secondsCount = (0, _dateFns.differenceInSeconds)(dateTo, dateFrom);
    var progress = Math.min(Math.max((0, _dateFns.differenceInSeconds)(new Date(), dateFrom), 0) / secondsCount * 100, 100);
    var label = "".concat(format(dateFrom, 'd. MMM yy'), " - ").concat(format(dateTo, 'd. MMM yy'));
    var tooltipId = (0, _alfredDesignSystem.uuid)();
    return _react.default.createElement("div", {
      "data-tip": true,
      "data-for": tooltipId,
      className: "tw-flex-1"
    }, _react.default.createElement("div", {
      className: (0, _classnames.default)({
        'tw-opacity-40': dateFrom > new Date() || progress === 100
      })
    }, _react.default.createElement("div", {
      className: 'tw-text-xs tw-mb-2 tw-flex tw-items-center tw-justify-center'
    }, progress === 100 ? _react.default.createElement(_alfredDesignSystem.PlainIcon, {
      icon: _alfredDesignSystem.AlfredIconT.Success,
      color: _alfredDesignSystem.ColorT.green,
      size: 11,
      className: "tw-mr-1"
    }) : _react.default.createElement(_alfredDesignSystem.Circle, {
      color: color,
      size: 10,
      className: "tw-mr-2"
    }), label), _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-h-2', 'tw-bg-gray-300', 'tw-rounded-xl', 'tw-overflow-hidden')
    }, _react.default.createElement("div", {
      style: {
        backgroundColor: (0, _alfredDesignSystem.getHexColor)(color),
        width: dateFrom > new Date() ? 0 : "".concat(Math.max(10, progress), "%")
      },
      className: (0, _classnames.default)('tw-h-full', 'tw-relative')
    }))), tooltip && _react.default.createElement(_reactTooltip.default, {
      id: tooltipId,
      place: "bottom"
    }, tooltip));
  }
});