define("admin/helpers/prod", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.prod = prod;
  var environment = _environment.default.environment;
  function prod() {
    return environment === 'production';
  }
  var _default = Ember.Helper.helper(prod);
  _exports.default = _default;
});