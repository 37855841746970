define("admin/controllers/client/settings/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    apiClient: Ember.inject.service(),
    client: Ember.computed.alias('model.client'),
    isValid: Ember.computed('client.{address,name}', 'invalidSsn', function () {
      return this.get('client.name') && this.get('client.address') && !this.invalidSsn;
    }),
    actions: {
      searchAddress: function searchAddress(search) {
        return this.store.query('address', {
          search: search,
          limit: 20
        });
      }
    }
  });
  _exports.default = _default;
});