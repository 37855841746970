define("admin/routes/client/applications/useraccess/index", ["exports", "admin/mixins/model-loader"], function (_exports, _modelLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_modelLoader.default, {
    model: function model() {
      return this.store.findAll('job-user');
    },
    actions: {
      toggleAccess: function toggleAccess(jobUser) {
        if (jobUser.get('accessLevel') === 'NONE') {
          jobUser.set('accessLevel', 'EDIT');
        } else {
          jobUser.set('accessLevel', 'NONE');
        }
        jobUser.save();
      }
    }
  });
  _exports.default = _default;
});