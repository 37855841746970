define("admin/components/applications/video-invite-modal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    apiClient: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    today: (0, _moment.default)().toDate(),
    maxDate: (0, _moment.default)().add(2, 'weeks').toDate(),
    isValid: Ember.computed.and('selectedTemplate.id', 'deadline', 'selectedMsg.template'),
    sendTooltip: Ember.computed('deadline', 'selectedMsg.template', 'selectedTemplate.id', function () {
      return "<table class=\"adChecks\">\n        <tr><td>".concat(this.intl.t('components.applications.video_invite_modal.vi'), ":</td><td class=\"").concat(this.get('selectedTemplate.id') ? 'ok' : 'no', "\"></td></tr>\n        <tr><td>").concat(this.intl.t('components.applications.video_invite_modal.due_date'), ":</td><td class=\"").concat(this.deadline ? 'ok' : 'no', "\"></td></tr>\n        <tr><td>").concat(this.intl.t('components.applications.video_invite_modal.vi_invitation'), ":</td><td class=\"").concat(this.selectedMsg.template ? 'ok' : 'no', "\"></td></tr>\n        </table>");
    }),
    templates: Ember.computed.sort('_templates', function (a, b) {
      return a.title.localeCompare(b.title);
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      this.store.query('videointerviews-template', {}).then(function (templates) {
        _this.set('_templates', templates);
        _this.set('selectedTemplate', _this.get('templates.firstObject'));
      });
      this.apiClient.get("/clients/messagetemplates/type/INVITATION_VIDEO_INTERVIEW").then(function (response) {
        var templates = response ? response.messageTemplates : [];
        _this.set('msgs', templates);
        _this.set('selectedMsg', templates.length ? templates[0] : {});
      });
    },
    afterSend: function afterSend(res) {
      var _this2 = this;
      this.set('selectedTemplate');
      this.set('deadline');
      if (this.application) {
        this.application.reload();
      } else {
        res.applications.forEach(function (a) {
          _this2.store.pushPayload('jobapplication', {
            application: a
          });
        });
      }
      this.toggle();
    },
    actions: {
      close: function close() {
        this.toggle();
      },
      toggleNewTemplate: function toggleNewTemplate() {
        this.set('newTemplate', this.store.createRecord('videointerviews-template'));
        this.toggleProperty('newVideoQuestionsModal');
      },
      reload: function reload(newTemplate) {
        var _this3 = this;
        this.store.query('videointerviews-template', {}).then(function (templates) {
          _this3.set('_templates', templates);
          if (newTemplate) {
            _this3.set('selectedTemplate', newTemplate);
          }
        });
      },
      setVideoDeadline: function setVideoDeadline(date) {
        this.set('deadline', date);
      },
      sendVideoInvite: function sendVideoInvite() {
        var _this4 = this;
        this.set('loading', true);
        var data = {
          interviewId: this.get('selectedTemplate.id'),
          deadline: (0, _moment.default)(this.deadline).endOf('day').utc().format(),
          message: this.selectedMsg.template,
          applicationIds: this.applicationIds || [this.application.id]
        };
        this.apiClient.post("/jobs/".concat(this.job.id, "/applications/videointerviews/invite"), data).then(function (res) {
          return _this4.afterSend(res);
        }).finally(function () {
          return _this4.set('loading');
        });
      } // resize(el) {
      //     console.log(el);
      //     el.target.style.height = 'auto';
      //     el.target.style.height = el.target.scrollHeight + 'px';
      // }
    }
  });
  _exports.default = _default;
});