define("admin/components/image-cropper", ["exports", "ember-cli-image-cropper/components/image-cropper"], function (_exports, _imageCropper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _imageCropper.default.extend({
    //override default options of cropper
    aspectRatio: 500 / 365,
    minContainerWidth: 500,
    minContainerHeight: 365,
    cropperContainer: '.cropper-container > img',
    previewClass: '.img-preview',
    toggleDragModeOnDblclick: false,
    guides: false,
    movable: true,
    zoomable: true,
    resizable: false,
    responsive: false,
    background: false,
    dragMode: 'move',
    cropBoxMovable: false,
    cropBoxResizable: false,
    autoCropArea: 0.5,
    actions: {
      zoomIn: function zoomIn() {
        this.cropper.zoom(0.1);
      },
      zoomOut: function zoomOut() {
        this.cropper.zoom(-0.1);
      },
      selectAgain: function selectAgain() {
        this.set('temp');
        this.set('modal');
        if (this.action) {
          this.action();
        }
      },
      saveCropImage: function saveCropImage() {
        var croppedImage = this.cropper.getCroppedCanvas();

        // Resize image to crop size

        var newCanvas = document.createElement('canvas');
        var ctx = newCanvas.getContext("2d");
        newCanvas.width = this.minCropBoxWidth;
        newCanvas.height = this.minCropBoxHeight;
        ctx.drawImage(croppedImage, 0, 0, newCanvas.width, newCanvas.height);
        this.set('image', newCanvas.toDataURL());
        if (this.update) {
          this.update(newCanvas.toDataURL());
        }
        this.set('modal');
      },
      closeModal: function closeModal() {
        this.set('temp');
        this.set('modal');
      }
    }
  });
  _exports.default = _default;
});