define("admin/routes/client/settings/questions/edit", ["exports", "admin/routes/client/_form/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    controllerName: 'client/settings/questions/form',
    renderTemplate: function renderTemplate() {
      this._super('settings/questions');
    },
    model: function model(_ref) {
      var question_id = _ref.question_id;
      var template = this.store.peekRecord('question-template', question_id);
      console.log(template);
      return template;
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.model.rollbackAttributes();
      }
    }
  });
  _exports.default = _default;
});