define("admin/components/esign-react/e-sign", ["exports", "react", "admin/compiled/e-sign/ESign", "admin/react-component"], function (_exports, _react, _ESign, _reactComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  
  */
  {
    "id": "jmTgWnR4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/components/esign-react/e-sign.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _reactComponent.default.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    renderComponent: function renderComponent() {
      var application = {
        id: this.applicationId,
        name: this.name,
        image: this.image,
        initials: this.initials
      };
      this.reactRender(_react.default.createElement(_ESign.ESign, {
        application: application,
        jobId: this.jobId,
        hireAnswers: this.hireAnswers,
        systems: this.systems,
        onClose: this.onClose,
        intl: this.intl
      }));
    }
  }));
  _exports.default = _default;
});