define("admin/compiled/alfrello-column/AlfrelloColumn", ["exports", "react", "admin/compiled/utils/useOutsideClick", "admin/compiled/alfrello-column-switcher/AlfrelloColumnSwitcher"], function (_exports, _react, _useOutsideClick, _AlfrelloColumnSwitcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AlfrelloColumnMenu = _exports.AlfrelloColumn = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var AlfrelloColumnMenu = function AlfrelloColumnMenu(_a) {
    var items = _a.items;
    var _b = (0, _react.useState)(false),
      showMenu = _b[0],
      setShowMenu = _b[1];
    var ref = (0, _react.useRef)(null);
    var _c = (0, _react.useState)(null),
      submenuItems = _c[0],
      setSubmenuItems = _c[1];
    (0, _useOutsideClick.default)(ref, function () {
      setShowMenu(false);
    });
    var menuItems = submenuItems || items;
    return _react.default.createElement("div", {
      ref: ref,
      className: "alfrello-column-menu ".concat(showMenu ? 'active' : '')
    }, _react.default.createElement("div", {
      className: "alfrello-column-menu--dots",
      onClick: function onClick() {
        return setShowMenu(!showMenu);
      }
    }, _react.default.createElement("span", null), _react.default.createElement("span", null), _react.default.createElement("span", null)), showMenu && _react.default.createElement("div", {
      className: 'alfrello-column-menu--content'
    }, _react.default.createElement("div", {
      className: "alfrello-column-menu--item back-icon ".concat(!submenuItems ? 'hide-arrow' : ''),
      onClick: function onClick() {
        setSubmenuItems(null);
      }
    }), menuItems.map(function (item, index) {
      return _react.default.createElement("div", {
        key: index,
        className: "alfrello-column-menu--item ".concat(item.icon || 'no-icon', " ").concat(item.disabled ? 'disabled' : ''),
        onClick: item.items ? function () {
          setSubmenuItems(item.items);
        } : function () {
          //@ts-ignore
          item === null || item === void 0 ? void 0 : item.onClick();
          setSubmenuItems(null);
          setShowMenu(false);
        }
      }, item.text);
    })));
  };
  _exports.AlfrelloColumnMenu = AlfrelloColumnMenu;
  var AlfrelloColumn = function AlfrelloColumn(_a) {
    var column = _a.column,
      applications = _a.applications,
      children = _a.children,
      description = _a.description,
      provided = _a.provided,
      menuItems = _a.menuItems,
      disableMenu = _a.disableMenu,
      switchColumn = _a.switchColumn,
      columns = _a.columns,
      isSidebar = _a.isSidebar,
      isDragging = _a.isDragging;
    if (!provided) {
      provided = {};
    }
    var _b = (0, _react.useState)(false),
      showColumnList = _b[0],
      setShowColumnList = _b[1];
    var ref = (0, _react.useRef)(null);
    var dismiss = function dismiss() {
      setShowColumnList(false);
    };
    (0, _useOutsideClick.default)(ref, dismiss);
    return _react.default.createElement("div", {
      className: "alfrello-column ".concat(isSidebar ? 'sidebar' : '', " ").concat(isDragging ? 'dragging' : ''),
      ref: provided.innerRef
    }, _react.default.createElement("div", __assign({
      className: "alfrello-column--header"
    }, provided.dragHandleProps), _react.default.createElement("span", {
      ref: ref
    }, _react.default.createElement("span", {
      onClick: function onClick() {
        return setShowColumnList(!showColumnList);
      }
    }, column.title, ' ', !!applications.length && _react.default.createElement("span", {
      className: 'alfrello-column--count'
    }, "(", applications.length, ")"), !!(columns === null || columns === void 0 ? void 0 : columns.length) && switchColumn && _react.default.createElement(_AlfrelloColumnSwitcher.AlfrelloColumnSwitcher, {
      showColumnList: showColumnList,
      setShowColumnList: setShowColumnList,
      column: column,
      columns: columns,
      onSwitch: switchColumn
    }))), description && _react.default.createElement("div", {
      className: 'alfrello-column--description'
    }, description), !disableMenu && !!menuItems.length && _react.default.createElement(AlfrelloColumnMenu, {
      items: menuItems
    }), _react.default.createElement("div", {
      className: "alfrello-column--header-bar",
      style: {
        backgroundColor: column.color
      }
    })), children);
  };
  _exports.AlfrelloColumn = AlfrelloColumn;
});