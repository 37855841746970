define("admin/controllers/client/settings/api", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    _copyToClipboard: function _copyToClipboard(token) {
      var input = document.createElement('input');
      //input.setAttribute('type', 'hidden');
      input.setAttribute('value', token);
      input.setAttribute('class', 'hidden-input');
      document.querySelector('body').appendChild(input);
      input.focus();
      input.select();
      var isSuccessful = document.execCommand('copy');
      if (!isSuccessful) {
        console.log('Failed to copy text.');
      } else {
        this.notifications.success('Lykillinn hefur verið afritaður.', {
          clearDuration: 1000
        });
      }
    },
    actions: {
      newCredential: function newCredential() {
        var _this = this;
        var credential = this.store.createRecord('api-credential', {
          name: this.credentialName
        });
        credential.save().then(function () {
          _this.set('credentialName');
          _this.set('modalVisible');
        }).catch(function (err) {
          return _this.notifications.error(err.message);
        });
      },
      deleteCredential: function deleteCredential(credential) {
        credential.destroyRecord();
      },
      copyCredential: function copyCredential(credential) {
        this._copyToClipboard(credential.token);
      },
      toggleModal: function toggleModal() {
        this.toggleProperty('modalVisible');
      }
    }
  });
  _exports.default = _default;
});