define("admin/compiled/alfrello-profile-info/ProfileAnswers", ["exports", "react", "moment", "admin/compiled/utils/languageContext", "admin/compiled/alfrello-profile-items/AlfrelloProfileItems"], function (_exports, _react, _moment, _languageContext, _AlfrelloProfileItems) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ProfileAnswers = _exports.ProfileAnswer = void 0;
  var ProfileAnswer = function ProfileAnswer(_a) {
    var _b, _c;
    var answer = _a.answer,
      index = _a.index;
    var ans;
    if ((_b = answer.question.options) === null || _b === void 0 ? void 0 : _b.length) {
      ans = (_c = answer.question.options.find(function (o) {
        return o.id === answer.optionId;
      })) === null || _c === void 0 ? void 0 : _c.option;
    } else {
      ans = answer.date || answer.answer;
    }
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("div", null, _react.default.createElement("strong", null, index + 1, ". ", answer.question.content)), _react.default.createElement("div", null, ans));
  };
  _exports.ProfileAnswer = ProfileAnswer;
  var ProfileAnswers = function ProfileAnswers(_a) {
    var answers = _a.answers;
    var intl = (0, _react.useContext)(_languageContext.default);
    if (!answers || !answers.length) {
      return null;
    }
    answers.sort(function (a, b) {
      if (a.id > b.id) {
        return 1;
      }
      if (a.id < b.id) {
        return -1;
      }
      return 0;
    });
    return _react.default.createElement(_AlfrelloProfileItems.AlfrelloProfileItems, {
      label: intl.t('string.custom_questions'),
      items: answers.map(function (answer, index) {
        var _a;
        var a = {
          id: answer.id,
          answer: answer.get('answer'),
          date: answer.get('date') ? (0, _moment.default)(answer.get('date')).format('D. MMMM YYYY') : null,
          optionId: answer.get('optionId'),
          question: {
            content: answer.get('questionId.question'),
            options: ((_a = answer.get('questionId.options')) === null || _a === void 0 ? void 0 : _a.map(function (o) {
              return {
                id: o.get('id'),
                option: o.get('option')
              };
            })) || []
          }
        };
        return _react.default.createElement(ProfileAnswer, {
          key: answer.id,
          answer: a,
          index: index
        });
      })
    });
  };
  _exports.ProfileAnswers = ProfileAnswers;
});