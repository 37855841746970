define("admin/compiled/ui/GaButton", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GaButton = void 0;
  var GaButton = function GaButton(_a) {
    var _onClick = _a.onClick,
      testId = _a.testId,
      disabled = _a.disabled,
      children = _a.children;
    return _react.default.createElement("button", {
      "data-testid": testId,
      className: "ga-button ".concat(disabled ? 'disabled' : ''),
      onClick: function onClick() {
        if (!disabled) {
          _onClick();
        }
      }
    }, children);
  };
  _exports.GaButton = GaButton;
});