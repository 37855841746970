define("admin/templates/components/applications/ad-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZrqSWH8C",
    "block": "{\"symbols\":[\"@job\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"select \",[30,[36,5],[[35,3,[\"isActive\"]],\"green\"],null],\" \",[30,[36,5],[[35,3,[\"isProgress\"]],\"blue\"],null],\" \",[30,[36,5],[[30,[36,4],[[35,3,[\"isEnded\"]],[35,3,[\"isArchived\"]]],null],\"disabled\"],null]]]],[4,[38,1],[[32,0],[30,[36,0],[\"adStatusModal\",[32,0]],null]],null],[12],[2,\"\\n  \"],[1,[35,3,[\"jobstatus\",\"description\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,5],[[32,0,[\"adStatusModal\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"click-outside\",[],[[\"@onClickOutside\"],[[30,[36,1],[[32,0],[30,[36,0],[\"adStatusModal\",[32,0]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"applications/ad-status-modal\",[],[[\"@job\",\"@toggle\"],[[32,1],[30,[36,2],[\"adStatusModal\",[32,0]],null]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"toggle\",\"action\",\"toggle-action\",\"job\",\"or\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/applications/ad-status.hbs"
    }
  });
  _exports.default = _default;
});