define("admin/models/jobapplication", ["exports", "@ember-data/model", "ember-api-actions"], function (_exports, _model, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    advertismentId: (0, _model.attr)('string'),
    archived: (0, _model.attr)('boolean'),
    attachmentCount: (0, _model.attr)('number'),
    attachmentIds: (0, _model.attr)(),
    cancelMessage: (0, _model.attr)('string'),
    category: (0, _model.attr)('string'),
    columnId: (0, _model.attr)('string'),
    endDate: (0, _model.attr)('moment'),
    expired: (0, _model.attr)('boolean'),
    feedActive: (0, _model.attr)('boolean'),
    interviewDate: (0, _model.attr)('moment'),
    interviewStatus: (0, _model.attr)('string'),
    lastChange: (0, _model.attr)('moment'),
    lastEventType: (0, _model.attr)('string'),
    new: (0, _model.attr)('boolean'),
    noteCount: (0, _model.attr)('number'),
    ordinal: (0, _model.attr)('number'),
    origin: (0, _model.attr)('string'),
    profileId: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    submitted: (0, _model.attr)('moment'),
    unreadCount: (0, _model.attr)('number'),
    updated: (0, _model.attr)('moment'),
    hired: (0, _model.attr)('boolean'),
    hirePackageSent: (0, _model.attr)('boolean'),
    hireAnswers: (0, _model.attr)(),
    hiredDate: (0, _model.attr)('moment'),
    applicationCompasses: (0, _model.attr)(),
    copied: (0, _model.attr)('boolean'),
    jobId: (0, _model.attr)('string'),
    jobTitle: (0, _model.attr)('string'),
    orgApplicationId: (0, _model.attr)('string'),
    orgJobId: (0, _model.attr)('string'),
    orgJobTitle: (0, _model.attr)('string'),
    orgBrandName: (0, _model.attr)('string'),
    profile: (0, _model.belongsTo)('profile'),
    interview: (0, _model.belongsTo)('interview'),
    videoInterview: (0, _model.belongsTo)('videointerview'),
    answers: (0, _model.hasMany)('answer'),
    attachments: (0, _model.hasMany)('attachment'),
    notes: (0, _model.hasMany)('note'),
    isnew: (0, _model.attr)('boolean'),
    hasinterview: (0, _model.attr)('boolean'),
    hasunread: (0, _model.attr)('boolean'),
    cancelmessage: (0, _model.attr)('string'),
    dismissed: (0, _model.attr)('boolean'),
    viaveragerating: (0, _model.attr)('number'),
    vistatus: (0, _model.attr)('string'),
    vistatussort: (0, _model.attr)('number'),
    viyourrating: (0, _model.attr)('number'),
    vinoteid: (0, _model.attr)('number'),
    viquestions: (0, _model.hasMany)('videointerviews-question'),
    feeds: (0, _model.hasMany)('feed'),
    brand: (0, _model.belongsTo)('brand'),
    contracts: (0, _model.attr)(),
    resetvideointerview: (0, _emberApiActions.memberAction)({
      path: 'resetvideointerview',
      type: 'patch'
    }),
    pdf: (0, _emberApiActions.memberAction)({
      path: 'pdf',
      type: 'get',
      ajaxOptions: {
        arraybuffer: true
      }
    })
  });
  _exports.default = _default;
});