define("admin/compiled/ui/analytics-card/AnalyticsCard", ["exports", "react", "admin/compiled/utils"], function (_exports, _react, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AnalyticsCard = void 0;
  var AnalyticsCard = function AnalyticsCard(_a) {
    var label = _a.label,
      number = _a.number,
      icon = _a.icon,
      numberDelimiter = _a.numberDelimiter;
    return _react.default.createElement("div", {
      className: 'analytics-card',
      style: {
        backgroundImage: "url('".concat(icon, "')")
      }
    }, _react.default.createElement("div", {
      className: 'analytics-card--label'
    }, label), _react.default.createElement("div", {
      className: 'analytics-card--number'
    }, number > 0 ? (0, _utils.formatNumber)(number, numberDelimiter || '.') : '-'));
  };
  _exports.AnalyticsCard = AnalyticsCard;
});