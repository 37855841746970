define("admin/components/settings/bills-invoice-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['row'],
    isPhantom: Ember.computed.alias('currentSession.user.phantom')
  });
  _exports.default = _default;
});