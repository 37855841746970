define("admin/components/settings/hr-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <LinkTo
    @route="client.settings.hr.system"
    @model={{@system.type}}
    class="hr-card {{unless @system.configured 'new'}}"
  >
    {{#if @system.configured}}
      <div class="status">{{t "client.settings.active_connection"}}</div>
    {{/if}}
  
    <img class="icon" src={{@system.logo}} alt="" />
  
    <div class="title">{{@system.name}}</div>
    <div class="text">{{@system.description}}</div>
  </LinkTo>
  */
  {
    "id": "/InVDUZZ",
    "block": "{\"symbols\":[\"@system\"],\"statements\":[[8,\"link-to\",[[16,0,[31,[\"hr-card \",[30,[36,1],[[32,1,[\"configured\"]],\"new\"],null]]]]],[[\"@route\",\"@model\"],[\"client.settings.hr.system\",[32,1,[\"type\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,1,[\"configured\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"status\"],[12],[1,[30,[36,0],[\"client.settings.active_connection\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"img\"],[14,0,\"icon\"],[15,\"src\",[32,1,[\"logo\"]]],[14,\"alt\",\"\"],[12],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"title\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"text\"],[12],[1,[32,1,[\"description\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"unless\",\"if\"]}",
    "meta": {
      "moduleName": "admin/components/settings/hr-card.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});