define("admin/routes/client/statistics/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        brands: this.store.query('brand', {
          status: 'ACTIVE',
          withga: true,
          skipaddresses: true
        })
      });
    }
  });
  _exports.default = _default;
});