define("admin/compiled/ui/ContextMenu", ["exports", "react", "react-redux", "admin/compiled/utils/useOutsideClick", "admin/compiled/utils/useWindowSize", "admin/compiled/ui/MaterialIcon"], function (_exports, _react, _reactRedux, _useOutsideClick, _useWindowSize, _MaterialIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ContextMenu = void 0;
  var MenuTrigger = function MenuTrigger(_a) {
    var isLoading = _a.isLoading,
      triggerShow = _a.triggerShow,
      disabled = _a.disabled;
    return _react.default.createElement(_MaterialIcon.MaterialIcon, {
      noPointer: isLoading || disabled,
      onClick: function onClick() {
        if (isLoading) {
          return;
        }
        triggerShow();
      }
    }, _react.default.createElement("div", {
      "data-testid": 'context-menu-trigger',
      className: "context-menu--trigger ".concat(isLoading ? 'loading' : '', " ").concat(disabled ? 'disabled' : '')
    }));
  };
  var ContextMenu = function ContextMenu(_a) {
    var items = _a.items,
      white = _a.white,
      withIcons = _a.withIcons,
      loading = _a.loading,
      disabled = _a.disabled;
    var width = (0, _useWindowSize.useWindowSize)()[0];
    var _b = (0, _react.useState)(false),
      show = _b[0],
      setShow = _b[1];
    var ref = (0, _react.useRef)(null);
    (0, _useOutsideClick.default)(ref, function () {
      return setShow(false);
    });
    var dispatch = (0, _reactRedux.useDispatch)();
    return _react.default.createElement("span", {
      ref: ref,
      className: "context-menu ".concat(white ? 'context-menu--white' : '', " ").concat(withIcons ? 'with-icons' : '')
    }, _react.default.createElement(MenuTrigger, {
      isLoading: loading,
      triggerShow: function triggerShow() {
        if (!disabled) {
          setShow(!show);
        }
      },
      disabled: disabled
    }), show && _react.default.createElement("div", {
      className: "context-menu--dropdown ".concat((items === null || items === void 0 ? void 0 : items.length) === 1 ? 'single-item' : ''),
      onClick: function onClick() {
        return setShow(false);
      }
    }, items.filter(function (i) {
      return !i.maxScreenWidth || width <= i.maxScreenWidth;
    }).map(function (item, index) {
      return _react.default.createElement("div", {
        key: index,
        "data-testid": item.testId,
        onClick: typeof item.onClick !== 'undefined' ? item.onClick : function () {
          return dispatch(item.dispatchFn);
        },
        className: "".concat(item.className || '')
      }, item.title);
    })));
  };
  _exports.ContextMenu = ContextMenu;
});