define("admin/compiled/utils/useWindowSize", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.useWindowSize = void 0;
  var useWindowSize = function useWindowSize() {
    var _a = (0, _react.useState)([0, 0]),
      size = _a[0],
      setSize = _a[1];
    (0, _react.useLayoutEffect)(function () {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return function () {
        return window.removeEventListener("resize", updateSize);
      };
    }, []);
    return size;
  };
  _exports.useWindowSize = useWindowSize;
});