define("admin/controllers/admin/customers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    queryParams: ['search'],
    loginManager: Ember.inject.service(),
    actions: {
      manage: function manage(customer) {
        var _this = this;
        this.loginManager.goPhantom(customer.get('id')).then(function () {
          _this.set('search', '');
          _this.transitionToRoute('index');
        }).catch(function () {
          _this.notifications.error(_this.intl.t('string.generic_error'));
        });
      }
    }
  });
  _exports.default = _default;
});