define("admin/components/applications/interview-invite-modal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    apiClient: Ember.inject.service(),
    today: (0, _moment.default)().toDate(),
    date: (0, _moment.default)().toDate(),
    isValid: Ember.computed('address', 'caldate', 'date', 'from', 'selectedTemplate.template', 'to', function () {
      return this.address && this.selectedTemplate && this.selectedTemplate.template && this.date && (0, _moment.default)(this.from, 'HH:mm').isValid() && (0, _moment.default)(this.to, 'HH:mm').isValid();
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      this.from = (0, _moment.default)().add(1, 'hour').set({
        minute: 30
      }).format('HH:mm');
      this.to = (0, _moment.default)().add(2, 'hour').set({
        minute: 30
      }).format('HH:mm');
      if (this.application && this.get('application.interview.id')) {
        this.set('applicationId', this.get('application.id'));
        var fromDate = (0, _moment.default)(this.get('application.interview.from'));
        var toDate = (0, _moment.default)(this.get('application.interview.to'));
        // set date to the previously chosen one if it's not already in the past.
        if (fromDate.isSameOrAfter((0, _moment.default)())) {
          this.set('date', fromDate.toDate());
          this.set('from', fromDate.format('HH:mm'));
          this.set('to', toDate.format('HH:mm'));
        }
        this.set('address', this.get('application.interview.address'));
        this.set('remote', !!this.get('application.interview.remoteInterview'));
      } else {
        this.set('address', this.get('job.addresses.firstObject.format'));
      }
      this.apiClient.get("/clients/messagetemplates/type/LETTER_INVITATION").then(function (response) {
        var templates = response ? response.messageTemplates : [];
        if (_this.application && _this.get('application.interview.id')) {
          templates.unshiftObject({
            title: _this.intl.t('string.invite_sent'),
            template: _this.get('application.interview.message')
          });
        }
        _this.set('templates', templates);
        _this.set('selectedTemplate', templates.length ? templates[0] : {});
      });
    },
    actions: {
      close: function close() {
        this.toggle();
      },
      setDate: function setDate(date) {
        this.set('date', (0, _moment.default)(date).toDate());
      },
      sendInvitation: function sendInvitation() {
        var _this2 = this;
        var fromH = (0, _moment.default)(this.from, 'HH:mm').format('HH');
        var fromM = (0, _moment.default)(this.from, 'HH:mm').format('mm');
        var toH = (0, _moment.default)(this.to, 'HH:mm').format('HH');
        var toM = (0, _moment.default)(this.to, 'HH:mm').format('mm');
        var from = (0, _moment.default)(this.date).set({
          h: fromH,
          m: fromM
        }).utc();
        var to = (0, _moment.default)(this.date).set({
          h: toH,
          m: toM
        }).utc();
        var data = {
          from: from.format(),
          to: to.format(),
          address: this.address,
          message: this.selectedTemplate.template,
          remote: this.remote
        };
        if (from.isSameOrAfter(to) || from.isBefore((0, _moment.default)())) {
          this.notifications.warning(this.intl.t('string.invalid_date'));
          return;
        }
        Ember.set(this, 'loading', true);
        this.apiClient.post("/jobs/".concat(this.job.id, "/applications/").concat(this.application.id, "/interviews/invite"), data).then(function () {
          _this2.refresh(_this2.application);
          _this2.toggle();
        }).finally(function () {
          return Ember.set(_this2, 'loading', false);
        });
      },
      updateFrom: function updateFrom(unmasked, masked) {
        this.set('from', masked);
      },
      updateTo: function updateTo(unmasked, masked) {
        this.set('to', masked);
      },
      setRemote: function setRemote(remote) {
        this.set('remote', remote);
      }
    }
  });
  _exports.default = _default;
});