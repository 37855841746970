define("admin/routes/client/jobs/index", ["exports", "admin/mixins/skeleton-loader", "ember-local-storage", "jquery"], function (_exports, _skeletonLoader, _emberLocalStorage, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_skeletonLoader.default, {
    queryParams: {
      brand: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      user: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      }
    },
    infinity: Ember.inject.service(),
    storage: (0, _emberLocalStorage.storageFor)('current-user'),
    model: function model(_ref) {
      var brand = _ref.brand,
        status = _ref.status,
        user = _ref.user,
        search = _ref.search;
      var _model = this.modelFor('client.jobs.index');
      return Ember.RSVP.hash({
        adtypes: _model ? _model.adtypes : this.store.query('adtype', {}),
        jobstatuses: _model ? _model.jobstatuses : this.store.query('jobstatus', {}),
        brands: this.store.query('brand', {
          status: 'ACTIVE',
          withga: true,
          skipaddresses: true
        }),
        // We can not reuse the model here cause of phantom users (so they will have a fresh list of users and brands after logging in)
        users: this.store.query('user', {}),
        jobs: this.infinity.model('job', {
          perPage: 9,
          startingPage: 0,
          user: user,
          status: status,
          brand: brand,
          search: search,
          perPageParam: 'limit'
        }),
        filter: {
          brand: brand,
          status: status,
          user: user,
          search: search
        }
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var jobsCtrl = this.controllerFor('client.jobs');
      jobsCtrl.set('title', jobsCtrl.get('name'));
      jobsCtrl.set('showBtn', true);
      this.set('storage.lastKnownAdCount', model.jobs.get('length'));
      setTimeout(function () {
        return (0, _jquery.default)(document).scrollTop(controller.scrollPosition);
      }, 50);
    },
    activate: function activate() {
      (0, _jquery.default)('body').attr('id', 'jobs');
    },
    deactivate: function deactivate() {
      (0, _jquery.default)('body').removeAttr('id');
      this.controller.set('search');
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      },
      willTransition: function willTransition() {
        this._super.apply(this, arguments);
        this.controller.set('scrollPosition', window.scrollY);
      }
    }
  });
  _exports.default = _default;
});