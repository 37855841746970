define("admin/components/autotag/keywords-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    apiAdmin: Ember.inject.service(),
    chosenKeywords: Ember.computed('keywords.@each.active', function () {
      return this.keywords.filter(function (k) {
        return k.active;
      });
    }),
    notChosenKeywords: Ember.computed('keywords.@each.active', 'search', function () {
      var _this = this;
      if (!this.search) {
        return this.keywords.filter(function (k) {
          return !k.active;
        });
      }
      return this.keywords.filter(function (k) {
        return !k.active && k.name.indexOf(_this.search) >= 0;
      });
    }),
    classNames: ['keywords-list'],
    saveKeyword: function saveKeyword(keyword) {
      this.apiAdmin.patch("/autotag/jobtags/".concat(this.tag.id, "/keywords/").concat(keyword.id), {
        keyword: keyword
      });
    },
    actions: {
      addKeyword: function addKeyword(keyword) {
        Ember.set(keyword, 'active', true);
        this.saveKeyword(keyword);
      },
      deleteKeyword: function deleteKeyword(keyword) {
        Ember.set(keyword, 'active', false);
        this.saveKeyword(keyword);
      },
      createKeyword: function createKeyword() {
        var _this2 = this;
        var keyword = {
          name: this.newKeyword,
          jobTagId: this.tag.id,
          active: true
        };
        this.tag.createKeyword({
          keyword: keyword
        }).then(function (res) {
          _this2.set('newKeyword');
          keyword.id = res.keyword.id;
          _this2.keywords.pushObject(keyword);
        });
      },
      testKeywords: function testKeywords() {
        var _this3 = this;
        this.set('testingKeywords', true);
        this.tag.testKeywords().then(function (res) {
          _this3.set('testingKeywords');
          _this3.tag.set('performance', res.performance);
          _this3.tag.set('negativePerformance', res.negativePerformance);
        });
      }
    }
  });
  _exports.default = _default;
});