define("admin/components/skills/categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    queryParams: ['subcatid'],
    apiAdmin: Ember.inject.service(),
    actions: {
      selectSubcat: function selectSubcat(subcat) {
        this.set('subcatid', subcat.id);
      },
      toggleCat: function toggleCat(cat) {
        Ember.set(cat, 'isOpen', !cat.isOpen);
      },
      toggleNewSubcat: function toggleNewSubcat(category, subcat) {
        this.set('newSubcat', !this.newSubcat);
        this.set('subcat', subcat ? subcat : null);
        this.set('category', category);
        Ember.run.schedule('afterRender', this, function () {
          $('body').click(); // Hides the context menu
          $('#new-subcat-input').focus();
        });
      },
      toggleNewCategory: function toggleNewCategory(category) {
        this.set('category', category);
        this.set('newCategory', !this.newCategory);
        Ember.run.schedule('afterRender', this, function () {
          $('body').click(); // Hides the context menu
          $('#new-category-input').focus();
        });
      },
      deleteSubcat: function deleteSubcat(category, subcat) {
        var _this = this;
        this.set('isDeletingSubcat', true);
        this.apiAdmin.delete("/skillsubcategories/".concat(subcat.id)).then(function () {
          var subCategories = category.subCategories.filter(function (s) {
            return s.id !== subcat.id;
          });
          var filteredSubcategories = category.filteredSubcategories.filter(function (s) {
            return s.id !== subcat.id;
          });
          Ember.set(category, 'subCategories', subCategories);
          Ember.set(category, 'filteredSubcategories', filteredSubcategories);
        }).finally(function () {
          return _this.set('isDeletingSubcat');
        });
      },
      deleteCategory: function deleteCategory(cat) {
        var _this2 = this;
        this.set('isDeletingCategory', true);
        this.apiAdmin.delete("/skillcategories/".concat(cat.id)).then(function () {
          _this2.set('categories', _this2.categories.filter(function (c) {
            return c.id !== cat.id;
          }));
        }).finally(function () {
          return _this2.set('isDeletingCategory');
        });
      }
    }
  });
  _exports.default = _default;
});