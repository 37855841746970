define("admin/controllers/admin/library/crad-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    categories: [{
      name: 'Veitingastörf'
    }, {
      name: 'Iðnstörf'
    }, {
      name: 'Upplýsingatækni'
    }]
  });
  _exports.default = _default;
});