define("admin/routes/client/edit-job/index", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var region = _environment.default.REGION.region;
  var _default = Ember.Route.extend({
    activate: function activate() {
      this._super.apply(this, arguments);
      window.scrollTo(0, 0);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('model.brands', model.brands.toArray()); // make it mutable to be able to add records later
      controller.set('hideEmailField', !model.job.applyemail && region === 'is');
    }
  });
  _exports.default = _default;
});