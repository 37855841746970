define("admin/components/settings/manage-invoice-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    apiClient: Ember.inject.service(),
    intl: Ember.inject.service(),
    isConfirmed: Ember.computed('confirm', function () {
      return this.intl ? this.confirm == this.intl.t("components.settings.manage_invoice_modal.confirm_string") : null;
    }),
    validEmail: Ember.computed('email', function () {
      return this.email && this.email.email();
    }),
    actions: {
      close: function close() {
        this.toggle();
      },
      sendMail: function sendMail() {
        var _this = this;
        this.invoice.email({
          email: this.email,
          ssn: this.get('invoice.ssn')
        }).then(function () {
          _this.set('email');
          _this.notifications.success(_this.intl.t("components.settings.manage_invoice_modal.success"));
        }).catch(function () {
          _this.notifications.error(_this.intl.t("components.settings.manage_invoice_modal.error"));
        });
      },
      changeStatus: function changeStatus() {
        var _this2 = this;
        this.set('changingStatus', true);
        this.apiClient.patch("/settings/invoices/".concat(this.invoice.id, "/paid")).then(function () {
          _this2.set('invoice.status', 'PAID');
          _this2.notifications.success(_this2.intl.t("components.settings.manage_invoice_modal.success"));
        }).finally(function () {
          return _this2.set('changingStatus');
        });
      }
    }
  });
  _exports.default = _default;
});