define("admin/helpers/filter-utils/skills-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.skillsFilter = void 0;
  var skillsFilter = function skillsFilter(allUserSkills, _skillsFilter, fit) {
    _skillsFilter.forEach(function (skillFilter) {
      if (skillFilter.skill) {
        var requiredSkill = skillFilter.skill;
        var profileSkill = allUserSkills.find(function (s) {
          return s.skillId == requiredSkill.id;
        });
        if (!profileSkill) {
          fit = false;
        } else {
          if (requiredSkill.skillType === 'SCALE') {
            var requiredLevel = skillFilter.values;
            if (!(profileSkill.level >= requiredLevel[0] && profileSkill.level <= requiredLevel[1])) {
              fit = false;
            }
          }
          if (requiredSkill.skillType === 'BINARY') {
            var shouldQualify = !!skillFilter.values.values;
            if (!!profileSkill.qualified !== shouldQualify) {
              fit = false;
            }
          }
        }
      }
    });
    return fit;
  };
  _exports.skillsFilter = skillsFilter;
});