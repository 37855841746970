define("admin/compiled/alfrello-card/AlfrelloCardAttachment", ["exports", "react", "admin/compiled/utils/languageContext", "moment"], function (_exports, _react, _languageContext, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AlfrelloCardVideoInterview = _exports.AlfrelloCardAttachment = void 0;
  var AlfrelloCardInterview = function AlfrelloCardInterview(_a) {
    var interview = _a.interview;
    var intl = (0, _react.useContext)(_languageContext.default);
    return _react.default.createElement("div", {
      className: 'infoline inv-info'
    }, _react.default.createElement("div", {
      className: 'name'
    }, _react.default.createElement("small", null, (0, _moment.default)(interview.get('from')).format('DD. MMM @ HH:mm'))), _react.default.createElement("div", {
      className: "info-status ".concat(interview.get('status'))
    }, interview.get('status') === 'ACCEPTED' && intl.t('string.accepted'), interview.get('status') === 'SENT' && intl.t('string.pending'), interview.get('status') === 'REQUEST_NEW' && intl.t('string.new_time_request'), interview.get('status') === 'SEEN' && intl.t('components.applications.alfrello.application_profile.seen'), interview.get('status') === 'DECLINED' && intl.t('string.declined')));
  };
  var AlfrelloCardVideoInterview = function AlfrelloCardVideoInterview(_a) {
    var videoInterview = _a.videoInterview;
    var intl = (0, _react.useContext)(_languageContext.default);
    if (videoInterview.get('status') === 'EXPIRED' && videoInterview.get('seen') === true) {
      return _react.default.createElement("div", {
        className: 'infoline vi-info'
      }, _react.default.createElement("div", {
        className: "name"
      }, intl.t('components.applications.alfrello.application_profile.vi')), _react.default.createElement("div", {
        className: "scores"
      }, _react.default.createElement("span", {
        className: "info-status SEEN"
      }, intl.t('vi_status.SEEN') + ' /'), _react.default.createElement("span", {
        className: "info-status  ".concat(videoInterview.get('status'))
      }, videoInterview.get('translated_vistatus')), !!videoInterview.get('averageRating') && _react.default.createElement("div", {
        className: "virating ".concat(videoInterview.get('avgratingcolor'))
      }, videoInterview.get('averageRating'))));
    } else {
      return _react.default.createElement("div", {
        className: 'infoline vi-info'
      }, _react.default.createElement("div", {
        className: "name"
      }, intl.t('components.applications.alfrello.application_profile.vi')), _react.default.createElement("div", {
        className: "scores"
      }, _react.default.createElement("span", {
        className: "info-status ".concat(videoInterview.get('status'))
      }, videoInterview.get('translated_vistatus')), !!videoInterview.get('averageRating') && _react.default.createElement("div", {
        className: "virating ".concat(videoInterview.get('avgratingcolor'))
      }, videoInterview.get('averageRating'))));
    }
  };
  _exports.AlfrelloCardVideoInterview = AlfrelloCardVideoInterview;
  var AlfrelloCardCompasses = function AlfrelloCardCompasses(_a) {
    var compasses = _a.compasses;
    var intl = (0, _react.useContext)(_languageContext.default);
    var getScore = function getScore(score) {
      if (score || score === 0) {
        return Math.round(score * 10) / 10;
      }
      return '-';
    };
    return _react.default.createElement("div", {
      className: "infoline compass"
    }, _react.default.createElement("div", {
      className: "name"
    }, intl.t('applications.alfrello.application_profile.compass')), _react.default.createElement("div", {
      className: 'scores'
    }, compasses.map(function (compass, index) {
      var style = {
        '--compass-color': compass.compass.color
      };
      return _react.default.createElement("a", {
        title: compass.compass.name,
        key: index,
        className: 'score',
        style: style
      }, getScore(compass.score));
    })));
  };
  var AlfrelloCardAttachment = function AlfrelloCardAttachment(_a) {
    var videoInterview = _a.videoInterview,
      interview = _a.interview,
      status = _a.status,
      compasses = _a.compasses;
    var intl = (0, _react.useContext)(_languageContext.default);
    var getAttachment = function getAttachment() {
      if (status === 'CANCELED') {
        return _react.default.createElement("div", {
          className: 'infoline'
        }, intl.t('components.applications.alfrello.application_profile.canceled'));
      }
      if (status === 'REJECTED') {
        return _react.default.createElement("div", {
          className: 'infoline'
        }, intl.t('application.rejected'));
      }
      return _react.default.createElement(_react.default.Fragment, null, interview && _react.default.createElement(AlfrelloCardInterview, {
        interview: interview
      }), videoInterview && _react.default.createElement(AlfrelloCardVideoInterview, {
        videoInterview: videoInterview
      }), !!(compasses === null || compasses === void 0 ? void 0 : compasses.length) && _react.default.createElement(AlfrelloCardCompasses, {
        compasses: compasses
      }));
    };
    return _react.default.createElement("div", {
      className: 'alfrello-card--attachment'
    }, getAttachment());
  };
  _exports.AlfrelloCardAttachment = AlfrelloCardAttachment;
});