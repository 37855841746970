define("admin/components/crad/selected-tags-by-categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['crad-selected-tags-by-categories']
  });
  _exports.default = _default;
});