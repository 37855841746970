define("admin/components/jobs/lift-up-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    actions: {
      close: function close() {
        this.toggle();
      },
      liftUp: function liftUp() {
        var _this = this;
        this.job.boost({}).then(function (data) {
          _this.set('purchased', true);
          _this.store.pushPayload(data);
        }).catch(function (error) {
          _this.set('failed', true);
          _this.notifications.error(error.errors[0].detail);
        });
      },
      refresh: function refresh() {
        window.location.reload(true);
      }
    }
  });
  _exports.default = _default;
});