define("admin/components/crad/date-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['crad-datepicker'],
    classNameBindings: ['showPicker:active'],
    click: function click() {
      this.toggleProperty('showPicker');
    },
    actions: {
      setDate: function setDate(date) {
        if (this.setDate) {
          this.setDate(date);
        }
        this.set('showPicker', false);
      }
    }
  });
  _exports.default = _default;
});