define("admin/templates/components/browser-support-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ppV76pAT",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@overlayClass\"],[[30,[36,0],[[32,0],\"close\"],null],\"modal-popup browser-support-modal\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"default-dialog text-center\"],[12],[2,\"\\n    \"],[11,\"a\"],[24,0,\"close\"],[4,[38,0],[[32,0],\"close\"],null],[12],[13],[2,\"\\n\\n    \"],[10,\"h2\"],[12],[1,[30,[36,1],[\"components.browser_support_modal.title\"],null]],[13],[2,\"\\n\\n    \"],[10,\"p\"],[12],[1,[30,[36,1],[\"components.browser_support_modal.p\"],null]],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"browsers\"],[12],[2,\"\\n      \"],[10,\"a\"],[14,0,\"chrome\"],[14,6,\"https://www.google.com/chrome/\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[2,\"Google Chrome\"],[13],[2,\"\\n      \"],[10,\"a\"],[14,0,\"safari\"],[14,6,\"https://www.apple.com/safari/\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[2,\"Safari\"],[13],[2,\"\\n      \"],[10,\"a\"],[14,0,\"firefox\"],[14,6,\"https://www.mozilla.org/firefox\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[2,\"Mozilla Firefox\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"t\"]}",
    "meta": {
      "moduleName": "admin/templates/components/browser-support-modal.hbs"
    }
  });
  _exports.default = _default;
});