define("admin/compiled/alfrello-profile-info/ProfileAttachments", ["exports", "react", "admin/compiled/alfrello-profile-items/AlfrelloProfileItems"], function (_exports, _react, _AlfrelloProfileItems) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ProfileAttachments = void 0;
  var ProfileAttachment = function ProfileAttachment(_a) {
    var attachment = _a.attachment;
    return _react.default.createElement("div", {
      className: 'alfrello-profile-attachment'
    }, _react.default.createElement("a", {
      href: attachment.url,
      target: '_blank'
    }, _react.default.createElement("img", {
      src: "/img/types/".concat(attachment.type, ".png"),
      alt: ''
    }), attachment.name), _react.default.createElement("span", {
      className: 'alfrello-profile-attachment--size'
    }, attachment.size_mb, " mb"));
  };
  var ProfileAttachments = function ProfileAttachments(_a) {
    var attachments = _a.attachments,
      label = _a.label;
    if (!attachments || !attachments.length) {
      return null;
    }
    return _react.default.createElement(_AlfrelloProfileItems.AlfrelloProfileItems, {
      label: label,
      items: attachments.map(function (a) {
        return _react.default.createElement(ProfileAttachment, {
          key: a.id,
          attachment: a
        });
      })
    });
  };
  _exports.ProfileAttachments = ProfileAttachments;
});