define("admin/controllers/client/settings/questions/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    lastUsedMin: (0, _moment.default)().subtract(2, 'months'),
    actions: {
      deleteQuestion: function deleteQuestion(question) {
        question.destroyRecord();
      }
    }
  });
  _exports.default = _default;
});