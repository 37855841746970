define("admin/compiled/stats-graph/StatsGraph", ["exports", "react", "recharts", "admin/compiled/utils/emberContext", "admin/compiled/stats-graph/StatsGraphButton"], function (_exports, _react, _recharts, _emberContext, _StatsGraphButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.StatsGraph = void 0;
  var __awaiter = void 0 && (void 0).__awaiter || function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P ? value : new P(function (resolve) {
        resolve(value);
      });
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }
      function rejected(value) {
        try {
          step(generator["throw"](value));
        } catch (e) {
          reject(e);
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };
  var __generator = void 0 && (void 0).__generator || function (thisArg, body) {
    var _ = {
        label: 0,
        sent: function sent() {
          if (t[0] & 1) throw t[1];
          return t[1];
        },
        trys: [],
        ops: []
      },
      f,
      y,
      t,
      g;
    return g = {
      next: verb(0),
      "throw": verb(1),
      "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
      return this;
    }), g;
    function verb(n) {
      return function (v) {
        return step([n, v]);
      };
    }
    function step(op) {
      if (f) throw new TypeError("Generator is already executing.");
      while (g && (g = 0, op[0] && (_ = 0)), _) try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];
        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;
          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };
          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;
          case 7:
            op = _.ops.pop();
            _.trys.pop();
            continue;
          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }
            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }
            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }
            if (t && _.label < t[2]) {
              _.label = t[2];
              _.ops.push(op);
              break;
            }
            if (t[2]) _.ops.pop();
            _.trys.pop();
            continue;
        }
        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
      if (op[0] & 5) throw op[1];
      return {
        value: op[0] ? op[1] : void 0,
        done: true
      };
    }
  };
  var CustomTooltip = function CustomTooltip(props) {
    var _a;
    if (!props.active) {
      return null;
    }
    var payload = props.payload[0].payload;
    if (!payload.value) {
      return null;
    }
    return _react.default.createElement("div", {
      className: 'stats-graph--tooltip'
    }, _react.default.createElement("div", null, _react.default.createElement("strong", null, "".concat(payload.name, ": ").concat(payload.value)), (_a = payload.tooltipList) === null || _a === void 0 ? void 0 : _a.map(function (item, index) {
      return _react.default.createElement("div", {
        key: index
      }, item);
    })));
  };
  var StatsGraph = function StatsGraph(_a) {
    var data = _a.data,
      title = _a.title,
      onDownload = _a.onDownload;
    var _b = (0, _react.useState)(null),
      focusBar = _b[0],
      setFocusBar = _b[1];
    var _c = (0, _react.useState)(false),
      isDownloading = _c[0],
      setIsDownloading = _c[1];
    var intl = (0, _react.useContext)(_emberContext.default).intl;
    return _react.default.createElement("div", {
      className: 'stats-graph'
    }, _react.default.createElement("div", {
      className: 'stats-graph--top'
    }, _react.default.createElement("div", {
      className: 'stats-graph--title'
    }, title), onDownload && _react.default.createElement(_StatsGraphButton.StatsGraphButton, {
      disabled: isDownloading,
      onClick: function onClick() {
        return __awaiter(void 0, void 0, void 0, function () {
          return __generator(this, function (_a) {
            switch (_a.label) {
              case 0:
                setIsDownloading(true);
                return [4 /*yield*/, onDownload()];
              case 1:
                _a.sent();
                setIsDownloading(false);
                return [2 /*return*/];
            }
          });
        });
      }
    }, isDownloading ? intl.t('stats.chart_loading') : intl.t('stats.chart_download'))), _react.default.createElement(_recharts.ResponsiveContainer, {
      width: "100%",
      minHeight: 400
    }, _react.default.createElement(_recharts.BarChart, {
      maxBarSize: 50,
      barCategoryGap: '20%',
      data: data,
      margin: {
        top: 0,
        right: 0,
        left: -20,
        bottom: 0
      },
      onMouseMove: function onMouseMove(state) {
        if (state.isTooltipActive) {
          setFocusBar(state.activeTooltipIndex);
        } else {
          setFocusBar(null);
        }
      }
    }, _react.default.createElement(_recharts.CartesianGrid, {
      vertical: false,
      stroke: "#F7F7F7"
    }), _react.default.createElement(_recharts.XAxis, {
      dataKey: "name",
      axisLine: false,
      tickLine: false
    }), _react.default.createElement(_recharts.YAxis, {
      axisLine: false,
      tickLine: false
    }), _react.default.createElement(_recharts.Tooltip, {
      cursor: {
        fill: 'transparent'
      },
      content: _react.default.createElement(CustomTooltip, {
        data: {
          list: []
        }
      })
    }), _react.default.createElement(_recharts.Bar, {
      dataKey: "value",
      radius: [10, 10, 0, 0]
    }, data.map(function (entry, index) {
      return _react.default.createElement(_recharts.Cell, {
        key: "cell-".concat(index),
        fill: focusBar === index ? '#ff7200' : '#ff8e33'
      });
    })))));
  };
  _exports.StatsGraph = StatsGraph;
});