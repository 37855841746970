define("admin/components/terms-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    loginManager: Ember.inject.service(),
    store: Ember.inject.service(),
    agree: function agree() {
      var _this = this;
      this.currentSession.user.set('acceptedTerms', true);
      this.currentSession.user.acceptTerms().then(function () {
        _this.currentSession.load();
      });
    },
    disagree: function disagree() {
      // this.toggleProperty('userDelete');
      this.loginManager.logout();
    }
  });
  _exports.default = _default;
});