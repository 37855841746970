define("admin/compiled/jobtag-input/JobTagInput", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.JobTagInput = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };
  var JobTagInput = function JobTagInput(_a) {
    var language = _a.language,
      _onChange = _a.onChange,
      props = __rest(_a, ["language", "onChange"]);
    return _react.default.createElement("div", {
      className: 'jobtag-input'
    }, _react.default.createElement("input", __assign({
      style: {
        backgroundImage: "url('https://alfredflags.imgix.net/".concat(language.code, ".png?w=25')")
      },
      type: 'text'
    }, props, {
      onChange: function onChange(e) {
        if (_onChange) {
          _onChange(e.target.value);
        }
      }
    })));
  };
  _exports.JobTagInput = JobTagInput;
});