define("admin/models/product", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    productKey: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    price: (0, _model.attr)('number'),
    pricemodel: (0, _model.attr)('string'),
    offerPrice: (0, _model.attr)('number'),
    color: (0, _model.attr)('string'),
    canPurchase: (0, _model.attr)('boolean'),
    addDescription: (0, _model.attr)('boolean')
  });
  _exports.default = _default;
});