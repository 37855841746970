define("admin/mixins/model-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    loaderTypes: ['modelLoading', 'pageTransition'],
    actions: {
      loading: function loading(transition) {
        var _this = this;
        this.activeLoader = 0;
        this.loaderDelay = 0;
        var controller = this.controllerFor(transition.from && transition.from.name === transition.targetName && !this.showFullscreenLoader ? transition.to.name : 'application');
        if (transition.from && transition.from.name === 'login') {
          this.activeLoader = 1;
        }
        if (this.activeLoader === 1) {
          this.loaderDelay = 2000;
        }
        var _type = this.loaderTypes[this.activeLoader];
        controller.set('loaderType', _type);
        controller.set(_type, true);
        transition.promise.finally(function () {
          Ember.run.later(_this, function () {
            return controller.set(_type, false);
          }, _this.loaderDelay);
        });
      }
    }
  });
  _exports.default = _default;
});