define("admin/templates/components/crad/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "h0l1gDVD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"crad-header-title\"],[12],[2,\"\\n  \"],[1,[34,0]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"crad-header-subtitle\"],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"title\"]}",
    "meta": {
      "moduleName": "admin/templates/components/crad/header.hbs"
    }
  });
  _exports.default = _default;
});