define("admin/templates/components/crad/crad-dropdown-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "GYCdDXtC",
    "block": "{\"symbols\":[\"@leftEdge\",\"&default\"],\"statements\":[[6,[37,2],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"click-outside\",[],[[\"@onClickOutside\",\"@exceptSelector\"],[[30,[36,1],[[30,[36,0],[\"active\",[32,0]],null]],null],\".search\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"crad-dropdown-options \",[30,[36,2],[[32,1],\"left-edge\"],null]]]],[14,\"data-test\",\"dropdown-options\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,\"data-test\",\"dropdown-option\"],[12],[2,\"\\n        \"],[18,2,null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"toggle\",\"fn\",\"if\",\"active\"]}",
    "meta": {
      "moduleName": "admin/templates/components/crad/crad-dropdown-options.hbs"
    }
  });
  _exports.default = _default;
});