define("admin/components/ui/icon-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['icon-input'],
    actions: {
      didSelectIcon: function didSelectIcon(files) {
        var _this = this;
        if (files.length) {
          var reader = new FileReader();
          reader.readAsDataURL(files[0]);
          reader.addEventListener("load", function () {
            if (_this.saveIcon) {
              _this.saveIcon(reader.result);
            }
            $(".x-file--input")[0].value = '';
          }, false);
        }
      },
      onSaveAction: function onSaveAction() {
        if (this.onSave) {
          this.onSave();
        }
      },
      onDeleteAction: function onDeleteAction() {
        if (this.onDelete) {
          this.onDelete();
        }
      }
    }
  });
  _exports.default = _default;
});