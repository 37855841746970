define("admin/components/protect-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    
  */
  {
    "id": "pU9+2P2N",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/components/protect-route.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    router: Ember.inject.service(),
    layout: '',
    didRender: function didRender() {
      this._super.apply(this, arguments);
      if (this.get('currentSession.client.blacklisted') && !this.get('currentSession.user.phantom')) {
        this.router.transitionTo('client.settings.bills');
      }
    }
  }));
  _exports.default = _default;
});