define("admin/routes/client/applications/compass/index", ["exports", "admin/mixins/model-loader"], function (_exports, _modelLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_modelLoader.default, {
    apiClient: Ember.inject.service(),
    model: function model() {
      var applications = this.modelFor('client.applications');
      return Ember.RSVP.hash({
        compasses: this.apiClient.get("/jobs/".concat(applications.job.id, "/compasses")),
        jobId: applications.job.id
      });
    },
    resetController: function resetController(controller) {
      controller.set('templates');
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});