define("admin/templates/components/crad/date-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "oskcQb8w",
    "block": "{\"symbols\":[\"@position\",\"@date\",\"@min_date\",\"@max_date\",\"&default\",\"@placeholder\",\"@title\",\"@tooltip\",\"@required\",\"@icon\"],\"statements\":[[8,\"ui/icon-section\",[],[[\"@title\",\"@tooltip\",\"@required\",\"@icon\"],[[32,7],[32,8],[32,9],[30,[36,3],[[32,10],[32,10],[30,[36,3],[[32,2],\"checkbox-complete\",\"checkbox-default\"],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],[[32,2],\"DD. MMM YYYY\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"gray\"],[12],[2,\"\\n      \"],[1,[32,6]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[32,0,[\"showPicker\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"click-outside\",[],[[\"@action\"],[[30,[36,0],[\"showPicker\",[32,0]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"drop-datepicker pos_\",[32,1]]]],[12],[2,\"\\n      \"],[8,\"pikaday-inputless\",[],[[\"@value\",\"@minDate\",\"@maxDate\",\"@onSelection\"],[[32,2],[32,3],[32,4],[30,[36,1],[[32,0],\"setDate\"],null]]],null],[2,\"\\n      \"],[18,5,null],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"toggle-action\",\"action\",\"moment-format\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/crad/date-picker.hbs"
    }
  });
  _exports.default = _default;
});