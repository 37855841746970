define("admin/templates/admin/customers/stats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3hrttdwX",
    "block": "{\"symbols\":[],\"statements\":[[8,\"customer-stats-react/customer-stats-react\",[],[[],[]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/templates/admin/customers/stats.hbs"
    }
  });
  _exports.default = _default;
});