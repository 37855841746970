define("admin/models/subscription", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    term: (0, _model.attr)('string'),
    enddate: (0, _model.attr)('string'),
    lastchange: (0, _model.attr)('string'),
    active: (0, _model.attr)('boolean')
  });
  _exports.default = _default;
});