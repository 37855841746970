define("admin/models/column", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    title: (0, _model.attr)('string'),
    ordinal: (0, _model.attr)('number'),
    isdefault: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    rejected: (0, _model.attr)('number'),
    color: (0, _model.attr)('string'),
    canEdit: (0, _model.attr)('boolean'),
    category: (0, _model.belongsTo)('alfrello-category'),
    applications: (0, _model.hasMany)('jobapplications'),
    rejectedApplications: (0, _model.hasMany)('jobapplications'),
    isNew: Ember.computed.equal('category.type', 'NEW'),
    isRejected: Ember.computed.equal('category.type', 'REJECT'),
    dragLocked: (0, _model.attr)('boolean')
  });
  _exports.default = _default;
});