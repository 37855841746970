define("admin/components/region-locales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    localeManager: Ember.inject.service(),
    classNames: ['region-locales'],
    changeLocale: function changeLocale(locale) {
      this.localeManager.changeLang(locale);
    }
  });
  _exports.default = _default;
});