define("admin/components/ui/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['button'],
    classNameBindings: ['loading:disabled', 'disabled', 'color', 'normalText:normal-text'],
    attributeBindings: ['data-test', 'style'],
    click: function click() {
      if (this.onClick && !this.disabled) {
        this.onClick();
      }
    }
  });
  _exports.default = _default;
});