define("admin/components/interview-invite/interview-invite-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="interview-invite-status">
    <div class="interview-invite-status--icon {{@icon}} {{@status}}">
      {{#if @iconContent}}
        <span class="interview-invite-status--icon-content">{{@iconContent}}</span>
      {{/if}}
    </div>
    <div class="interview-invite-status--content">
      <div class="interview-invite-status--title">{{@title}}</div>
      <div class="interview-invite-status--meta">{{@subtitle}}</div>
      <div class="interview-invite-status--meta">{{yield}}</div>
    </div>
  </div>
  
  */
  {
    "id": "YENi6LcZ",
    "block": "{\"symbols\":[\"@iconContent\",\"@status\",\"@icon\",\"@title\",\"@subtitle\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"interview-invite-status\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"interview-invite-status--icon \",[32,3],\" \",[32,2]]]],[12],[2,\"\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"interview-invite-status--icon-content\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"interview-invite-status--content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"interview-invite-status--title\"],[12],[1,[32,4]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"interview-invite-status--meta\"],[12],[1,[32,5]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"interview-invite-status--meta\"],[12],[18,6,null],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "admin/components/interview-invite/interview-invite-status.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});