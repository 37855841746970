define("admin/compiled/modal/ModalRoot", ["exports", "react", "react-redux", "admin/compiled/types", "admin/compiled/modal/ConfirmDialogModal", "admin/compiled/modal/CreateGeneralApplicationModal", "admin/compiled/modal/DownloadFilesModal", "admin/compiled/modal/GaJobVisibilityModal", "admin/compiled/modal/JobsListAnnouncementsModal", "admin/compiled/modal/JobTagsModal"], function (_exports, _react, _reactRedux, _types, _ConfirmDialogModal, _CreateGeneralApplicationModal, _DownloadFilesModal, _GaJobVisibilityModal, _JobsListAnnouncementsModal, _JobTagsModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ModalRoot = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var MODAL_COMPONENTS = {};
  MODAL_COMPONENTS[_types.ModalType.CREATE_GENERAL_APPLICATION] = _CreateGeneralApplicationModal.CreateGeneralApplicationModal;
  MODAL_COMPONENTS[_types.ModalType.GA_VISIBILITY] = _GaJobVisibilityModal.GaJobVisibilityModal;
  MODAL_COMPONENTS[_types.ModalType.JOBS_LIST_ANNOUNCEMENTS] = _JobsListAnnouncementsModal.JobsListAnnouncementsModal;
  MODAL_COMPONENTS[_types.ModalType.CONFIRMATION_MODAL] = _ConfirmDialogModal.ConfirmDialogModal;
  MODAL_COMPONENTS[_types.ModalType.JOB_TAGS_MODAL] = _JobTagsModal.JobTagsModal;
  MODAL_COMPONENTS[_types.ModalType.DOWNLOAD_FILES_MODAL] = _DownloadFilesModal.DownloadFilesModal;
  var ModalRoot = function ModalRoot() {
    var _a = (0, _reactRedux.useSelector)(function (state) {
        return state.modal;
      }),
      modalType = _a.modalType,
      modalProps = _a.modalProps;
    if (!modalType) {
      return null;
    }
    var SpecificModal = MODAL_COMPONENTS[modalType];
    return _react.default.createElement(SpecificModal, __assign({}, modalProps));
  };
  _exports.ModalRoot = ModalRoot;
});