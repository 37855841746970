define("admin/mixins/extract-guid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    primaryKey: '_id',
    extractId: function extractId(modelClass, hash) {
      return hash[this.primaryKey] || Ember.guidFor(hash);
    }
  });
  _exports.default = _default;
});