define("admin/compiled/utils/useForceUpdate", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.useForceUpdate = void 0;
  var useForceUpdate = function useForceUpdate() {
    var _a = (0, _react.useState)(0),
      value = _a[0],
      setValue = _a[1];
    return function () {
      return setValue(function (value) {
        return ++value;
      });
    };
  };
  _exports.useForceUpdate = useForceUpdate;
});