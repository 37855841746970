define("admin/compiled/modal/GaJobVisibilityModal", ["exports", "react", "admin/compiled/modal/AlfredModal", "admin/compiled/utils/emberContext", "admin/compiled/ga-job-visibility-item/GaJobVisibilityItem"], function (_exports, _react, _AlfredModal, _emberContext, _GaJobVisibilityItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GaJobVisibilityModal = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var GaJobVisibilityModal = function GaJobVisibilityModal(_a) {
    var items = _a.items;
    var intl = (0, _react.useContext)(_emberContext.default).intl;
    return _react.default.createElement(_AlfredModal.AlfredModal, {
      title: intl.t('ga-application-visibility.title'),
      wider: true
    }, _react.default.createElement("div", {
      className: 'ga-job-visibility-modal--description'
    }, intl.t('ga-application-visibility.description')), _react.default.createElement("div", {
      className: 'ga-job-visibility-modal--list'
    }, items === null || items === void 0 ? void 0 : items.map(function (item) {
      return _react.default.createElement(_GaJobVisibilityItem.GaJobVisibilityItem, __assign({
        key: item.title
      }, item));
    })));
  };
  _exports.GaJobVisibilityModal = GaJobVisibilityModal;
});