define("admin/components/applications/cancel-invite-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    apiClient: Ember.inject.service(),
    actions: {
      close: function close() {
        this.toggle();
      },
      cancelInvitation: function cancelInvitation() {
        var _this = this;
        this.apiClient.post("/jobs/".concat(this.job.id, "/applications/").concat(this.application.id, "/interviews/cancel"), {
          message: this.cancelmessage
        }).then(function () {
          _this.refresh(_this.application);
          _this.toggle();
        });
      }
    }
  });
  _exports.default = _default;
});