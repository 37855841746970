define("admin/helpers/dev", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.dev = dev;
  var environment = _environment.default.environment;
  function dev() {
    return environment === 'development';
  }
  var _default = Ember.Helper.helper(dev);
  _exports.default = _default;
});