define("admin/initializers/setup-pikaday-i18n", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'setup-pikaday-i18n',
    initialize: function initialize(app) {
      var i18n = Ember.Object.extend({
        // previousMonth: 'Previous month',
        // nextMonth: 'Next month',
        init: function init() {
          this._super.apply(this, arguments);
          this.months = _moment.default.localeData()._months;
          this.weekdays = _moment.default.localeData()._weekdays;
          this.weekdaysShort = _moment.default.localeData()._weekdaysShort;
        }
      });
      app.register('pikaday-i18n:main', i18n, {
        singleton: true
      });
      app.inject('component:pikaday-input', 'i18n', 'pikaday-i18n:main');
    }
  };
  _exports.default = _default;
});