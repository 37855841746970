define("admin/routes/client/applications/videointerviews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    utils: Ember.inject.service(),
    apiClient: Ember.inject.service(),
    model: function model() {
      var _this$modelFor = this.modelFor('client.applications'),
        job = _this$modelFor.job;
      return Ember.RSVP.hash({
        product: this.apiClient.get('/products?productkey=VIDEOINTERVIEWS'),
        job: job,
        applications: this.store.query('jobapplication', {
          video: true
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.set('currentSession.rememberPrevious', 'videointerviews');
      if (model.job.get('videoInterviewPurchased') && controller.get('sortedApplications.length')) {
        var _this$paramsFor = this.paramsFor('client.applications.videointerviews.detail'),
          application_id = _this$paramsFor.application_id;
        if (!application_id) {
          this.transitionTo('client.applications.videointerviews.detail', controller.get('sortedApplications.firstObject.id'));
        }
      }
    }
  });
  _exports.default = _default;
});