define("admin/compiled/test-helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fixtures = _exports.currentSession = _exports.config = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var config = {
    REGION: {
      thousand: '.',
      symbol: 'kr.'
    }
  };
  _exports.config = config;
  var currentSession = {
    user: {
      phantom: false
    }
  };
  _exports.currentSession = currentSession;
  var fixtures = {
    statsGraph: {
      title: 'Advertisements for all brands between 1. jan 2020 and 4 des. 2020.',
      data: [{
        name: 'Jan',
        value: 0,
        tooltipList: ['item 1', 'item 2']
      }, {
        name: 'Feb',
        value: 1,
        tooltipList: ['item 3', 'item 4']
      }, {
        name: 'Mars',
        value: 0
      }, {
        name: 'Apríl',
        value: 3
      }, {
        name: 'Maí',
        value: 1
      }, {
        name: 'Júní',
        value: 2
      }, {
        name: 'Júlí',
        value: 0
      }, {
        name: 'Ágúst',
        value: 7
      }, {
        name: 'Sept',
        value: 0
      }, {
        name: 'Okt',
        value: 0
      }, {
        name: 'Nóv',
        value: 4
      }, {
        name: 'Des',
        value: 0
      }]
    },
    statsItems: [{
      stat: 18,
      title: 'Advertisements'
    }, {
      stat: 1215,
      title: 'Applications received'
    }, {
      stat: 7,
      title: 'Job categories'
    }],
    statsItem: {
      stat: 1225,
      title: 'Advertisements'
    },
    gaSelectPlan: {
      plans: [{
        title: 'Mánaðarlega',
        price: 16000,
        type: 'MONTHLY'
      }, {
        title: 'Árlega',
        price: 134400,
        subtitle: '(11.200 kr. / á mánuði)',
        discount: 30,
        type: 'ANNUALLY'
      }]
    },
    activeJobItem: {
      title: 'Almenn umsókn',
      brandName: 'Reykjavík Marina',
      logo: 'https://alfredprod.imgix.net/logo/45d9c7b1-8cde-4951-b950-6959edf70953.png?dpr=2&h=90',
      status: {
        color: '32AA05',
        description: 'Virk'
      },
      messageCount: 2
    },
    gaEmptyJobItem: {
      brand: {
        id: 1623,
        domainurl: null,
        facebook: 'appalfred',
        facts: null,
        femalePercentage: null,
        figures: null,
        foundingYear: null,
        generalApplicationJob: null,
        images: null,
        instagram: '',
        invoicecontact: null,
        invoiceemail: '',
        invoicetype: 'ONE_INVOICE',
        isdefault: false,
        lat: 0,
        linkedin: '',
        locale: null,
        logo: 'https://alfredprod.imgix.net/logo/30de96e4-ce24-4f5b-8a9a-0b7d7ccbd419.png',
        lon: 0,
        malePercentage: null,
        name: 'Icelandair Hotels Hamar',
        paragraphs: null,
        parentBrands: null,
        phone: '6642222',
        placeId: '10017668',
        slogan: 'Starfasíða',
        slug: null,
        status: 'ACTIVE',
        twitter: 'appalfred',
        verificationRequired: false,
        web: 'http://www.alfred.is',
        zipId: 1
      }
    },
    detailedJobItem: {
      accesslevel: 'ADMIN',
      adtype: 2,
      appcount: 0,
      applicationsExpired: false,
      applied: 0,
      brand: 4,
      brandLogo: 'https://alfredprod.imgix.net/logo/45d9c7b1-8cde-4951-b950-6959edf70953.png',
      brandName: 'Reykjavik Marina',
      clientId: 4,
      deadline: '2020-11-05T23:59:59Z',
      editable: true,
      generalApplication: true,
      id: 38025,
      jobstatus: 2,
      jobtype: 1,
      messages: 0,
      nodeadline: true,
      opened: 0,
      price: 1200,
      scheduled: false,
      slug: 'almenn-umsokn-7',
      startdate: '2020-09-24T10:44:55Z',
      isActive: true,
      status: {
        id: 2,
        name: 'Active',
        active: true,
        description: 'Virk',
        value: 'ACTIVE',
        color: '32AA05'
      },
      title: 'Almenn umsókn',
      useAlfrello: true,
      userId: 1259,
      username: 'Kerfisstjóri',
      videoInterviewCount: 0,
      videoInterviewPurchased: false,
      watchers: 6974,
      webcount: 0,
      hasAccess: true,
      productPurchases: [{
        icon: 'https://storage.googleapis.com/alfredstatic/producticons/product_icon_electronic_auth.svg',
        price: 2900,
        id: 3636,
        tooltip: 'Rafræn skilríki'
      }]
    }
  };
  _exports.fixtures = fixtures;
  fixtures.detailedJobItemEnded = __assign(__assign({}, fixtures.detailedJobItem), {
    isEnded: true,
    isActive: false,
    status: {
      name: 'Ended',
      description: 'Lokið'
    }
  });
});