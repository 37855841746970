define("admin/models/autotag-jobtag", ["exports", "@ember-data/model", "admin/models/jobtag", "ember-api-actions"], function (_exports, _model, _jobtag, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _jobtag.default.extend({
    performance: (0, _model.attr)('number'),
    negativePerformance: (0, _model.attr)('number'),
    getKeywords: (0, _emberApiActions.memberAction)({
      path: 'keywords',
      type: 'get'
    }),
    createKeyword: (0, _emberApiActions.memberAction)({
      path: 'keywords',
      type: 'post'
    }),
    testKeywords: (0, _emberApiActions.memberAction)({
      path: 'keywordperformance',
      type: 'get'
    }),
    initializeKeywords: (0, _emberApiActions.memberAction)({
      path: 'initkeywords',
      type: 'post'
    }),
    performancePercentage: Ember.computed('performance', 'negativePerformance', function () {
      return {
        performance: this.performance ? Math.round(this.performance * 100 * 100) / 100 : 0,
        negativePerformance: this.negativePerformance ? Math.round(this.negativePerformance * 100 * 100) / 100 : 0
      };
    })
  });
  _exports.default = _default;
});