define("admin/compiled/alfrello-profile-info/ProfileLanguages", ["exports", "react", "admin/compiled/utils/languageContext", "admin/compiled/alfrello-profile-items/AlfrelloProfileItems"], function (_exports, _react, _languageContext, _AlfrelloProfileItems) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ProfileLanguages = void 0;
  var LanguageItem = function LanguageItem(_a) {
    var language = _a.language;
    return _react.default.createElement("div", {
      className: 'alfrello-profile-language--item'
    }, _react.default.createElement("div", {
      className: 'alfrello-profile-language--left'
    }, _react.default.createElement("img", {
      src: language.flagUrl,
      alt: language.name
    }), _react.default.createElement("span", {
      className: 'alfrello-profile-language--name'
    }, language.name)), _react.default.createElement("div", {
      className: 'alfrello-profile-language--right'
    }, _react.default.createElement("strong", {
      className: 'gray'
    }, language.languageSkill)));
  };
  var ProfileLanguages = function ProfileLanguages(_a) {
    var languages = _a.languages;
    var intl = (0, _react.useContext)(_languageContext.default);
    if (!(languages === null || languages === void 0 ? void 0 : languages.length)) {
      return null;
    }
    return _react.default.createElement(_AlfrelloProfileItems.AlfrelloProfileItems, {
      label: intl.t('string.lang_skills'),
      items: languages.map(function (l) {
        return _react.default.createElement(LanguageItem, {
          key: l.id,
          language: {
            id: l.id,
            flagUrl: l.get('language.flagUrl'),
            name: l.get('language.name'),
            languageSkill: l.get('languageSkill.name')
          }
        });
      })
    });
  };
  _exports.ProfileLanguages = ProfileLanguages;
});