define("admin/templates/client/hire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "OlBqHhsZ",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"alfrello-profile/hire-package\",[],[[\"@job\",\"@application\",\"@standalone\"],[[32,1,[\"job\"]],[32,1,[\"application\"]],true]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/templates/client/hire.hbs"
    }
  });
  _exports.default = _default;
});