define("admin/compiled/spotlight/spotlight-image/spotlight-image", ["exports", "@alfred-is/alfred-design-system", "classnames", "react", "admin/compiled/spotlight/image-input/image-input"], function (_exports, _alfredDesignSystem, _classnames, _react, _imageInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SpotlightImage = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };
  var SpotlightImage = function SpotlightImage(_a) {
    var imageLabel = _a.imageLabel,
      file = _a.file,
      rest = __rest(_a, ["imageLabel", "file"]);
    return _react.default.createElement(_imageInput.default, __assign({
      theme: _alfredDesignSystem.Theme.Blue,
      minWidth: 970,
      minHeight: 250,
      customContainer: _react.default.createElement("div", {
        className: (0, _classnames.default)('hover:tw-border-gray-400', 'tw-border-dashed', 'tw-flex', 'tw-items-center', 'tw-overflow-hidden', 'tw-justify-center', 'tw-rounded-lg', 'tw-h-full', 'tw-cursor-pointer', 'tw-flex-col', 'tw-min-h-[200px]', {
          'tw-border tw-border-gray-300 tw-bg-gray-100': !file
        })
      }, !file && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("div", {
        className: "tw-mb-2 tw-bg-gray-200 tw-p-1 tw-rounded-sm"
      }, _react.default.createElement("img", {
        src: "/img/icons/base_img_plus.svg",
        alt: "",
        className: 'tw-w-[30px] tw-h-[30px]'
      })), _react.default.createElement("span", {
        className: (0, _classnames.default)('tw-text-gray-500', 'tw-font-medium')
      }, imageLabel)), file && _react.default.createElement("img", {
        className: "tw-w-full",
        src: file.value,
        alt: ''
      })),
      file: file,
      aspectRatio: 3.88
    }, rest));
  };
  _exports.SpotlightImage = SpotlightImage;
});