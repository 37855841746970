define("admin/models/address", ["exports", "@ember-data/model", "admin/mixins/copyable", "admin/config/environment"], function (_exports, _model, _copyable, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var region = _environment.default.REGION.region;
  var _default = _model.default.extend(_copyable.default, {
    administrativeAreaLevel1: (0, _model.attr)('string'),
    administrativeAreaLevel2: (0, _model.attr)('string'),
    country: (0, _model.attr)('string'),
    formatted: (0, _model.attr)('string'),
    lat: (0, _model.attr)('number'),
    lon: (0, _model.attr)('number'),
    neighborhood: (0, _model.attr)('string'),
    placeId: (0, _model.attr)('string'),
    postalCode: (0, _model.attr)('string'),
    premise: (0, _model.attr)('string'),
    route: (0, _model.attr)('string'),
    streetNumber: (0, _model.attr)('string'),
    subLocality: (0, _model.attr)('string'),
    zipId: (0, _model.attr)('number'),
    address: (0, _model.attr)('string'),
    format: Ember.computed('formatted', 'address', function () {
      if (this.address) {
        return this.address;
      }
      if (region !== 'is') {
        var _number = this.streetNumber || this.premise;
        if (this.route && _number) {
          var _city = this.subLocality || this.administrativeAreaLevel2;
          return "".concat(this.route, " ").concat(_number, ", ").concat(_city);
        }
      }
      return this.formatted ? this.formatted : ' ';
    })
  });
  _exports.default = _default;
});