define("admin/components/compass/action-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="actions-button">
    <button class={{@btnClass}}
      tabindex="-1"
      {{on "click" (toggle "showMore" this)}}>{{@btn}}</button>
  
    {{#if this.showMore}}
      <ClickOutside @onClickOutside={{fn (toggle "showMore" this)}}
        @exceptSelector=".modal-popup">
        <div class="popup side-{{@side}}">
          {{yield}}
        </div>
      </ClickOutside>
    {{/if}}
  </div>
  
  */
  {
    "id": "aGQkTikE",
    "block": "{\"symbols\":[\"@side\",\"&default\",\"@btnClass\",\"@btn\"],\"statements\":[[10,\"div\"],[14,0,\"actions-button\"],[12],[2,\"\\n  \"],[11,\"button\"],[16,0,[32,3]],[24,\"tabindex\",\"-1\"],[4,[38,2],[\"click\",[30,[36,0],[\"showMore\",[32,0]],null]],null],[12],[1,[32,4]],[13],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"showMore\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"click-outside\",[],[[\"@onClickOutside\",\"@exceptSelector\"],[[30,[36,1],[[30,[36,0],[\"showMore\",[32,0]],null]],null],\".modal-popup\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[15,0,[31,[\"popup side-\",[32,1]]]],[12],[2,\"\\n        \"],[18,2,null],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"toggle\",\"fn\",\"on\",\"if\"]}",
    "meta": {
      "moduleName": "admin/components/compass/action-button.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({}));
  _exports.default = _default;
});