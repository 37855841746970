define("admin/compiled/job-card/job-card-services", ["exports", "react", "react-tooltip", "@alfred-is/alfred-design-system"], function (_exports, _react, _reactTooltip, _alfredDesignSystem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.JobCardServices = void 0;
  var JobCardServices = function JobCardServices(_a) {
    var _b = _a.items,
      items = _b === void 0 ? [] : _b;
    var t = (0, _alfredDesignSystem.useNxTranslation)().t;
    return _react.default.createElement("div", {
      className: "tw-flex"
    }, items.slice(0, 5).map(function (i, index) {
      var tooltipId = (0, _alfredDesignSystem.uuid)();
      return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("img", {
        key: index,
        src: i.icon,
        alt: i.name,
        className: "tw-w-5 tw-h-5 tw-rounded tw-ml-1 first:tw-ml-0",
        "data-for": tooltipId,
        "data-tip": "".concat(i.tooltip, " (").concat((0, _alfredDesignSystem.formatNumber)(Math.round(i.price || 0)), " ").concat(t('string.currency'), ".)")
      }), i.tooltip && _react.default.createElement(_reactTooltip.default, {
        id: tooltipId
      }, _react.default.createElement("span", {
        className: "tw-font-normal"
      }, i.tooltip)));
    }), items.length > 5 && _react.default.createElement(_react.default.Fragment, null, " +", items.length - 5));
  };
  _exports.JobCardServices = JobCardServices;
});