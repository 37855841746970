define("admin/compiled/utils/useDebounce", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = useDebounce;
  // Our hook
  function useDebounce(value, delay) {
    var _a = (0, _react.useState)(value),
      debouncedValue = _a[0],
      setDebouncedValue = _a[1];
    (0, _react.useEffect)(function () {
      var handler = setTimeout(function () {
        setDebouncedValue(value);
      }, delay);
      return function () {
        clearTimeout(handler);
      };
    }, [value]);
    return debouncedValue;
  }
});