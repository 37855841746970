define("admin/mixins/skeleton-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    actions: {
      loading: function loading(transition, route) {
        var controller = this.controllerFor(route.routeName);
        controller.set('skeletonLoading', true);
        transition.finally(function () {
          controller.set('skeletonLoading', false);
        });
        return !transition.from || transition.from && transition.from.name !== transition.to.name;
      }
    }
  });
  _exports.default = _default;
});