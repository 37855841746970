define("admin/templates/components/skills/new-skill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gySddTsc",
    "block": "{\"symbols\":[],\"statements\":[[8,\"alfred-modal\",[],[[\"@alfred\",\"@toggle\"],[true,[30,[36,0],[\"newSkill\",[32,0]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,1,\"new-skill\"],[14,0,\"body\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[2,\"Ný hæfni\"],[13],[2,\"\\n    \"],[8,\"layout/space\",[],[[\"@horizontal\",\"@bottom\"],[\"40\",\"30\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"ui/labeled-input\",[],[[\"@inputId\",\"@label\",\"@placeholder\",\"@onEnter\",\"@value\"],[\"new-skill-input\",\"Nafn\",\"Skrifaðu hér...\",[30,[36,1],[[32,0],\"save\"],null],[34,2,[\"name\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn\"],[4,[38,1],[[32,0],[30,[36,0],[\"newSkill\",[32,0]],null]],null],[12],[2,\"Loka\"],[13],[2,\"\\n      \"],[11,\"button\"],[16,0,[31,[\"btn blue \",[30,[36,6],[[30,[36,5],[[30,[36,4],[[35,2,[\"name\"]]],null],[35,3]],null],\"disabled\"],null]]]],[4,[38,1],[[32,0],\"save\"],null],[12],[2,\"\\n        \"],[6,[37,6],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"Hinkraðu...\"]],\"parameters\":[]},{\"statements\":[[2,\"Vista\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"toggle-action\",\"action\",\"skill\",\"loading\",\"not\",\"or\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/skills/new-skill.hbs"
    }
  });
  _exports.default = _default;
});