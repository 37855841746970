define("admin/components/applications/video-purchase-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    apiClient: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      this.set('loading', true);
      this.apiClient.get('/products?productkey=VIDEOINTERVIEWS').then(function (p) {
        _this.set('product', p.product);
      }).finally(function () {
        return _this.set('loading');
      });
    },
    actions: {
      close: function close() {
        this.toggle();
      },
      purchase: function purchase() {
        var _this2 = this;
        this.set('isPurchasing', true);
        this.job.purchase({
          productKey: 'VIDEOINTERVIEWS'
        }).then(function () {
          // Reloading job model will not work as expected, as there is some delay from the backend after
          // the purchase is made (with regard to videoInterviewPurchased boolean)
          _this2.set('job.videoInterviewPurchased', true);
          _this2.set('videoInterviewPurchased', true);
          _this2.toggle();
        }).catch(function () {
          _this2.set('failed', true);
          _this2.notifications.error(_this2.intl.t('string.generic_error'));
        }).finally(function () {
          return _this2.set('isPurchasing');
        });
      },
      okey: function okey() {
        this.toggle();
        if (this.done) {
          this.done();
        }
      },
      refresh: function refresh() {
        window.location.reload();
      }
    }
  });
  _exports.default = _default;
});