define("admin/routes/admin/messages/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.query('sysmsg/messageuser', {});
    },
    deactivate: function deactivate() {
      this.controllerFor('admin.messages').setHeader();
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('admin.messages').setHeaderForUsers(this.routeName);
    }
  });
  _exports.default = _default;
});