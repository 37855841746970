define("admin/templates/admin/autotagging/category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "bgGxpCc5",
    "block": "{\"symbols\":[\"jobTag\"],\"statements\":[[10,\"div\"],[14,0,\"container\"],[14,1,\"autotags\"],[12],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"jobtags\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0,[\"jobTags\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"link-to\",[],[[\"@route\",\"@model\",\"@tagName\"],[\"admin.autotagging.category.jobtag\",[32,1,[\"id\"]],\"li\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,1,[\"name\"]]],[2,\"\\n        \"],[10,\"div\"],[14,0,\"performance\"],[12],[2,\"\\n          \"],[10,\"span\"],[14,0,\"green\"],[12],[1,[32,1,[\"performancePercentage\",\"performance\"]]],[2,\"%\"],[13],[2,\" \"],[10,\"span\"],[14,0,\"gray\"],[12],[2,\"/\"],[13],[2,\" \"],[10,\"span\"],[14,0,\"red\"],[12],[1,[32,1,[\"performancePercentage\",\"negativePerformance\"]]],[2,\"%\"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n    \"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"-track-array\",\"each\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "admin/templates/admin/autotagging/category.hbs"
    }
  });
  _exports.default = _default;
});