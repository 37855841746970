define("admin/controllers/admin/messages/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      selectUser: function selectUser(id) {
        var user = this.store.peekRecord('sysmsg/messageuser', id);
        this.set('model.sysmessage.messageuser', user);
      },
      selectType: function selectType(type) {
        this.set('model.sysmessage.messagetype', type);
      },
      selectClient: function selectClient(client) {
        this.set('model.sysmessage.client', client);
      },
      searchClients: function searchClients(query) {
        if (query.length > 2) {
          return this.store.query('client', {
            page: 0,
            limit: 15,
            search: query
          });
        }
      }
    }
  });
  _exports.default = _default;
});