define("admin/compiled/modal/DownloadFilesModal", ["exports", "@alfred-is/alfred-design-system", "react", "react-redux", "admin/compiled/redux/actions/modal", "admin/compiled/ui/Button", "admin/compiled/utils/emberContext", "admin/compiled/modal/AlfredModal"], function (_exports, _alfredDesignSystem, _react, _reactRedux, _modal, _Button, _emberContext, _AlfredModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DownloadFilesModal = void 0;
  var __awaiter = void 0 && (void 0).__awaiter || function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P ? value : new P(function (resolve) {
        resolve(value);
      });
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }
      function rejected(value) {
        try {
          step(generator["throw"](value));
        } catch (e) {
          reject(e);
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };
  var __generator = void 0 && (void 0).__generator || function (thisArg, body) {
    var _ = {
        label: 0,
        sent: function sent() {
          if (t[0] & 1) throw t[1];
          return t[1];
        },
        trys: [],
        ops: []
      },
      f,
      y,
      t,
      g;
    return g = {
      next: verb(0),
      "throw": verb(1),
      "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
      return this;
    }), g;
    function verb(n) {
      return function (v) {
        return step([n, v]);
      };
    }
    function step(op) {
      if (f) throw new TypeError("Generator is already executing.");
      while (g && (g = 0, op[0] && (_ = 0)), _) try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];
        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;
          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };
          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;
          case 7:
            op = _.ops.pop();
            _.trys.pop();
            continue;
          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }
            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }
            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }
            if (t && _.label < t[2]) {
              _.label = t[2];
              _.ops.push(op);
              break;
            }
            if (t[2]) _.ops.pop();
            _.trys.pop();
            continue;
        }
        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
      if (op[0] & 5) throw op[1];
      return {
        value: op[0] ? op[1] : void 0,
        done: true
      };
    }
  };
  var DownloadFilesModal = function DownloadFilesModal(_a) {
    var title = _a.title,
      downloadFile = _a.downloadFile,
      jobId = _a.jobId;
    var dispatch = (0, _reactRedux.useDispatch)();
    var t = (0, _alfredDesignSystem.useNxTranslation)().t;
    var _b = (0, _react.useState)(false),
      isLoading = _b[0],
      setIsLoading = _b[1];
    var _c = (0, _react.useState)(false),
      isSubmitting = _c[0],
      setIsSubmitting = _c[1];
    var _d = (0, _react.useState)(),
      users = _d[0],
      setUsers = _d[1];
    var _e = (0, _react.useState)(),
      selectedUser = _e[0],
      setSelectedUser = _e[1];
    var _f = (0, _emberContext.useEmberContext)(),
      notifications = _f.notifications,
      intl = _f.intl,
      apiClient = _f.apiClient;
    var getData = function getData() {
      return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              setIsLoading(true);
              return [4 /*yield*/, apiClient.get("/jobs/".concat(jobId, "/jobusers"))];
            case 1:
              data = _a.sent();
              setUsers(data.jobUsers);
              setIsLoading(false);
              return [2 /*return*/];
          }
        });
      });
    };

    (0, _react.useEffect)(function () {
      getData();
    }, []);
    if (isLoading) {
      return _react.default.createElement(_alfredDesignSystem.LoadingScreen, {
        variant: 'orange'
      });
    }
    return _react.default.createElement(_AlfredModal.AlfredModal, {
      title: title,
      preventOutsideClick: true,
      overflowInitial: true
    }, _react.default.createElement("div", {
      className: "tw-text-center"
    }, t('download-files-modal.title')), _react.default.createElement("div", {
      className: "tw-py-4"
    }, _react.default.createElement(_alfredDesignSystem.SelectInput, {
      name: 'job-user',
      selectTheme: _alfredDesignSystem.Theme.Blue,
      placeholder: 'Aðgangur',
      isSearchable: true,
      options: users === null || users === void 0 ? void 0 : users.filter(function (u) {
        return u.accessLevel !== 'NONE';
      }).map(function (user) {
        return {
          label: "".concat(user.name, " (").concat(user.roleName, ")"),
          value: user.id
        };
      }),
      value: selectedUser ? {
        label: "".concat(selectedUser.name, " (").concat(selectedUser.roleName, ")"),
        value: selectedUser.id
      } : null,
      onChange: function onChange(user) {
        return setSelectedUser(users === null || users === void 0 ? void 0 : users.find(function (u) {
          return u.id === (user === null || user === void 0 ? void 0 : user.value);
        }));
      }
    })), _react.default.createElement("div", {
      className: "tw-text-center tw-mt-4"
    }, _react.default.createElement(_Button.Button, {
      color: "gray",
      onClick: function onClick() {
        dispatch((0, _modal.hideModal)());
      }
    }, t('string.cancel')), _react.default.createElement(_Button.Button, {
      onClick: function onClick() {
        return __awaiter(void 0, void 0, void 0, function () {
          var e_1;
          return __generator(this, function (_a) {
            switch (_a.label) {
              case 0:
                if (!selectedUser) {
                  return [2 /*return*/];
                }

                _a.label = 1;
              case 1:
                _a.trys.push([1, 3, 4, 5]);
                setIsSubmitting(true);
                return [4 /*yield*/, downloadFile(selectedUser.id)];
              case 2:
                _a.sent();
                dispatch((0, _modal.hideModal)());
                notifications.success(intl.t('alfrello.attachments.success'));
                return [3 /*break*/, 5];
              case 3:
                e_1 = _a.sent();
                notifications.error((e_1 === null || e_1 === void 0 ? void 0 : e_1.message) || intl.t('filter.actions.error'));
                return [3 /*break*/, 5];
              case 4:
                setIsSubmitting(false);
                return [7 /*endfinally*/];
              case 5:
                return [2 /*return*/];
            }
          });
        });
      },

      disabled: isSubmitting || !selectedUser
    }, isSubmitting ? t('general.loading') : t('string.confirm'))));
  };
  _exports.DownloadFilesModal = DownloadFilesModal;
});