define("admin/components/jobs/demo-text-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    utils: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.demotext = this.utils.getDemoText();
      this.options = {
        readonly: true,
        menubar: false,
        toolbar: false,
        statusbar: false,
        plugins: ['autoresize'],
        autoresize_bottom_margin: 0,
        autoresize_overflow_padding: 10,
        content_style: '.mce-content-body {font-family:"source-sans-pro",sans-serif;font-size:16px;}'
      };
    },
    actions: {
      close: function close() {
        this.toggle();
      },
      useDemo: function useDemo() {
        this.set('job.bodyhtml', this.demotext);
        this.toggle();
      }
    }
  });
  _exports.default = _default;
});