define("admin/models/profile", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    birthday: (0, _model.attr)('string'),
    body: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    gender: (0, _model.attr)('string'),
    image: (0, _model.attr)('string'),
    initials: (0, _model.attr)('string'),
    msisdn: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    phonenumber: (0, _model.attr)('string'),
    profileType: (0, _model.attr)('string'),
    profileSkills: (0, _model.attr)(),
    status: (0, _model.attr)('string'),
    verification: (0, _model.attr)(),
    profileRecommendations: (0, _model.attr)(),
    attachmentcount: (0, _model.attr)('number'),
    notes: (0, _model.attr)('number'),
    about: (0, _model.attr)('string'),
    skills: (0, _model.hasMany)('skill'),
    educations: (0, _model.attr)(),
    experiences: (0, _model.hasMany)('experience'),
    languages: (0, _model.hasMany)('language-level'),
    links: (0, _model.hasMany)('link'),
    age: Ember.computed('birthday', function () {
      if (!this.birthday) {
        return null;
      }
      var a = (0, _moment.default)();
      var b = (0, _moment.default)(this.birthday, 'YYYY-MM-DD');
      var age = _moment.default.duration(a.diff(b));
      return age.years();
    }),
    thumb_initials: Ember.computed('image', function () {
      if (!this.image) {
        return this.initials;
      }
    }),
    latestWorkOrEducation: Ember.computed('experiences,educations', function () {
      var experience = this.get('experiences.firstObject.title');
      var company = this.get('experiences.firstObject.company');
      var education = this.get('educations.firstObject.name');
      var school = this.get('educations.firstObject.school');
      if (experience && company) {
        return "".concat(experience, " (").concat(company.trim(), ")");
      }
      return education && school ? "".concat(education, " (").concat(school.trim(), ")") : '';
    })
  });
  _exports.default = _default;
});