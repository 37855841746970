define("admin/templates/components/new/float-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Jt24Cbum",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"close\"],[4,[38,0],[[32,0],\"close\"],null],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex-row spaced\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"title\"],[12],[2,\"\\n      \"],[10,\"h1\"],[12],[1,[34,1]],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n      \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"title\"]}",
    "meta": {
      "moduleName": "admin/templates/components/new/float-header.hbs"
    }
  });
  _exports.default = _default;
});