define("admin/templates/client/settings/questions/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "bt410xWN",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"new\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex-container\"],[12],[2,\"\\n    \"],[8,\"settings/custom-question-form\",[],[[\"@question\",\"@returnRoute\"],[[34,0],\"client.settings.questions\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "admin/templates/client/settings/questions/form.hbs"
    }
  });
  _exports.default = _default;
});