define("admin/compiled/job-card/job-card", ["exports", "@alfred-is/alfred-design-system", "classnames", "react", "react-use", "admin/compiled/job-card/job-card-actions", "admin/compiled/job-card/job-card-applications-btn", "admin/compiled/job-card/job-card-container", "admin/compiled/job-card/job-card-external-btn", "admin/compiled/job-card/job-card-header", "admin/compiled/job-card/job-card-info", "admin/compiled/job-card/job-card-progress", "admin/compiled/job-card/job-card-services"], function (_exports, _alfredDesignSystem, _classnames, _react, _reactUse, _jobCardActions, _jobCardApplicationsBtn, _jobCardContainer, _jobCardExternalBtn, _jobCardHeader, _jobCardInfo, _jobCardProgress, _jobCardServices) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.JobCardVariant = _exports.JobCard = void 0;
  _exports.useJobCardContext = useJobCardContext;
  var JobCardContext = _react.default.createContext(null);
  function useJobCardContext() {
    var context = _react.default.useContext(JobCardContext);
    if (!context) {
      throw new Error('Job card components cannot be rendered outside the JobCardContext');
    }
    return context;
  }
  var JobCardVariant;
  _exports.JobCardVariant = JobCardVariant;
  (function (JobCardVariant) {
    JobCardVariant["BLUE"] = "BLUE";
    JobCardVariant["GRAY"] = "GRAY";
  })(JobCardVariant || (_exports.JobCardVariant = JobCardVariant = {}));
  var JobCard = function JobCard(_a) {
    var children = _a.children,
      _b = _a.variant,
      variant = _b === void 0 ? JobCardVariant.BLUE : _b;
    var _c = (0, _react.useState)(false),
      infoActive = _c[0],
      setInfoActive = _c[1];
    var exposedProps = {
      infoActive: infoActive
    };
    var element = _react.default.createElement("div", null, children === null || children === void 0 ? void 0 : children(exposedProps));
    var _d = (0, _reactUse.useHover)(element),
      hoverable = _d[0],
      isHovered = _d[1];
    var contextValue = {
      infoActive: infoActive,
      setInfoActive: setInfoActive,
      isHovered: isHovered,
      variant: variant
    };
    return _react.default.createElement(JobCardContext.Provider, {
      value: contextValue
    }, hoverable);
  };
  _exports.JobCard = JobCard;
  JobCard.Container = _jobCardContainer.JobCardContainer;
  JobCard.Header = _jobCardHeader.JobCardHeader;
  JobCard.Title = function (_a) {
    var children = _a.children;
    return _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-text-[1.125rem]', 'tw-leading-[1.5rem]', 'tw-font-semibold', 'tw-text-center', 'tw-px-5')
    }, children);
  };
  JobCard.Progress = _jobCardProgress.JobCardProgress;
  JobCard.Status = function (_a) {
    var color = _a.color,
      children = _a.children;
    return _react.default.createElement(_jobCardProgress.JobCardProgress, {
      items: [],
      status: {
        color: color,
        description: children
      }
    });
  };
  JobCard.ApplicationsBtn = _jobCardApplicationsBtn.JobCardApplicationsBtn;
  JobCard.ExternalBtn = _jobCardExternalBtn.JobCardExternalBtn;
  var Spacer = function Spacer() {
    var _a = useJobCardContext(),
      isHovered = _a.isHovered,
      variant = _a.variant;
    return _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-relative', 'tw-mt-5', 'tw-h-px', {
        'tw-bg-gray-200': !isHovered || variant === JobCardVariant.GRAY,
        'tw-bg-orange-300': isHovered && variant === JobCardVariant.BLUE
      })
    });
  };
  JobCard.Spacer = Spacer;
  JobCard.Actions = _jobCardActions.JobCardActions;
  JobCard.Action = _jobCardActions.JobCardAction;
  JobCard.CenteredContent = function (_a) {
    var children = _a.children;
    return _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-px-5', 'tw-flex-1', 'tw-flex', 'tw-justify-center', 'tw-flex-col')
    }, children);
  };
  var JobCardFooter = function JobCardFooter(_a) {
    var children = _a.children;
    var _b = useJobCardContext(),
      isHovered = _b.isHovered,
      setInfoActive = _b.setInfoActive,
      variant = _b.variant;
    return _react.default.createElement("div", {
      onClick: function onClick() {
        return setInfoActive(true);
      },
      className: (0, _classnames.default)('tw-border-t', 'tw-text-xs', 'tw-flex', 'tw-items-center', 'tw-cursor-pointer', 'tw-py-4 tw-px-5', {
        'tw-opacity-60': !isHovered,
        'tw-border-gray-200': !isHovered || isHovered && variant !== JobCardVariant.BLUE,
        'tw-border-orange-300': isHovered && variant === JobCardVariant.BLUE
      })
    }, children);
  };
  JobCard.Footer = JobCardFooter;
  var FooterItem = function FooterItem(_a) {
    var children = _a.children,
      hideDot = _a.hideDot,
      item = _a.item;
    var _b = useJobCardContext(),
      isHovered = _b.isHovered,
      variant = _b.variant;
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("div", {
      className: (0, _classnames.default)({
        'tw-text-gray-600': !isHovered,
        'tw-text-black-500 tw-font-semibold': isHovered
      })
    }, item && _react.default.createElement(_alfredDesignSystem.TextWithIcon, {
      icon: item.icon,
      size: _alfredDesignSystem.SizeT.medium,
      color: isHovered ? _alfredDesignSystem.ColorT.black500 : _alfredDesignSystem.ColorT.gray600
    }, item.text), children), !hideDot && _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-w-[4px]', 'tw-h-[4px]', 'tw-rounded-sm', 'tw-mx-3', {
        'tw-bg-orange-500': isHovered && variant === JobCardVariant.BLUE,
        'tw-bg-black-500': isHovered && variant === JobCardVariant.GRAY,
        'tw-bg-gray-500': !isHovered
      })
    }));
  };
  JobCard.FooterItem = FooterItem;
  JobCard.Services = _jobCardServices.JobCardServices;
  JobCard.InfoRow = _jobCardInfo.JobCardInfoRow;
});