define("admin/mixins/query-record-with-model-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    urlForQueryRecord: function urlForQueryRecord(query, modelName) {
      var modelUrl = '';
      if (query._id) {
        modelUrl = "/".concat(query._id);
        delete query._id;
      }
      return this._super(query, modelName) + modelUrl;
    }
  });
  _exports.default = _default;
});