define("admin/compiled/hooks/useCreateSpotlight", ["exports", "@alfred-is/alfred-design-system", "moment", "react", "react-query", "admin/compiled/utils/emberContext"], function (_exports, _alfredDesignSystem, _moment, _react, _reactQuery, _emberContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.useCreateSpotlight = useCreateSpotlight;
  var __awaiter = void 0 && (void 0).__awaiter || function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P ? value : new P(function (resolve) {
        resolve(value);
      });
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }
      function rejected(value) {
        try {
          step(generator["throw"](value));
        } catch (e) {
          reject(e);
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };
  var __generator = void 0 && (void 0).__generator || function (thisArg, body) {
    var _ = {
        label: 0,
        sent: function sent() {
          if (t[0] & 1) throw t[1];
          return t[1];
        },
        trys: [],
        ops: []
      },
      f,
      y,
      t,
      g;
    return g = {
      next: verb(0),
      "throw": verb(1),
      "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
      return this;
    }), g;
    function verb(n) {
      return function (v) {
        return step([n, v]);
      };
    }
    function step(op) {
      if (f) throw new TypeError("Generator is already executing.");
      while (g && (g = 0, op[0] && (_ = 0)), _) try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];
        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;
          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };
          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;
          case 7:
            op = _.ops.pop();
            _.trys.pop();
            continue;
          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }
            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }
            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }
            if (t && _.label < t[2]) {
              _.label = t[2];
              _.ops.push(op);
              break;
            }
            if (t[2]) _.ops.pop();
            _.trys.pop();
            continue;
        }
        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
      if (op[0] & 5) throw op[1];
      return {
        value: op[0] ? op[1] : void 0,
        done: true
      };
    }
  };
  function useCreateSpotlight() {
    var _this = this;
    var _a = (0, _react.useContext)(_emberContext.default),
      apiManager = _a.apiManager,
      apiClient = _a.apiClient;
    var queryClient = (0, _reactQuery.useQueryClient)();
    var format = (0, _alfredDesignSystem.useDateUtils)().format;
    return (0, _reactQuery.useMutation)(function (_a) {
      var brandId = _a.brandId,
        image = _a.image,
        start = _a.start,
        end = _a.end;
      return __awaiter(_this, void 0, void 0, function () {
        var brandImage;
        return __generator(this, function (_b) {
          switch (_b.label) {
            case 0:
              return [4 /*yield*/, apiClient.post("/brands/".concat(brandId, "/uploadimage"), {
                type: 'BANNER',
                data: image
              })];
            case 1:
              brandImage = _b.sent();
              return [2 /*return*/, apiManager.post("/spotlight/schedule", {
                brandId: brandId,
                brandImageId: brandImage.id,
                start: (0, _moment.default)(start).utc().format(),
                end: (0, _moment.default)(end).utc().format(),
                placement: 'JOBS',
                type: 'BRAND',
                platform: 'WEB_APP'
              })];
          }
        });
      });
    }, {
      onMutate: function onMutate() {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            switch (_a.label) {
              case 0:
                return [4 /*yield*/, queryClient.cancelQueries(['spotlights'])];
              case 1:
                _a.sent();
                return [2 /*return*/];
            }
          });
        });
      },

      onSettled: function onSettled(spotlight) {
        return __awaiter(_this, void 0, void 0, function () {
          var data, newSpotlight;
          return __generator(this, function (_a) {
            switch (_a.label) {
              case 0:
                return [4 /*yield*/, queryClient.invalidateQueries(['spotlights'])];
              case 1:
                _a.sent();
                data = queryClient.getQueryData(['spotlights']);
                if (data.pages.length > 0) {
                  newSpotlight = data.pages[0].spotlights.find(function (s) {
                    return s.id === (spotlight === null || spotlight === void 0 ? void 0 : spotlight.id);
                  });
                  if (newSpotlight) {
                    newSpotlight.price = spotlight.price;
                  }
                }
                queryClient.setQueryData(['spotlights'], data);
                return [2 /*return*/];
            }
          });
        });
      }
    });
  }
});