define("admin/compiled/alfrello-profile-info/AlfrelloProfileInfo", ["exports", "react", "admin/compiled/alfrello-profile-card/AlfrelloProfileCard", "admin/compiled/alfrello-profile-info/ProfileAttachments", "admin/compiled/ui/Spinner", "admin/compiled/alfrello-profile-info/ProfileLinks", "admin/compiled/alfrello-profile-info/ProfileExperience", "admin/compiled/utils/languageContext", "admin/compiled/alfrello-profile-info/ProfileEducation", "admin/compiled/alfrello-profile-info/ProfileSkills", "admin/compiled/alfrello-profile-info/ProfileLanguages", "admin/compiled/alfrello-profile-info/ProfileAnswers", "admin/compiled/alfrello-profile-info/ProfileVerification", "admin/compiled/alfrello-profile-info/ProfileRecommendations"], function (_exports, _react, _AlfrelloProfileCard, _ProfileAttachments, _Spinner, _ProfileLinks, _ProfileExperience, _languageContext, _ProfileEducation, _ProfileSkills, _ProfileLanguages, _ProfileAnswers, _ProfileVerification, _ProfileRecommendations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AlfrelloProfileInfo = void 0;
  var AlfrelloProfileInfo = function AlfrelloProfileInfo(_a) {
    var application = _a.application,
      intl = _a.intl,
      isLoading = _a.isLoading,
      color = _a.color,
      jobSkills = _a.jobSkills,
      verificationRequired = _a.verificationRequired;
    var experiences = application.get('profile.experiences').toArray();
    var educations = application.get('profile.educations').toArray();
    var languages = application.get('profile.languages').toArray();
    return _react.default.createElement(_languageContext.LanguageProvider, {
      value: intl
    }, _react.default.createElement("div", {
      className: 'alfrello-profile-info'
    }, _react.default.createElement("div", {
      className: 'alfrello-profile-info--section'
    }, application.get('profile.body') && _react.default.createElement(_AlfrelloProfileCard.AlfrelloProfileCard, {
      label: intl.t('string.about')
    }, _react.default.createElement("div", {
      style: {
        padding: '20px 0'
      }
    }, application.get('profile.body'))), _react.default.createElement(_react.default.Fragment, null, isLoading ? _react.default.createElement(_Spinner.Spinner, null) : _react.default.createElement(_react.default.Fragment, null, verificationRequired && _react.default.createElement(_ProfileVerification.ProfileVerification, {
      verification: application.get('profile.verification')
    }), _react.default.createElement(_ProfileLanguages.ProfileLanguages, {
      languages: languages
    }), _react.default.createElement(_ProfileAttachments.ProfileAttachments, {
      attachments: application.attachments.toArray(),
      label: intl.t('string.attachments')
    }), _react.default.createElement(_ProfileLinks.ProfileLinks, {
      color: color,
      label: intl.t('string.links'),
      links: application.get('profile.links').toArray()
    }), _react.default.createElement(_ProfileAnswers.ProfileAnswers, {
      answers: application.answers.toArray()
    }), _react.default.createElement(_ProfileRecommendations.ProfileRecommendations, {
      recommendations: application.get('profile.profileRecommendations')
    })))), _react.default.createElement("div", {
      className: 'alfrello-profile-info--section'
    }, !!experiences.length && _react.default.createElement(_ProfileExperience.ProfileExperience, {
      label: intl.t('string.experience'),
      experiences: experiences
    }), !!educations.length && _react.default.createElement(_ProfileEducation.ProfileEducation, {
      educations: educations,
      label: intl.t('string.education')
    }), isLoading ? _react.default.createElement(_Spinner.Spinner, null) : _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_ProfileSkills.ProfileSkills, {
      label: intl.t('profile.profile-skills.jobprofileskills'),
      skills: application.get('profile.skills').filter(function (s) {
        return jobSkills.includes(function (js) {
          return js.id == s.id;
        });
      }),
      showAllInitially: true
    }), _react.default.createElement(_ProfileSkills.ProfileSkills, {
      label: intl.t('profile.profile-skills.title'),
      skills: application.get('profile.skills')
    })))));
  };
  _exports.AlfrelloProfileInfo = AlfrelloProfileInfo;
});