define("admin/serializers/education", ["exports", "admin/serializers/rest-serializer"], function (_exports, _restSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _restSerializer.default.extend({
    attrs: {
      degree: {
        serialize: false,
        deserialize: 'records'
      }
    }
  });
  _exports.default = _default;
});