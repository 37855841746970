define("admin/models/imports/area", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    subAreas: (0, _model.hasMany)('sub-area')
  });
  _exports.default = _default;
});