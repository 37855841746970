define("admin/components/brands/form-departments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    actions: {
      newDepartment: function newDepartment() {
        if (!this.get('brand.divisions.length')) {
          this.set('brand.divisions', Ember.A());
        }
        this.brand.divisions.pushObject({
          name: ''
        });
      },
      removeDepartment: function removeDepartment(division) {
        this.get('brand.divisions').removeObject(division);
      },
      searchAddress: function searchAddress(search) {
        return this.store.query('address', {
          search: search
        });
      },
      invoiceCheck: function invoiceCheck(value) {
        this.set('brand.invoice.type', value);
      }
    }
  });
  _exports.default = _default;
});