define("admin/initializers/extensions", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  var region = _environment.default.REGION.region;
  function initialize() {
    String.prototype.email = function () {
      var reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return reg.test(this);
    };
    String.prototype.ssn = function () {
      if (region === 'cee') {
        return /^[0-9]{3} [0-9]{2} [0-9]{3}$|^[0-9]{8}$/.test(this);
      }
      if (region === 'is') {
        return /^[0-9]{6}-[0-9]{4}$|^[0-9]{10}$/.test(this);
      }
      if (region === 'mt') {
        return /^[a-zA-Z]{1,4} {0,1}[0-9]{1,5}$/.test(this);
      }
      if (region === 'fo') {
        return /^[0-9]{4}$/.test(this);
      }
    };
    String.prototype.name = function () {
      return /^[\D]{2,} [\D]{2,}$/.test(this);
    };
    String.prototype.flatten = function () {
      return this.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    };
  }
  var _default = {
    name: 'extensions',
    initialize: initialize
  };
  _exports.default = _default;
});