define("admin/mixins/protected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    isPhantom: Ember.computed.alias('currentSession.user.phantom'),
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});