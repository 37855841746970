define("admin/components/jobs-list-react/jobs-list-header-placeholder", ["exports", "admin/react-component", "admin/compiled/utils/emberContext", "react", "admin/compiled/jobs-list-header-placeholder/JobsListHeaderPlaceholder"], function (_exports, _reactComponent, _emberContext, _react, _JobsListHeaderPlaceholder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  
  */
  {
    "id": "1W2miIoy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/components/jobs-list-react/jobs-list-header-placeholder.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _reactComponent.default.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    renderComponent: function renderComponent() {
      var _this = this;
      this.reactRender(_react.default.createElement(_emberContext.EmberProvider, {
        value: {
          intl: this.intl,
          transitionTo: function transitionTo(route) {
            return _this.router.transitionTo(route);
          }
        }
      }, _react.default.createElement(_JobsListHeaderPlaceholder.JobsListHeaderPlaceholder, null)));
    }
  }));
  _exports.default = _default;
});