define("admin/templates/components/videointerview-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "WmctwTg3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"profile\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col\"],[12],[2,\"\\n    \"],[8,\"profile-thumb\",[[24,\"size\",\"sm\"]],[[\"@bg\",\"@initials\"],[[30,[36,2],[[35,0,[\"image\"]]],[[\"h\"],[100]]],[34,0,[\"thumb_initials\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col info\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"name\"],[12],[1,[35,0,[\"name\"]]],[13],[2,\"\\n\"],[6,[37,3],[[35,0,[\"age\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"age\"],[12],[1,[35,0,[\"age\"]]],[2,\" \"],[1,[30,[36,1],[\"string.years\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n  \"],[8,\"applications/video-rating\",[],[[\"@application\"],[[34,4]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"profile\",\"t\",\"imgix\",\"if\",\"application\"]}",
    "meta": {
      "moduleName": "admin/templates/components/videointerview-header.hbs"
    }
  });
  _exports.default = _default;
});