define("admin/routes/admin/sales/index", ["exports", "moment", "admin/mixins/model-loader"], function (_exports, _moment, _modelLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_modelLoader.default, {
    apiAdmin: Ember.inject.service(),
    infinity: Ember.inject.service(),
    queryParams: {
      date: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var date = _ref.date,
        search = _ref.search;
      var year = (0, _moment.default)(date, 'YYYY-MM').format('YYYY');
      var month = (0, _moment.default)(date, 'YYYY-MM').format('M');
      return Ember.RSVP.hash({
        summary: this.apiAdmin.get("/sales/summaries?month=".concat(month, "&year=").concat(year)),
        invoices: this.infinity.model('sales-invoice', {
          year: year,
          month: month,
          search: search,
          perPage: 40,
          perPageParam: 'limit',
          startingPage: 0
        })
      });
    }
  });
  _exports.default = _default;
});