define("admin/compiled/jobs-list/jobs-ga-visiblity", ["exports", "admin/compiled/redux/actions/modal", "admin/compiled/types", "admin/compiled/embed-ga-button/EmbedGaButton", "react"], function (_exports, _modal, _types, _EmbedGaButton, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.showGaVisibilityModal = void 0;
  var showGaVisibilityModal = function showGaVisibilityModal(intl, jobUrl, region, brandId, companyProfileUrl, brandColor) {
    var items = [{
      title: intl.t('ga-application-visibility.link.title'),
      content: intl.t('ga-application-visibility.link.content'),
      icon: 'link',
      textToCopy: jobUrl,
      gtmType: 'job_url'
    }];
    if (companyProfileUrl) {
      items.push({
        title: intl.t('ga-application-visibility.company-profile.title'),
        content: intl.t('ga-application-visibility.company-profile.content'),
        icon: 'link',
        textToCopy: companyProfileUrl,
        gtmType: 'company_profile_url'
      });
    }
    items.push({
      title: intl.t('ga-application-visibility.job-board.title'),
      content: intl.t('ga-application-visibility.job-board.content'),
      icon: 'embed',
      textToCopy: "<!-- Put between <head></head> in the HTML -->\n<script src=\"https://storage.googleapis.com/alfred-embed-widgets.appspot.com/embed.js\"></script>\n<!-- Put between <body></body> in the HTML -->\n<div class=\"alfred-widget\" data-init=\"{host:'client',type:'jobs-page',region:'".concat(region, "',brandId:").concat(brandId, ",color:'").concat(brandColor ? brandColor : '#FF7200', "'}\" data-lang=\"").concat(intl.primaryLocale, "\"></div>"),
      gtmType: 'jobs_embed'
    }, {
      title: intl.t('ga-application-visibility.button.title'),
      content: intl.t('ga-application-visibility.button.content'),
      icon: 'embed',
      textToCopy: "<a href=\"".concat(jobUrl, "\" target=\"_blank\" style=\"display:inline-flex;background-color:#FF7200;text-decoration:none;border:1px solid #F16D02;font-family:Arial,sans-serif;color:#fff;height:60px;font-weight:500;font-size:18px;align-items:center;border-radius:10px;padding:0 16px\"><span><img src=\"https://storage.googleapis.com/alfredstatic/alfred-ga-button/alfred.svg\" alt=\"\"></span> <span style=\"background-color:#e56800;width:1px;height:calc(100% + 2px);position:relative;margin:0 14px\"></span><div><img style=\"margin-right:8px;position:relative;width:28px;height:28px;vertical-align:middle;border:0;display:inline-block\" src=\"https://storage.googleapis.com/alfredstatic/alfred-ga-button/profile.svg\"> <span style=\"position:relative;top:2px\">").concat(intl.t('general-application.title'), "</span></div></a>"),
      preview: _react.default.createElement(_EmbedGaButton.EmbedGaButton, null),
      gtmType: 'ga_button_embed'
    });
    return (0, _modal.showModal)(_types.ModalType.GA_VISIBILITY, {
      items: items
    });
  };
  _exports.showGaVisibilityModal = showGaVisibilityModal;
});