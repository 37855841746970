define("admin/components/alfrello/alfrello-tabs-links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <LinkTo @route="client.applications.index">{{t "client.applications.review_board"}}</LinkTo>
  <LinkTo @route="client.applications.videointerviews">{{t "client.applications.vi"}}</LinkTo>
  <LinkTo @route="client.applications.compass">{{t "applications.tabs.compass"}}</LinkTo>
  {{#if @showAccessTab}}
    <LinkTo @route="client.applications.useraccess">{{t "client.applications.access"}}</LinkTo>
  {{/if}}
  
  */
  {
    "id": "OY1k2E5r",
    "block": "{\"symbols\":[\"@showAccessTab\"],\"statements\":[[8,\"link-to\",[],[[\"@route\"],[\"client.applications.index\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"client.applications.review_board\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"link-to\",[],[[\"@route\"],[\"client.applications.videointerviews\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"client.applications.vi\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"link-to\",[],[[\"@route\"],[\"client.applications.compass\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"applications.tabs.compass\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"link-to\",[],[[\"@route\"],[\"client.applications.useraccess\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"client.applications.access\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "admin/components/alfrello/alfrello-tabs-links.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});