define("admin/components/crad/jobtags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    apiClient: Ember.inject.service(),
    classNames: ['crad-jobtags'],
    attributeBindings: ['data-test'],
    showSearchResults: Ember.computed('search.length', 'forceSearch', function () {
      return this.forceSearch || this.search && this.search.length >= 3;
    }),
    searchResults: Ember.computed('selectedCategory.id', 'showSearchResults', 'categories.@each.tags', function () {
      var _this = this;
      if (!this.categories || !this.search || !this.showSearchResults) {
        return;
      }
      var result = [];
      this.categories.forEach(function (cat) {
        result.push({
          name: cat.name,
          tags: cat.tags.filter(function (t) {
            return t.name.flatten().includes(_this.search.trim().flatten());
          })
        });
      });
      return result.filter(function (c) {
        return c.tags.length;
      });
    }),
    // eslint-disable-next-line
    observeTags: Ember.observer('job.tags.length', function () {
      var _this2 = this;
      if (!this.job.tags.length) {
        this.set('watchersCount', 0);
      }
      this.apiClient.get("/jobtags/countwatchers?tagids=".concat(this.job.tags.map(function (t) {
        return t.id;
      }).join())).then(function (res) {
        _this2.set('watchersCount', res.count);
      });
    }),
    actions: {
      toggle: function toggle(tag) {
        if (this.onToggle) {
          this.onToggle(tag);
        }
      },
      switchTab: function switchTab(tab) {
        this.set('chosenTab', tab);
      },
      clearSearch: function clearSearch() {
        this.set('search');
        this.set('forceSearch');
      }
    }
  });
  _exports.default = _default;
});