define("admin/templates/admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "g9I02Fkv",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"admin\"],[12],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"hideNav\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"nav-bar\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"unless\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "admin/templates/admin.hbs"
    }
  });
  _exports.default = _default;
});