define("admin/controllers/admin/customers/imports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    apiClient: Ember.inject.service(),
    queryParams: ['status', 'locale', 'search', 'filter', 'district', 'refresh'],
    status: 'INCOMPLETE',
    offset: 400,
    jobIndex: 0,
    search: null,
    filter: 'brand',
    getNextBrand: function getNextBrand() {
      var current = this.model.indexOf(this._brand);
      if (current + 1 < this.model.length) {
        this.editBrand(this.model.objectAt(current + 1));
      } else {
        this.closeBrandEdit();
      }
    },
    editBrand: function editBrand(brand) {
      var _this = this;
      this.set('_brand', brand);
      this.store.findRecord('brand', brand.id).then(function (data) {
        _this.set('brand', data);
      });
    },
    editJobs: function editJobs(brand) {
      this.set('jobs', brand.jobs);
      this.jobIndex = 0;
      this.getNextJob();
    },
    getNextJob: function getNextJob() {
      if (this.jobIndex < this.jobs.length) {
        this.getJob(this.jobs[this.jobIndex]);
        this.jobIndex += 1;
      } else {
        this.closeJobEdit();
      }
    },
    getRandomJob: function getRandomJob() {
      var randBrand = Math.floor(Math.random() * this.model.get('length'));
      var jobs = this.model.objectAt(randBrand).get('jobs');
      var randJob = Math.floor(Math.random() * jobs.get('length'));
      this.getJob(jobs[randJob]);
    },
    getJob: function getJob(id) {
      var _this2 = this;
      Ember.RSVP.hash({
        adtypes: this.store.query('adtype', {}),
        jobstatuses: this.store.query('jobstatus', {}),
        jobtypes: this.store.query('jobtype', {}),
        questions: this.store.query('question', {}),
        categories: this.store.query('category', {}),
        job: this.store.findRecord('imports/job', id),
        subCategories: this.apiClient.get('/subcats').then(function (res) {
          return res.subCategories;
        }),
        compensationUnits: this.apiClient.get('/compensation/units').then(function (d) {
          return d.compensationUnits;
        }),
        compensationCurrencies: this.apiClient.get('/compensation/currencies').then(function (d) {
          return d.currencies;
        }),
        languages: this.store.findAll('language'),
        languageSkills: this.store.findAll('language-skill')
      }).then(function (model) {
        _this2.set('modelJob', model);
      });
    },
    deleteJob: function deleteJob() {
      this.set('modelJob');
    },
    closeBrandEdit: function closeBrandEdit() {
      this.set('brand');
    },
    onBrandDelete: function onBrandDelete() {
      this.set('brand');
      this.set('refresh', true);
    },
    closeJobEdit: function closeJobEdit() {
      this.set('modelJob');
    },
    selectLang: function selectLang(locale) {
      this.set('locale', locale == '' ? undefined : locale);
    },
    clearSearch: function clearSearch() {
      this.set('query');
      this.set('search');
    },
    actions: {
      search: function search() {
        this.set('search', this.query);
      },
      selectDistrict: function selectDistrict(subArea) {
        this.set('district', subArea);
      },
      refresh: function refresh() {
        this.send('refreshModel');
      }
    }
  });
  _exports.default = _default;
});