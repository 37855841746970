define("admin/routes/admin/skills/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    apiAdmin: Ember.inject.service(),
    model: function model() {
      return this.apiAdmin.get('/skillcategories');
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      var subCategories = [];
      model.categories.forEach(function (cat) {
        return cat.subCategories.forEach(function (subCat) {
          return subCategories.push(subCat);
        });
      });
      controller.set('subCategories', subCategories);
    },
    actions: {
      refreshData: function refreshData() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});