define("admin/templates/components/crad/nav-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "caXFHhqI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"link-to\",[],[[\"@route\",\"@class\",\"@tagName\"],[[31,[[34,0]]],\"nav-item-link\",\"div\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"crad-nav-item-left \",[30,[36,2],[[35,3],\"complete\"],null],\" \",[30,[36,2],[[35,1],\"progress\"],null]]]],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"crad-nav-item-content\"],[12],[18,1,null],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"route\",\"progress\",\"if\",\"complete\"]}",
    "meta": {
      "moduleName": "admin/templates/components/crad/nav-item.hbs"
    }
  });
  _exports.default = _default;
});