define("admin/templates/components/crad/list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gvYflZPu",
    "block": "{\"symbols\":[\"@newItem\",\"@deleteItem\"],\"statements\":[[8,\"textarea\",[[16,\"placeholder\",[34,2]]],[[\"@id\",\"@value\",\"@rows\",\"@key-up\"],[[34,0,[\"selector\"]],[34,0,[\"description\"]],\"1\",[30,[36,1],[[32,0],\"proxyKeys\"],null]]],null],[2,\"\"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n  \"],[11,\"div\"],[24,0,\"add\"],[4,[38,1],[[32,0],[32,1]],null],[12],[13],[2,\"\\n  \"],[11,\"div\"],[24,0,\"delete\"],[4,[38,1],[[32,0],[32,2],[35,3]],[[\"bubbles\"],[false]]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"dot\"],[12],[2,\"•\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"item\",\"action\",\"placeholder\",\"index\"]}",
    "meta": {
      "moduleName": "admin/templates/components/crad/list-item.hbs"
    }
  });
  _exports.default = _default;
});