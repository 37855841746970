define("admin/components/customers/edit-customer-modal", ["exports", "admin/components/edit-modal"], function (_exports, _editModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _editModal.default.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.deadlines = ['D15', 'D20', 'D30', 'D40'];
      this.discounts = [0, 5, 10, 15, 20];
      this.statuses = [{
        id: 2,
        title: this.intl.t('client_status.INACTIVE'),
        name: 'INACTIVE'
      }, {
        id: 1,
        title: this.intl.t('client_status.ACTIVE'),
        name: 'ACTIVE'
      }, {
        id: 3,
        title: this.intl.t('client_status.ARCHIVED'),
        name: 'ARCHIVED'
      }, {
        id: 6,
        title: this.intl.t('client_status.BLACKLIST'),
        name: 'BLACKLIST'
      }];
    },
    willDestroyElement: function willDestroyElement() {
      var _this = this;
      Ember.run.next(this, function () {
        return _this.customer.rollbackAttributes();
      });
      this._super.apply(this, arguments);
    },
    actions: {
      searchAddress: function searchAddress(search) {
        return this.store.query('address', {
          search: search,
          limit: 20
        });
      },
      save: function save() {
        var _this2 = this;
        this.customer.save().then(function () {
          _this2.toggle();
        });
      }
    }
  });
  _exports.default = _default;
});