define("admin/compiled/modal/WhiteModal", ["exports", "react", "admin/compiled/modal/GeneralModal"], function (_exports, _react, _GeneralModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.WhiteModal = void 0;
  var WhiteModal = function WhiteModal(_a) {
    var children = _a.children,
      preventOutsideClick = _a.preventOutsideClick;
    return _react.default.createElement(_GeneralModal.default, {
      preventOutsideClick: preventOutsideClick
    }, _react.default.createElement("div", {
      className: 'white-modal'
    }, _react.default.createElement("div", {
      className: 'white-modal--content'
    }, children)));
  };
  _exports.WhiteModal = WhiteModal;
});