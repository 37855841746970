define("admin/templates/components/applications/canceled-msg-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9jLhFMdW",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@overlayClass\"],[[30,[36,0],[[32,0],\"close\"],null],\"modal-popup canceled-msg-modal\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"default-dialog small text-center\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[1,[30,[36,1],[\"components.applications.canceled_msg_modal.title\"],null]],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"msgs row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n        \"],[8,\"profile-thumb\",[],[[\"@bg\",\"@initials\"],[[30,[36,3],[[35,2,[\"profile\",\"image\"]]],[[\"h\"],[100]]],[34,2,[\"profile\",\"thumb_initials\"]]]],null],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-20\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"author gray\"],[12],[1,[35,2,[\"profile\",\"name\"]]],[13],[2,\"\\n        \"],[10,\"p\"],[12],[1,[35,2,[\"cancelmessage\"]]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\\n    \"],[10,\"div\"],[14,0,\"edit-form\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn gray-brd inline upper\"],[4,[38,0],[[32,0],\"close\"],null],[12],[1,[30,[36,1],[\"components.applications.canceled_msg_modal.close\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"application\",\"imgix\"]}",
    "meta": {
      "moduleName": "admin/templates/components/applications/canceled-msg-modal.hbs"
    }
  });
  _exports.default = _default;
});