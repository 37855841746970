define("admin/templates/components/loading-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "SkosHdHE",
    "block": "{\"symbols\":[],\"statements\":[[10,\"img\"],[14,\"src\",\"/img/icons/loader.svg\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/templates/components/loading-bar.hbs"
    }
  });
  _exports.default = _default;
});