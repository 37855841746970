define("admin/components/tagger-stats", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    apiClient: Ember.inject.service(),
    from: (0, _moment.default)().subtract(7, 'days').toDate(),
    to: (0, _moment.default)().toDate(),
    today: (0, _moment.default)().toDate(),
    todayCount: 0,
    yesterdayCount: 0,
    lastMonthCount: 0,
    dateCount: 0,
    todayCountClass: Ember.computed('todayCount', function () {
      return this._getCountClass(this.todayCount);
    }),
    yesterdayCountClass: Ember.computed('yesterdayCount', function () {
      return this._getCountClass(this.yesterdayCount);
    }),
    lastMonthCountClass: Ember.computed('lastMonthCount', function () {
      return this._getCountClass(this.lastMonthCount);
    }),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      var _today = (0, _moment.default)().format('YYYY-MM-DD');
      var _yesterday = (0, _moment.default)().subtract(1, 'days').format('YYYY-MM-DD');
      var _firstDayOfMonth = (0, _moment.default)().startOf('month').format('YYYY-MM-DD');
      this._getCount(_today, _today).then(function (response) {
        return _this._response(response, function (count) {
          return _this.set('todayCount', count);
        });
      });
      this._getCount(_yesterday, _yesterday).then(function (response) {
        return _this._response(response, function (count) {
          return _this.set('yesterdayCount', count);
        });
      });
      this._getCount(_firstDayOfMonth, _today).then(function (response) {
        return _this._response(response, function (count) {
          return _this.set('lastMonthCount', count);
        });
      });
      this._getCountBySelected();
    },
    _response: function _response(response, callback) {
      if (this.isDestroyed) {
        return;
      }
      return callback(response.count);
    },
    _getCount: function _getCount(_from, _to) {
      return this.apiClient.get("/jobs/report/tagging/count?fromDate=".concat(_from, "&toDate=").concat(_to));
    },
    _getCountBySelected: function _getCountBySelected() {
      var _this2 = this;
      this._getCount((0, _moment.default)(this.from).format('YYYY-MM-DD'), (0, _moment.default)(this.to).format('YYYY-MM-DD')).then(function (response) {
        return _this2._response(response, function (count) {
          return _this2.set('dateCount', count);
        });
      });
    },
    _getCountClass: function _getCountClass(count) {
      if (count < 50) {
        return 'red';
      }
      if (count < 100) {
        return 'orange';
      }
      if (count < 150) {
        return 'yellow';
      }
      if (count > 150) {
        return 'green';
      }
    },
    actions: {
      search: function search() {
        this._getCountBySelected();
      }
    }
  });
  _exports.default = _default;
});