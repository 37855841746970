define("admin/components/login-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    loginManager: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    _onSuccess: function _onSuccess() {
      var _this = this;
      this.set('unauthorized');
      if (this.session.data.authenticated.two_factor) {
        return this.router.transitionTo('two-factor');
      } else {
        return this.currentSession.load().then(function () {
          _this.success(_this.loginManager.session.data.authenticated);
        });
      }
    },
    actions: {
      authenticate: function authenticate() {
        var _this2 = this;
        if (this.email && this.password) {
          this.set('loading', true);
          this.loginManager.userLogin(this.email, this.password).then(function () {
            _this2._onSuccess();
          }).catch(function (err) {
            _this2.set('token');
            _this2.notifications.error(err && err.json && err.json.message ? err.json.message : _this2.intl.t('string.generic_error'));
          }).finally(function () {
            _this2.set('loading', false);
          });
        }
      },
      test: function test() {
        console.log('test');
      }
    }
  });
  _exports.default = _default;
});