define("admin/compiled/alfrello-profile-info/ProfileSkills", ["exports", "react", "admin/compiled/alfrello-profile-card/AlfrelloProfileCard", "admin/compiled/utils/languageContext", "react-tooltip"], function (_exports, _react, _AlfrelloProfileCard, _languageContext, _reactTooltip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SkillItem = _exports.ProfileSkills = void 0;
  var sortSkillsFunction = function sortSkillsFunction(a, b) {
    var _a, _b, _c, _d;
    if (((_a = a.profileSkill) === null || _a === void 0 ? void 0 : _a.level) < ((_b = b.profileSkill) === null || _b === void 0 ? void 0 : _b.level)) {
      return 1;
    }
    if (((_c = a.profileSkill) === null || _c === void 0 ? void 0 : _c.level) > ((_d = b.profileSkill) === null || _d === void 0 ? void 0 : _d.level)) {
      return -1;
    }
    return 0;
  };
  var SkillItem = function SkillItem(_a) {
    var _b;
    var skill = _a.skill;
    var intl = (0, _react.useContext)(_languageContext.default);
    var isBinary = skill.skillType === 'BINARY';
    var isQualified = isBinary && ((_b = skill.profileSkill) === null || _b === void 0 ? void 0 : _b.qualified);
    //@ts-ignore
    var skillCat = skill.get('subCategories.firstObject');
    var getScore = function getScore() {
      var _a;
      if (isBinary) {
        return isQualified ? intl.t('string.yes') : intl.t('string.no');
      }
      return (_a = skill.profileSkill) === null || _a === void 0 ? void 0 : _a.level;
    };
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactTooltip.default, null), _react.default.createElement("div", {
      className: "skills-skill",
      "data-tip": skillCat.name
    }, _react.default.createElement("div", {
      className: "skills-skill-content"
    }, _react.default.createElement("div", {
      className: "skills-score ".concat(isBinary ? isQualified ? 'binary selected thunder orange-thunder' : 'binary selected' : 'thunder orange-thunder')
    }, getScore()), ' ', skill.name)));
  };
  _exports.SkillItem = SkillItem;
  var ProfileSkills = function ProfileSkills(_a) {
    var skills = _a.skills,
      label = _a.label,
      showAllInitially = _a.showAllInitially;
    if (!skills || !skills.length) {
      return null;
    }
    var intl = (0, _react.useContext)(_languageContext.default);
    var _b = (0, _react.useState)(!!showAllInitially),
      showAll = _b[0],
      setShowAll = _b[1];
    var maxSkills = 10;
    var sortedSkills = skills.toArray();
    sortedSkills.sort(function (a, b) {
      return sortSkillsFunction(a, b);
    });
    return _react.default.createElement(_AlfrelloProfileCard.AlfrelloProfileCard, {
      label: label,
      className: 'profile-skills'
    }, _react.default.createElement("div", {
      className: "alfrello-profile-skills"
    }, sortedSkills.map(function (skill, index) {
      return !showAll && index >= maxSkills ? null : _react.default.createElement(SkillItem, {
        key: skill.id,
        skill: skill
      });
    })), !showAll && skills.length > maxSkills && _react.default.createElement("div", {
      className: "alfrello-profile-skills--show-more",
      onClick: function onClick() {
        return setShowAll(true);
      }
    }, intl.t('profile.profile-skills.show-all')));
  };
  _exports.ProfileSkills = ProfileSkills;
});