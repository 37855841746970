define("admin/templates/admin/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lMHhL8C+",
    "block": "{\"symbols\":[],\"statements\":[[8,\"nav-header\",[],[[\"@title\",\"@showBtn\",\"@newItem\",\"@routeLink\",\"@submenu\"],[[30,[36,0],[\"menu.messages\"],null],[34,1],[34,2],[34,3],[34,4]]],null],[2,\"\\n\\n\"],[1,[30,[36,6],[[30,[36,5],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"showBtn\",\"newItem\",\"routeLink\",\"submenu\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "admin/templates/admin/messages.hbs"
    }
  });
  _exports.default = _default;
});