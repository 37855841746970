define("admin/helpers/is-phantom", ["exports", "admin/helpers/can-helper"], function (_exports, _canHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _canHelper.default.extend({
    compute: function compute() {
      return this.isPhantom;
    }
  });
  _exports.default = _default;
});