define("admin/adapters/application", ["exports", "admin/adapters/rest-clientweb"], function (_exports, _restClientweb) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _restClientweb.default.extend({
    ajax: function ajax(url, method, hash) {
      hash = hash || {};
      hash.crossDomain = true;
      hash.xhrFields = {
        withCredentials: true
      };
      return this._super(url, method, hash);
    }
  });
  _exports.default = _default;
});