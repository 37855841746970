define("admin/adapters/rest-clientweb", ["exports", "@ember-data/adapter/rest", "admin/mixins/build-url-with-prefix", "admin/mixins/trim-model-path", "ember-local-storage", "admin/config/environment"], function (_exports, _rest, _buildUrlWithPrefix, _trimModelPath, _emberLocalStorage, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var resturl = _environment.default.REGION.resturl,
    CLIENTSPACE = _environment.default.CLIENTSPACE;
  var _default = _rest.default.extend(_buildUrlWithPrefix.default, _trimModelPath.default, {
    intl: Ember.inject.service(),
    host: resturl,
    namespace: CLIENTSPACE,
    notifications: Ember.inject.service(),
    loginManager: Ember.inject.service(),
    storage: (0, _emberLocalStorage.storageFor)('current-user'),
    session: Ember.inject.service(),
    headers: Ember.computed('storage.lang', function () {
      return {
        'Accept-Language': Ember.get(this, 'storage.lang')
      };
    }),
    ajax: function ajax(url, method, hash) {
      hash = hash || {};
      hash.xhrFields = {
        withCredentials: true
      };
      return this._super(url, method, hash);
    },
    ajaxOptions: function ajaxOptions(url, type, options) {
      var methodType = type === 'PUT' ? 'PATCH' : type === '_PUT' ? 'PUT' : type;
      return this._super(url, methodType, options);
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      if (payload && payload.errors) {
        if (!this.notifications.content.length && status !== 401) {
          this.notifications.clearAll().error(payload.errors[0] ? payload.errors[0] : this.intl.t('string.generic_error'));
        }
      }
      return this._super(status, headers, payload, requestData);
    }
  });
  _exports.default = _default;
});