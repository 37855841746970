define("admin/compiled/job-card/job-card-actions", ["exports", "@alfred-is/alfred-design-system", "classnames", "react", "admin/compiled/job-card/job-card"], function (_exports, _alfredDesignSystem, _classnames, _react, _jobCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.JobCardActions = _exports.JobCardAction = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };
  var Circle = function Circle(_a) {
    var children = _a.children,
      _b = _a.variant,
      variant = _b === void 0 ? 'default' : _b;
    var isHovered = (0, _jobCard.useJobCardContext)().isHovered;
    return _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-relative')
    }, _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-rounded-full', 'tw-w-[22px]', 'tw-h-[22px]', {
        'tw-bg-black-500': variant === 'default' && isHovered,
        'tw-bg-gray-400': variant === 'default' && !isHovered,
        'tw-bg-yellow-400': variant === 'yellow' && !isHovered,
        'tw-bg-yellow-500': variant === 'yellow' && isHovered
      })
    }), _react.default.createElement("span", {
      className: (0, _classnames.default)('tw-absolute tw-text-white tw-no-underline', 'tw-top-[50%]', 'tw-left-[50%]', 'tw-text-sm', 'tw--translate-x-1/2', 'tw--translate-y-1/2')
    }, children));
  };
  var MessageBubble = function MessageBubble(_a) {
    var count = _a.count;
    var isHovered = (0, _jobCard.useJobCardContext)().isHovered;
    return _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-relative')
    }, _react.default.createElement(_alfredDesignSystem.PlainIcon, {
      size: 24,
      icon: _alfredDesignSystem.AlfredIconT.Chat,
      color: count > 0 ? _alfredDesignSystem.ColorT.red500 : isHovered ? _alfredDesignSystem.ColorT.black : _alfredDesignSystem.ColorT.gray400
    }), _react.default.createElement("span", {
      className: (0, _classnames.default)('tw-absolute tw-text-white tw-no-underline', 'tw-top-[calc(50%-1px)]', 'tw-left-[50%]', 'tw-text-sm', 'tw--translate-x-1/2', 'tw--translate-y-1/2')
    }, count));
  };
  var JobCardActions = function JobCardActions(_a) {
    var children = _a.children;
    return _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-flex tw-items-center tw-justify-center tw-mt-4 tw-gap-4 tw-flex-wrap')
    }, children);
  };
  _exports.JobCardActions = JobCardActions;
  var JobCardAction = function JobCardAction(_a) {
    var className = _a.className,
      children = _a.children,
      _b = _a.metaType,
      metaType = _b === void 0 ? 'DEFAULT' : _b,
      metaValue = _a.metaValue,
      metaIcon = _a.metaIcon,
      _c = _a.metaIconSize,
      metaIconSize = _c === void 0 ? _alfredDesignSystem.SizeT.medium : _c,
      _d = _a.metaIconColor,
      metaIconColor = _d === void 0 ? _alfredDesignSystem.ColorT.white : _d,
      metaCircleVariant = _a.metaCircleVariant,
      rest = __rest(_a, ["className", "children", "metaType", "metaValue", "metaIcon", "metaIconSize", "metaIconColor", "metaCircleVariant"]);
    var isHovered = (0, _jobCard.useJobCardContext)().isHovered;
    return _react.default.createElement("button", __assign({
      className: (0, _classnames.default)(className, 'tw-flex tw-items-center tw-justify-center')
    }, rest), metaValue && _react.default.createElement(_react.default.Fragment, null, metaType === 'MESSAGES' && _react.default.createElement(MessageBubble, {
      count: Number(metaValue)
    }), metaType === 'DEFAULT' && _react.default.createElement(Circle, {
      variant: metaCircleVariant
    }, metaValue), metaType === 'SERVICES' && _react.default.createElement(Circle, {
      variant: metaCircleVariant
    }, metaValue)), metaType === 'ICON' && metaIcon && _react.default.createElement(Circle, {
      variant: metaCircleVariant
    }, _react.default.createElement(_alfredDesignSystem.PlainIcon, {
      icon: metaIcon,
      color: metaIconColor,
      size: metaIconSize
    })), _react.default.createElement("span", {
      className: (0, _classnames.default)('tw-text-xs tw-ml-2 hover:tw-underline', {
        'tw-text-gray-500': !isHovered,
        'tw-text-black-500': isHovered,
        'tw-ml-2': !!metaValue
      })
    }, children));
  };
  _exports.JobCardAction = JobCardAction;
});