define("admin/compiled/alfrello-column-add/AlfrelloColumnAdd", ["exports", "react", "admin/compiled/utils/useOutsideClick", "admin/compiled/utils/languageContext"], function (_exports, _react, _useOutsideClick, _languageContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AlfrelloColumnAdd = void 0;
  var __awaiter = void 0 && (void 0).__awaiter || function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P ? value : new P(function (resolve) {
        resolve(value);
      });
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }
      function rejected(value) {
        try {
          step(generator["throw"](value));
        } catch (e) {
          reject(e);
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };
  var __generator = void 0 && (void 0).__generator || function (thisArg, body) {
    var _ = {
        label: 0,
        sent: function sent() {
          if (t[0] & 1) throw t[1];
          return t[1];
        },
        trys: [],
        ops: []
      },
      f,
      y,
      t,
      g;
    return g = {
      next: verb(0),
      "throw": verb(1),
      "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
      return this;
    }), g;
    function verb(n) {
      return function (v) {
        return step([n, v]);
      };
    }
    function step(op) {
      if (f) throw new TypeError("Generator is already executing.");
      while (g && (g = 0, op[0] && (_ = 0)), _) try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];
        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;
          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };
          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;
          case 7:
            op = _.ops.pop();
            _.trys.pop();
            continue;
          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }
            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }
            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }
            if (t && _.label < t[2]) {
              _.label = t[2];
              _.ops.push(op);
              break;
            }
            if (t[2]) _.ops.pop();
            _.trys.pop();
            continue;
        }
        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
      if (op[0] & 5) throw op[1];
      return {
        value: op[0] ? op[1] : void 0,
        done: true
      };
    }
  };
  var AlfrelloColumnAdd = function AlfrelloColumnAdd(_a) {
    var persistColumn = _a.persistColumn;
    var _b = (0, _react.useState)(false),
      isAdding = _b[0],
      setIsAdding = _b[1];
    var _c = (0, _react.useState)(''),
      columnName = _c[0],
      setColumnName = _c[1];
    var _d = (0, _react.useState)(false),
      isLoading = _d[0],
      setIsLoading = _d[1];
    var inputRef = (0, _react.useRef)(null);
    var intl = (0, _react.useContext)(_languageContext.default);
    (0, _react.useEffect)(function () {
      var _a;
      if (isAdding) {
        //@ts-ignore
        (_a = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
      }
    }, [isAdding]);
    var ref = (0, _react.useRef)(null);
    var saveColumn = function saveColumn(name) {
      return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              setIsLoading(true);
              return [4 /*yield*/, persistColumn(name || intl.t('new-column.unnamed'))];
            case 1:
              _a.sent();
              setColumnName('');
              setIsAdding(false);
              setIsLoading(false);
              return [2 /*return*/];
          }
        });
      });
    };

    var dismiss = function dismiss() {
      var _a;
      //@ts-ignore
      var val = (_a = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _a === void 0 ? void 0 : _a.value;
      if (!val) {
        setIsAdding(false);
      } else {
        saveColumn(val);
      }
    };
    (0, _useOutsideClick.default)(ref, dismiss);
    return _react.default.createElement("div", {
      className: "alfrello-column-add ".concat(isAdding ? 'is-adding' : ''),
      onClick: function onClick() {
        return setIsAdding(true);
      }
    }, _react.default.createElement("div", {
      className: 'alfrello-column'
    }, _react.default.createElement("div", {
      className: "alfrello-column--header",
      ref: ref
    }, isAdding ? _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("div", {
      className: 'alfrello-column-add--input'
    }, _react.default.createElement("input", {
      ref: inputRef,
      placeholder: intl.t('new-column.placeholder'),
      type: 'text',
      value: columnName,
      maxLength: 40,
      className: isLoading ? 'is-loading' : '',
      readOnly: isLoading,
      onKeyDown: function onKeyDown(e) {
        return e.key === 'Enter' ? saveColumn(columnName) : null;
      },
      onChange: function onChange(e) {
        return setColumnName(e.target.value);
      }
    })), _react.default.createElement("div", {
      className: 'alfrello-column--header-bar'
    })) : _react.default.createElement(_react.default.Fragment, null, intl.t('new-column.add')))));
  };
  _exports.AlfrelloColumnAdd = AlfrelloColumnAdd;
});