define("admin/compiled/stats-filter/StatsFilter", ["exports", "react", "admin/compiled/ui/filter-bar/FilterBar", "admin/compiled/ui/filter-container/FilterContainer", "admin/compiled/ui/filter-dropdown/FilterDropdown", "admin/compiled/utils/emberContext", "admin/compiled/ui/filter-separator/FilterSeparator", "admin/compiled/alfrello-column/LoadingBar", "admin/compiled/ui/filter-daterange/FilterDateRange"], function (_exports, _react, _FilterBar, _FilterContainer, _FilterDropdown, _emberContext, _FilterSeparator, _LoadingBar, _FilterDateRange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.StatsFilter = void 0;
  var StatsFilter = function StatsFilter(_a) {
    var range = _a.range,
      brands = _a.brands,
      brand = _a.brand,
      setBrand = _a.setBrand,
      _setRange = _a.setRange,
      isLoading = _a.isLoading,
      rangeInfo = _a.rangeInfo;
    var intl = (0, _react.useContext)(_emberContext.default).intl;
    return _react.default.createElement(_FilterBar.FilterBar, null, _react.default.createElement("div", {
      className: 'stats-filter--hide-mobile'
    }, _react.default.createElement(_FilterContainer.FilterContainer, {
      isActive: range === 'MONTH',
      // className={'stats-filter--hide-mobile'}
      onClick: function onClick() {
        _setRange('MONTH');
      }
    }, intl.t('statistics.last_month')), _react.default.createElement(_FilterContainer.FilterContainer, {
      onClick: function onClick() {
        _setRange('YEAR');
      },
      isActive: range === 'YEAR'
    }, intl.t('statistics.last_year'))), _react.default.createElement(_FilterDateRange.FilterDateRange, {
      range: range,
      rangeInfo: rangeInfo,
      setRange: function setRange(range) {
        return _setRange(range);
      }
    }), _react.default.createElement(_FilterSeparator.FilterSeparator, null), _react.default.createElement(_FilterDropdown.FilterDropdown, {
      placeholder: intl.t('string.all_brands'),
      items: brands === null || brands === void 0 ? void 0 : brands.map(function (b) {
        return {
          name: b.name,
          val: b
        };
      }),
      selected: brand,
      onSelect: function onSelect(item) {
        setBrand(item === null || item === void 0 ? void 0 : item.val);
      }
    }), isLoading && _react.default.createElement("div", {
      className: 'stats-filter--loading'
    }, _react.default.createElement(_LoadingBar.LoadingBar, null)));
  };
  _exports.StatsFilter = StatsFilter;
});