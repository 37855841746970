define("admin/templates/components/crad/crad-select-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nSmrqhCD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"ui/icon-section\",[],[[\"@title\",\"@active\",\"@icon\"],[[34,0],[34,1],[30,[36,3],[[35,2],\"checkbox-complete\",\"checkbox-default\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"title\",\"active\",\"selected\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/crad/crad-select-box.hbs"
    }
  });
  _exports.default = _default;
});