define("admin/components/crad/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['crad-list'],
    utils: Ember.inject.service(),
    showPlus: Ember.computed('items.length', function () {
      return !this.get('items.length');
    }),
    classNameBindings: ['showPlus:show-plus'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (!this.items || !this.items.length) {
        Ember.set(this, 'items', [{
          description: '',
          selector: this.utils.randomString(8)
        }]);
      }
    },
    _newItem: function _newItem(index, value) {
      var selector = this.utils.randomString(8);
      if (index && index < this.items.length) {
        this.items.insertAt(index, {
          selector: selector,
          description: value ? value : ''
        });
      } else {
        this.items.pushObject({
          selector: selector,
          description: value ? value : ''
        });
      }
      Ember.run.next(this, function () {
        document.getElementById(selector).focus();
      });
    },
    click: function click() {
      if (!this.items || !this.items.length) {
        this._newItem();
      }
    },
    actions: {
      pasteItems: function pasteItems(index, lines) {
        lines = lines.filter(function (l) {
          return !!l;
        });
        for (var i = 0; i < lines.length; i++) {
          this._newItem(index + 1 + i, lines[i]);
        }
      },
      newItem: function newItem(index) {
        this._newItem(index + 1);
      },
      deleteItem: function deleteItem(index) {
        this.items.removeAt(index);
      }
    }
  });
  _exports.default = _default;
});