define("admin/models/ssn", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    ssn: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    firstname: (0, _model.attr)('string'),
    middlename: (0, _model.attr)('string'),
    lastname: (0, _model.attr)('string'),
    postcode: (0, _model.attr)('string'),
    location: (0, _model.attr)('string'),
    individual: (0, _model.attr)('boolean'),
    address: (0, _model.belongsTo)('address')
  });
  _exports.default = _default;
});