define("admin/components/alfrello-react/alfrello-profile-sidebar", ["exports", "admin/compiled/alfrello-profile-sidebar/AlfrelloProfileSidebar", "admin/react-component"], function (_exports, _AlfrelloProfileSidebar, _reactComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  
  */
  {
    "id": "nkaA4pxg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/components/alfrello-react/alfrello-profile-sidebar.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _reactComponent.default.extend({
    store: Ember.inject.service(),
    utils: Ember.inject.service(),
    apiManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    renderComponent: function renderComponent() {
      var _this = this;
      this.reactRender(React.createElement(_AlfrelloProfileSidebar.AlfrelloProfileSidebar, {
        initialColumnId: this.initialColumnId,
        allApplications: this.applications.map(function (a) {
          a.menuItems = _this.utils.getSidebarApplicationMenuItems(a, _this.showModal);
          a.onClick = function () {
            _this.switchActiveApplication(a);
          };
          return a;
        }),
        emberUtils: this.utils,
        showNewMessages: this.showNewMessages,
        columnActions: {
          showModal: this.showModal.bind(this),
          moveAll: this.moveAll.bind(this)
        },
        hasPurchasedVi: this.hasPurchasedVi,
        showInactiveApplications: this.showInactiveApplications,
        intl: this.intl,
        reorderApplication: this.reorderApplication.bind(this),
        activeApplication: this.activeApplication,
        filter: this.filter,
        columns: this.columns,
        jobIsHRB: this.jobIsHRB
      }));
    }
  }));
  _exports.default = _default;
});