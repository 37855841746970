define("admin/helpers/currency", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.currency = currency;
  _exports.default = void 0;
  var REGION = _environment.default.REGION;
  function currency() {
    return REGION.currency;
  }
  var _default = Ember.Helper.helper(currency);
  _exports.default = _default;
});