define("admin/components/users/npm-score-modal", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    scores: Ember.computed(function () {
      var i = 0;
      var scores = [];
      while (i <= 10) {
        scores.push({
          score: i,
          class: i <= 6 ? "red" : i > 6 && i < 9 ? "gray" : "green",
          selected: false
        });
        i++;
      }
      return scores;
    }),
    isSelected: Ember.computed('scores.@each.selected', function () {
      var _this = this;
      var selected = false;
      this.scores.forEach(function (score) {
        if (score.selected) {
          selected = true;
          _this.set('selectedScore', score);
        }
      });
      return selected;
    }),
    didInsertElement: function didInsertElement() {
      (0, _jquery.default)('body').addClass('with-modal');
    },
    didDestroyElement: function didDestroyElement() {
      (0, _jquery.default)('body').removeClass('with-modal');
    },
    actions: {
      close: function close() {
        this.toggle();
        if (this.hasSent) {
          if (this.comment) {
            this.get('currentSession.user').submitNpsComment({
              comment: this.comment
            });
          }
        } else {
          this.get('currentSession.user').submitNps({
            score: null
          });
        }
      },
      select: function select(score) {
        this.scores.forEach(function (s) {
          if (s.score == score.score) {
            Ember.set(s, 'selected', true);
          } else {
            Ember.set(s, 'selected', false);
          }
        });
      },
      submitScore: function submitScore() {
        var _this2 = this;
        var selectedScore = this.scores.filter(function (s) {
          return s.selected;
        });
        if (!selectedScore.length || selectedScore.length > 1) {
          return;
        }
        selectedScore = selectedScore[0];
        this.set('isLoading', true);
        this.get('currentSession.user').submitNps(selectedScore).finally(function () {
          _this2.set('isLoading');
          _this2.set('hasSent', true);
          setTimeout(function () {
            (0, _jquery.default)('#comment-area').focus();
          }, 500);
        });
      }
    }
  });
  _exports.default = _default;
});