define("admin/serializers/address", ["exports", "admin/serializers/rest-serializer", "admin/mixins/extract-guid"], function (_exports, _restSerializer, _extractGuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _restSerializer.default.extend(_extractGuid.default, {
    primaryKey: 'placeId'

    // serializeAttribute(snapshot, json, key) {
    //   if (snapshot.__attributes[key] && key != this.primaryKey) {
    //     json[key] = snapshot.__attributes[key];
    //   }
    // },
  });
  _exports.default = _default;
});