define("admin/routes/admin/customers/index", ["exports", "admin/mixins/model-loader"], function (_exports, _modelLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_modelLoader.default, {
    limit: 25,
    queryParams: {
      search: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var search = _ref.search;
      return this.store.query('customer', {
        search: search
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      if (model.query.search && !controller.query) {
        controller.set('query', model.query.search);
      }
      if (controller.query && !model.query.search) {
        Ember.run.next(this, function () {
          return controller.set('search', controller.query);
        });
      }
    }
  });
  _exports.default = _default;
});