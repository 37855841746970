define("admin/compiled/ui/Button", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Button = void 0;
  var Button = function Button(_a) {
    var onClick = _a.onClick,
      className = _a.className,
      disabled = _a.disabled,
      testId = _a.testId,
      color = _a.color,
      children = _a.children;
    return _react.default.createElement("div", {
      "data-testid": testId,
      className: "react-ui-button ".concat(className ? className : '', " ").concat(disabled ? 'disabled' : '', " ").concat(color ? color : 'green'),
      onClick: onClick
    }, children);
  };
  _exports.Button = Button;
});