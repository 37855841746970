define("admin/components/applications/email-notification-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.notificationTypes = [{
        notificationType: 'NEVER',
        title: this.intl.t('applications.email_notif.NEVER')
      }, {
        notificationType: 'WEEKLY',
        title: this.intl.t('applications.email_notif.WEEKLY')
      }, {
        notificationType: 'DAILY',
        title: this.intl.t('applications.email_notif.DAILY')
      }];
    },
    actions: {
      close: function close() {
        this.toggle();
      },
      setNotifications: function setNotifications(type) {
        var _this = this;
        this.set('job.notificationType', type.notificationType);
        this.job.saveEmailNotification({
          notificationType: type.notificationType
        }).then(function () {
          _this.toggle();
          _this.job.reload();
        });
      }
    }
  });
  _exports.default = _default;
});