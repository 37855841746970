define("admin/routes/client/edit-job/tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    activate: function activate() {
      this._super.apply(this, arguments);
      window.scrollTo(0, 0);
    },
    model: function model() {
      var categories = this.store.peekAll('category');
      if (!categories || !categories.length) {
        categories = this.store.query('category', {});
      }
      return Ember.RSVP.hash({
        categories: categories,
        job: this.modelFor('client.edit-job').job
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});