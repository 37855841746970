define("admin/templates/components/jobs/invoice-email-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lN3zVY63",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-dialog\",[],[[\"@overlayClass\"],[\"modal-popup invoice-email-modal\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"default-dialog default text-center\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[1,[30,[36,0],[\"components.jobs.invoice_email_modal.title\"],null]],[13],[2,\"\\n\\n    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"components.jobs.invoice_email_modal.p\"],null]],[13],[2,\"\\n\\n    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"components.jobs.invoice_email_modal.p_two\"],null]],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"edit-form\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n        \"],[8,\"forms/input-field\",[],[[\"@value\",\"@length\",\"@label\"],[[34,1],128,[30,[36,0],[\"string.email\"],null]]],null],[2,\"\\n\\n        \"],[10,\"p\"],[12],[1,[30,[36,0],[\"components.jobs.invoice_email_modal.p_three\"],null]],[13],[2,\"\\n\\n        \"],[11,\"button\"],[16,0,[31,[\"btn green inline upper \",[30,[36,3],[[35,2],\"disabled\"],null]]]],[4,[38,4],[[32,0],\"confirm\"],null],[12],[1,[30,[36,0],[\"components.jobs.invoice_email_modal.confirm\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"email\",\"isValid\",\"unless\",\"action\"]}",
    "meta": {
      "moduleName": "admin/templates/components/jobs/invoice-email-modal.hbs"
    }
  });
  _exports.default = _default;
});