define("admin/components/settings/custom-question-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['custom-question-form'],
    isValid: Ember.computed('question.{question,type.id,options.@each.option}', function () {
      if (this.get('question.question') && this.get('question.type.id')) {
        if (this.get('question.type.id') === '3' || this.get('question.type.id') === '5') {
          return this.get('question.options.length') >= 2 && this.get('question.options').every(function (o) {
            return o.option.trim();
          });
        }
        return true;
      }
      return false;
    }),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      this.store.query('questiontype', {}).then(function (res) {
        _this.set('questionTypes', res);
      });
      if (!this.question) {
        var question = this.store.createRecord('question');
        this.set('question', question);
      }
    },
    close: function close() {
      if (this.onClose) {
        this.onClose();
      } else if (this.returnRoute) {
        this.router.transitionTo(this.returnRoute, {
          queryParams: {
            reload: true
          }
        });
      }
    },
    actions: {
      closeModal: function closeModal() {
        this.close();
      },
      selectType: function selectType(id) {
        if (!this.question.id) {
          var questiontype = this.questionTypes.findBy('id', id);
          this.question.set('type', questiontype);
        }
      },
      save: function save(question) {
        var _this2 = this;
        this.set('loading', true);
        question.save().then(function (res) {
          if (_this2.job && _this2.job.questions) {
            if (_this2.job.questions.length < 6) {
              _this2.job.questions.pushObject(res);
            }
            _this2.questions.pushObject(res);
          }
          _this2.close();
        }).finally(function () {
          return _this2.set('loading');
        });
      }
    }
  });
  _exports.default = _default;
});