define("admin/components/applications/email-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['email-notification']
  });
  _exports.default = _default;
});