define("admin/adapters/message-template", ["exports", "admin/adapters/rest-clientweb", "admin/config/environment"], function (_exports, _restClientweb, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CLIENTSPACE = _environment.default.CLIENTSPACE;
  var _default = _restClientweb.default.extend({
    namespace: "".concat(CLIENTSPACE, "/clients")
  });
  _exports.default = _default;
});