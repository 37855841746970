define("admin/models/language", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    code: (0, _model.attr)('string'),
    flagUrl: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    ordinal: (0, _model.attr)('number'),
    ordinalpopular: (0, _model.attr)('number'),
    popular: (0, _model.attr)('boolean')
  });
  _exports.default = _default;
});