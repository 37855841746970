define("admin/compiled/schemas", ["exports", "zod"], function (_exports, _zod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.spotlightFormSchema = _exports.brandSchema = void 0;
  var fileSchema = _zod.default.object({
    name: _zod.default.string(),
    value: _zod.default.string()
  });
  var brandSchema = _zod.default.object({
    id: _zod.default.number().optional(),
    name: _zod.default.string(),
    description: _zod.default.string().optional(),
    logo: _zod.default.string().optional(),
    web: _zod.default.string().optional(),
    body: _zod.default.string().optional(),
    facebook: _zod.default.string().optional(),
    twitter: _zod.default.string().optional(),
    instagram: _zod.default.string().optional(),
    linkedin: _zod.default.string().optional(),
    cover: _zod.default.string().optional(),
    slug: _zod.default.string().optional(),
    generalApplicationJob: _zod.default.string().optional(),
    jobCount: _zod.default.number().optional()
  });
  _exports.brandSchema = brandSchema;
  var spotlightFormSchema = _zod.default.object({
    image: fileSchema,
    brand: _zod.default.number()
  });
  _exports.spotlightFormSchema = spotlightFormSchema;
});