define("admin/adapters/autotag-jobtag", ["exports", "admin/adapters/rest-superadmin", "admin/mixins/prefix-model-path", "admin/config/environment"], function (_exports, _restSuperadmin, _prefixModelPath, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var locale = _environment.default.REGION.locale;
  var _default = _restSuperadmin.default.extend(_prefixModelPath.default, {
    headers: Ember.computed('locale', function () {
      return {
        'Accept-Language': locale
      };
    })
  });
  _exports.default = _default;
});