define("admin/templates/components/settings/english-viquestions-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "BCwOXoJQ",
    "block": "{\"symbols\":[\"question\"],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@overlayClass\"],[[30,[36,1],[[32,0],\"close\"],null],\"modal-popup english-modal\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"default-dialog wide\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[1,[30,[36,2],[\"components.settings.english_viquestions_modal.title\"],null]],[13],[2,\"\\n\\n    \"],[10,\"br\"],[12],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"edit-form\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[30,[36,4],[\"weight\",[35,3,[\"viquestions\"]]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,1,[\"question\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"forms/input-field\",[],[[\"@value\",\"@length\",\"@label\"],[[32,1,[\"enquestion\"]],100,[32,1,[\"question\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n      \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn gray-brd inline\"],[4,[38,1],[[32,0],\"close\"],null],[12],[1,[30,[36,2],[\"string.cancel\"],null]],[13],[2,\"\\n        \"],[10,\"span\"],[15,0,[30,[36,8],[[35,7],\"disabled\"],null]],[12],[2,\"\\n          \"],[11,\"button\"],[24,0,\"btn green inline\"],[4,[38,1],[[32,0],\"save\"],null],[12],[1,[30,[36,2],[\"string.continue\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"t\",\"interview\",\"sort-by\",\"-track-array\",\"each\",\"valid\",\"unless\"]}",
    "meta": {
      "moduleName": "admin/templates/components/settings/english-viquestions-modal.hbs"
    }
  });
  _exports.default = _default;
});