define("admin/templates/components/alfrello/alfrello-upsell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "TL3GyiBi",
    "block": "{\"symbols\":[],\"statements\":[[8,\"alfred-modal\",[],[[\"@alfred\",\"@toggle\"],[true,[30,[36,0],[[32,0],\"dismiss\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"title\"],[12],[1,[34,1]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"alfrello-upsell--image\"],[12],[2,\"\\n    \"],[10,\"img\"],[15,\"src\",[31,[[34,2]]]],[14,\"alt\",\"\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"alfrello-upsell--body\"],[12],[2,\"\\n    \"],[1,[34,3]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn\"],[4,[38,0],[[32,0],\"dismiss\"],null],[12],[1,[30,[36,4],[\"string.close\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"title\",\"icon\",\"description\",\"t\"]}",
    "meta": {
      "moduleName": "admin/templates/components/alfrello/alfrello-upsell.hbs"
    }
  });
  _exports.default = _default;
});