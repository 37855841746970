define("admin/compiled/ui/empty-screen/EmptyScreen", ["exports", "@alfred-is/alfred-design-system", "react"], function (_exports, _alfredDesignSystem, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EmptyScreen = void 0;
  var EmptyScreen = function EmptyScreen(_a) {
    var title = _a.title,
      content = _a.content,
      action = _a.action;
    return _react.default.createElement("div", {
      className: 'ui-empty-screen'
    }, _react.default.createElement("div", {
      className: 'ui-empty-screen--logo'
    }, _react.default.createElement("img", {
      src: '/img/alfred-icon.svg',
      alt: ''
    })), _react.default.createElement("div", {
      className: 'ui-empty-screen--title'
    }, title), _react.default.createElement("div", {
      className: 'ui-empty-screen--content',
      dangerouslySetInnerHTML: {
        __html: content
      }
    }), action && _react.default.createElement("div", {
      className: 'ui-empty-screen--action'
    }, _react.default.createElement(_alfredDesignSystem.StandardButton, {
      onClick: action.onClick,
      variant: 'orange',
      leadingIcon: _alfredDesignSystem.AlfredIconT.Plus,
      size: _alfredDesignSystem.SizeT.medium,
      className: 'tw-rounded-sm'
    }, action.title)));
  };
  _exports.EmptyScreen = EmptyScreen;
});