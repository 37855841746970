define("admin/controllers/admin/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    showBtn: true,
    submenu: 'messages',
    intl: Ember.inject.service(),
    setHeader: function setHeader() {
      this.set('newItem', this.intl.t('admin.messages.new_message'));
      this.set('routeLink', 'admin.messages.new');
    },
    setHeaderForUsers: function setHeaderForUsers(routeName) {
      this.set('newItem', this.intl.t('admin.messages.page_employees.new_employee'));
      this.set('routeLink', routeName + '.new');
    }
  });
  _exports.default = _default;
});