define("admin/compiled/alfrello-card/AlfrelloCardDotMenu", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AlfrelloCardDotMenu = void 0;
  var AlfrelloCardDotMenu = function AlfrelloCardDotMenu(_a) {
    var items = _a.items,
      visible = _a.visible,
      toggleVisible = _a.toggleVisible;
    if (!items.length) {
      return null;
    }
    var click = function click(e, item) {
      e.stopPropagation();
      toggleVisible();
      item.onClick();
    };
    return _react.default.createElement("div", {
      className: 'alfrello-card-dot-menu'
    }, _react.default.createElement("div", {
      className: 'three-dots',
      onClick: function onClick(e) {
        e.stopPropagation();
        toggleVisible();
      }
    }), visible && _react.default.createElement("div", {
      className: "dot-menu ".concat(items.length === 1 ? 'single-item' : '')
    }, items.map(function (item) {
      return _react.default.createElement("li", {
        key: item.name,
        onClick: function onClick(e) {
          return click(e, item);
        }
      }, _react.default.createElement("span", {
        className: "icon ".concat(item.icon)
      }), " ", item.name);
    })));
  };
  _exports.AlfrelloCardDotMenu = AlfrelloCardDotMenu;
});