define("admin/compiled/redux/reducers/modal", ["exports", "admin/compiled/types"], function (_exports, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.modalReducer = void 0;
  var initialState = {
    modalType: null,
    modalProps: {}
  };
  var modalReducer = function modalReducer(state, action) {
    if (state === void 0) {
      state = initialState;
    }
    switch (action.type) {
      case _types.ModalActionType.SHOW_MODAL:
        return {
          modalType: action.modalType,
          modalProps: action.modalProps
        };
      case _types.ModalActionType.HIDE_MODAL:
        return initialState;
      default:
        return state;
    }
  };
  _exports.modalReducer = modalReducer;
});