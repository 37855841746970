define("admin/routes/client/jobs", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    apiClient: Ember.inject.service(),
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      (0, _jquery.default)('body').removeClass('with-modal');
      controller.set('loading', true);
      this.apiClient.get('/brands?status=ACTIVE&skipaddresses=true').then(function (res) {
        controller.set('loading');
        controller.set('hasBrands', res.brands && res.brands.length);
      });
    }
  });
  _exports.default = _default;
});