define("admin/controllers/client/settings", ["exports", "ember-local-storage", "admin/config/environment"], function (_exports, _emberLocalStorage, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var version = _environment.default.APP.version;
  var _default = Ember.Controller.extend({
    storage: (0, _emberLocalStorage.storageFor)('current-user'),
    currentVersion: version.match(/\d+[.]\d+[.]\d+/),
    isPhantom: Ember.computed.alias('currentSession.user.phantom')
  });
  _exports.default = _default;
});