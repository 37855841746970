define("admin/templates/components/jobs/job-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "cN7n4PAI",
    "block": "{\"symbols\":[],\"statements\":[[11,\"a\"],[16,0,[30,[36,3],[[30,[36,2],[[35,1],[35,0]],null],\"active\"],null]],[4,[38,4],[[32,0],\"selectTag\"],[[\"on\"],[\"click\"]]],[12],[1,[35,1,[\"name\"]]],[2,\" \"],[10,\"span\"],[14,0,\"gray\"],[12],[2,\"(\"],[1,[35,1,[\"count\"]]],[2,\")\"],[13],[13]],\"hasEval\":false,\"upvars\":[\"jobtags\",\"jobtag\",\"contains\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "admin/templates/components/jobs/job-tag.hbs"
    }
  });
  _exports.default = _default;
});