define("admin/compiled/modal/CreateGeneralApplicationModal", ["exports", "react", "admin/compiled/modal/AlfredModal", "admin/compiled/utils/emberContext", "admin/compiled/ga-select-plan/GaSelectPlan", "admin/compiled/utils", "admin/compiled/ui/Spinner", "admin/compiled/ui/Button", "admin/compiled/redux/actions/modal", "react-redux"], function (_exports, _react, _AlfredModal, _emberContext, _GaSelectPlan, _utils, _Spinner, _Button, _modal, _reactRedux) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CreateGeneralApplicationModal = void 0;
  var __awaiter = void 0 && (void 0).__awaiter || function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P ? value : new P(function (resolve) {
        resolve(value);
      });
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }
      function rejected(value) {
        try {
          step(generator["throw"](value));
        } catch (e) {
          reject(e);
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };
  var __generator = void 0 && (void 0).__generator || function (thisArg, body) {
    var _ = {
        label: 0,
        sent: function sent() {
          if (t[0] & 1) throw t[1];
          return t[1];
        },
        trys: [],
        ops: []
      },
      f,
      y,
      t,
      g;
    return g = {
      next: verb(0),
      "throw": verb(1),
      "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
      return this;
    }), g;
    function verb(n) {
      return function (v) {
        return step([n, v]);
      };
    }
    function step(op) {
      if (f) throw new TypeError("Generator is already executing.");
      while (g && (g = 0, op[0] && (_ = 0)), _) try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];
        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;
          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };
          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;
          case 7:
            op = _.ops.pop();
            _.trys.pop();
            continue;
          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }
            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }
            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }
            if (t && _.label < t[2]) {
              _.label = t[2];
              _.ops.push(op);
              break;
            }
            if (t[2]) _.ops.pop();
            _.trys.pop();
            continue;
        }
        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
      if (op[0] & 5) throw op[1];
      return {
        value: op[0] ? op[1] : void 0,
        done: true
      };
    }
  };
  var CreateGeneralApplicationModal = function CreateGeneralApplicationModal(_a) {
    var brand = _a.brand;
    var _b = (0, _react.useContext)(_emberContext.default),
      intl = _b.intl,
      apiClient = _b.apiClient,
      config = _b.config,
      transitionTo = _b.transitionTo,
      gtm = _b.gtm;
    var _c = (0, _react.useState)([]),
      plans = _c[0],
      setPlans = _c[1];
    var _d = (0, _react.useState)(false),
      isLoading = _d[0],
      setIsLoading = _d[1];
    var _e = config.REGION,
      thousand = _e.thousand,
      symbol = _e.symbol;
    (0, _react.useEffect)(function () {
      setIsLoading(true);
      apiClient.get('/products?productkey=JOB_GA').then(function (data) {
        var product = data.product;
        var annualPrice = product.offerPrice * 12;
        var regularPrice = product.price;
        var discount = 100 - annualPrice / (regularPrice * 12) * 100;
        var plans = [{
          type: 'MONTHLY',
          title: intl.t('general-application.monthly-payment'),
          price: product.price
        }, {
          type: 'ANNUALLY',
          title: intl.t('general-application.annual-payment'),
          price: annualPrice,
          subtitle: "(".concat((0, _utils.formatNumber)(annualPrice / 12, thousand), " ").concat(symbol, " / ").concat(intl.t('price.per-month'), ")"),
          discount: discount
        }];
        setPlans(plans);
      }).finally(function () {
        return setIsLoading(false);
      });
    }, []);
    var _f = (0, _react.useState)(),
      selectedPlan = _f[0],
      _setSelectedPlan = _f[1];
    var _g = (0, _react.useState)(false),
      isPurchasing = _g[0],
      setIsPurchasing = _g[1];
    var dispatch = (0, _reactRedux.useDispatch)();
    var purchase = function purchase() {
      return __awaiter(void 0, void 0, void 0, function () {
        var jobData;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              if (!selectedPlan) {
                return [2 /*return*/];
              }

              setIsPurchasing(true);
              return [4 /*yield*/, brand.purchaseGa({
                subscriptionType: selectedPlan === null || selectedPlan === void 0 ? void 0 : selectedPlan.type
              })];
            case 1:
              jobData = _a.sent();
              gtm.purchaseGeneralApplication(selectedPlan === null || selectedPlan === void 0 ? void 0 : selectedPlan.type);
              return [4 /*yield*/, transitionTo('client.create-job.edit', jobData.job.id)];
            case 2:
              _a.sent();
              dispatch((0, _modal.hideModal)());
              return [2 /*return*/];
          }
        });
      });
    };

    return _react.default.createElement(_AlfredModal.AlfredModal, {
      title: intl.t('general-application.title')
    }, _react.default.createElement("div", {
      style: {
        textAlign: 'center'
      }
    }, intl.t('general-application-create-description')), _react.default.createElement("div", {
      className: 'create-general-application-modal--select-plan'
    }, !isLoading ? _react.default.createElement(_GaSelectPlan.GaSelectPlan, {
      setSelectedPlan: function setSelectedPlan(plan) {
        return _setSelectedPlan(plan);
      },
      plans: plans,
      selectedPlan: selectedPlan
    }) : _react.default.createElement(_Spinner.Spinner, null)), _react.default.createElement("div", {
      style: {
        textAlign: 'center'
      },
      className: 'create-general-application-modal--actions'
    }, _react.default.createElement(_Button.Button, {
      testId: 'purchase-button',
      disabled: !selectedPlan || isPurchasing,
      onClick: function onClick() {
        return purchase();
      }
    }, !isLoading && !isPurchasing ? intl.t('components.upsell.purchase') : intl.t('general.loading')), _react.default.createElement("div", {
      className: 'create-general-application-modal--price-disclaimer'
    }, intl.t('components.upsell.purchase_disclaimer'))));
  };
  _exports.CreateGeneralApplicationModal = CreateGeneralApplicationModal;
});