define("admin/templates/components/crad/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gR3HklNu",
    "block": "{\"symbols\":[\"item\",\"index\",\"@placeholder\",\"@title\",\"@tooltip\"],\"statements\":[[8,\"ui/icon-section\",[],[[\"@title\",\"@tooltip\",\"@icon\",\"@data-test\"],[[32,4],[32,5],\"crad-menu\",\"crad-list\"]],null],[2,\"\\n\"],[6,[37,4],[[35,1,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"crad-list-items\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"crad/list-item\",[],[[\"@item\",\"@index\",\"@placeholder\",\"@deleteItem\",\"@pasteItems\",\"@newItem\",\"@data-test\"],[[32,1],[32,2],[32,3],[30,[36,0],[[32,0],\"deleteItem\"],null],[30,[36,0],[[32,0],\"pasteItems\"],null],[30,[36,0],[[32,0],\"newItem\",[32,2]],null],\"crad-list-item\"]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"items\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/crad/list.hbs"
    }
  });
  _exports.default = _default;
});