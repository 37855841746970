define("admin/compiled/spotlight/spotlight-list-item/spotlight-list-item", ["exports", "@alfred-is/alfred-design-system", "classnames", "react"], function (_exports, _alfredDesignSystem, _classnames, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SpotlightListItemVariant = _exports.SpotlightListItem = void 0;
  var SpotlightListItemVariant;
  _exports.SpotlightListItemVariant = SpotlightListItemVariant;
  (function (SpotlightListItemVariant) {
    SpotlightListItemVariant["PRIMARY"] = "primary";
    SpotlightListItemVariant["GREEN"] = "green";
  })(SpotlightListItemVariant || (_exports.SpotlightListItemVariant = SpotlightListItemVariant = {}));
  var SpotlightListItem = function SpotlightListItem(_a) {
    var _b, _c;
    var status = _a.status,
      img = _a.img,
      _d = _a.variant,
      variant = _d === void 0 ? SpotlightListItemVariant.PRIMARY : _d,
      name = _a.name,
      items = _a.items;
    return _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-p-6 tw-border tw-rounded-2xl tw-flex tw-bg-white', (_b = {}, _b['tw-border-gray-200'] = variant === SpotlightListItemVariant.PRIMARY, _b['tw-border-green-500'] = variant === SpotlightListItemVariant.GREEN, _b))
    }, _react.default.createElement("div", {
      className: "tw-flex-grow tw-flex tw-gap-6 tw-items-center"
    }, status && _react.default.createElement("span", {
      className: (0, _classnames.default)('tw-font-medium tw-hidden sm:tw-inline-block', (_c = {}, _c['tw-text-green-500'] = variant === SpotlightListItemVariant.GREEN, _c['tw-text-gray-500'] = variant === SpotlightListItemVariant.PRIMARY, _c))
    }, status), img && _react.default.createElement("img", {
      className: "tw-h-10 tw-border tw-hidden sm:tw-block",
      alt: '',
      src: img
    }), _react.default.createElement("span", null, name)), _react.default.createElement("div", {
      className: "tw-flex tw-gap-4 tw-items-center tw-flex-shrink-0"
    }, items.map(function (item, i) {
      var _a, _b;
      return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("div", {
        key: i,
        className: (0, _classnames.default)(' md:tw-block', (_a = {}, _a['tw-hidden'] = !item.showInMobile, _a))
      }, item.icon ? _react.default.createElement(_alfredDesignSystem.TextWithIcon, {
        icon: item.icon
      }, item.text) : item.text), i !== (items === null || items === void 0 ? void 0 : items.length) - 1 && _react.default.createElement("span", {
        className: (0, _classnames.default)('tw-w-2 tw-h-2 tw-rounded-full tw-hidden md:tw-block', (_b = {}, _b['tw-bg-green-500'] = variant === SpotlightListItemVariant.GREEN, _b['tw-bg-gray-300'] = variant === SpotlightListItemVariant.PRIMARY, _b))
      }));
    })));
  };
  _exports.SpotlightListItem = SpotlightListItem;
  var _default = SpotlightListItem;
  _exports.default = _default;
});