define("admin/models/system-user", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    image: (0, _model.attr)('string'),
    active: (0, _model.attr)('boolean'),
    thumb: Ember.computed('image', function () {
      if (this.image) {
        return Ember.String.htmlSafe("background-image: url('" + this.image + "')");
      }
    })
  });
  _exports.default = _default;
});