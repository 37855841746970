define("admin/templates/components/video-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "WOgkcsk6",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"video-controller\"],[12],[2,\"\\n    \"],[8,\"videojs-player\",[],[[\"@src\",\"@type\",\"@playing\",\"@ended\",\"@pause\",\"@controls\",\"@fluid\"],[[34,0],\"video/mp4\",[30,[36,1],[[32,0],\"videoIsPlaying\"],null],[30,[36,1],[[32,0],\"ended\"],null],[30,[36,1],[[32,0],\"pause\"],null],true,true]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"src\",\"action\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/video-controller.hbs"
    }
  });
  _exports.default = _default;
});