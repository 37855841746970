define("admin/compiled/utils/useOutsideClick", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var useOutsideClick = function useOutsideClick(ref, callback, initialFocus) {
    var handleClick = function handleClick(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };
    (0, _react.useEffect)(function () {
      if (ref.current && initialFocus) {
        ref.current.click();
      }
      document.addEventListener('click', handleClick);
      return function () {
        document.removeEventListener('click', handleClick);
      };
      // eslint-disable-next-line
    }, []);
  };
  var _default = useOutsideClick;
  _exports.default = _default;
});