define("admin/compiled/alfrello-profile-nav/AlfrelloProfileNav", ["exports", "react", "admin/compiled/alfrello-profile-header/ColumnSwitcher"], function (_exports, _react, _ColumnSwitcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AlfrelloProfileNav = void 0;
  var __spreadArray = void 0 && (void 0).__spreadArray || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
      if (ar || !(i in from)) {
        if (!ar) ar = Array.prototype.slice.call(from, 0, i);
        ar[i] = from[i];
      }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
  };
  var RejectApplication = function RejectApplication(_a) {
    var onReject = _a.onReject,
      isRejected = _a.isRejected,
      children = _a.children;
    return _react.default.createElement("div", {
      className: "alfrello-profile-nav--action alfrello-profile-nav--reject ".concat(isRejected ? 'rejected' : ''),
      onClick: function onClick() {
        if (isRejected) {
          return;
        }
        onReject();
      }
    }, children);
  };
  var DownloadPDF = function DownloadPDF(_a) {
    var onClick = _a.onClick,
      children = _a.children;
    return _react.default.createElement("div", {
      className: 'alfrello-profile-nav--action alfrello-profile-nav--pdf',
      onClick: onClick
    }, children);
  };
  var AlfrelloProfileNav = function AlfrelloProfileNav(_a) {
    var context = _a.context,
      changeContext = _a.changeContext,
      column = _a.column,
      columns = _a.columns,
      unread = _a.unread,
      intl = _a.intl,
      moveToColumn = _a.moveToColumn,
      showModal = _a.showModal,
      application = _a.application,
      apiClientV2 = _a.apiClientV2,
      job = _a.job;
    var items = __spreadArray(__spreadArray([], job.isHRB ? [] : [{
      name: intl.t('menu.application'),
      context: 'PROFILE'
    }, {
      name: intl.t('menu.notes'),
      context: 'NOTES'
    }, {
      name: _react.default.createElement(_react.default.Fragment, null, intl.t('menu.messaging'), _react.default.createElement("span", {
        className: 'alfrello-profile-nav--badge'
      }, unread)),
      context: 'MESSAGES'
    }, {
      name: intl.t('menu.video_interview'),
      context: 'VIDEOINTERVIEW'
    }, {
      name: intl.t('applications.alfrello.application_profile.compass'),
      context: 'COMPASS'
    }], true), [{
      name: intl.t('profile.hire.title'),
      context: 'HIRE_PACKAGE'
    }], false);
    var _b = (0, _react.useState)(false),
      downloadingPdf = _b[0],
      setDownloadingPdf = _b[1];
    var downloadPdf = function downloadPdf() {
      setDownloadingPdf(true);
      apiClientV2._plainFetch({
        url: "/jobs/".concat(job.id, "/applications/").concat(application.id, "/pdf")
      }).then(function (res) {
        return res.blob();
      }).then(function (blob) {
        var fileURL = URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = fileURL;
        link.download = "CV-".concat(application.get('profile.name'), ".pdf");
        link.click();
      }).finally(function () {
        return setDownloadingPdf(false);
      });
    };
    return _react.default.createElement("div", {
      className: 'alfrello-profile-nav'
    }, _react.default.createElement("div", {
      className: 'alfrello-profile-nav--items'
    }, items.map(function (item) {
      return _react.default.createElement("div", {
        key: item.context,
        className: "alfrello-profile-nav--item ".concat(item.context === context ? 'active' : ''),
        style: {
          borderColor: column.color
        },
        onClick: function onClick() {
          return changeContext(item.context);
        }
      }, item.name);
    })), _react.default.createElement("div", {
      className: 'alfrello-profile-nav--actions'
    }, !job.isHRB && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(RejectApplication, {
      isRejected: application.status === 'REJECTED',
      onReject: function onReject() {
        return showModal('rejectAllModal', {
          applicationIds: [application.id],
          rejectSingle: true,
          name: application.get('profile.name')
        });
      }
    }, application.status === 'REJECTED' && intl.t('application.rejected'), application.status !== 'REJECTED' && intl.t('alfrello.reject-single')), _react.default.createElement("div", {
      className: 'alfrello-profile-nav--separator'
    }), _react.default.createElement(DownloadPDF, {
      onClick: downloadPdf
    }, downloadingPdf ? intl.t('general.loading') : intl.t('client.application.profile.pdf')), _react.default.createElement("div", {
      className: 'alfrello-profile-nav--separator'
    })), _react.default.createElement(_ColumnSwitcher.ColumnSwitcher, {
      currentColumn: column,
      columns: columns,
      switchColumn: moveToColumn
    })));
  };
  _exports.AlfrelloProfileNav = AlfrelloProfileNav;
});