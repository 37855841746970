define("admin/components/invoice-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    invoice: Ember.computed.alias('currentSession.client.invoice')
  });
  _exports.default = _default;
});