define("admin/compiled/header-button/header-button", ["exports", "@alfred-is/alfred-design-system", "classnames", "react"], function (_exports, _alfredDesignSystem, _classnames, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.HeaderButtonVariant = _exports.HeaderButton = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };
  // import s from './header-button.module.css';
  var HeaderButtonVariant;
  _exports.HeaderButtonVariant = HeaderButtonVariant;
  (function (HeaderButtonVariant) {
    HeaderButtonVariant["PRIMARY"] = "primary";
    HeaderButtonVariant["DARK_BLUE"] = "dark-blue";
  })(HeaderButtonVariant || (_exports.HeaderButtonVariant = HeaderButtonVariant = {}));
  var HeaderButton = function HeaderButton(_a) {
    var _b;
    var _c = _a.variant,
      variant = _c === void 0 ? HeaderButtonVariant.PRIMARY : _c,
      children = _a.children,
      label = _a.label,
      icon = _a.icon,
      rest = __rest(_a, ["variant", "children", "label", "icon"]);
    var iconColor = (_b = {}, _b[HeaderButtonVariant.PRIMARY] = _alfredDesignSystem.ColorT.white, _b[HeaderButtonVariant.DARK_BLUE] = _alfredDesignSystem.ColorT.orange, _b);
    return _react.default.createElement("button", __assign({
      className: (0, _classnames.default)('tw-px-6 tw-py-2 tw-inline-flex tw-items-center tw-flex-shrink-0 tw-rounded-sm', 'tw-gap-2 tw-relative tw-min-h-[48px] tw-whitespace-nowrap', {
        'tw-bg-orange-500 tw-text-white hover:tw-bg-orange-400': variant === HeaderButtonVariant.PRIMARY,
        'tw-bg-white tw-text-orange-500 hover:tw-text-orange-400 tw-border-2 tw-border-orange-500': variant === HeaderButtonVariant.DARK_BLUE
      })
    }, rest), icon && _react.default.createElement(_alfredDesignSystem.PlainIcon, {
      icon: icon,
      color: iconColor[variant]
    }), label && _react.default.createElement("div", {
      className: "tw-absolute tw-top-0 tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2 tw-leading-4"
    }, _react.default.createElement(_alfredDesignSystem.SimpleLabel, {
      variant: label.variant
    }, label.text)), children);
  };
  _exports.HeaderButton = HeaderButton;
  var _default = HeaderButton;
  _exports.default = _default;
});