define("admin/models/imports/job", ["exports", "@ember-data/model", "ember-api-actions", "moment"], function (_exports, _model, _emberApiActions, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    useAlfrello: (0, _model.attr)('boolean'),
    applicationClassification: (0, _model.attr)(),
    accesslevel: (0, _model.attr)('string'),
    appcount: (0, _model.attr)('number'),
    applicationsExpired: (0, _model.attr)('boolean'),
    applied: (0, _model.attr)('number'),
    applyemail: (0, _model.attr)('string'),
    applyweb: (0, _model.attr)('string'),
    bodyhtml: (0, _model.attr)('string'),
    brandLogo: (0, _model.attr)('string'),
    brandName: (0, _model.attr)('string'),
    city: (0, _model.attr)('string'),
    created: (0, _model.attr)('moment'),
    deadline: (0, _model.attr)('moment'),
    displaydate: (0, _model.attr)('string'),
    invoiced: (0, _model.attr)('boolean'),
    invoiceline: (0, _model.attr)('boolean'),
    nodeadline: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    notificationType: (0, _model.attr)('string'),
    notifynewapplications: (0, _model.attr)('boolean'),
    oldclickcount: (0, _model.attr)('number'),
    paid: (0, _model.attr)('boolean'),
    price: (0, _model.attr)('number'),
    pushcreated: (0, _model.attr)('boolean'),
    slug: (0, _model.attr)('string'),
    startdate: (0, _model.attr)('moment'),
    title: (0, _model.attr)('string'),
    updated: (0, _model.attr)('string'),
    username: (0, _model.attr)('string'),
    videoInterviewCount: (0, _model.attr)('number'),
    videoInterviewPurchased: (0, _model.attr)('boolean'),
    webcount: (0, _model.attr)('number'),
    zip: (0, _model.attr)('string'),
    verificationRequired: (0, _model.attr)('boolean'),
    responsibilities: (0, _model.attr)(),
    benefits: (0, _model.attr)(),
    qualifications: (0, _model.attr)(),
    compensations: (0, _model.attr)(),
    subCategories: (0, _model.attr)(),
    footer: (0, _model.attr)('string'),
    subscriptionType: (0, _model.attr)('string'),
    scheduled: (0, _model.attr)('boolean'),
    watchers: (0, _model.attr)('number'),
    opened: (0, _model.attr)('number'),
    messages: (0, _model.attr)('number'),
    vipurchased: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    revealratings: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    applicationsexpired: (0, _model.attr)('boolean'),
    jobTemplate: (0, _model.belongsTo)('job-template'),
    overtimeHours: (0, _model.attr)('number'),
    percentage: (0, _model.attr)('number'),
    entitle: (0, _model.attr)('string'),
    enbody: (0, _model.attr)('string'),
    enbodyhtml: (0, _model.attr)('string'),
    generalApplication: (0, _model.attr)('boolean'),
    address: (0, _model.belongsTo)('address'),
    brand: (0, _model.belongsTo)('brand'),
    adtype: (0, _model.belongsTo)('adtype'),
    jobstatus: (0, _model.belongsTo)('jobstatus'),
    jobtype: (0, _model.belongsTo)('jobtype'),
    division: (0, _model.attr)(),
    status: (0, _model.attr)(),
    user: (0, _model.belongsTo)('user'),
    tags: (0, _model.hasMany)('jobtag'),
    questions: (0, _model.hasMany)('question'),
    productPurchases: (0, _model.hasMany)('productpurchase'),
    skillIds: (0, _model.hasMany)('skill'),
    skills: (0, _model.hasMany)('skill'),
    languages: (0, _model.attr)('array', {
      defaultValue: function defaultValue() {
        return Ember.A();
      }
    }),
    stats: (0, _emberApiActions.memberAction)({
      path: 'stats',
      type: 'get'
    }),
    boost: (0, _emberApiActions.memberAction)({
      path: 'boost',
      type: 'post'
    }),
    columns: (0, _emberApiActions.memberAction)({
      path: 'columns',
      type: 'get'
    }),
    newColumn: (0, _emberApiActions.memberAction)({
      path: 'columns',
      type: 'post'
    }),
    showRatings: (0, _emberApiActions.memberAction)({
      path: 'revealratings',
      type: '_put'
    }),
    hideRatings: (0, _emberApiActions.memberAction)({
      path: 'revealratings',
      type: 'delete'
    }),
    close: (0, _emberApiActions.memberAction)({
      path: 'close',
      type: 'post'
    }),
    ended: (0, _emberApiActions.memberAction)({
      path: 'end',
      type: 'post'
    }),
    emailNotification: (0, _emberApiActions.memberAction)({
      path: 'emailnotifications',
      type: 'get'
    }),
    saveEmailNotification: (0, _emberApiActions.memberAction)({
      path: 'emailnotifications',
      type: 'patch'
    }),
    getProducts: (0, _emberApiActions.memberAction)({
      path: 'products',
      type: 'get'
    }),
    getProductVI: (0, _emberApiActions.memberAction)({
      path: 'products/VIDEOINTERVIEWS',
      type: 'get'
    }),
    purchase: (0, _emberApiActions.memberAction)({
      path: 'purchase',
      type: 'post'
    }),
    publish: (0, _emberApiActions.memberAction)({
      path: 'publish',
      type: 'patch'
    }),
    inviteVideointerview: (0, _emberApiActions.memberAction)({
      path: 'invitevideointerview',
      type: 'patch'
    }),
    excelExport: (0, _emberApiActions.memberAction)({
      path: 'applications/excelexport',
      type: 'get'
    }),
    clone: (0, _emberApiActions.memberAction)({
      path: 'clone',
      type: 'patch'
    }),
    hasAccess: Ember.computed('accesslevel', function () {
      return this.accesslevel !== 'NONE';
    }),
    isOneYearOld: Ember.computed('startdate', function () {
      var started = (0, _moment.default)(this.startdate);
      var isOneYearOld = started.isBefore((0, _moment.default)().subtract(1, 'years'));
      return isOneYearOld;
    }),
    canBoost: Ember.computed('startdate', function () {
      return (0, _moment.default)().isAfter((0, _moment.default)(this.startdate).add(2, 'd'));
    }),
    isDraft: Ember.computed.equal('status.value', 'DRAFT'),
    isActive: Ember.computed.equal('status.value', 'ACTIVE'),
    isProgress: Ember.computed.equal('status.value', 'IN_PROGRESS'),
    isEnded: Ember.computed.equal('status.value', 'ENDED'),
    isArchived: Ember.computed.equal('status.value', 'ARCHIVED'),
    isScheduled: Ember.computed('isActive', 'status.icon', function () {
      // we use the status icon for this due to the browser time possibly differing from the backend time
      // therefore it can cause bugs to just check if startdate is after now.
      return this.isActive && this.get('status.icon') == 'clock';
    }),
    sortedTags: Ember.computed.sort('tags', function (a, b) {
      return a.name.localeCompare(b.name);
    }),
    translatedType: Ember.computed('intl', 'notificationType', function () {
      return this.notificationType ? this.intl.t("applications.email_notif.".concat(this.notificationType), {
        default: 'applications.email_notif.DAILY'
      }) : this.intl.t('applications.email_notif.DAILY');
    }),
    clearQuestions: function clearQuestions() {
      this.questions.map(function (question) {
        question.clearOptions();
        question.clearDates();
      });
    }
  });
  _exports.default = _default;
});