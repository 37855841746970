define("admin/compiled/alfrello-profile-items/AlfrelloProfileItems", ["exports", "react", "admin/compiled/alfrello-profile-card/AlfrelloProfileCard"], function (_exports, _react, _AlfrelloProfileCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AlfrelloProfileItems = void 0;
  var AlfrelloProfileItems = function AlfrelloProfileItems(_a) {
    var items = _a.items,
      label = _a.label;
    return _react.default.createElement(_AlfrelloProfileCard.AlfrelloProfileCard, {
      label: label
    }, _react.default.createElement("div", {
      className: 'alfrello-profile-items'
    }, items.map(function (item, index) {
      return _react.default.createElement("div", {
        className: 'alfrello-profile-item',
        key: index
      }, item);
    })));
  };
  _exports.AlfrelloProfileItems = AlfrelloProfileItems;
});