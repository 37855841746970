define("admin/templates/client/settings/compass/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "yPFdaB4S",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"new\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex-container\"],[12],[2,\"\\n\\n    \"],[8,\"new/float-header\",[],[[\"@title\",\"@returnRoute\"],[[30,[36,1],[[32,0,[\"model\",\"compassTemplate\",\"id\"]],[30,[36,0],[\"client.settings.compass\"],null],[30,[36,0],[\"client.settings.new_compass\"],null]],null],\"client.settings.compass\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n      \"],[8,\"link-to\",[[24,0,\"btn gray-brd\"]],[[\"@route\"],[\"client.settings.compass\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"string.cancel\"],null]]],\"parameters\":[]}]]],[2,\"\\n\\n      \"],[10,\"span\"],[15,0,[30,[36,2],[[32,0,[\"isValid\"]],\"disabled\"],null]],[12],[2,\"\\n        \"],[11,\"button\"],[16,0,[31,[\"btn green \",[30,[36,1],[[35,3],\"loading\"],null]]]],[4,[38,5],[\"click\",[30,[36,4],[[30,[36,1],[[32,0,[\"model\",\"compassTemplate\",\"id\"]],[32,0,[\"update\"]],[32,0,[\"save\"]]],null],[32,0,[\"model\",\"compassTemplate\"]]],null]],null],[12],[1,[30,[36,0],[\"string.save\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"float-top\"],[12],[2,\"\\n      \"],[8,\"compass/template-compass\",[],[[\"@compass\",\"@total\",\"@isEditing\"],[[32,0,[\"model\",\"compassTemplate\"]],[32,0,[\"total\"]],true]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"unless\",\"loading\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "admin/templates/client/settings/compass/form.hbs"
    }
  });
  _exports.default = _default;
});