define("admin/templates/components/applications/archive-application-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gZXvmqmm",
    "block": "{\"symbols\":[\"@name\",\"@toggle\"],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@overlayClass\"],[[30,[36,0],[[32,2]],null],\"modal-popup\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"default-dialog\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"applications.archive.title\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,2],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"tw-font-600\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"edit-form\"],[12],[2,\"\\n      \"],[10,\"div\"],[12],[1,[30,[36,1],[\"applications.archive.confirm\"],null]],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"text-center tw-space-x-4\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn gray-brd inline\"],[4,[38,3],[\"click\",[32,2]],null],[12],[2,\"\\n          \"],[1,[30,[36,1],[\"string.cancel\"],null]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[11,\"button\"],[16,0,[31,[\"btn red inline upper \",[30,[36,2],[[32,0,[\"loading\"]],\"loading\"],null]]]],[4,[38,3],[\"click\",[32,0,[\"archiveApplication\"]]],null],[12],[2,\"\\n          \"],[1,[30,[36,1],[\"string.confirm\"],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"t\",\"if\",\"on\"]}",
    "meta": {
      "moduleName": "admin/templates/components/applications/archive-application-modal.hbs"
    }
  });
  _exports.default = _default;
});