define("admin/compiled/alfrello-profile-info/ProfileRecommendations", ["exports", "react", "admin/compiled/alfrello-profile-items/AlfrelloProfileItems", "admin/compiled/utils/languageContext"], function (_exports, _react, _AlfrelloProfileItems, _languageContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ProfileRecommendations = void 0;
  var ProfileRecommendation = function ProfileRecommendation(_a) {
    var recommendation = _a.recommendation;
    return _react.default.createElement("div", {
      className: 'alfrello-profile-recommendation'
    }, _react.default.createElement("div", null, _react.default.createElement("strong", null, recommendation.name)), _react.default.createElement("div", {
      className: 'alfrello-profile-recommendation--job-title'
    }, recommendation.jobTitle, " @ ", recommendation.company), _react.default.createElement("div", {
      className: 'alfrello-profile-recommendation--meta'
    }, recommendation.email, recommendation.phone && " / ".concat(recommendation.phone)));
  };
  var ProfileRecommendations = function ProfileRecommendations(_a) {
    var recommendations = _a.recommendations;
    var intl = (0, _react.useContext)(_languageContext.default);
    if (!recommendations || !recommendations.length) {
      return null;
    }
    return _react.default.createElement(_AlfrelloProfileItems.AlfrelloProfileItems, {
      label: intl.t('client.applications.profile.recommendations'),
      items: recommendations.map(function (r) {
        return _react.default.createElement(ProfileRecommendation, {
          key: r.id,
          recommendation: r
        });
      })
    });
  };
  _exports.ProfileRecommendations = ProfileRecommendations;
});