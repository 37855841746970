define("admin/compiled/jobs-list/jobs-ga-info", ["exports", "react", "admin/compiled/utils/emberContext", "admin/compiled/utils"], function (_exports, _react, _emberContext, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getGaInfoItems = void 0;
  var getGaInfoItems = function getGaInfoItems(job, subscription) {
    var _a, _b;
    var _c = (0, _react.useContext)(_emberContext.default),
      config = _c.config,
      intl = _c.intl,
      moment = _c.moment;
    var thousand = config.REGION.thousand;
    var numberFormatter = function numberFormatter(number) {
      return (0, _utils.formatNumber)(number, thousand);
    };
    var items = [{
      label: intl.t('general-application.created'),
      content: moment(job.startdate).format('DD. MMM YYYY')
    }, {
      label: intl.t('general-application.next-payment'),
      content: subscription ? moment(subscription === null || subscription === void 0 ? void 0 : subscription.nextRenewal).format('DD. MMM YYYY') : intl.t('general-application.never-renew')
    }];
    items.push({
      label: intl.t('string.opened'),
      content: (_a = numberFormatter(job.opened)) === null || _a === void 0 ? void 0 : _a.toString()
    });
    if (job.applied !== undefined && job.applied !== null) {
      items.push({
        label: intl.t('string.applied'),
        content: (_b = numberFormatter(job.applied)) === null || _b === void 0 ? void 0 : _b.toString()
      });
    }
    return items;
  };
  _exports.getGaInfoItems = getGaInfoItems;
});