define("admin/templates/components/applications/capacent-btn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "efxwssRP",
    "block": "{\"symbols\":[\"@job\"],\"statements\":[[6,[37,3],[[32,1,[\"applicationClassification\",\"done\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"alfrello/button\",[],[[\"@class\",\"@onClick\"],[\"capacent\",[30,[36,2],[\"showCapacentReport\",[32,0]],null]]],[[\"default\"],[{\"statements\":[[2,\"Lokið\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"alfrello/button\",[],[[\"@class\",\"@onClick\"],[\"capacent\",[30,[36,2],[\"showCapacentReport\",[32,0]],null]]],[[\"default\"],[{\"statements\":[[2,\"Í vinnslu\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[30,[36,6],[[32,1,[\"applicationClassification\"]],[30,[36,5],[\"CONSULTANT\",[35,4,[\"client\",\"premiumFeatures\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"alfrello/button\",[],[[\"@class\",\"@onClick\"],[\"capacent\",[30,[36,2],[\"writeCapacentReport\",[32,0]],null]]],[[\"default\"],[{\"statements\":[[2,\"Samantekt \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"capacent-report-modal\",[],[[\"@showCapacentReport\",\"@report\",\"@comment\"],[[34,1],[32,1,[\"applicationClassification\",\"report\"]],[32,1,[\"applicationClassification\",\"comment\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"write-capacent-report-modal\",[],[[\"@slug\",\"@jobId\",\"@brandName\",\"@jobTitle\",\"@applicationClassification\",\"@writeCapacentReport\"],[[32,1,[\"slug\"]],[32,1,[\"id\"]],[32,1,[\"brand\",\"name\"]],[32,1,[\"title\"]],[32,1,[\"applicationClassification\"]],[34,0]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"writeCapacentReport\",\"showCapacentReport\",\"toggle-action\",\"if\",\"currentSession\",\"contains\",\"and\"]}",
    "meta": {
      "moduleName": "admin/templates/components/applications/capacent-btn.hbs"
    }
  });
  _exports.default = _default;
});