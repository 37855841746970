define("admin/components/mark-as-hired/hire-answers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="hire-package">
    <div>{{t "hired.request.answer_title"}}</div>
    <div class="hire-answers">
      {{#each @hireAnswers as |answer|}}
        <div class="item">
          <div class="question">{{answer.hireQuestion.title}}</div>
          <div class="answer">
            <CopyToClipboard @text={{answer.answer}} />
          </div>
        </div>
      {{/each}}
    </div>
  </div>
  */
  {
    "id": "lxzDhGcr",
    "block": "{\"symbols\":[\"answer\",\"@hireAnswers\"],\"statements\":[[10,\"div\"],[14,0,\"hire-package\"],[12],[2,\"\\n  \"],[10,\"div\"],[12],[1,[30,[36,0],[\"hired.request.answer_title\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hire-answers\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"item\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"question\"],[12],[1,[32,1,[\"hireQuestion\",\"title\"]]],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"answer\"],[12],[2,\"\\n          \"],[8,\"copy-to-clipboard\",[],[[\"@text\"],[[32,1,[\"answer\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/components/mark-as-hired/hire-answers.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});