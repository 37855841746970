define("admin/helpers/stage", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stage = stage;
  var environment = _environment.default.environment;
  function stage() {
    return environment === 'stage';
  }
  var _default = Ember.Helper.helper(stage);
  _exports.default = _default;
});