define("admin/components/brands/brand-edit", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    utils: Ember.inject.service(),
    store: Ember.inject.service(),
    apiClient: Ember.inject.service(),
    router: Ember.inject.service(),
    socialRegex: /^(?:http|https)?:?\/?\/?.+\.com\/(.+)/,
    isValid: Ember.computed('brand.{name,logo,brandAddress,body}', 'invalidSsn', function () {
      return this.get('brand.name') && this.get('brand.logo') && this.get('brand.brandAddress') && this.get('brand.body') && !this.invalidSsn;
    }),
    saveTooltip: Ember.computed('brand.{name,logo,brandAddress,body}', function () {
      return "<table class=\"adChecks\">\n        <tr><td>".concat(this.intl.t('string.brand_name'), ":</td><td class=\"").concat(this.get('brand.name') ? 'ok' : 'no', "\"></td></tr>\n        <tr><td>").concat(this.intl.t('string.logo'), ":</td><td class=\"").concat(this.get('brand.logo') ? 'ok' : 'no', "\"></td></tr>\n        <tr><td>").concat(this.intl.t('string.address'), ":</td><td class=\"").concat(this.get('brand.brandAddress') ? 'ok' : 'no', "\"></td></tr>\n        <tr><td>").concat(this.intl.t('string.about_brand'), ":</td><td class=\"").concat(this.get('brand.body') ? 'ok' : 'no', "\"></td></tr>\n        </table>");
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (!this.brand) {
        this.set('brand', this.store.createRecord('brand', {
          invoice: {},
          social: {}
        }));
      }
    },
    checkActiveSsn: function checkActiveSsn(ssn) {
      var _this = this;
      if (ssn && ssn.ssn()) {
        if (!this.searching) {
          this.set('searching', true);
          var trimmed = ssn.replace(/\s+/g, '').replace('-', '');
          this.apiClient.get("/clients/verification?ssn=".concat(trimmed), false).then(function (response) {
            if (!_this.get('brand.name')) {
              _this.set('brand.name', response.name);
            }
            if (!_this.get('brand.brandAddress')) {
              _this.store.query('address', {
                search: response.address,
                limit: 1
              }).then(function (address) {
                _this.set('brand.brandAddress', address.firstObject.formatted);
              });
            }
            _this.set('invalidSsn');
          }).catch(function () {
            _this.set('invalidSsn', true);
          }).finally(function () {
            _this.set('searching');
          });
        }
      }
    },
    openCroppingModalLogo: function openCroppingModalLogo() {
      this.toggleProperty('modalLogoShown');
    },
    openCroppingModalImage: function openCroppingModalImage() {
      this.toggleProperty('modalImageShown');
    },
    _precheckSocials: function _precheckSocials() {
      this.onSocialChange('brand.facebook', this.get('brand.facebook'));
      this.onSocialChange('brand.twitter', this.get('brand.twitter'));
      this.onSocialChange('brand.instagram', this.get('brand.instagram'));
      this.onSocialChange('brand.linkedin', this.get('brand.linkedin'));
    },
    onSocialChange: function onSocialChange(attr, value) {
      if (typeof value === 'string') {
        var match = value.match(this.socialRegex);
        if (match && match.length > 1) {
          this.set(attr, match[1]);
        }
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.checkActiveSsn(this.get('brand.ssn'));
      this._precheckSocials();
    },
    actions: {
      save: function save(brand) {
        var _this2 = this;
        brand.set('web', this.utils.checkWeb(brand.get('web')));
        brand.set('status', 'ACTIVE');
        this.set('loading', true);
        brand.save().then(function () {
          brand.reload();
          _this2.onSave();
        }).finally(function () {
          return _this2.set('loading');
        });
      },
      onSsnChange: function onSsnChange(ssn) {
        if (typeof ssn === 'string') {
          this.checkActiveSsn(ssn);
        }
      },
      searchAddress: function searchAddress(search) {
        return this.store.query('address', {
          search: search,
          limit: 20
        });
      },
      toggleHowUse: function toggleHowUse() {
        this.toggleProperty('howUseShown');
      },
      clickLogo: function clickLogo() {
        (0, _jquery.default)('.f-logo input').click();
      },
      clickImage: function clickImage() {
        (0, _jquery.default)('.f-image input').click();
      },
      didSelectLogo: function didSelectLogo(brand, files) {
        var _this3 = this;
        if (files.length) {
          var reader = new FileReader();
          reader.readAsDataURL(files[0]);
          reader.addEventListener('load', function () {
            var image = new Image();
            image.src = reader.result;
            if (image.width === 500 && image.height === 365) {
              brand.set('logo', reader.result);
            } else {
              brand.set('logo_temp', reader.result);
              _this3.openCroppingModalLogo();
            }
            (0, _jquery.default)('.x-file--input')[0].value = '';
          }, false);
        }
      },
      didSelectImage: function didSelectImage(brand, files) {
        var _this4 = this;
        if (files.length) {
          var reader = new FileReader();
          reader.readAsDataURL(files[0]);
          reader.addEventListener('load', function () {
            var image = new Image();
            image.src = reader.result;
            if (image.width === 1200 && image.height >= 600) {
              brand.set('cover', reader.result);
            } else {
              brand.set('image_temp', reader.result);
              _this4.openCroppingModalImage();
            }
            (0, _jquery.default)('.x-file--input')[0].value = '';
          }, false);
        }
      },
      deleteBrand: function deleteBrand() {
        var _this5 = this;
        this.brand.destroyRecord().then(function () {
          _this5.onDelete();
        });
      }
    }
  });
  _exports.default = _default;
});