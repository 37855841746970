define("admin/controllers/admin/customers/registrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    queryParams: ['status'],
    status: 'CREATED'
  });
  _exports.default = _default;
});