define("admin/controllers/client/applications/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    apiClient: Ember.inject.service(),
    application: Ember.computed.alias('model.application'),
    observeApplication: Ember.observer('model.application.id', function () {
      var _this = this;
      this.apiClient.get("/jobs/".concat(this.model.job.id, "/applications/").concat(this.model.application.id, "/links")).then(function (res) {
        _this.set('links', res);
      });
    }),
    currentColumn: Ember.computed('application.columnId', function () {
      if (!this.get('application.columnId')) {
        return;
      }
      return this.store.peekRecord('column', this.application.columnId);
    }),
    actions: {
      changeColumn: function changeColumn(column) {
        var application = this.application;
        application.set('category', column.get('category.type'));
        application.set('columnId', column.id);
        var data = {
          jobid: this.model.job.id,
          columnid: column.id,
          category: column.get('category.type'),
          indexes: [application.id]
        };
        this.apiClient.put("/jobs/".concat(this.model.job.id, "/applications/sort"), data);
      },
      refresh: function refresh() {
        this.send('refreshModel');
      }
    }
  });
  _exports.default = _default;
});