define("admin/controllers/client/settings/video/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      close: function close() {
        this.transitionToRoute('client.settings.video', {
          queryParams: {
            reload: true
          }
        });
      }
    }
  });
  _exports.default = _default;
});