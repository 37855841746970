define("admin/components/alfrello-react/board-context-menu", ["exports", "admin/compiled/redux/actions/modal", "admin/compiled/types", "admin/compiled/ui/ContextMenu", "admin/react-component"], function (_exports, _modal, _types, _ContextMenu, _reactComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  
  */
  {
    "id": "jqPxr/MY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/components/alfrello-react/board-context-menu.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _reactComponent.default.extend({
    intl: Ember.inject.service(),
    apiClient: Ember.inject.service(),
    renderComponent: function renderComponent() {
      var _this = this;
      var excelItem = {
        title: this.intl.t('filter.actions.excel'),
        onClick: function onClick() {
          return _this.downloadExcel();
        },
        className: 'download-icon'
      };
      var attachmentItem = {
        title: this.intl.t('filter.actions.attachments'),
        // onClick: () => this.downloadAttachment(),
        dispatchFn: (0, _modal.showModal)(_types.ModalType.DOWNLOAD_FILES_MODAL, {
          title: this.intl.t('filter.actions.attachments'),
          jobId: this.job.id,
          downloadFile: function downloadFile(userId) {
            return _this.apiClient.get("/jobs/".concat(_this.job.id, "/applications/attachmentexport/async?otherUserId=").concat(userId));
          }
        }),
        className: 'download-icon'
      };
      var viItem = {
        className: 'download-icon',
        title: this.intl.t('filter.actions.vi'),
        // onClick: () => this.downloadAttachment(),
        dispatchFn: (0, _modal.showModal)(_types.ModalType.DOWNLOAD_FILES_MODAL, {
          title: this.intl.t('filter.actions.vi'),
          jobId: this.job.id,
          downloadFile: function downloadFile(userId) {
            return _this.apiClient.get("/jobs/".concat(_this.job.id, "/applications/viexport/async?otherUserId=").concat(userId));
          }
        })
      };
      var saveTemplateItem = {
        title: this.intl.t('filter.actions.save-template'),
        onClick: function onClick() {
          return _this.saveTemplate();
        },
        className: 'save'
      };
      var toggleInactive = {
        title: this.intl.t('alfrello.filter.inactive-applications'),
        onClick: function onClick() {
          return _this.toggleInactiveApplications();
        },
        className: this.showInactiveApplications ? 'eye-icon-b' : 'eye-icon',
        maxScreenWidth: 1580
      };
      var exportOneSystem = {
        title: this.oneSystem === 'exported' ? this.intl.t('hr.one_system.exported_btn') : this.intl.t('hr.one_system.export_btn'),
        onClick: function onClick() {
          return _this.oneSystem === 'configured' ? _this.exportOneSystem() : {};
        },
        className: this.oneSystem === 'exported' ? 'exported' : 'export'
      };
      var items = [saveTemplateItem, excelItem, attachmentItem, viItem, toggleInactive];
      if (this.oneSystem === 'configured' || this.oneSystem === 'exported') {
        items.push(exportOneSystem);
      }
      this.reactRender(React.createElement(_ContextMenu.ContextMenu, {
        items: items,
        loading: this.isLoading,
        withIcons: true
      }));
    }
  }));
  _exports.default = _default;
});