define("admin/compiled/jobtags-search/JobTagsSearch", ["exports", "react", "admin/compiled/utils", "admin/compiled/utils/useDebounce"], function (_exports, _react, _utils, _useDebounce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.JobTagsSearch = void 0;
  var JobTagsSearch = function JobTagsSearch(_a) {
    var search = _a.search,
      setSearch = _a.setSearch,
      placeholder = _a.placeholder;
    var id = (0, _utils.uuid)();
    var _b = (0, _react.useState)(search),
      searchVal = _b[0],
      setSearchVal = _b[1];
    var debounceSearchVal = (0, _useDebounce.default)(searchVal, 200);
    (0, _react.useEffect)(function () {
      setSearch(debounceSearchVal);
    }, [debounceSearchVal]);
    (0, _react.useEffect)(function () {
      setSearchVal(search);
    }, [search]);
    return _react.default.createElement("div", {
      className: "jobtags-search"
    }, _react.default.createElement("input", {
      id: id,
      type: 'text',
      placeholder: placeholder,
      value: searchVal,
      onChange: function onChange(e) {
        return setSearchVal(e.target.value);
      }
    }), searchVal && _react.default.createElement("span", {
      className: 'jobtags-search--clear',
      onClick: function onClick() {
        var _a;
        setSearchVal('');
        setSearch('');
        (_a = document.getElementById(id)) === null || _a === void 0 ? void 0 : _a.focus();
      }
    }));
  };
  _exports.JobTagsSearch = JobTagsSearch;
});