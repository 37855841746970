define("admin/components/ui/form-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="ui-form-header">
    <div class="container">
      <div class="ui-form-header--content">
        <div class="ui-form-header--title">{{@title}}</div>
        <div class="ui-form-header--actions">{{yield}}</div>
      </div>
    </div>
  </div>
  
  */
  {
    "id": "ZhkgH0+J",
    "block": "{\"symbols\":[\"@title\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"ui-form-header\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui-form-header--content\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui-form-header--title\"],[12],[1,[32,1]],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui-form-header--actions\"],[12],[18,2,null],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/components/ui/form-header.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});