define("admin/components/skills/new-subcat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var _default = Ember.Component.extend({
    apiAdmin: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.subcat) {
        this.set('subcat', {});
      }
    },
    actions: {
      save: function save() {
        var _this = this;
        var subCategory = _objectSpread(_objectSpread({}, this.subcat), {}, {
          category: {
            id: this.category.id
          }
        });
        if (this.subcat.id) {
          this.set('loading', true);
          this.apiAdmin.patch("/skillsubcategories/".concat(this.subcat.id), {
            subCategory: subCategory
          }).then(function () {
            return _this.set('newSubcat');
          }).finally(function () {
            return _this.set('loading');
          });
        } else {
          this.set('loading', true);
          this.apiAdmin.post("/skillsubcategories", {
            subCategory: subCategory
          }).then(function () {
            _this.refreshData();
            _this.set('newSubcat');
          }).finally(function () {
            return _this.set('loading');
          });
        }
      }
    }
  });
  _exports.default = _default;
});