define("admin/compiled/modal/JobTagsModal", ["exports", "react", "admin/compiled/modal/AlfredModal", "admin/compiled/jobtag-input/JobTagInput", "admin/compiled/ui/Button", "react-redux", "admin/compiled/redux/actions/modal", "admin/compiled/utils", "admin/compiled/ui/filter-dropdown/FilterDropdown", "admin/compiled/jobtags/hooks/useCreateJobTag", "admin/compiled/jobtags/hooks/useUpdateJobTag", "admin/compiled/utils/emberContext"], function (_exports, _react, _AlfredModal, _JobTagInput, _Button, _reactRedux, _modal, _utils, _FilterDropdown, _useCreateJobTag, _useUpdateJobTag, _emberContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.JobTagsModal = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var __awaiter = void 0 && (void 0).__awaiter || function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P ? value : new P(function (resolve) {
        resolve(value);
      });
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }
      function rejected(value) {
        try {
          step(generator["throw"](value));
        } catch (e) {
          reject(e);
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };
  var __generator = void 0 && (void 0).__generator || function (thisArg, body) {
    var _ = {
        label: 0,
        sent: function sent() {
          if (t[0] & 1) throw t[1];
          return t[1];
        },
        trys: [],
        ops: []
      },
      f,
      y,
      t,
      g;
    return g = {
      next: verb(0),
      "throw": verb(1),
      "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
      return this;
    }), g;
    function verb(n) {
      return function (v) {
        return step([n, v]);
      };
    }
    function step(op) {
      if (f) throw new TypeError("Generator is already executing.");
      while (g && (g = 0, op[0] && (_ = 0)), _) try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];
        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;
          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };
          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;
          case 7:
            op = _.ops.pop();
            _.trys.pop();
            continue;
          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }
            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }
            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }
            if (t && _.label < t[2]) {
              _.label = t[2];
              _.ops.push(op);
              break;
            }
            if (t[2]) _.ops.pop();
            _.trys.pop();
            continue;
        }
        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
      if (op[0] & 5) throw op[1];
      return {
        value: op[0] ? op[1] : void 0,
        done: true
      };
    }
  };
  var __spreadArray = void 0 && (void 0).__spreadArray || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
      if (ar || !(i in from)) {
        if (!ar) ar = Array.prototype.slice.call(from, 0, i);
        ar[i] = from[i];
      }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
  };
  var JobTagsModal = function JobTagsModal(_a) {
    var _b, _c;
    var categories = _a.categories,
      languages = _a.languages,
      jobTag = _a.jobTag,
      categoryId = _a.categoryId;
    var intl = (0, _react.useContext)(_emberContext.default).intl;
    var _d = (0, _react.useState)(languages.map(function (language) {
        var _a;
        return {
          id: (0, _utils.uuid)(),
          language: language,
          translation: ((_a = jobTag === null || jobTag === void 0 ? void 0 : jobTag.locales.find(function (l) {
            return l.locale == language.code;
          })) === null || _a === void 0 ? void 0 : _a.label) || ''
        };
      })),
      translations = _d[0],
      setTranslations = _d[1];
    var dispatch = (0, _reactRedux.useDispatch)();
    var _e = (0, _react.useState)(jobTag ? (_b = categories.find(function (c) {
        return c.id == jobTag.categoryId;
      })) !== null && _b !== void 0 ? _b : null : categoryId ? (_c = categories.find(function (c) {
        return c.id == categoryId;
      })) !== null && _c !== void 0 ? _c : null : null),
      selectedCategory = _e[0],
      setSelectedCategory = _e[1];
    var createJobTag = (0, _useCreateJobTag.useCreateJobTag)();
    var updateJobTag = (0, _useUpdateJobTag.useUpdateJobTag)();
    return _react.default.createElement(_AlfredModal.AlfredModal, {
      title: jobTag ? intl.t('admin.jobtags.edit-jobtag') : intl.t('admin.jobtags.new-jobtag'),
      preventOutsideClick: true
    }, _react.default.createElement("div", {
      className: 'jobtags-modal--category'
    }, _react.default.createElement(_FilterDropdown.FilterDropdown, {
      placeholder: intl.t('admin.jobtags.empty-category'),
      items: categories.map(function (c) {
        return {
          name: c.name,
          val: c
        };
      }),
      selected: selectedCategory ? {
        name: selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.name,
        val: selectedCategory
      } : undefined,
      onSelect: function onSelect(c) {
        return setSelectedCategory(c === null || c === void 0 ? void 0 : c.val);
      }
    })), translations.map(function (t) {
      return _react.default.createElement(_JobTagInput.JobTagInput, {
        key: t.id,
        language: t.language,
        value: t.translation,
        placeholder: t.language.name,
        onChange: function onChange(val) {
          return setTranslations(__spreadArray([], translations.map(function (old) {
            return __assign(__assign({}, old), {
              translation: old.id === t.id ? val : old.translation
            });
          }), true));
        }
      });
    }), _react.default.createElement("div", {
      className: 'jobtags-modal--actions'
    }, _react.default.createElement(_Button.Button, {
      onClick: function onClick() {
        return dispatch((0, _modal.hideModal)());
      },
      color: 'gray'
    }, intl.t('string.close')), _react.default.createElement(_Button.Button, {
      disabled: !selectedCategory || !translations || !translations.some(function (t) {
        return !!t.translation;
      }) || updateJobTag.isLoading,
      onClick: function onClick() {
        return __awaiter(void 0, void 0, void 0, function () {
          var jobTagDto;
          var _a;
          return __generator(this, function (_b) {
            switch (_b.label) {
              case 0:
                if (!selectedCategory) {
                  return [2 /*return*/];
                }

                jobTagDto = {
                  id: jobTag === null || jobTag === void 0 ? void 0 : jobTag.id,
                  name: ((_a = translations.find(function (t) {
                    return !!t.translation;
                  })) === null || _a === void 0 ? void 0 : _a.translation) || null,
                  categoryId: selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.id,
                  jobCount: (jobTag === null || jobTag === void 0 ? void 0 : jobTag.jobCount) || 0,
                  profileCount: (jobTag === null || jobTag === void 0 ? void 0 : jobTag.profileCount) || 0,
                  locales: translations.map(function (t) {
                    return {
                      locale: t.language.code,
                      label: t.translation
                    };
                  }).filter(function (t) {
                    return !!jobTag || !!t.label;
                  }) // skip empty translations only if creating new tag
                };

                if (!jobTag) return [3 /*break*/, 2];
                return [4 /*yield*/, updateJobTag.mutateAsync(jobTagDto)];
              case 1:
                _b.sent();
                dispatch((0, _modal.hideModal)());
                return [3 /*break*/, 3];
              case 2:
                createJobTag.mutate(jobTagDto);
                dispatch((0, _modal.hideModal)());
                _b.label = 3;
              case 3:
                return [2 /*return*/];
            }
          });
        });
      },

      color: 'green'
    }, updateJobTag.isLoading || createJobTag.isLoading ? intl.t('admin.jobtags.loading') : intl.t('general.save'))));
  };
  _exports.JobTagsModal = JobTagsModal;
});