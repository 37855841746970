define("admin/routes/forgotpasswd", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    templateName: 'newuser',
    intl: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('button', this.intl.t('login.reset_psw_btn'));
      controller.set('title', this.intl.t('login.reset_psw'));
    }
  });
  _exports.default = _default;
});