define("admin/components/applications/capacent-btn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    isReportCompleted: Ember.computed('applicationClassification.{report}', function () {
      var classification = this.applicationClassification;
      if (classification) {
        return classification.done;
      }
    }),
    classNames: ['capacent-btn'],
    classNameBindings: ['disabled:disable-btn']
  });
  _exports.default = _default;
});