define("admin/compiled/ui/page-dots/PageDots", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PageDots = void 0;
  var PageDots = function PageDots(_a) {
    var elements = _a.elements,
      activeEl = _a.activeEl,
      onChange = _a.onChange;
    var els = [];
    for (var i = 0; i < elements; i++) {
      els.push(i);
    }
    return _react.default.createElement("div", {
      className: 'ui-page-dots'
    }, els.map(function (el) {
      return _react.default.createElement("span", {
        key: el,
        className: "ui-page-dots--element ".concat(el === activeEl ? 'active' : ''),
        onClick: function onClick() {
          if (onChange && el !== activeEl) {
            onChange(el);
          }
        }
      });
    }));
  };
  _exports.PageDots = PageDots;
});