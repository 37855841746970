define("admin/transforms/date-without-time", ["exports", "@ember-data/serializer/transform", "moment"], function (_exports, _transform, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // The user has to choose only date, therefore the time does not matter.
  // This solves timezone issues for ad deadlines, that were offset by one day for FO, MT and CEE.
  var _default = _transform.default.extend({
    deserialize: function deserialize(date) {
      if (!date) {
        return null;
      }
      var splitDateTime = date.split('T');
      var datePart = splitDateTime[0];
      var timePart = splitDateTime === null || splitDateTime === void 0 ? void 0 : splitDateTime[1];
      if (timePart !== null && timePart !== void 0 && timePart.startsWith('23:59')) {
        return (0, _moment.default)(datePart, 'YYYY-MM-DD').endOf('day').toDate();
      }
      return (0, _moment.default)(datePart, 'YYYY-MM-DD').toDate();
    },
    serialize: function serialize(date) {
      return date ? "".concat((0, _moment.default)(date).format('YYYY-MM-DD'), "T00:00:00Z") : null;
    }
  });
  _exports.default = _default;
});