define("admin/compiled/alfrello-column/LoadingBar", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LoadingBar = void 0;
  var LoadingBar = function LoadingBar() {
    return _react.default.createElement("div", {
      className: 'alfrello-column--loading-bar'
    }, _react.default.createElement("div", {
      className: 'loading-columns'
    }));
  };
  _exports.LoadingBar = LoadingBar;
});