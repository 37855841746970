define("admin/components/applications/notes", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = Ember.Component.extend((_dec = Ember._action, (_obj = {
    store: Ember.inject.service(),
    classNames: ['notes'],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.getData(true);
    },
    _getNotes: function _getNotes(scroll) {
      var _this = this;
      var prefix = "jobs/".concat(this.jobId, "/applications/").concat(this.applicationId);
      this.set('loadingNotes', true);
      this.store.query('note', {
        prefix: prefix
      }).then(function (res) {
        if (_this.isDestroyed) {
          return;
        }
        _this.set('notes', res.filter(function (note) {
          var defaultLabel = _this._getDefaultLabel();
          return !defaultLabel || note.get('labelId.id') === defaultLabel.id;
        }));
        if (scroll) {
          (0, _jquery.default)('.full-modal').animate({
            scrollTop: (0, _jquery.default)('.full-modal > .container').height()
          }, 1000);
          (0, _jquery.default)('html, body').animate({
            scrollTop: (0, _jquery.default)(document).height()
          }, 1000);
        }
      }).finally(function () {
        return _this.set('loadingNotes');
      });
    },
    _getDefaultLabel: function _getDefaultLabel() {
      // TODO: Backend needs to create and return an enum for this
      var label = this.defaultLabelId ? this.notelabels.filterBy('id', this.defaultLabelId.toString()) : null;
      return label && label.length ? label[0] : null;
    },
    _getLabels: function _getLabels() {
      var _this2 = this;
      this.store.findAll('notelabel').then(function (res) {
        if (_this2.isDestroyed) {
          return;
        }
        _this2.set('notelabels', res);
        _this2.set('newnote', _this2.store.createRecord('note', {
          labelId: _this2._getDefaultLabel()
        }));
      });
    },
    getData: function getData(reset, scroll) {
      if (reset) {
        this.set('notes');
      }
      this._getNotes(scroll);
      this._getLabels();
    },
    addNewNote: function addNewNote() {
      this.getData(false, true);
    }
  }, (_applyDecoratedDescriptor(_obj, "addNewNote", [_dec], Object.getOwnPropertyDescriptor(_obj, "addNewNote"), _obj)), _obj)));
  _exports.default = _default;
});