define("admin/compiled/jobtags/JobTags", ["exports", "react", "admin/compiled/utils/emberContext", "admin/compiled/types", "admin/compiled/ui/analytics-card/AnalyticsCard", "admin/compiled/jobtags-search/JobTagsSearch", "admin/compiled/ui/filter-dropdown/FilterDropdown", "admin/compiled/ui/Button", "admin/compiled/jobtags-card/JobTagsCard", "admin/compiled/alfrello-column/LoadingBar", "admin/compiled/jobtags/hooks/useJobCategories", "admin/compiled/jobtags/hooks/useLanguages", "react-redux", "admin/compiled/redux/actions/modal"], function (_exports, _react, _emberContext, _types, _AnalyticsCard, _JobTagsSearch, _FilterDropdown, _Button, _JobTagsCard, _LoadingBar, _useJobCategories, _useLanguages, _reactRedux, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Jobtags = void 0;
  var Jobtags = function Jobtags(_a) {
    var intl = (0, _react.useContext)(_emberContext.default).intl;
    var _b = (0, _react.useState)(''),
      search = _b[0],
      setSearch = _b[1];
    var _c = (0, _react.useState)(),
      selectedCategory = _c[0],
      setSelectedCategory = _c[1];
    var _d = (0, _useJobCategories.useJobCategories)(),
      isLoading = _d.isLoading,
      isError = _d.isError,
      data = _d.data;
    var categories = data || [];
    var dropdownItems = categories.map(function (c) {
      return {
        name: "".concat(c.name, " (").concat(c.jobTags.length, ")"),
        val: c
      };
    });
    var jobTags = data ? categories.flatMap(function (c) {
      return c.jobTags;
    }).filter(function (jt) {
      var _a;
      var categoryCriteria = !selectedCategory || selectedCategory.val.id == jt.categoryId;
      var searchCriteria = !search || ((_a = jt.name) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(search.toLowerCase())) || jt.locales.some(function (jtTranslation) {
        var _a;
        return (_a = jtTranslation.label) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(search.toLowerCase());
      });
      return categoryCriteria && searchCriteria;
    }) : [];
    var languagesData = (0, _useLanguages.useLanguages)();
    var languages = languagesData.data || [];
    var dispatch = (0, _reactRedux.useDispatch)();
    var categoriesWithoutTags = categories.map(function (c) {
      return {
        id: c.id,
        name: c.name
      };
    });
    return _react.default.createElement("div", {
      className: 'container'
    }, _react.default.createElement("div", {
      className: 'admin-jobtags'
    }, _react.default.createElement("div", {
      className: 'admin-jobtags--stats'
    }, _react.default.createElement(_AnalyticsCard.AnalyticsCard, {
      label: intl.t('admin.jobtags.tag-count'),
      number: categories.reduce(function (a, b) {
        return a + b.jobTags.length;
      }, 0),
      icon: 'https://storage.googleapis.com/alfredstatic/admin/megaphone.svg'
    })), _react.default.createElement("div", {
      className: 'admin-jobtags--bar'
    }, _react.default.createElement("div", {
      className: 'admin-jobtags--bar-left'
    }, _react.default.createElement(_JobTagsSearch.JobTagsSearch, {
      setSearch: setSearch,
      search: search,
      placeholder: intl.t('admin.jobtags.search-placeholder')
    }), _react.default.createElement(_FilterDropdown.FilterDropdown, {
      placeholder: intl.t('admin.jobtags.all-categories'),
      items: dropdownItems,
      selected: selectedCategory,
      onSelect: function onSelect(item) {
        return setSelectedCategory(item);
      }
    })), _react.default.createElement("div", {
      className: 'admin-jobtags--bar-right'
    }, _react.default.createElement(_Button.Button, {
      disabled: !(categoriesWithoutTags === null || categoriesWithoutTags === void 0 ? void 0 : categoriesWithoutTags.length),
      onClick: function onClick() {
        return dispatch((0, _modal.showModal)(_types.ModalType.JOB_TAGS_MODAL, {
          categoryId: selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.val.id,
          categories: categoriesWithoutTags,
          languages: languages
        }));
      },
      color: 'green'
    }, intl.t('admin.jobtags.new-jobtag')))), _react.default.createElement("div", {
      className: 'admin-jobtags--list'
    }, isLoading && _react.default.createElement("div", {
      style: {
        marginTop: '40px'
      }
    }, _react.default.createElement(_LoadingBar.LoadingBar, null)), !isLoading && jobTags.map(function (jobTag) {
      var _a, _b;
      return _react.default.createElement(_JobTagsCard.JobTagsCard, {
        categoryId: jobTag.categoryId,
        key: jobTag.id,
        id: jobTag.id,
        category: (_b = (_a = categories.find(function (c) {
          return c.id == jobTag.categoryId;
        })) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '',
        name: jobTag.name,
        jobCount: jobTag.jobCount,
        tags: jobTag.locales.map(function (l) {
          return {
            name: l.label,
            flag: "https://alfredflags.imgix.net/".concat(l.locale, ".png?h=25")
          };
        }),
        onClick: function onClick() {
          if (jobTag.id) {
            dispatch((0, _modal.showModal)(_types.ModalType.JOB_TAGS_MODAL, {
              categories: categoriesWithoutTags,
              languages: languages,
              jobTag: jobTag
            }));
          }
        }
      });
    }))));
  };
  _exports.Jobtags = Jobtags;
});