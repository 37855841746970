define("admin/mixins/serialize-id-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    serialize: function serialize(snapshot) {
      var data = {};
      data[this.primaryKey] = snapshot.id;
      return data;
    }
  });
  _exports.default = _default;
});