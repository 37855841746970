define("admin/templates/components/crad/crad-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "FNiiuDzb",
    "block": "{\"symbols\":[\"question\",\"@index\"],\"statements\":[[8,\"crad/dropdown\",[],[[\"@title\",\"@selectedName\",\"@placeholder\"],[[34,5],[34,6],[30,[36,4],[\"crad.questions.placeholder\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[11,\"div\"],[24,0,\"option\"],[4,[38,0],[[32,0],\"showNewQuestion\"],null],[12],[2,\"\\n    \"],[10,\"strong\"],[12],[2,\"\\n      + \"],[1,[30,[36,4],[\"crad.new_question\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[24,0,\"option\"],[24,\"data-test\",\"clear-question\"],[4,[38,0],[[32,0],\"clearQuestion\"],null],[12],[2,\"\\n      \"],[10,\"strong\"],[14,0,\"red\"],[12],[2,\"\\n        \"],[1,[30,[36,4],[\"crad.clear_question\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,10],[[30,[36,9],[[30,[36,9],[[35,8]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[\"id\",[32,1,[\"id\"]],[35,1]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"div\"],[24,0,\"option\"],[24,\"data-test\",\"question-option\"],[4,[38,0],[[32,0],\"select\",[32,1],[32,2]],null],[12],[2,\"\\n        \"],[1,[32,1,[\"question\"]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"jobQuestions\",\"in-array\",\"unless\",\"t\",\"title\",\"selectedName\",\"if\",\"questions\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/components/crad/crad-question.hbs"
    }
  });
  _exports.default = _default;
});