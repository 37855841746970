define("admin/compiled/jobtags/hooks/useCreateJobTag", ["exports", "react", "admin/compiled/utils/emberContext", "react-query"], function (_exports, _react, _emberContext, _reactQuery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.useCreateJobTag = useCreateJobTag;
  var __awaiter = void 0 && (void 0).__awaiter || function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P ? value : new P(function (resolve) {
        resolve(value);
      });
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }
      function rejected(value) {
        try {
          step(generator["throw"](value));
        } catch (e) {
          reject(e);
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };
  var __generator = void 0 && (void 0).__generator || function (thisArg, body) {
    var _ = {
        label: 0,
        sent: function sent() {
          if (t[0] & 1) throw t[1];
          return t[1];
        },
        trys: [],
        ops: []
      },
      f,
      y,
      t,
      g;
    return g = {
      next: verb(0),
      "throw": verb(1),
      "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
      return this;
    }), g;
    function verb(n) {
      return function (v) {
        return step([n, v]);
      };
    }
    function step(op) {
      if (f) throw new TypeError("Generator is already executing.");
      while (g && (g = 0, op[0] && (_ = 0)), _) try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];
        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;
          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };
          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;
          case 7:
            op = _.ops.pop();
            _.trys.pop();
            continue;
          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }
            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }
            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }
            if (t && _.label < t[2]) {
              _.label = t[2];
              _.ops.push(op);
              break;
            }
            if (t[2]) _.ops.pop();
            _.trys.pop();
            continue;
        }
        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
      if (op[0] & 5) throw op[1];
      return {
        value: op[0] ? op[1] : void 0,
        done: true
      };
    }
  };
  var __spreadArray = void 0 && (void 0).__spreadArray || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
      if (ar || !(i in from)) {
        if (!ar) ar = Array.prototype.slice.call(from, 0, i);
        ar[i] = from[i];
      }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
  };
  function useCreateJobTag() {
    var _this = this;
    var apiAdmin = (0, _react.useContext)(_emberContext.default).apiAdmin;
    var queryClient = (0, _reactQuery.useQueryClient)();
    return (0, _reactQuery.useMutation)(function (jobTag) {
      return apiAdmin.post("/jobtags", jobTag);
    }, {
      onMutate: function onMutate(jobTag) {
        return __awaiter(_this, void 0, void 0, function () {
          var previousJobCategories, newJobCategories, currentCat;
          var _a;
          return __generator(this, function (_b) {
            switch (_b.label) {
              case 0:
                return [4 /*yield*/, queryClient.cancelQueries('jobCategories')];
              case 1:
                _b.sent();
                previousJobCategories = queryClient.getQueryData('jobCategories');
                newJobCategories = __spreadArray([], previousJobCategories, true);
                currentCat = newJobCategories.find(function (c) {
                  return c.id == jobTag.categoryId;
                });
                if (!currentCat) {
                  return [2 /*return*/];
                }

                currentCat.jobTags = __spreadArray(__spreadArray([], currentCat === null || currentCat === void 0 ? void 0 : currentCat.jobTags, true), [{
                  name: ((_a = jobTag.locales.find(function (l) {
                    return !!l.label;
                  })) === null || _a === void 0 ? void 0 : _a.label) || null,
                  categoryId: jobTag.categoryId,
                  locales: jobTag.locales,
                  jobCount: 0,
                  profileCount: 0
                }], false);
                queryClient.setQueryData('jobCategories', newJobCategories);
                return [2 /*return*/, {
                  previousJobCategories: previousJobCategories
                }];
            }
          });
        });
      },
      onSettled: function onSettled() {
        return queryClient.invalidateQueries('jobCategories');
      }
    });
  }
});