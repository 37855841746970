define("admin/models/interview", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    title: (0, _model.attr)('string'),
    message: (0, _model.attr)('string'),
    date: (0, _model.attr)('string', {
      defaultValue: (0, _moment.default)().format('DD.MM.YY')
    }),
    from: (0, _model.attr)('string', {
      defaultValue: "12:00"
    }),
    to: (0, _model.attr)('string', {
      defaultValue: "13:00"
    }),
    status: (0, _model.attr)('string'),
    remoteInterview: (0, _model.attr)(),
    address: (0, _model.attr)('string'),
    lat: (0, _model.attr)('number'),
    lon: (0, _model.attr)('number')
  });
  _exports.default = _default;
});