define("admin/routes/client/settings/compass/edit", ["exports", "admin/routes/client/_form/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    controllerName: 'client/settings/compass/form',
    apiClient: Ember.inject.service(),
    renderTemplate: function renderTemplate() {
      this._super('settings/compass');
    },
    model: function model(_ref) {
      var compass_id = _ref.compass_id;
      return this.apiClient.get("/compasstemplates/".concat(compass_id));
    }
  });
  _exports.default = _default;
});