define("admin/models/ga-job", ["exports", "admin/models/job", "@ember-data/model"], function (_exports, _job, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _job.default.extend({
    user: (0, _model.attr)('number')
  });
  _exports.default = _default;
});