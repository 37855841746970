define("admin/models/feed", ["exports", "@ember-data/model", "ember-api-actions", "moment"], function (_exports, _model, _emberApiActions, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    date: (0, _model.attr)('moment'),
    description: (0, _model.attr)('string'),
    image: (0, _model.attr)('string'),
    initials: (0, _model.attr)('string'),
    jobTitle: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    sender: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    type: (0, _model.attr)('string', {
      defaultValue: 'MESSAGE'
    }),
    userId: (0, _model.attr)('number'),
    systemMessage: (0, _model.attr)('boolean'),
    interview: (0, _model.belongsTo)('interview'),
    videoInterview: (0, _model.belongsTo)('videointerview'),
    message: (0, _model.attr)('string'),
    from: (0, _model.attr)('string', {
      defaultValue: "12:00"
    }),
    to: (0, _model.attr)('string', {
      defaultValue: "13:00"
    }),
    caltype: (0, _model.attr)('string'),
    calfrom: (0, _model.attr)('string'),
    calto: (0, _model.attr)('string'),
    address: (0, _model.attr)('string'),
    application: (0, _model.belongsTo)('application'),
    cancel: (0, _emberApiActions.memberAction)({
      path: 'cancel',
      type: 'patch'
    }),
    fromnow: Ember.computed('date', function () {
      return "".concat((0, _moment.default)(this.date).format('D. MMM H:mm'));
    }),
    thumb_initials: Ember.computed('image', function () {
      if (!this.image) {
        return this.initials;
      }
    }),
    // Depricated
    caldate: (0, _model.attr)('string', {
      defaultValue: (0, _moment.default)().add(1, 'days').format('YYYY-MM-DD')
    })
  });
  _exports.default = _default;
});