define("admin/templates/components/video-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "i3+G2cIA",
    "block": "{\"symbols\":[],\"statements\":[[10,\"video\"],[14,1,\"my-video\"],[14,0,\"video-js\"],[14,\"controls\",\"\"],[14,\"preload\",\"auto\"],[14,\"data-setup\",\"{}\"],[12],[2,\"\\n  \"],[10,\"source\"],[15,\"src\",[34,0]],[14,4,\"video/mp4\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"src\"]}",
    "meta": {
      "moduleName": "admin/templates/components/video-player.hbs"
    }
  });
  _exports.default = _default;
});