define("admin/mixins/job-application-namespace", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CLIENTSPACE_V2 = _environment.default.CLIENTSPACE_V2;
  var _default = Ember.Mixin.create({
    currentSession: Ember.inject.service(),
    namespace: Ember.computed('currentSession.jobId', function () {
      return "".concat(CLIENTSPACE_V2, "/jobs/").concat(this.currentSession.jobId);
    })
  });
  _exports.default = _default;
});