define("admin/templates/components/crad/jobtag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fGTU96KR",
    "block": "{\"symbols\":[\"@class\",\"@category\",\"@tooltip\",\"@onClick\",\"@active\",\"@tag\"],\"statements\":[[8,\"crad/crad-tag-container\",[],[[\"@class\",\"@tooltip\",\"@onClick\",\"@active\",\"@data-test\"],[[31,[\"crad-jobtag \",[32,1]]],[30,[36,0],[[32,3],[32,3],[30,[36,0],[[32,2],[32,2,[\"name\"]]],null]],null],[32,4],[32,5],\"jobtag\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"jobtag-container\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"tag-text\"],[12],[1,[32,6,[\"name\"]]],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"watchers\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[[32,6,[\"count\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"number-formatter\"]}",
    "meta": {
      "moduleName": "admin/templates/components/crad/jobtag.hbs"
    }
  });
  _exports.default = _default;
});