define("admin/controllers/admin/skills/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    queryParams: ['subcatid'],
    filteredCategories: Ember.computed('searchQuery', 'model.categories', 'subCategories', function () {
      this.model.categories.forEach(function (cat) {
        return Ember.set(cat, 'filteredSubcategories', cat.subCategories);
      });
      var query = this.searchQuery;
      if (!query) {
        return this.model.categories;
      }
      query = query.trim();
      var filteredCategories = this.model.categories.filter(function (category) {
        var cat = category.name.toLowerCase().search(query.toLowerCase()) >= 0;
        var subcat = category.subCategories.some(function (subcat) {
          return subcat.name.toLowerCase().search(query.toLowerCase()) >= 0;
        });
        var skills = category.subCategories.some(function (subcat) {
          return subcat.skills.some(function (skill) {
            return skill.name.toLowerCase().search(query.toLowerCase()) >= 0;
          });
        });
        return cat || subcat || skills;
      });

      // Filter subcategories
      filteredCategories.forEach(function (category) {
        Ember.set(category, 'filteredSubcategories', category.subCategories.filter(function (subcat) {
          var name = subcat.name.toLowerCase().search(query.toLowerCase()) >= 0;
          var skills = subcat.skills.some(function (skill) {
            return skill.name.toLowerCase().search(query.toLowerCase()) >= 0;
          });
          return subcat.category.id === category.id && (name || skills);
        }));
      });
      return filteredCategories;
    }),
    selectedSubcat: Ember.computed('subcatid', 'model.categories', function () {
      var _this = this;
      var subcat = null;
      if (this.model.categories) {
        this.model.categories.forEach(function (c) {
          var subcatFilteredArr = c.subCategories.filter(function (s) {
            return s.id === Number(_this.subcatid);
          });
          if (subcatFilteredArr && subcatFilteredArr.length) {
            subcat = subcatFilteredArr[0];
          }
        });
      }
      return subcat;
    }),
    actions: {
      refresh: function refresh() {
        this.send('refreshData');
      }
    }
  });
  _exports.default = _default;
});