define("admin/models/me", ["exports", "@ember-data/model", "ember-api-actions", "admin/models/user"], function (_exports, _model, _emberApiActions, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _user.default.extend({
    acceptedTerms: (0, _model.attr)('boolean'),
    employeeCount: (0, _model.attr)('string'),
    showEmployeeCount: (0, _model.attr)('boolean'),
    statusdescription: (0, _model.attr)('string'),
    ads: (0, _model.attr)('number'),
    lastactive: (0, _model.attr)('string'),
    phantom: (0, _model.attr)('boolean'),
    sysmsgcount: (0, _model.attr)('number'),
    premium: (0, _model.attr)('boolean'),
    canedit: (0, _model.attr)('boolean'),
    accessapplications: (0, _model.attr)('boolean'),
    cookies: (0, _model.attr)('model', 'cookies'),
    nps: (0, _emberApiActions.memberAction)({
      path: 'nps',
      type: 'get'
    }),
    submitNps: (0, _emberApiActions.memberAction)({
      path: 'nps',
      type: 'post'
    }),
    submitNpsComment: (0, _emberApiActions.memberAction)({
      path: 'nps/comment',
      type: 'post'
    }),
    submitEmployeeCount: (0, _emberApiActions.memberAction)({
      path: 'employeeCount',
      type: 'post'
    }),
    acceptTerms: (0, _emberApiActions.collectionAction)({
      path: 'terms',
      type: 'post'
    }),
    saveCookies: (0, _emberApiActions.collectionAction)({
      path: 'cookies',
      type: 'patch'
    })
  });
  _exports.default = _default;
});