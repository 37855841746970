define("admin/models/customer-contact", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    active: (0, _model.attr)('boolean'),
    added: (0, _model.attr)('moment'),
    contactName: (0, _model.attr)('string'),
    companyName: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    message: (0, _model.attr)('string'),
    openPositions: (0, _model.attr)('string')
  });
  _exports.default = _default;
});