define("admin/compiled/customer-stats/CustomerStats", ["exports", "@alfred-is/alfred-design-system", "classnames", "react", "admin/compiled/ui/Button", "admin/compiled/ui/Select", "admin/compiled/ui/Spinner", "admin/compiled/utils/emberContext"], function (_exports, _alfredDesignSystem, _classnames, _react, _Button, _Select, _Spinner, _emberContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CustomerStats = void 0;
  var __awaiter = void 0 && (void 0).__awaiter || function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P ? value : new P(function (resolve) {
        resolve(value);
      });
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }
      function rejected(value) {
        try {
          step(generator["throw"](value));
        } catch (e) {
          reject(e);
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };
  var __generator = void 0 && (void 0).__generator || function (thisArg, body) {
    var _ = {
        label: 0,
        sent: function sent() {
          if (t[0] & 1) throw t[1];
          return t[1];
        },
        trys: [],
        ops: []
      },
      f,
      y,
      t,
      g;
    return g = {
      next: verb(0),
      "throw": verb(1),
      "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
      return this;
    }), g;
    function verb(n) {
      return function (v) {
        return step([n, v]);
      };
    }
    function step(op) {
      if (f) throw new TypeError("Generator is already executing.");
      while (g && (g = 0, op[0] && (_ = 0)), _) try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];
        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;
          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };
          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;
          case 7:
            op = _.ops.pop();
            _.trys.pop();
            continue;
          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }
            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }
            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }
            if (t && _.label < t[2]) {
              _.label = t[2];
              _.ops.push(op);
              break;
            }
            if (t[2]) _.ops.pop();
            _.trys.pop();
            continue;
        }
        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
      if (op[0] & 5) throw op[1];
      return {
        value: op[0] ? op[1] : void 0,
        done: true
      };
    }
  };
  var __spreadArray = void 0 && (void 0).__spreadArray || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
      if (ar || !(i in from)) {
        if (!ar) ar = Array.prototype.slice.call(from, 0, i);
        ar[i] = from[i];
      }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
  };
  var CustomerStats = function CustomerStats() {
    var _a = (0, _react.useContext)(_emberContext.default),
      apiAdmin = _a.apiAdmin,
      apiClient = _a.apiClient;
    var _b = (0, _react.useState)(false),
      isLoading = _b[0],
      setIsLoading = _b[1];
    var _c = (0, _react.useState)(),
      stats = _c[0],
      setStats = _c[1];
    var _d = (0, _react.useState)([]),
      categories = _d[0],
      setCategories = _d[1];
    var _e = (0, _react.useState)([]),
      areas = _e[0],
      setAreas = _e[1];
    var _f = (0, _react.useState)(),
      selectedCategory = _f[0],
      setCategory = _f[1];
    var _g = (0, _react.useState)(),
      selectedTag = _g[0],
      setTag = _g[1];
    var _h = (0, _react.useState)(),
      selectedArea = _h[0],
      setArea = _h[1];
    var _j = (0, _react.useState)([]),
      zips = _j[0],
      setZips = _j[1];
    var getStats = function getStats() {
      return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              setIsLoading(true);
              return [4 /*yield*/, apiAdmin.get("/watch?tagid=".concat(selectedTag.id, "&zipids=").concat(zips.map(function (sub) {
                return sub.id;
              }).join(',')))];
            case 1:
              result = _a.sent();
              setIsLoading(false);
              setStats(result);
              return [2 /*return*/];
          }
        });
      });
    };

    (0, _react.useEffect)(function () {
      var fetchCategories = function fetchCategories() {
        return __awaiter(void 0, void 0, void 0, function () {
          var categories;
          return __generator(this, function (_a) {
            switch (_a.label) {
              case 0:
                return [4 /*yield*/, apiClient.get("/categories")];
              case 1:
                categories = _a.sent().categories;
                setCategories(categories);
                return [2 /*return*/];
            }
          });
        });
      };

      var fetchAreas = function fetchAreas() {
        return __awaiter(void 0, void 0, void 0, function () {
          var areas;
          return __generator(this, function (_a) {
            switch (_a.label) {
              case 0:
                return [4 /*yield*/, apiClient.get("/locations")];
              case 1:
                areas = _a.sent().areas;
                setAreas(areas);
                return [2 /*return*/];
            }
          });
        });
      };

      fetchCategories();
      fetchAreas();
    }, []);
    var selectSub = function selectSub(sub) {
      if (zips.includes(sub)) {
        setZips(zips.filter(function (item) {
          return item !== sub;
        }));
      } else {
        setZips(__spreadArray(__spreadArray([], zips, true), [sub], false));
      }
    };
    var selectAll = function selectAll(subs) {
      setZips(subs);
    };
    var unselectAll = function unselectAll() {
      setZips([]);
    };
    var zipIsActive = function zipIsActive(sub) {
      return zips.includes(sub);
    };
    if (!categories || !areas) {
      return _react.default.createElement(_Spinner.Spinner, null);
    }
    return _react.default.createElement("div", {
      className: "flex-container"
    }, _react.default.createElement("div", {
      className: "tw-flex tw-mt-4 tw-gap-4"
    }, _react.default.createElement(_Select.Select, {
      defaultValue: selectedCategory ? selectedCategory.id : undefined,
      onChange: function onChange(event) {
        setCategory(categories.find(function (cat) {
          return cat.id === Number(event.target.value);
        }));
      }
    }, !selectedCategory && _react.default.createElement("option", {
      value: ""
    }, "Select category"), (categories || []).map(function (category) {
      return _react.default.createElement("option", {
        key: category.id,
        value: category.id
      }, category.name);
    })), _react.default.createElement(_Select.Select, {
      defaultValue: selectedTag ? selectedTag.id : undefined,
      onChange: function onChange(event) {
        if (!selectedCategory) {
          return;
        }
        setTag(selectedCategory.tags.find(function (_tag) {
          return _tag.id === Number(event.target.value);
        }));
      },
      disabled: !selectedCategory
    }, !selectedTag && _react.default.createElement("option", {
      value: ""
    }, "Select tag"), !!selectedCategory && (selectedCategory.tags || []).map(function (tag) {
      return _react.default.createElement("option", {
        key: tag.id,
        value: tag.id
      }, tag.name);
    })), _react.default.createElement(_Select.Select, {
      defaultValue: selectedArea ? selectedArea.id : undefined,
      onChange: function onChange(event) {
        return setArea(areas.find(function (area) {
          return area.id === Number(event.target.value);
        }));
      }
    }, !selectedArea && _react.default.createElement("option", {
      value: ""
    }, "Select area"), (areas || []).map(function (area) {
      return _react.default.createElement("option", {
        key: area.id,
        value: area.id
      }, area.name);
    }))), !!selectedArea && _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-grid tw-grid-cols-6 tw-bg-white tw-p-px', 'tw-border tw-border-gray-300 tw-rounded tw-mt-4')
    }, _react.default.createElement("button", {
      onClick: function onClick() {
        return selectAll(selectedArea.subAreas);
      },
      className: "tw-text-sm tw-font-bold tw-text-left tw-px-8 hover:tw-bg-gray-100"
    }, "Select all"), _react.default.createElement("button", {
      onClick: unselectAll,
      className: "tw-text-sm tw-font-bold tw-text-left tw-px-8 hover:tw-bg-gray-100"
    }, "Unselect all"), (selectedArea ? selectedArea.subAreas : []).map(function (sub) {
      return _react.default.createElement("div", {
        key: "sub-".concat(sub.id),
        className: (0, _classnames.default)('tw-flex tw-items-center tw-text-sm tw-text-gray-700', 'tw-px-2 tw-py-2 tw-rounded hover:tw-bg-gray-100 tw-cursor-pointer'),
        onClick: function onClick() {
          return selectSub(sub);
        }
      }, _react.default.createElement("div", {
        className: (0, _classnames.default)('tw-h-4 tw-w-4 tw-mr-2 tw-border tw-border-gray-300 tw-rounded', 'tw-flex tw-items-center tw-justify-center tw-shrink-0', {
          'tw-bg-blue-500 tw-border-blue-600': zipIsActive(sub)
        })
      }, zipIsActive(sub) && _react.default.createElement(_alfredDesignSystem.Icon, {
        kind: _alfredDesignSystem.AlfredIconT.Success,
        color: "#ffffff",
        size: 10
      })), sub.name);
    })), !!zips.length && _react.default.createElement(_react.default.Fragment, null, selectedTag && _react.default.createElement("div", {
      className: "tw-text-center tw-mt-6 tw-font-bold"
    }, selectedTag === null || selectedTag === void 0 ? void 0 : selectedTag.name), _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-grid tw-grid-cols-6 tw-bg-white tw-p-px', 'tw-border tw-border-gray-300 tw-rounded tw-mt-4')
    }, zips.map(function (sub) {
      return _react.default.createElement("div", {
        key: "zip-".concat(sub.id),
        className: (0, _classnames.default)('tw-group tw-flex tw-items-center tw-justify-between tw-text-sm tw-text-gray-700', 'tw-px-2 tw-py-2 tw-rounded hover:tw-bg-gray-100 tw-cursor-pointer'),
        onClick: function onClick() {
          return selectSub(sub);
        }
      }, sub.name, _react.default.createElement(_alfredDesignSystem.Icon, {
        kind: _alfredDesignSystem.AlfredIconT.Exit,
        color: "#F44E4E",
        size: 10,
        className: "group-hover:tw-block tw-hidden"
      }));
    }))), _react.default.createElement("div", {
      className: "tw-mt-4 tw-text-center"
    }, _react.default.createElement(_Button.Button, {
      color: "blue",
      disabled: !(selectedTag && zips.length),
      onClick: function onClick() {
        return getStats();
      }
    }, "Submit")), isLoading && _react.default.createElement(_Spinner.Spinner, null), !isLoading && !!stats && _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-bg-white tw-border tw-border-gray-300 tw-rounded', 'tw-mt-4 tw-p-8 tw-w-1/2 tw-mx-auto')
    }, _react.default.createElement("div", {
      className: "tw-flex tw-justify-between"
    }, "One month: ", _react.default.createElement("strong", null, stats.countPerOneMonth)), _react.default.createElement("div", {
      className: "tw-flex tw-justify-between"
    }, "Three months: ", _react.default.createElement("strong", null, stats.countPerThreeMonths)), _react.default.createElement("div", {
      className: "tw-flex tw-justify-between"
    }, "Six months: ", _react.default.createElement("strong", null, stats.countPerSixMonths))));
  };
  _exports.CustomerStats = CustomerStats;
});