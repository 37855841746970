define("admin/controllers/admin/sales/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    queryParams: ['date', 'search'],
    date: (0, _moment.default)().format('YYYY-MM'),
    offset: 400,
    invoices: Ember.computed.alias('model.invoices'),
    summary: Ember.computed.alias('model.summary'),
    apiAdmin: Ember.inject.service(),
    actions: {
      exportInvoice: function exportInvoice(invoice) {
        var _this = this;
        this.apiAdmin.get("/sales/invoices/".concat(invoice.id, "/export")).then(function () {
          invoice.set('exportDate', 'date');
          _this.notifications.success("Successfully exported");
        });
      }
    }
  });
  _exports.default = _default;
});