define("admin/helpers/filter-utils/question-filter", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.questionFilter = void 0;
  var questionFilter = function questionFilter(answers, filter, fit) {
    if (filter != null && filter.length) {
      filter.forEach(function (questionFilter) {
        var question = questionFilter.question;
        var answer = answers.find(function (a) {
          return a.get('questionId.id') == question.id;
        });
        if (answer == null) {
          fit = false;
          return;
        } // should not happen unless some error occured and the user did not answer the question.
        if (question.get('type.type') === 'DATE') {
          if (!answer.date) {
            fit = false;
          } else {
            var answerDate = (0, _moment.default)(answer.date.toISOString());
            if (answerDate.isBefore(questionFilter.values[0]) || answerDate.isAfter(questionFilter.values[1])) {
              fit = false;
            }
          }
        } else {
          var optionIds = answers.map(function (a) {
            return a.optionId;
          });
          var desiredOptionIds = questionFilter.options.map(function (o) {
            return o.id;
          });
          if (desiredOptionIds.length && !desiredOptionIds.some(function (optId) {
            return optionIds.includes(optId);
          })) {
            fit = false;
          }
        }
      });
    }
    return fit;
  };
  _exports.questionFilter = questionFilter;
});