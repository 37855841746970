define("admin/components/compass/editable-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @edit}}
    <Textarea @value={{@value}}
      @focusOut={{@focusOut}}
      placeholder={{@placeholder}}
      class="editable textarea"
      autocomplete="off"
      ...attributes />
  {{else}}
    <div class="text">{{{format-text @value}}}</div>
  {{/if}}
  
  */
  {
    "id": "ZohwGTju",
    "block": "{\"symbols\":[\"@value\",\"@focusOut\",\"@placeholder\",\"&attrs\",\"@edit\"],\"statements\":[[6,[37,1],[[32,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"textarea\",[[16,\"placeholder\",[32,3]],[24,0,\"editable textarea\"],[24,\"autocomplete\",\"off\"],[17,4]],[[\"@value\",\"@focusOut\"],[[32,1],[32,2]]],null],[2,\"\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"text\"],[12],[2,[30,[36,0],[[32,1]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"format-text\",\"if\"]}",
    "meta": {
      "moduleName": "admin/components/compass/editable-textarea.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});