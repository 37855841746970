define("admin/compiled/job-card/job-card-info", ["exports", "classnames", "react"], function (_exports, _classnames, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.JobCardInfoRow = void 0;
  var JobCardInfoRow = function JobCardInfoRow(_a) {
    var items = _a.items;
    return _react.default.createElement("div", {
      className: "tw-overflow-y-scroll"
    }, items === null || items === void 0 ? void 0 : items.map(function (row) {
      return _react.default.createElement("div", {
        className: "tw-p-5 tw-border-b tw-border-gray-200 last:tw-border-0"
      }, _react.default.createElement("span", {
        className: "tw-text-gray-500 tw-mr-2"
      }, row.label, ":"), _react.default.createElement("span", {
        className: (0, _classnames.default)('tw-font-semibold')
      }, row.content));
    }));
  };
  _exports.JobCardInfoRow = JobCardInfoRow;
});