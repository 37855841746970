define("admin/components/notification-center", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    page: 0,
    limit: 10,
    pageChanged: Ember.observer('page', function () {
      this.loadNotifications();
    }),
    loadNotifications: function loadNotifications() {
      var _this = this;
      var data = this.data(this.page, this.limit);
      this.set('loading', true);
      data.then(function (data) {
        var messages = data.messages.toArray();
        if (messages.length < _this.limit) {
          _this.set('fullyLoaded', true);
        }
        _this.set('messages', _this.messages.concat(messages));
        _this.set('loading');
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.messages = [];
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.loadNotifications();
    },
    willDestroyElement: function willDestroyElement() {
      this.afterClose();
      this._super.apply(this, arguments);
    },
    actions: {
      close: function close() {
        this.toggle();
      },
      loadMore: function loadMore() {
        this.incrementProperty('page');
      }
    }
  });
  _exports.default = _default;
});