define("admin/helpers/filter-utils/age-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ageFilter = void 0;
  var ageFilter = function ageFilter(age, min, max, fit) {
    if (age < min || age > max || !age && max < 100) {
      fit = false;
    }
    return fit;
  };
  _exports.ageFilter = ageFilter;
});