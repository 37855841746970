define("admin/templates/components/image-cropper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "uKEBUIN1",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"cropper-container\"],[12],[2,\"\\n  \"],[10,\"img\"],[15,\"src\",[34,0]],[14,\"alt\",\"\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"zoom-buttons\"],[12],[2,\"\\n  \"],[11,\"a\"],[4,[38,1],[[32,0],\"zoomOut\"],null],[12],[10,\"img\"],[14,\"src\",\"/img/icons/minus.svg\"],[14,\"alt\",\"-\"],[12],[13],[13],[2,\"\\n  \"],[11,\"a\"],[4,[38,1],[[32,0],\"zoomIn\"],null],[12],[10,\"img\"],[14,\"src\",\"/img/icons/plus.svg\"],[14,\"alt\",\"+\"],[12],[13],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"edit-form clear-form text-center\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn gray-brd inline\"],[4,[38,1],[[32,0],\"closeModal\"],null],[12],[1,[30,[36,2],[\"string.cancel\"],null]],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn inline\"],[4,[38,1],[[32,0],\"saveCropImage\"],null],[12],[1,[30,[36,2],[\"string.save\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"temp\",\"action\",\"t\"]}",
    "meta": {
      "moduleName": "admin/templates/components/image-cropper.hbs"
    }
  });
  _exports.default = _default;
});