define("admin/components/customer-stats-react/customer-stats-react", ["exports", "admin/compiled/customer-stats/CustomerStats", "admin/compiled/utils/emberContext", "admin/react-component"], function (_exports, _CustomerStats, _emberContext, _reactComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  
  */
  {
    "id": "Fbbhz+Cx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/components/customer-stats-react/customer-stats-react.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _reactComponent.default.extend({
    apiAdmin: Ember.inject.service(),
    apiClient: Ember.inject.service(),
    renderComponent: function renderComponent() {
      this.reactRender(React.createElement(_emberContext.EmberProvider, {
        value: {
          apiAdmin: this.apiAdmin,
          apiClient: this.apiClient
        }
      }, React.createElement(_CustomerStats.CustomerStats, null)));
    }
  }));
  _exports.default = _default;
});