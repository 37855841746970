define("admin/compiled/modal/JobsListAnnouncementsModal", ["exports", "react", "react-redux", "admin/compiled/redux/actions/modal", "admin/compiled/ui/Button", "admin/compiled/ui/page-dots/PageDots", "admin/compiled/utils/emberContext", "admin/compiled/modal/WhiteModal", "sanitize-html"], function (_exports, _react, _reactRedux, _modal, _Button, _PageDots, _emberContext, _WhiteModal, _sanitizeHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.JobsListAnnouncementsModal = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var JobsListAnnouncementsModal = function JobsListAnnouncementsModal(_a) {
    var announcements = _a.announcements,
      onDismiss = _a.onDismiss;
    var defaultOptions = {
      allowedTags: ['b', 'i', 'em', 'strong', 'a', 'br', 'p', 'div'],
      allowedAttributes: {
        a: ['href', 'target', 'style'],
        div: ['style']
      }
    };
    var sanitize = function sanitize(dirty, options) {
      return {
        __html: (0, _sanitizeHtml.default)(dirty, __assign(__assign({}, defaultOptions), options))
      };
    };
    var _b = (0, _react.useContext)(_emberContext.default),
      transitionTo = _b.transitionTo,
      intl = _b.intl,
      gtm = _b.gtm;
    var _c = (0, _react.useState)(announcements.length ? 0 : null),
      announcementEl = _c[0],
      setAnnouncementEl = _c[1];
    if (!announcementEl && announcementEl != 0) {
      return _react.default.createElement(_WhiteModal.WhiteModal, null, intl.t('announcements-modal.empty'));
    }
    var announcement = announcements[announcementEl];
    var dispatch = (0, _reactRedux.useDispatch)();
    (0, _react.useEffect)(function () {
      return function () {
        announcements.forEach(function (a) {
          return a.dismiss({
            jobid: null
          });
        });
        gtm.announcementModalClose(announcement.announcementType);
        onDismiss(); // for removing the bug
      };
    }, []);
    return _react.default.createElement(_WhiteModal.WhiteModal, {
      preventOutsideClick: true
    }, announcements.length > 1 && _react.default.createElement("div", {
      style: {
        marginBottom: '20px'
      }
    }, _react.default.createElement(_PageDots.PageDots, {
      elements: announcements.length,
      activeEl: announcementEl,
      onChange: function onChange(el) {
        return setAnnouncementEl(el);
      }
    })), _react.default.createElement("img", {
      className: 'jobs-list-announcements-modal--image',
      src: announcement.icon,
      alt: ''
    }), announcement.label && _react.default.createElement("div", {
      className: 'jobs-list-announcements-modal--label-container',
      style: {
        backgroundColor: announcement.labelColor
      }
    }, _react.default.createElement("div", {
      className: 'jobs-list-announcements-modal--label'
    }, announcement.label)), _react.default.createElement("div", {
      className: 'jobs-list-announcements-modal--title'
    }, announcement.title), _react.default.createElement("div", {
      className: 'jobs-list-announcements-modal--content'
    }, _react.default.createElement("div", {
      dangerouslySetInnerHTML: sanitize(announcement.description, defaultOptions)
    })), announcement.route && _react.default.createElement("div", {
      style: {
        textAlign: 'center'
      }
    }, _react.default.createElement("span", {
      className: 'jobs-list-announcements-modal--route',
      onClick: function onClick() {
        announcements.forEach(function (a) {
          return a.dismiss({
            jobid: null
          });
        });
        gtm.announcementModalSeeMore(announcement.announcementType);
        transitionTo(announcement === null || announcement === void 0 ? void 0 : announcement.route);
      }
    }, intl.t('announcements-modal.details'))), _react.default.createElement("div", {
      className: 'jobs-list-announcements-modal--actions'
    }, _react.default.createElement(_Button.Button, {
      onClick: function onClick() {
        gtm.announcementModalClose(announcement.announcementType);
        dispatch((0, _modal.hideModal)());
      },
      color: 'gray'
    }, intl.t('announcements-modal.close')), announcementEl < announcements.length - 1 && _react.default.createElement(_Button.Button, {
      onClick: function onClick() {
        gtm.announcementModalNext(announcement.announcementType);
        setTimeout(function () {
          return setAnnouncementEl(announcementEl + 1);
        }, 100);
      }
    }, intl.t('announcements-modal.next'))));
  };
  _exports.JobsListAnnouncementsModal = JobsListAnnouncementsModal;
});