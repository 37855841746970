define("admin/templates/components/applications/video-questions-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "GxNKGApL",
    "block": "{\"symbols\":[\"@template\",\"@toggle\",\"@done\"],\"statements\":[[8,\"modal-dialog\",[],[[\"@overlayClass\"],[\"full-screen-modal\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"forms/video-questions\",[],[[\"@template\",\"@toggle\",\"@done\"],[[32,1],[32,2],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/templates/components/applications/video-questions-modal.hbs"
    }
  });
  _exports.default = _default;
});