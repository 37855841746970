define("admin/components/crad/crad-email-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="crad-email-warning">
    <Ui::IconSection @title={{t "alfred.create-job.email-warning.title"}}
                     @icon="alfred-chat">
      <p>{{{t "alfred.create-job.email-warning.p1"}}}</p>
      <p>{{{t "alfred.create-job.email-warning.p2"}}}</p>
      <div class="crad-email-warning--action">
        <LinkTo @route="client.create-job.edit" @model="{{@jobId}}">{{t "alfred.create-job.email-warning.use-alfrello"}} &nbsp; <span>→</span></LinkTo>
      </div>
    </Ui::IconSection>
  </div>
  
  */
  {
    "id": "mWVZbOe/",
    "block": "{\"symbols\":[\"@jobId\"],\"statements\":[[10,\"div\"],[14,0,\"crad-email-warning\"],[12],[2,\"\\n  \"],[8,\"ui/icon-section\",[],[[\"@title\",\"@icon\"],[[30,[36,0],[\"alfred.create-job.email-warning.title\"],null],\"alfred-chat\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"p\"],[12],[2,[30,[36,0],[\"alfred.create-job.email-warning.p1\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,[30,[36,0],[\"alfred.create-job.email-warning.p2\"],null]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"crad-email-warning--action\"],[12],[2,\"\\n      \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"client.create-job.edit\",[31,[[32,1]]]]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"alfred.create-job.email-warning.use-alfrello\"],null]],[2,\"   \"],[10,\"span\"],[12],[2,\"→\"],[13]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "admin/components/crad/crad-email-warning.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});