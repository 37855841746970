define("admin/adapters/rest-superadmin", ["exports", "admin/adapters/rest-clientweb", "admin/config/environment"], function (_exports, _restClientweb, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SUPERADMINSPACE = _environment.default.SUPERADMINSPACE;
  var _default = _restClientweb.default.extend({
    namespace: SUPERADMINSPACE
  });
  _exports.default = _default;
});