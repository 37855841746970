define("admin/compiled/alfrello-profile-invite/VideoInterviewInvite", ["exports", "moment", "react", "admin/compiled/utils/languageContext", "admin/compiled/alfrello-profile-invite/AlfrelloProfileInvite", "admin/compiled/alfrello-profile-invite/EmptyInvite"], function (_exports, _moment, _react, _languageContext, _AlfrelloProfileInvite, _EmptyInvite) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VideoInterviewInvite = void 0;
  var VideoInterviewInvite = function VideoInterviewInvite(_a) {
    var interview = _a.interview,
      showInviteModal = _a.showInviteModal;
    var intl = (0, _react.useContext)(_languageContext.default);
    if (!interview) {
      return _react.default.createElement(_EmptyInvite.EmptyInvite, {
        showInviteModal: showInviteModal,
        icon: 'video'
      }, intl.t('string.invite_video'));
    }
    var meta = "".concat(interview.get('title'), " (").concat(intl.t('client.applications.profile.due'), ": ").concat((0, _moment.default)(interview.get('deadline')).format('D. MMM @ H:mm'), ")");
    var getStatus = function getStatus(status) {
      switch (status) {
        case 'FINISHED':
          return intl.t('vi_status.FINISHED');
        default:
          return '';
      }
    };
    var status = getStatus(interview.get('status')) ? "- ".concat(getStatus(interview.get('status'))) : '';
    return _react.default.createElement(_AlfrelloProfileInvite.AlfrelloProfileInviteSent, {
      isGreen: interview.get('status') === 'FINISHED',
      title: "".concat(intl.t('client.applications.profile.videointerview'), " ").concat(status),
      meta: meta,
      contextMenuItems: [{
        title: intl.t('string.invite_again'),
        onClick: showInviteModal
      }]
    });
  };
  _exports.VideoInterviewInvite = VideoInterviewInvite;
});