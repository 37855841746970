define("admin/components/jobs/invoice-email-modal", ["exports", "admin/components/edit-modal"], function (_exports, _editModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _editModal.default.extend({
    isValid: Ember.computed('email', function () {
      return this.email && this.email.email();
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.get('brands.length') === 1) {
        this.set('email', this.get('brands.firstObject.invoice.email'));
      }
    },
    actions: {
      confirm: function confirm() {
        this.set('currentSession.client.invoice.email', this.email);
        this.get('currentSession.client').save();
      }
    }
  });
  _exports.default = _default;
});