define("admin/templates/two-factor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "CR4MRsUA",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"login\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"overlay\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n\\n        \"],[10,\"img\"],[14,0,\"logo\"],[14,\"src\",\"/img/alfred-icon.png\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"wrap\"],[12],[2,\"\\n          \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"login.two-factor.verification-code\"],null]],[13],[2,\"\\n\\n          \"],[10,\"div\"],[14,0,\"login-inputs\"],[12],[2,\"\\n            \"],[8,\"input\",[[16,0,[30,[36,2],[[32,0,[\"code\"]],\"checked\"],null]],[16,\"placeholder\",[30,[36,0],[\"login.two-factor.code\"],null]]],[[\"@type\",\"@value\",\"@enter\"],[\"text\",[32,0,[\"code\"]],[30,[36,1],[[32,0],\"verify\"],null]]],null],[2,\"\\n          \"],[13],[2,\"\\n\\n          \"],[10,\"div\"],[14,0,\"login-submit\"],[12],[2,\"\\n            \"],[11,\"button\"],[24,0,\"btn\"],[4,[38,3],[\"click\",[32,0,[\"verify\"]]],null],[12],[1,[30,[36,0],[\"string.continue\"],null]],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"foot\"],[12],[2,\"\\n          \"],[11,\"a\"],[4,[38,3],[\"click\",[32,0,[\"cancel\"]]],null],[12],[1,[30,[36,0],[\"string.back\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"if\",\"on\"]}",
    "meta": {
      "moduleName": "admin/templates/two-factor.hbs"
    }
  });
  _exports.default = _default;
});