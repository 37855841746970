define("admin/routes/client/applications/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    reloadTime: 60000,
    utils: Ember.inject.service(),
    queryParams: {
      applicationid: {
        refreshModel: true
      },
      messages: {
        refreshModel: false
      }
    },
    model: function model(params, transition) {
      if (transition.from && transition.from.name !== 'client.jobs.index' && transition.from.name !== transition.to.name && this.modelFor('client.applications.index')) {
        return this.modelFor('client.applications.index');
      }
      var _this$modelFor = this.modelFor('client.applications'),
        job = _this$modelFor.job,
        questions = _this$modelFor.questions,
        languages = _this$modelFor.languages,
        languageskills = _this$modelFor.languageskills,
        degrees = _this$modelFor.degrees,
        compasses = _this$modelFor.compasses;
      job.clearQuestions();
      return Ember.RSVP.hash({
        announcements: this.store.query('announcement', {
          jobid: job.get('id'),
          placement: 'ALFRELLO'
        }),
        job: job,
        questions: questions,
        languages: languages,
        languageskills: languageskills,
        columns: this.store.query('column', {}),
        degrees: degrees,
        compasses: compasses,
        applications: [],
        applicationId: params.applicationid
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.set('currentSession.rememberPrevious', 'profile');
      controller.set('columns', model.columns.toArray());
      if (!model.applicationId) {
        controller.set('profileModalApplication', null);
        $('body').removeClass('with-modal');
      }
      if (!controller.get('model.applications.length')) {
        controller.set('isLoading', true);
        this.store.query('jobapplication', {
          includerejected: true
        }).then(function (res) {
          controller.set('model.applications', res);
        }).finally(function () {
          controller.set('isLoading');
        });
      }
    },
    actions: {
      refresh: function refresh() {
        var model = this.modelFor('client.applications');
        if (model && model.job) {
          model.job.reload();
        }
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});