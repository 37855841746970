define("admin/controllers/admin/library/crad-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    brands: [{
      icon: 'https://storage.googleapis.com/dev-alfred-images/logo/53af61df-af0d-4c7a-9e3d-0ee9a2d77bc4.png?dpr=1&h=90',
      name: 'Sniðugt ehf.'
    }, {
      icon: 'https://storage.googleapis.com/dev-alfred-images/logo/8038652a-047b-41a9-a303-0b340b1a2656.png?dpr=1&h=90',
      name: 'Alfreð'
    }, {
      icon: 'https://storage.googleapis.com/dev-alfred-images/logo/8038652a-047b-41a9-a303-0b340b1a2656.png?dpr=1&h=90',
      name: 'Alfreð'
    }, {
      icon: 'https://storage.googleapis.com/dev-alfred-images/logo/8038652a-047b-41a9-a303-0b340b1a2656.png?dpr=1&h=90',
      name: 'Alfreð'
    }, {
      icon: 'https://storage.googleapis.com/dev-alfred-images/logo/8038652a-047b-41a9-a303-0b340b1a2656.png?dpr=1&h=90',
      name: 'Alfreð'
    }, {
      icon: 'https://storage.googleapis.com/dev-alfred-images/logo/8038652a-047b-41a9-a303-0b340b1a2656.png?dpr=1&h=90',
      name: 'Alfreð'
    }, {
      icon: 'https://storage.googleapis.com/dev-alfred-images/logo/8038652a-047b-41a9-a303-0b340b1a2656.png?dpr=1&h=90',
      name: 'Alfreð'
    }, {
      icon: 'https://storage.googleapis.com/dev-alfred-images/logo/8038652a-047b-41a9-a303-0b340b1a2656.png?dpr=1&h=90',
      name: 'Alfreð'
    }],
    jobtypes: [{
      id: 1,
      name: 'Fullt starf'
    }, {
      id: 2,
      name: 'Hlutastarf'
    }, {
      id: 3,
      name: 'Tímabundið'
    }, {
      id: 4,
      name: 'Sumarstarf'
    }, {
      id: 5,
      name: 'Lærlingur'
    }]
  });
  _exports.default = _default;
});