define("admin/models/invoiceline", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    amount: (0, _model.attr)('number'),
    brandId: (0, _model.attr)('string'),
    brandLogo: (0, _model.attr)('string'),
    brandName: (0, _model.attr)('string'),
    clicks: (0, _model.attr)('number'),
    created: (0, _model.attr)('string'),
    discount: (0, _model.attr)('number'),
    jobId: (0, _model.attr)('number'),
    productIcon: (0, _model.attr)('string'),
    productKey: (0, _model.attr)('string'),
    title: (0, _model.attr)('string')
  });
  _exports.default = _default;
});