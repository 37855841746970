define("admin/components/ui/search-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['search-input'],
    classNameBindings: ['loading']
  });
  _exports.default = _default;
});