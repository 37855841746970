define("admin/models/videointerview-answer", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    duration: (0, _model.attr)('number'),
    partial: (0, _model.attr)('boolean'),
    signed: (0, _model.attr)('boolean'),
    started: (0, _model.attr)('string'),
    submitted: (0, _model.attr)('string'),
    text: (0, _model.attr)('string'),
    video: (0, _model.attr)('string'),
    time: Ember.computed('duration', function () {
      var m = Math.floor(this.duration / 60);
      var s = '0' + Math.floor(this.duration - m * 60);
      return "".concat(m, ":").concat(s.substr(-2));
    })
  });
  _exports.default = _default;
});