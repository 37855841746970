define("admin/components/crad/crad-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    attributeBindings: ['data-test'],
    actions: {
      select: function select(question, index) {
        if (index !== undefined) {
          this.jobQuestions.removeAt(index);
          this.jobQuestions.insertAt(index, question);
        } else {
          this.jobQuestions.pushObject(question);
        }
      },
      showNewQuestion: function showNewQuestion() {
        this.showNewQuestion();
      },
      clearQuestion: function clearQuestion() {
        this.jobQuestions.removeAt(this.index);
      }
    }
  });
  _exports.default = _default;
});