define("admin/templates/client/settings/msg-templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "laJrgWl/",
    "block": "{\"symbols\":[\"template\",\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"heading tw-mb-6\"],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\",\"@class\"],[\"client.settings.msg-templates.new\",\"btn pull-right\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,1],[\"client.settings.templates.new\"],null]]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[10,\"h4\"],[12],[1,[30,[36,1],[\"replies.title\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"edit-form clear-form\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"table tiny\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,2,[\"messageTemplates\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"col-lg-12 col-sm-10\"],[12],[2,\"\\n            \"],[1,[32,1,[\"title\"]]],[2,\"\\n          \"],[13],[2,\"\\n          \"],[10,\"div\"],[14,0,\"col-lg-9 col-sm-10 tw-text-xs tw-text-right\"],[12],[2,\"\\n            \"],[10,\"span\"],[14,0,\"tw-bg-gray-200 tw-px-4 tw-py-1 tw-rounded-xl\"],[12],[1,[32,1,[\"type\",\"title\"]]],[13],[2,\"\\n          \"],[13],[2,\"\\n          \"],[10,\"div\"],[14,0,\"col-lg-3 col-sm-4 icon tw-text-right\"],[12],[2,\"\\n            \"],[8,\"link-to\",[],[[\"@route\",\"@model\",\"@class\"],[\"client.settings.msg-templates.edit\",[32,1,[\"id\"]],\"edit\"]],null],[2,\"\\n            \"],[8,\"confirm-button\",[],[[\"@onConfirm\",\"@className\",\"@btnColor\"],[[30,[36,0],[[32,0,[\"delete\"]],[32,1,[\"id\"]]],null],\"delete\",\"red\"]],null],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/client/settings/msg-templates/index.hbs"
    }
  });
  _exports.default = _default;
});