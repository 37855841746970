define("admin/templates/components/crad/crad-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "rK+Oy9ye",
    "block": "{\"symbols\":[\"@title\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"crad-title-icon\"],[12],[13],[2,\"\\n\"],[8,\"layout/space\",[],[[\"@left\"],[\"5\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"crad-title-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"crad-title-title\"],[12],[2,\"\\n      \"],[1,[32,1]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"crad-title-subtitle\"],[12],[2,\"\\n      \"],[18,2,null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/templates/components/crad/crad-title.hbs"
    }
  });
  _exports.default = _default;
});