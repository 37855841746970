define("admin/components/jobs/job-skeleton", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    storage: (0, _emberLocalStorage.storageFor)('current-user'),
    itemCount: Ember.computed('storage.lastKnownAdCount', function () {
      var maxPlaceholders = 3;
      if (window.innerHeight > 920) {
        maxPlaceholders = 6;
      }
      if (window.innerHeight > 1200) {
        maxPlaceholders = 9;
      }
      if (this.get('storage.lastKnownAdCount')) {
        return this.get('storage.lastKnownAdCount') > maxPlaceholders ? maxPlaceholders : this.get('storage.lastKnownAdCount');
      }
      return maxPlaceholders;
    }),
    items: Ember.computed('itemCount', function () {
      var items = [];
      for (var i = 0; i < this.itemCount; i++) {
        items.push({
          titleWidth: 80,
          subtitleWidth: 40,
          logo: 'BOX'
        });
      }
      return items;
    })
  });
  _exports.default = _default;
});