define("admin/helpers/can-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.extend({
    currentSession: Ember.inject.service(),
    isPhantom: Ember.computed.alias('currentSession.user.phantom')
  });
  _exports.default = _default;
});