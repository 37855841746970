define("admin/routes/admin/messages/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    queryParams: {
      type: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var type = _ref.type;
      return this.store.query('system-message', {
        page: 0,
        limit: 10,
        type: type
      });
    }
  });
  _exports.default = _default;
});