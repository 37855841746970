define("admin/templates/components/applications/message-all-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "y/B61K+f",
    "block": "{\"symbols\":[\"@column\",\"@message\"],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@overlayClass\"],[[30,[36,0],[[32,0],\"close\"],null],\"modal-popup\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"default-dialog\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"components.applications.message_all_modal.title\"],[[\"column\",\"htmlSafe\"],[[32,1,[\"title\"]],true]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"components.applications.message_all_modal.p\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"edit-form\"],[12],[2,\"\\n      \"],[8,\"textarea\",[],[[\"@value\"],[[32,2]]],null],[2,\"\\n      \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn gray-brd inline\"],[4,[38,0],[[32,0],\"close\"],null],[12],[1,[30,[36,1],[\"string.cancel\"],null]],[13],[2,\"\\n        \"],[10,\"span\"],[15,0,[30,[36,2],[[32,2],\"disabled\"],null]],[12],[2,\"\\n          \"],[11,\"button\"],[16,0,[31,[\"btn blue inline upper \",[30,[36,2],[[32,2],\"disabled\"],null],\" \",[30,[36,4],[[35,3],\"loading\"],null]]]],[4,[38,0],[[32,0],\"sendMessage\"],null],[12],[1,[30,[36,1],[\"string.send\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"unless\",\"loading\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/applications/message-all-modal.hbs"
    }
  });
  _exports.default = _default;
});