define("admin/compiled/job-item/GaJobItem", ["exports", "@alfred-is/alfred-design-system", "moment", "react", "admin/compiled/job-card/job-card", "admin/compiled/jobs-list/jobs-context-menu", "admin/compiled/utils", "admin/compiled/utils/emberContext"], function (_exports, _alfredDesignSystem, _moment, _react, _jobCard, _jobsContextMenu, _utils, _emberContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GaJobItem = void 0;
  var GaJobItem = function GaJobItem(_a) {
    var _b, _c;
    var job = _a.job,
      subscription = _a.subscription,
      actions = _a.actions;
    var _d = (0, _react.useContext)(_emberContext.default),
      intl = _d.intl,
      config = _d.config;
    var thousand = config.REGION.thousand;
    var brand = job.brand;
    var numberFormatter = function numberFormatter(number) {
      return (0, _utils.formatNumber)(number, thousand);
    };
    var infoItems = [{
      label: intl.t('string.opened'),
      content: (_b = numberFormatter(job.opened)) === null || _b === void 0 ? void 0 : _b.toString()
    }, {
      label: intl.t('string.applied'),
      content: ((_c = numberFormatter(job.applied)) === null || _c === void 0 ? void 0 : _c.toString()) || '0'
    }, {
      label: intl.t('general-application.created'),
      content: (0, _moment.default)(job.startdate).format('DD. MMM YYYY')
    }, {
      label: intl.t('general-application.next-payment'),
      content: subscription ? (0, _moment.default)(subscription === null || subscription === void 0 ? void 0 : subscription.nextRenewal).format('DD. MMM YYYY') : intl.t('general-application.never-renew')
    }];
    return _react.default.createElement(_jobCard.JobCard, {
      variant: _jobCard.JobCardVariant.BLUE
    }, function (_a) {
      var infoActive = _a.infoActive;
      return _react.default.createElement(_jobCard.JobCard.Container, null, _react.default.createElement(_jobCard.JobCard.Header, {
        brandName: brand.get('name'),
        logo: brand.get('logo'),
        hideInfo: !job.hasAccess
      }, _react.default.createElement(_jobCard.JobCard.Header.Menu, {
        withInfo: job.hasAccess,
        menuItems: (0, _jobsContextMenu.getMenuItems)(job, actions)
      })), !infoActive && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_jobCard.JobCard.Title, null, job.title), _react.default.createElement(_jobCard.JobCard.Status, {
        color: job.isActive && !job.scheduled ? _alfredDesignSystem.colors.green500 : _alfredDesignSystem.colors.gray500
      }, job.status.description), _react.default.createElement(_jobCard.JobCard.Spacer, null), job.hasAccess && _react.default.createElement(_jobCard.JobCard.CenteredContent, null, !job.useAlfrello ? _react.default.createElement(_jobCard.JobCard.ExternalBtn, {
        isEmail: !!job.applyemail,
        onClick: job.applyweb ? function () {
          if (job.applyweb) {
            window.open(job.applyweb);
          }
        } : undefined
      }, (0, _alfredDesignSystem.cutText)(job.applyweb || job.applyemail, 35), job.applyemail ? " (".concat(job.applied, ")") : null) : _react.default.createElement(_jobCard.JobCard.ApplicationsBtn, {
        applications: job.applied,
        onClick: function onClick() {
          return actions.openApplications();
        }
      }, intl.t('string.applications')), _react.default.createElement(_jobCard.JobCard.Actions, null, !!job.messages && _react.default.createElement(_jobCard.JobCard.Action, {
        onClick: function onClick() {
          return actions.openApplications();
        },
        metaType: 'MESSAGES',
        metaValue: job.messages.toString()
      }, intl.t('string.messages')), _react.default.createElement(_jobCard.JobCard.Action, {
        metaType: 'ICON',
        metaIcon: _alfredDesignSystem.AlfredIconT.Preview,
        onClick: function onClick() {
          var _a;
          return (_a = actions.triggerGaVisibility) === null || _a === void 0 ? void 0 : _a.call(actions);
        }
      }, intl.t('ga-application-visibility.title')))), !job.hasAccess && _react.default.createElement(_jobCard.JobCard.CenteredContent, null, _react.default.createElement("div", {
        className: 'tw-text-center tw-text-gray-500'
      }, intl.t('ga-application.no-access')))), infoActive && _react.default.createElement(_jobCard.JobCard.InfoRow, {
        items: infoItems
      }));
    });
  };
  _exports.GaJobItem = GaJobItem;
});