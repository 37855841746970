define("admin/compiled/jobs-list/jobs-info", ["exports", "react", "admin/compiled/utils/emberContext", "admin/compiled/utils"], function (_exports, _react, _emberContext, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getRegularJobInfoItems = void 0;
  var getRegularJobInfoItems = function getRegularJobInfoItems(job) {
    var _a, _b;
    var _c = (0, _react.useContext)(_emberContext.default),
      moment = _c.moment,
      intl = _c.intl,
      config = _c.config;
    var thousand = config.REGION.thousand;
    var numberFormatter = function numberFormatter(number) {
      return (0, _utils.formatNumber)(number, thousand);
    };
    var items = [{
      label: intl.t('string.started_short'),
      content: moment(job.startdate).format('DD. MMM YYYY')
    }, {
      label: intl.t('string.deadline_short'),
      content: job.nodeadline ? intl.t('components.jobs.job_item.no_duedate') : moment(job.deadline).format('DD. MMM YYYY')
    }];
    if (job.user.get('name')) {
      items.push({
        label: intl.t('string.posted_by'),
        content: (0, _utils.trunc)((0, _utils.limitWords)(job.user.get('name'), 2), 12)
      });
    }
    items.push({
      label: intl.t('string.opened'),
      content: (_a = numberFormatter(job.opened)) === null || _a === void 0 ? void 0 : _a.toString()
    });
    if (job.useAlfrello && job.applied !== undefined && job.applied !== null) {
      items.push({
        label: intl.t('string.applied'),
        content: (_b = numberFormatter(job.applied)) === null || _b === void 0 ? void 0 : _b.toString()
      });
    }
    return items;
  };
  _exports.getRegularJobInfoItems = getRegularJobInfoItems;
});