define("admin/compiled/alfrello-column/AlfrelloColumnActions", ["exports", "react", "admin/compiled/utils/hrbContext"], function (_exports, _react, _hrbContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AlfrelloColumnActions = void 0;
  var AlfrelloColumnActions = /** @class */function () {
    function AlfrelloColumnActions(columns, currentColumn, applications, columnActions, hasPurchasedVi, intl) {
      this.columns = columns;
      this.currentColumn = currentColumn;
      this.applications = applications;
      this.columnActions = columnActions;
      this.hasPurchasedVi = hasPurchasedVi;
      this.intl = intl;
    }
    AlfrelloColumnActions.prototype.createMenuItem = function (icon, text, onClick, items, disabled) {
      return {
        icon: icon,
        text: text,
        onClick: onClick,
        items: items,
        disabled: disabled
      };
    };
    AlfrelloColumnActions.prototype.getMoveAllMenuItem = function () {
      var _this = this;
      var items = this.columns.filter(function (c) {
        return c.id != _this.currentColumn.id;
      }).map(function (c) {
        return {
          text: c.title,
          onClick: function onClick() {
            return _this.columnActions.moveAll(_this.currentColumn.id, c.id);
          }
        };
      });
      return this.createMenuItem('moveall', this.intl.t('column-actions.moveall'), null, items);
    };
    AlfrelloColumnActions.prototype.getMessageAllMenuItem = function () {
      var _this = this;
      return this.createMenuItem('messageall', this.intl.t('column-actions.sendmessage'), function () {
        return _this.columnActions.showModal('messageAllModal', {
          column: _this.currentColumn,
          message: '',
          applicationIds: _this.applications.map(function (a) {
            return a.id;
          })
        });
      });
    };
    AlfrelloColumnActions.prototype.getRejectAllMenutItem = function () {
      var _this = this;
      return this.createMenuItem('rejectall', this.intl.t('column-actions.reject-all'), function () {
        _this.columnActions.showModal('rejectAllModal', {
          applicationIds: _this.applications.map(function (a) {
            return a.id;
          })
        });
      });
    };
    AlfrelloColumnActions.prototype.inviteVideoMenuItem = function () {
      var _this = this;
      return this.createMenuItem('invitevideo', this.intl.t('column-actions.vi-invite'), function () {
        return _this.columnActions.showModal('videoInviteModal', {
          applicationIds: _this.applications.map(function (a) {
            return a.id;
          })
        });
      }, null, this.applications.filter(function (a) {
        return a.status !== 'CANCELED' && (!a.get('videoInterview.id') || a.get('videoInterview.status') === 'EXPIRED');
      }).length == 0);
    };
    AlfrelloColumnActions.prototype.editColumnMenuItem = function () {
      var _this = this;
      return this.createMenuItem('edit', this.intl.t('column-actions.edit-column'), function () {
        _this.columnActions.showModal('editColumnModal', {
          column: _this.currentColumn,
          canDelete: !_this.applications.length
        });
      });
    };
    AlfrelloColumnActions.prototype.downloadColumnMenuItem = function () {
      var _this = this;
      return this.createMenuItem('download', this.intl.t('column-actions.download'), function () {
        _this.columnActions.downloadColumn(_this.currentColumn);
      });
    };
    AlfrelloColumnActions.prototype.copyApplicationsMenuItem = function () {
      var _this = this;
      return this.createMenuItem('copyall', this.intl.t('column-actions.copy-all'), function () {
        _this.columnActions.showModal('copyApplicationsModal', {
          applicationIds: _this.applications.map(function (a) {
            return a.id;
          })
        });
      });
    };
    AlfrelloColumnActions.prototype.getColumnActions = function () {
      var jobIsHRB = (0, _react.useContext)(_hrbContext.default);
      var menuItems = [];
      if (!jobIsHRB) {
        if (this.applications.length) {
          menuItems.push(this.getMoveAllMenuItem(), this.inviteVideoMenuItem(), this.getMessageAllMenuItem(), this.getRejectAllMenutItem(), this.downloadColumnMenuItem());
        }
        if (this.columnActions.canCopy) {
          menuItems.push(this.copyApplicationsMenuItem());
        }
      }
      if (this.currentColumn.canEdit) {
        menuItems.push(this.editColumnMenuItem());
      }
      return menuItems;
    };
    return AlfrelloColumnActions;
  }();
  _exports.AlfrelloColumnActions = AlfrelloColumnActions;
});