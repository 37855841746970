define("admin/adapters/rest-app", ["exports", "admin/adapters/rest-clientweb", "admin/config/environment"], function (_exports, _restClientweb, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var APPSPACE = _environment.default.APPSPACE;
  var _default = _restClientweb.default.extend({
    namespace: APPSPACE
  });
  _exports.default = _default;
});