define("admin/templates/admin/general", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "w082JDaT",
    "block": "{\"symbols\":[],\"statements\":[[8,\"nav-header\",[],[[\"@title\",\"@showBtn\",\"@submenu\",\"@newItem\",\"@routeLink\"],[[30,[36,0],[\"menu.general\"],null],false,\"general\",[34,1],\"admin.customers.domains.new\"]],null],[2,\"\\n\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"newText\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "admin/templates/admin/general.hbs"
    }
  });
  _exports.default = _default;
});