define("admin/templates/client/settings/hr/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XU+pGE3Z",
    "block": "{\"symbols\":[\"system\"],\"statements\":[[10,\"h4\"],[12],[1,[30,[36,0],[\"client.settings.hr_systems\"],null]],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"hr-cards\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"model\",\"systems\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"settings/hr-card\",[],[[\"@system\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/client/settings/hr/index.hbs"
    }
  });
  _exports.default = _default;
});