define("admin/compiled/ui/copy-text-btn/CopyTextBtn", ["exports", "react", "admin/compiled/ui/Button", "admin/compiled/utils"], function (_exports, _react, _Button, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CopyTextBtn = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var CopyTextBtn = function CopyTextBtn(props) {
    var _a = (0, _react.useState)(false),
      copied = _a[0],
      setCopied = _a[1];
    var copyToClipboard = function copyToClipboard() {
      (0, _utils.copyTextToClipboard)(props.textToCopy);
      setCopied(true);
      setTimeout(function () {
        return setCopied(false);
      }, 1500);
      if (props.onClick) {
        props.onClick();
      }
    };
    return _react.default.createElement("div", {
      className: "copy-text-btn ".concat(copied ? 'copied' : '')
    }, _react.default.createElement(_Button.Button, __assign({}, props, {
      onClick: copyToClipboard,
      color: props.color ? props.color : 'blue'
    }), _react.default.createElement("span", {
      className: 'copy-text-btn--text-label'
    }, props.children), _react.default.createElement("span", {
      className: 'copy-text-btn--copied-mark'
    })));
  };
  _exports.CopyTextBtn = CopyTextBtn;
});