define("admin/compiled/ui/filter-daterange/FilterDateRange", ["exports", "date-fns/locale/is", "react", "react-date-range", "admin/compiled/utils/emberContext", "admin/compiled/ui/filter-dropdown/FilterDropdown"], function (_exports, _is, _react, _reactDateRange, _emberContext, _FilterDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FilterDateRange = void 0;
  var FilterDateRange = function FilterDateRange(_a) {
    var _b, _c;
    var range = _a.range,
      setRange = _a.setRange,
      rangeInfo = _a.rangeInfo;
    var moment = (0, _react.useContext)(_emberContext.default).moment;
    var intl = (0, _react.useContext)(_emberContext.default).intl;
    var getLabels = function getLabels() {
      if (range === 'MONTH' || range === 'YEAR') {
        return intl.t('stats.chart_choose_range');
      }
      if (rangeInfo.from && rangeInfo.to) {
        return "".concat(rangeInfo.from.format('DD. MMM YY'), " - ").concat(moment(rangeInfo.to).format('DD. MMM YY'));
      }
      return intl.t('stats.chart_period');
    };
    return _react.default.createElement("div", {
      className: 'ui-filter-daterange'
    }, _react.default.createElement(_FilterDropdown.FilterDropdown, {
      isActive: range !== 'MONTH' && range !== 'YEAR' && !!(rangeInfo.from && rangeInfo.to),
      placeholder: getLabels(),
      items: [],
      onSelect: function onSelect() {}
    }, _react.default.createElement("div", {
      className: 'ui-filter-daterange--range-container'
    }, _react.default.createElement(_reactDateRange.DateRange, {
      maxDate: new Date(),
      locale: _is.default,
      //@ts-ignore
      ranges: range === 'YEAR' || range === 'MONTH' ? [{
        startDate: new Date(),
        endDate: new Date()
      }] : [{
        startDate: (_b = rangeInfo.from) === null || _b === void 0 ? void 0 : _b.toDate(),
        endDate: (_c = rangeInfo.to) === null || _c === void 0 ? void 0 : _c.toDate()
      }],
      color: '#ff7200',
      rangeColors: ['#ff7200', '#ff7200'],
      onChange: function onChange(range) {
        //@ts-ignore
        setRange({
          from: moment(range.range1.startDate),
          to: moment(range.range1.endDate)
        });
      }
    }))));
  };
  _exports.FilterDateRange = FilterDateRange;
});