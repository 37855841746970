define("admin/compiled/error-map", ["exports", "zod"], function (_exports, _zod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getZodErrorMap = void 0;
  var getZodErrorMap = function getZodErrorMap(t) {
    var zodErrorMap = function zodErrorMap(issue, ctx) {
      if (issue.code === _zod.default.ZodIssueCode.too_small) {
        return {
          message: t('validation.errors.required')
        };
      }
      if (issue.code === _zod.default.ZodIssueCode.invalid_string) {
        // issue.validation: "email" | "url" | "uuid" | "regex" | "cuid"
        return {
          message: t("validation.errors.invalid_string.".concat(issue.validation))
        };
      }
      if (issue.code === _zod.default.ZodIssueCode.custom) {
        return {
          message: t("validation.errors.invalid_input")
        };
      }
      if (issue.code === _zod.default.ZodIssueCode.invalid_type) {
        return {
          message: t("validation.errors.invalid_input")
        };
      }
      return {
        message: ctx.defaultError
      };
    };
    return zodErrorMap;
  };
  _exports.getZodErrorMap = getZodErrorMap;
});