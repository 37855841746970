define("admin/components/applications/ad-status-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    _onDone: function _onDone() {
      this.job.reload();
      this.toggle();
    },
    actions: {
      close: function close() {
        this.toggle();
      },
      markAsCompleted: function markAsCompleted() {
        var _this = this;
        if (this.job.isActive) {
          this.job.close().then(function () {
            return _this._onDone();
          });
        } else {
          this.job.ended().then(function () {
            return _this._onDone();
          });
        }
      }
    }
  });
  _exports.default = _default;
});