define("admin/components/alfrello/board-actions-filters-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Alfrello::Filter::AlfrelloFilter
    @compasses={{@compasses}}
    @languageSkills={{@languageSkills}}
    @languages={{@languages}}
    @job={{@job}}
    @filter={{@filter}}
    @degrees={{@degrees}}
    @updateFilter={{@updateFilter}} />
  <Alfrello::BoardActionsSeparator/>
  <div class="board-actions--early-hide">
    <Alfrello::Filter::FilterItem
      @toggle={{@toggleInactiveApplications}}
      @active={{@showInactiveApplications}}
      @class="inactive-applications" as |section|>
      {{#if (eq section 'trigger')}}
        {{t "alfrello.filter.inactive-applications"}} <span>({{@inactiveApplicationsCount}})</span>
      {{/if}}
    </Alfrello::Filter::FilterItem>
  </div>
  <Alfrello::Filter::FilterItem
    @toggle={{@toggleNewMessages}}
    @active={{@showNewMessages}}
    @class={{if @unreadCount "new-message unread" "new-message"}} as |section|>
    {{#if (eq section 'trigger')}}
      {{t "alfrello.filter.new-messages"}} <span>({{@unreadCount}})</span>
    {{/if}}
  </Alfrello::Filter::FilterItem>
  
  */
  {
    "id": "i7za4M/3",
    "block": "{\"symbols\":[\"section\",\"section\",\"@unreadCount\",\"@inactiveApplicationsCount\",\"@compasses\",\"@languageSkills\",\"@languages\",\"@job\",\"@filter\",\"@degrees\",\"@updateFilter\",\"@toggleInactiveApplications\",\"@showInactiveApplications\",\"@toggleNewMessages\",\"@showNewMessages\"],\"statements\":[[8,\"alfrello/filter/alfrello-filter\",[],[[\"@compasses\",\"@languageSkills\",\"@languages\",\"@job\",\"@filter\",\"@degrees\",\"@updateFilter\"],[[32,5],[32,6],[32,7],[32,8],[32,9],[32,10],[32,11]]],null],[2,\"\\n\"],[8,\"alfrello/board-actions-separator\",[],[[],[]],null],[2,\"\\n\"],[10,\"div\"],[14,0,\"board-actions--early-hide\"],[12],[2,\"\\n  \"],[8,\"alfrello/filter/filter-item\",[],[[\"@toggle\",\"@active\",\"@class\"],[[32,12],[32,13],\"inactive-applications\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,2],\"trigger\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"alfrello.filter.inactive-applications\"],null]],[2,\" \"],[10,\"span\"],[12],[2,\"(\"],[1,[32,4]],[2,\")\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[2]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"alfrello/filter/filter-item\",[],[[\"@toggle\",\"@active\",\"@class\"],[[32,14],[32,15],[30,[36,2],[[32,3],\"new-message unread\",\"new-message\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,1],\"trigger\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"alfrello.filter.new-messages\"],null]],[2,\" \"],[10,\"span\"],[12],[2,\"(\"],[1,[32,3]],[2,\")\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "admin/components/alfrello/board-actions-filters-wrapper.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});