define("admin/templates/components/crad/categorized-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "LzXmlhkB",
    "block": "{\"symbols\":[\"section\",\"&default\"],\"statements\":[[8,\"crad/container-with-bar\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[32,1],\"header\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[35,0,[\"length\"]],3],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"list-icon\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[8,\"crad/categories-dropdown\",[],[[\"@hide\",\"@categories\",\"@selectedCategory\"],[[30,[36,1],[[35,0,[\"length\"]],3],null],[34,3],[34,4]]],null],[2,\"\\n    \"],[8,\"crad/categories-search\",[],[[\"@search\",\"@placeholder\",\"@data-test\"],[[34,0],[34,5],\"categories-search-container\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,7],[[30,[36,6],[[32,1],\"content\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"search\",\"gt\",\"unless\",\"categories\",\"selectedCategory\",\"searchPlaceholder\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/crad/categorized-container.hbs"
    }
  });
  _exports.default = _default;
});