define("admin/templates/client/create-job/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "hqoGuavY",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"protect-route\",[],[[],[]],null],[2,\"\\n\"],[8,\"crad/crad-top-bar\",[],[[\"@title\"],[[30,[36,0],[\"crad.new_job.title\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"a\"],[15,6,[30,[36,1],[\"jobs\"],null]],[12],[1,[30,[36,0],[\"crad.close\"],null]],[2,\" \"],[10,\"span\"],[14,0,\"close-btn\"],[12],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"top-section\"],[12],[2,\"\\n    \"],[8,\"crad/select-ad-type\",[],[[\"@setUseAlfrello\",\"@useAlfrello\",\"@adTypes\",\"@isSavingJob\",\"@saveJob\",\"@title\"],[[30,[36,3],[[30,[36,2],[[32,1,[\"job\",\"useAlfrello\"]]],null]],null],[32,1,[\"job\",\"useAlfrello\"]],[32,1,[\"adTypes\"]],[34,4,[\"isRunning\"]],[30,[36,5],[[35,4]],null],[30,[36,0],[\"crad.new_job.title\"],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"new-admin\",\"mut\",\"fn\",\"saveJob\",\"perform\"]}",
    "meta": {
      "moduleName": "admin/templates/client/create-job/index.hbs"
    }
  });
  _exports.default = _default;
});