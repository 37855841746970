define("admin/compiled/job-card/job-card-external-btn", ["exports", "@alfred-is/alfred-design-system", "classnames", "react", "admin/compiled/job-card/job-card"], function (_exports, _alfredDesignSystem, _classnames, _react, _jobCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.JobCardExternalBtn = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };
  var JobCardExternalBtn = function JobCardExternalBtn(_a) {
    var children = _a.children,
      isEmail = _a.isEmail,
      rest = __rest(_a, ["children", "isEmail"]);
    var isHovered = (0, _jobCard.useJobCardContext)().isHovered;
    var t = (0, _alfredDesignSystem.useNxTranslation)().t;
    return _react.default.createElement(_alfredDesignSystem.CtaButton, __assign({
      size: _alfredDesignSystem.SizeT.small,
      variant: isHovered ? _alfredDesignSystem.CtaButtonVariant.BLACK : _alfredDesignSystem.CtaButtonVariant.WHITE_BLUE,
      className: 'tw-rounded-sm'
    }, rest), _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-flex', 'tw-justify-center', 'tw-items-center', 'tw-w-full', 'tw-px-1')
    }, _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-flex-shrink-0', 'tw-mr-3', 'tw-text-xs', 'tw-text-white', {
        'tw-px-1.5 tw-py-0.5 tw-rounded-sm': !isEmail,
        'tw-px-2 tw-py-1.5 tw-rounded-sm': isEmail,
        'tw-bg-white': isHovered,
        'tw-bg-gray-200': !isHovered
      })
    }, _react.default.createElement(_alfredDesignSystem.PlainIcon, {
      icon: isEmail ? _alfredDesignSystem.AlfredIconT.Email : _alfredDesignSystem.AlfredIconT.Hyperlink,
      color: isHovered ? _alfredDesignSystem.ColorT.black : _alfredDesignSystem.ColorT.gray500,
      size: isEmail ? 16 : 20
    })), _react.default.createElement("div", {
      className: (0, _classnames.default)({
        'tw-font-bold tw-text-xs': isHovered,
        'tw-font-semibold tw-text-gray-500': !isHovered
      })
    }, isHovered ? children : t(isEmail ? 'job-card.external-btn.email' : 'job-card.external-btn.link'))));
  };
  _exports.JobCardExternalBtn = JobCardExternalBtn;
});