define("admin/components/crad/list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['crad-list-item'],
    attributeBindings: ['data-test'],
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      Ember.run.scheduleOnce('afterRender', this, function () {
        var textarea = document.getElementById(_this.item.selector);
        if (textarea) {
          // eslint-disable-next-line no-undef
          autosize(textarea);
        }
      });
    },
    actions: {
      proxyKeys: function proxyKeys(text) {
        var lines = text.split("\n");
        if (lines.length > 1) {
          this.set('item.description', lines[0].trim());
          lines.shift();
          this.pasteItems(this.index, lines);
        }
      }
    }
  });
  _exports.default = _default;
});