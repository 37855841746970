define("admin/helpers/filter-utils/compass-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.compassFilter = void 0;
  var compassFilter = function compassFilter(applicationCompasses, filter, fit) {
    if (filter != null && filter.length && filter.some(function (f) {
      return f.compass != null;
    })) {
      if (applicationCompasses == null) {
        fit = false;
      } else {
        filter.filter(function (f) {
          return f.compass != null;
        }).forEach(function (compassFilter) {
          var requiredCompass = applicationCompasses.find(function (c) {
            return c.compass.id == compassFilter.compass.id;
          });
          if (!requiredCompass) {
            fit = false;
          } else if (!(requiredCompass.score >= compassFilter.values[0] && requiredCompass.score <= compassFilter.values[1])) {
            fit = false;
          }
        });
      }
    }
    return fit;
  };
  _exports.compassFilter = compassFilter;
});