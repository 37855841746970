define("admin/routes/client/edit-job/subcategories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    apiClient: Ember.inject.service(),
    model: function model() {
      var job = this.modelFor('client.edit-job').job;
      return Ember.RSVP.hash({
        job: job,
        subCategories: this.apiClient.get('/subcats').then(function (res) {
          return res.subCategories;
        })
      });
    }
  });
  _exports.default = _default;
});