define("admin/compiled/ui/Select", ["exports", "classnames", "react"], function (_exports, _classnames, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Select = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };
  var Select = function Select(_a) {
    var disabled = _a.disabled,
      children = _a.children,
      rest = __rest(_a, ["disabled", "children"]);
    return _react.default.createElement("select", __assign({
      className: (0, _classnames.default)('form-select tw-appearance-none tw-block tw-w-full tw-px-3 tw-py-1.5 tw-text-base tw-font-normal', 'tw-text-gray-700 tw-bg-white disabled:tw-bg-gray-200 tw-bg-clip-padding tw-bg-no-repeat', 'tw-border tw-border-solid tw-border-gray-300 tw-rounded tw-transition tw-ease-in-out tw-m-0', 'focus:tw-text-gray-700 focus:tw-bg-white focus:tw-border-blue-600 focus:tw-outline-none', 'tw-cursor-pointer'),
      disabled: disabled
    }, rest), children);
  };
  _exports.Select = Select;
});