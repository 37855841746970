define("admin/components/crad/suggestive-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['suggestive-tags'],
    attributeBindings: ['data-test'],
    intl: Ember.inject.service(),
    notifications: Ember.inject.service(),
    isPhantom: Ember.computed.alias('currentSession.user.phantom'),
    actions: {
      toggle: function toggle(tag) {
        if (this.jobTags.includes(tag)) {
          this.jobTags.removeObject(tag);
        } else {
          if (this.get('jobTags.length') < 5 || this.isPhantom) {
            this.jobTags.pushObject(tag);
          } else {
            this.notifications.clearAll().warning(this.intl.t('alfred.publish.tags.limit'));
          }
        }
      }
    }
  });
  _exports.default = _default;
});