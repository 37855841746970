define("admin/controllers/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    loading: false,
    successLogin: function successLogin(authenticated) {
      if (Ember.testing) {
        Ember.run.cancelTimers();
      }
      if (authenticated.two_factor) {
        return this.transitionToRoute('two-factor');
      }
      return this.transitionToRoute('index');
    }
  });
  _exports.default = _default;
});