define("admin/templates/components/crad/dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xPfpj4Mw",
    "block": "{\"symbols\":[\"@tooltip\",\"@required\",\"@icon\",\"@title\",\"&default\"],\"statements\":[[8,\"ui/icon-section\",[[24,\"tabindex\",\"0\"]],[[\"@tooltip\",\"@required\",\"@icon\",\"@title\"],[[32,1],[32,2],[30,[36,1],[[32,3],[32,3],[30,[36,4],[[35,2],\"checkbox-default\",\"checkbox-complete\"],null]],null],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"gray\"],[12],[2,\"\\n      \"],[1,[34,3]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"selected-option\"],[12],[2,\"\\n\"],[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"img\"],[15,\"src\",[31,[[34,0]]]],[12],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[1,[34,2]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"crad/crad-dropdown-options\",[],[[\"@active\"],[[34,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,5,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"selectedIcon\",\"if\",\"selectedName\",\"placeholder\",\"unless\",\"active\"]}",
    "meta": {
      "moduleName": "admin/templates/components/crad/dropdown.hbs"
    }
  });
  _exports.default = _default;
});