define("admin/compiled/utils/useRenderColumn", ["exports", "admin/compiled/alfrello-column/AlfrelloColumnActions", "react", "admin/compiled/alfrello-column/AlfrelloStandardColumnContainer"], function (_exports, _AlfrelloColumnActions, _react, _AlfrelloStandardColumnContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.useRenderColumn = void 0;
  var useRenderColumn = function useRenderColumn(columns, filteredApplications, columnActions, hasPurchasedVi, isLoading, columnHeightReduce, index, intl, switchColumn, activeApplication, isSidebar) {
    return function (column, provided, isDragging) {
      var menuItems = new _AlfrelloColumnActions.AlfrelloColumnActions(columns, column, filteredApplications, columnActions, hasPurchasedVi, intl).getColumnActions();
      return _react.default.createElement(_AlfrelloStandardColumnContainer.AlfrelloStandardColumnContainer, {
        isLoading: isLoading,
        isSidebar: isSidebar,
        key: column.id,
        column: column,
        provided: provided,
        index: index,
        applications: filteredApplications,
        menuItems: menuItems,
        columnHeightReduce: columnHeightReduce,
        switchColumn: switchColumn,
        columns: columns,
        activeApplication: activeApplication,
        isDragging: isDragging
      });
    };
  };
  _exports.useRenderColumn = useRenderColumn;
});