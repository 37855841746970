define("admin/compiled/cta-button/cta-button", ["exports", "@alfred-is/alfred-design-system", "classnames", "react"], function (_exports, _alfredDesignSystem, _classnames, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CtaButtonVariant = _exports.CtaButton = void 0;
  var __assign = void 0 && (void 0).__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };
  var CtaButtonVariant;
  _exports.CtaButtonVariant = CtaButtonVariant;
  (function (CtaButtonVariant) {
    CtaButtonVariant["ORANGE"] = "ORANGE";
    CtaButtonVariant["BLUE"] = "BLUE";
    CtaButtonVariant["GREEN"] = "GREEN";
    CtaButtonVariant["WHITE_BLUE"] = "WHITE_BLUE";
    CtaButtonVariant["WHITE_GRAY"] = "WHITE_GRAY";
    CtaButtonVariant["GRAY"] = "GRAY";
    CtaButtonVariant["BLACK"] = "BLACK";
  })(CtaButtonVariant || (_exports.CtaButtonVariant = CtaButtonVariant = {}));
  var CtaButton = function CtaButton(_a) {
    var _b;
    var _c = _a.variant,
      variant = _c === void 0 ? CtaButtonVariant.ORANGE : _c,
      disabled = _a.disabled,
      children = _a.children,
      _onClick = _a.onClick,
      icon = _a.icon,
      _d = _a.iconPosition,
      iconPosition = _d === void 0 ? _alfredDesignSystem.HorizontalPosition.LEFT : _d,
      _e = _a.iconSize,
      iconSize = _e === void 0 ? 25 : _e,
      className = _a.className,
      _f = _a.size,
      size = _f === void 0 ? _alfredDesignSystem.SizeT.large : _f,
      rest = __rest(_a, ["variant", "disabled", "children", "onClick", "icon", "iconPosition", "iconSize", "className", "size"]);
    var iconColor = (_b = {}, _b[CtaButtonVariant.GRAY] = _alfredDesignSystem.ColorT.black500, _b[CtaButtonVariant.WHITE_BLUE] = _alfredDesignSystem.ColorT.blue500, _b);
    var iconEl = icon ? _react.default.createElement(_alfredDesignSystem.PlainIcon, {
      icon: icon,
      color: iconColor[variant] || _alfredDesignSystem.ColorT.white,
      size: iconSize
    }) : null;
    return _react.default.createElement("button", __assign({
      className: (0, _classnames.default)('tw-font-semibold', 'tw-w-full', 'tw-rounded-sm', 'tw-flex', 'tw-justify-center', 'tw-items-center', 'tw-select-none', className || '', {
        'tw-p-4 tw-text-md': size === _alfredDesignSystem.SizeT.large,
        'tw-p-3 tw-text': size === _alfredDesignSystem.SizeT.medium || size === _alfredDesignSystem.SizeT.small,
        'tw-text-white': variant !== CtaButtonVariant.WHITE_BLUE && variant !== CtaButtonVariant.GRAY && variant !== CtaButtonVariant.WHITE_GRAY,
        'tw-bg-black-500': variant === CtaButtonVariant.BLACK,
        'tw-bg-orange-500 hover:tw-bg-orange-600': variant === CtaButtonVariant.ORANGE || variant === CtaButtonVariant.BLUE,
        'tw-bg-green-500 hover:tw-bg-green-600': variant === CtaButtonVariant.GREEN,
        'tw-bg-white tw-text-blue-500 tw-border tw-border-gray-200': variant === CtaButtonVariant.WHITE_BLUE,
        'tw-bg-white tw-text-gray-500 tw-border tw-border-gray-200': variant === CtaButtonVariant.WHITE_GRAY,
        'tw-bg-gray-200 tw-text-black-500': variant === CtaButtonVariant.GRAY,
        'tw-opacity-60 tw-pointer-events-none': disabled
      }),
      onClick: function onClick(e) {
        if (_onClick && !disabled) {
          _onClick(e);
        }
      }
    }, rest), icon && iconPosition === _alfredDesignSystem.HorizontalPosition.LEFT && _react.default.createElement("div", {
      className: "tw-mr-2.5"
    }, iconEl), children, icon && iconPosition === _alfredDesignSystem.HorizontalPosition.RIGHT && _react.default.createElement("div", {
      className: "tw-ml-2.5"
    }, iconEl));
  };
  _exports.CtaButton = CtaButton;
});