define("admin/routes/client/edit-job/skills", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function model() {
      var job = this.modelFor('client.edit-job').job;
      return Ember.RSVP.hash({
        job: job,
        skillcategories: this.store.findAll('skillcategory'),
        languages: this.store.findAll('language'),
        languageSkills: this.store.findAll('language-skill')
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('selectedCategory', model.skillcategories.firstObject);
    }
  });
  _exports.default = _default;
});