define("admin/models/client", ["exports", "@ember-data/model", "ember-api-actions", "moment"], function (_exports, _model, _emberApiActions, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    hrSystems: (0, _model.attr)(),
    premiumFeatures: (0, _model.attr)(),
    brandline: (0, _model.attr)('boolean'),
    contact: (0, _model.attr)('string'),
    discount: (0, _model.attr)('number'),
    email: (0, _model.attr)('string'),
    gracedays: (0, _model.attr)('string'),
    locale: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    placeId: (0, _model.attr)('string'),
    ssn: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    clientAddress: (0, _model.attr)('string'),
    demo: (0, _model.attr)('boolean'),
    logo: (0, _model.attr)('string'),
    adcount: (0, _model.attr)('number'),
    avgprice: (0, _model.attr)('number'),
    brandcount: (0, _model.attr)('number'),
    lastad: (0, _model.attr)('string'),
    sales: (0, _model.attr)('number'),
    usercount: (0, _model.attr)('number'),
    paymentdeadline: (0, _model.attr)('number'),
    invoice: (0, _model.attr)('model', 'invoice'),
    address: (0, _model.belongsTo)('address'),
    products: (0, _model.hasMany)('product'),
    subscriptions: (0, _model.hasMany)('subscription'),
    lastad_format: Ember.computed('lastad', function () {
      return this.lastad ? (0, _moment.default)(this.lastad).fromNow() : '-';
    }),
    created_format: Ember.computed('created', function () {
      return this.created ? (0, _moment.default)(this.created).fromNow() : '-';
    }),
    blacklisted: Ember.computed.equal('invoice.invoicestatus', 'RED'),
    getJobs: (0, _emberApiActions.memberAction)({
      path: 'getJobs',
      type: 'get'
    }),
    resetJob: (0, _emberApiActions.memberAction)({
      path: 'resetJob',
      type: 'patch'
    })
  });
  _exports.default = _default;
});