define("admin/components/date-picker", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ['date-picker'],
    hasYear: true,
    hasMonth: true,
    hasDay: true,
    format: 'YYYY-MM-DD',
    months: Ember.computed('intl.locale', function () {
      return _moment.default.months();
    }),
    init: function init() {
      this._super.apply(this, arguments);
      var days = [],
        years = [],
        currentYear = (0, _moment.default)().year();
      if (this.hasYear) {
        for (var y = currentYear + 1; y >= currentYear - 100; y--) {
          years.push("".concat(y));
        }
        this.years = years;
      }
      if (this.hasDay) {
        for (var d = 1; d <= 31; d++) {
          days.push("".concat(d));
        }
        this.days = days;
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.date) {
        if (this.hasYear) {
          this.set('year', (0, _moment.default)(this.date).format('YYYY'));
        }
        if (this.hasMonth) {
          this.set('month', (0, _moment.default)(this.date).format('MMMM'));
        }
        if (this.hasDay) {
          this.set('day', (0, _moment.default)(this.date).format('DD'));
        }
      }
    },
    setDate: function setDate() {
      if (this.hasYear && this.year && this.hasMonth && this.month && this.hasDay && this.day) {
        return this.set('date', (0, _moment.default)("".concat(this.year, "-").concat(this.month, "-").concat(this.day), 'YYYY-MMMM-DD').format(this.format));
      }
      if (this.hasYear && this.year && this.hasMonth && this.month && !this.hasDay) {
        return this.set('date', (0, _moment.default)("".concat(this.year, "-").concat(this.month), 'YYYY-MMMM').format(this.format));
      }
      if (this.hasMonth && this.month && this.hasDay && this.day && !this.hasYear) {
        return this.set('date', (0, _moment.default)("".concat(this.month, "-").concat(this.day), 'MMMM-DD').format('MM-DD'));
      }
    },
    actions: {
      setYear: function setYear(year) {
        this.set('year', year);
        this.setDate();
      },
      setMonth: function setMonth(month) {
        this.set('month', month);
        this.setDate();
      },
      setDay: function setDay(day) {
        this.set('day', day);
        this.setDate();
      }
    }
  });
  _exports.default = _default;
});