define("admin/templates/components/applications/video-rating-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7CSFzkmW",
    "block": "{\"symbols\":[\"rating\"],\"statements\":[[10,\"h4\"],[12],[1,[30,[36,4],[\"client.applications.profile.video.rate_applicant\"],null]],[13],[2,\"\\n\"],[10,\"p\"],[12],[10,\"small\"],[12],[2,[30,[36,4],[\"client.applications.profile.video.rating_disclaimer\"],null]],[13],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"ratings\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"confirm-button\",[],[[\"@button_text\",\"@onConfirm\",\"@className\",\"@mclass\",\"@title\",\"@rating\",\"@cancel\",\"@confirm\"],[[32,1],[30,[36,0],[[32,0],\"virate\",[32,1]],null],[30,[36,3],[[30,[36,2],[[32,1],[35,1,[\"rating\"]]],null],[35,1,[\"ratingcolor\"]],\"gray-brd\"],null],\"text-center\",[30,[36,4],[\"client.applications.profile.video.confirm_rating\"],null],[32,1],[30,[36,4],[\"string.cancel\"],null],[30,[36,4],[\"client.applications.profile.video.confirm_btn\"],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"vi\",\"eq\",\"if\",\"t\",\"ratings\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/components/applications/video-rating-profile.hbs"
    }
  });
  _exports.default = _default;
});