define("admin/routes/admin/customers/betas", ["exports", "admin/mixins/model-loader"], function (_exports, _modelLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_modelLoader.default, {
    limit: 25,
    queryParams: {
      search: {
        refreshModel: true
      },
      active: {
        refreshModel: true
      }
    },
    infinity: Ember.inject.service(),
    model: function model(_ref) {
      var search = _ref.search,
        active = _ref.active;
      return this.infinity.model('customer-contact', {
        search: search,
        perPageParam: 'limit',
        active: active,
        startingPage: 0
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      if (model.extraParams.search && !controller.query) {
        controller.set('query', model.extraParams.search);
      }
      if (controller.query && !model.extraParams.search) {
        Ember.run.next(this, function () {
          return controller.set('search', controller.query);
        });
      }
    }
  });
  _exports.default = _default;
});