define("admin/components/customers/reset-demo-ad-modal", ["exports", "admin/components/edit-modal"], function (_exports, _editModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _editModal.default.extend({
    actions: {
      changeSelectState: function changeSelectState() {
        this.toggleProperty('selectIsOpen');
      },
      save: function save(customer) {
        var _this = this;
        customer.resetJob({
          jobId: customer.jobToReset.id
        }).then(function () {
          _this.notifications.success("A\xF0ger\xF0 t\xF3kst.");
          _this.toggle();
        });
      }
    }
  });
  _exports.default = _default;
});