define("admin/routes/client/applications/index-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      var _this$modelFor = this.modelFor('client.applications'),
        job = _this$modelFor.job;
      if (job) {
        controller.set('appliedCount', job.get('applied'));
      }
    }
  });
  _exports.default = _default;
});