define("admin/templates/components/brands/english-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "NZtNt2vt",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@overlayClass\"],[[30,[36,0],[[32,0],\"close\"],null],\"modal-popup english-modal\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"default-dialog wide\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[1,[30,[36,1],[\"components.brands.english_modal.title\"],null]],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"edit-form\"],[12],[2,\"\\n      \"],[8,\"forms/textarea-field\",[],[[\"@value\",\"@label\"],[[34,2,[\"enbody\"]],[30,[36,1],[\"string.about_brand\"],null]]],null],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn gray-brd inline\"],[4,[38,0],[[32,0],\"close\"],null],[12],[1,[30,[36,1],[\"string.cancel\"],null]],[13],[2,\"\\n        \"],[11,\"button\"],[16,0,[31,[\"btn green inline \",[30,[36,4],[[35,3],\"disabled\"],null]]]],[4,[38,0],[[32,0],\"save\"],null],[12],[1,[30,[36,1],[\"string.save\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"brand\",\"isValid\",\"unless\"]}",
    "meta": {
      "moduleName": "admin/templates/components/brands/english-modal.hbs"
    }
  });
  _exports.default = _default;
});