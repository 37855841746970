define("admin/templates/components/invoice-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4uM6KkGP",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,6],[[30,[36,7],[[30,[36,5],[[35,4,[\"invoicestatus\"]],\"YELLOW\"],null],[30,[36,5],[[35,4,[\"invoicestatus\"]],\"RED\"],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"warning-banner\",[],[[\"@color\"],[[34,4,[\"invoicestatus\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[2,[35,4,[\"invoiceTitle\"]]],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[35,4,[\"invoicestatus\"]],\"RED\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"button\"],[24,0,\"btn\"],[4,[38,2],[[32,0],[30,[36,1],[\"clientBlacklistModal\",[32,0]],null]],null],[12],[1,[30,[36,3],[\"components.invoice_warning.more\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,6],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"client-blacklist-modal\",[],[[\"@toggle\"],[[30,[36,0],[\"clientBlacklistModal\",[32,0]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"toggle-action\",\"toggle\",\"action\",\"t\",\"invoice\",\"eq\",\"if\",\"or\",\"clientBlacklistModal\"]}",
    "meta": {
      "moduleName": "admin/templates/components/invoice-warning.hbs"
    }
  });
  _exports.default = _default;
});