define("admin/components/jobs/filter-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['search-bar', 'clearfix'],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('query', this.search);
    },
    actions: {
      jobSearch: function jobSearch() {
        this.set('search', this.query);
      },
      clearSearch: function clearSearch() {
        this.set('search');
      },
      selectUser: function selectUser(user) {
        if (user) {
          this.set('user', user.id);
        } else {
          this.set('user');
        }
        this.reload();
      },
      selectStatus: function selectStatus(status) {
        if (status) {
          this.set('status', status.id);
        } else {
          this.set('status');
        }
        this.reload();
      },
      selectBrand: function selectBrand(brand) {
        if (brand) {
          this.set('brand', brand.id);
        } else {
          this.set('brand');
        }
        this.reload();
      }
    }
  });
  _exports.default = _default;
});