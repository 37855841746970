define("admin/templates/components/profile-thumb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "hVgYWMsY",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[30,[36,4],[[35,1],[35,3]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"thumb \",[34,0]]]],[15,5,[30,[36,2],null,[[\"bg\"],[[35,1]]]]],[15,\"data-initials\",[34,3]],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"thumb empty \",[34,0]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"size\",\"bg\",\"html-safe\",\"initials\",\"or\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/profile-thumb.hbs"
    }
  });
  _exports.default = _default;
});