define("admin/models/category", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    count: (0, _model.attr)('number'),
    active: (0, _model.attr)('boolean'),
    tags: (0, _model.hasMany)('jobtag'),
    sortedTags: Ember.computed.sort('tags', function (a, b) {
      return a.name.localeCompare(b.name);
    })
  });
  _exports.default = _default;
});