define("admin/compiled/redux/actions/modal", ["exports", "admin/compiled/types"], function (_exports, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.showModal = _exports.hideModal = void 0;
  var showModal = function showModal(type, modalProps) {
    return function (dispatch) {
      dispatch({
        type: _types.ModalActionType.SHOW_MODAL,
        modalType: type,
        modalProps: modalProps
      });
    };
  };
  _exports.showModal = showModal;
  var hideModal = function hideModal() {
    return function (dispatch) {
      dispatch({
        type: _types.ModalActionType.HIDE_MODAL
      });
    };
  };
  _exports.hideModal = hideModal;
});