define("admin/adapters/ga-job", ["exports", "admin/adapters/rest-clientweb"], function (_exports, _restClientweb) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _restClientweb.default.extend({
    pathForType: function pathForType() {
      return this._super.apply(this, arguments).replace('gajob', 'job');
    }
  });
  _exports.default = _default;
});