define("admin/compiled/alfrello-profile-info/ProfileExperience", ["exports", "react", "admin/compiled/alfrello-profile-info/ProfileTimeline", "moment", "admin/compiled/utils/languageContext"], function (_exports, _react, _ProfileTimeline, _moment, _languageContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ProfileExperience = void 0;
  var ProfileExperience = function ProfileExperience(_a) {
    var experiences = _a.experiences,
      label = _a.label;
    var intl = (0, _react.useContext)(_languageContext.default);
    return _react.default.createElement(_ProfileTimeline.ProfileTimeline, {
      label: label,
      items: experiences.map(function (experience) {
        return {
          id: experience.id,
          title: experience.title,
          place: experience.company,
          description: experience.description,
          from: experience.fromdate ? (0, _moment.default)(experience.fromdate, 'YYYY-MM-DD').format('MMM YYYY') : '',
          to: experience.todate ? (0, _moment.default)(experience.todate, 'YYYY-MM-DD').format('MMM YYYY') : intl.t('date.now')
        };
      })
    });
  };
  _exports.ProfileExperience = ProfileExperience;
});