define("admin/compiled/e-sign/Edit", ["exports", "@alfred-is/alfred-design-system", "classnames", "react", "admin/compiled/hooks/useESign", "admin/compiled/utils/languageContext", "admin/compiled/e-sign/Previews", "admin/compiled/e-sign/types"], function (_exports, _alfredDesignSystem, _classnames, _react, _useESign, _languageContext, _Previews, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Edit = void 0;
  var Edit = function Edit(_a) {
    var applicationId = _a.applicationId,
      jobId = _a.jobId,
      contracts = _a.contracts,
      editContract = _a.editContract,
      setEditContract = _a.setEditContract,
      setPage = _a.setPage;
    if (!contracts) {
      return _react.default.createElement(_react.default.Fragment, null);
    }
    var intl = (0, _react.useContext)(_languageContext.default);
    var _b = (0, _react.useState)(true),
      loading = _b[0],
      setLoading = _b[1];
    var template = contracts[editContract];
    var _c = (0, _useESign.useEditContract)(template.id, applicationId, jobId),
      contract = _c.data,
      isLoading = _c.isLoading;
    if (contract) {
      template.contractId = contract.contractId;
    }
    return _react.default.createElement("div", {
      className: "tw-flex-1 tw-flex tw-flex-col"
    }, _react.default.createElement("div", {
      className: "tw-flex tw-items-center tw-justify-between tw-mb-4"
    }, _react.default.createElement(_Previews.SmallButton, {
      color: "blue",
      onClick: function onClick() {
        return editContract === 0 ? setPage(_types.PageType.Reviews) : setEditContract(editContract - 1);
      }
    }, _react.default.createElement(_alfredDesignSystem.Icon, {
      kind: _alfredDesignSystem.AlfredIconT.ArrowLeft,
      size: 11,
      color: '#fff'
    }), editContract === 0 ? intl.t('esign.back') : intl.t('esign.previous')), _react.default.createElement("div", null, template.name), _react.default.createElement(_Previews.SmallButton, {
      color: "blue",
      disabled: loading,
      onClick: function onClick() {
        return editContract === contracts.length - 1 ? setPage(_types.PageType.Summary) : setEditContract(editContract + 1);
      }
    }, editContract === contracts.length - 1 ? intl.t('esign.continue') : intl.t('esign.next'), _react.default.createElement(_alfredDesignSystem.Icon, {
      kind: _alfredDesignSystem.AlfredIconT.Arrow,
      size: 11,
      color: '#fff'
    }))), _react.default.createElement("div", {
      className: (0, _classnames.default)('tw-flex-1 tw-min-h-[600px] tw-p-5 tw-flex tw-items-center tw-justify-center', 'tw-bg-white tw-border tw-border-gray-200 tw-rounded-sm', 'tw-bg-center tw-bg-no-repeat'),
      style: {
        backgroundImage: loading ? 'url("/img/icons/loader.svg")' : ''
      }
    }, !!contract && !!contract.url && !isLoading && _react.default.createElement("iframe", {
      src: contract.url,
      name: contract.name,
      width: "100%",
      height: "100%",
      frameBorder: 0,
      marginHeight: 0,
      marginWidth: 0,
      onLoad: function onLoad() {
        return setLoading(false);
      }
    })));
  };
  _exports.Edit = Edit;
});