define("admin/templates/components/alfred-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "V4IEdbwf",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@overlayClass\",\"@containerClass\"],[[30,[36,0],[[32,0],\"close\"],null],\"modal-popup alfred-popup\",[34,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"alfred-modal default-dialog \",[34,2]]]],[12],[2,\"\\n    \"],[11,\"div\"],[24,0,\"close-btn\"],[4,[38,0],[[32,0],\"close\"],null],[12],[13],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"alfred\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"header\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"alfred-logo\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"containerClass\",\"modalClass\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/alfred-modal.hbs"
    }
  });
  _exports.default = _default;
});