define("admin/compiled/jobs-list/jobs-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sortGaJobs = _exports.filterGaJob = void 0;
  var filterGaJob = function filterGaJob(filter) {
    return function (brand) {
      var job = brand.get('generalApplicationJob.content');
      var user = job === null || job === void 0 ? void 0 : job.get('user.content');
      var filterFailConditions = [((filter === null || filter === void 0 ? void 0 : filter.brand) || (filter === null || filter === void 0 ? void 0 : filter.brand) == '0') && (filter === null || filter === void 0 ? void 0 : filter.brand) !== brand.id, ((filter === null || filter === void 0 ? void 0 : filter.status) || (filter === null || filter === void 0 ? void 0 : filter.status) == '0') && (filter === null || filter === void 0 ? void 0 : filter.status) != (job === null || job === void 0 ? void 0 : job.status.id), ((filter === null || filter === void 0 ? void 0 : filter.user) || (filter === null || filter === void 0 ? void 0 : filter.user) == '0') && (filter === null || filter === void 0 ? void 0 : filter.user) !== (user === null || user === void 0 ? void 0 : user.id), (filter === null || filter === void 0 ? void 0 : filter.search) && !(brand === null || brand === void 0 ? void 0 : brand.name.toLowerCase().includes(filter.search.toLowerCase())) && !(job === null || job === void 0 ? void 0 : job.title.toLowerCase().includes(filter.search.toLowerCase()))];
      return !filterFailConditions.some(function (f) {
        return f;
      });
    };
  };
  _exports.filterGaJob = filterGaJob;
  var sortGaJobs = function sortGaJobs(a, b) {
    var aModel = a.get('generalApplicationJob.content');
    var bModel = b.get('generalApplicationJob.content');
    if (aModel && !bModel) {
      return -1;
    }
    if (!aModel && bModel) {
      return 1;
    }
    if ((aModel === null || aModel === void 0 ? void 0 : aModel.isActive) && !(bModel === null || bModel === void 0 ? void 0 : bModel.isActive)) {
      return -1;
    }
    if (!(aModel === null || aModel === void 0 ? void 0 : aModel.isActive) && (bModel === null || bModel === void 0 ? void 0 : bModel.isActive)) {
      return 1;
    }
    return 0;
  };
  _exports.sortGaJobs = sortGaJobs;
});