define("admin/components/page-header-react/page-header", ["exports", "admin/react-component", "react", "admin/compiled/page-title-header/PageTitleHeader"], function (_exports, _reactComponent, _react, _PageTitleHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  
  */
  {
    "id": "Z9JtXARy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/components/page-header-react/page-header.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _reactComponent.default.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    renderComponent: function renderComponent() {
      var _this = this;
      this.reactRender(_react.default.createElement(_PageTitleHeader.PageTitleHeader, {
        title: this.title,
        actionName: this.actionName,
        onAction: function onAction() {
          return _this.router.transitionTo(_this.actionRoute);
        }
      }));
    }
  }));
  _exports.default = _default;
});