define("admin/components/alfrello-react/alfrello-profile-info", ["exports", "admin/react-component", "admin/compiled/alfrello-profile-info/AlfrelloProfileInfo"], function (_exports, _reactComponent, _AlfrelloProfileInfo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  
  */
  {
    "id": "kBHCcG8+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/components/alfrello-react/alfrello-profile-info.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _reactComponent.default.extend({
    store: Ember.inject.service(),
    utils: Ember.inject.service(),
    apiManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    renderComponent: function renderComponent() {
      var _this = this;
      this.reactRender(React.createElement(_AlfrelloProfileInfo.AlfrelloProfileInfo, {
        intl: this.intl,
        application: this.application,
        isLoading: this.isLoading,
        jobSkills: this.jobSkills,
        color: this.columns.find(function (c) {
          return c.id === _this.application.columnId;
        }).color,
        verificationRequired: this.verificationRequired
      }));
    }
  }));
  _exports.default = _default;
});