define("admin/controllers/client/edit-job/deadline", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    today: (0, _moment.default)().toDate(),
    minDeadline: Ember.computed('model.initialNoDeadline', 'model.job.model.initialNoDeadline', 'model.job.{deadline,isActive,nodeadline,startdate}', function () {
      if (this.model.job.isActive && !this.model.initialNoDeadline) {
        return (0, _moment.default)(this.model.job.deadline).toDate();
      }
      var startDate = (0, _moment.default)(this.model.job.startdate);
      if (!startDate) {
        startDate = (0, _moment.default)();
      }
      return startDate.add(1, 'day').toDate();
    }),
    maxDeadline: Ember.computed('model.job.startdate', function () {
      var startDate = (0, _moment.default)(this.model.job.startdate);
      if (!startDate) {
        startDate = (0, _moment.default)();
      }
      return startDate.add(6, 'weeks').toDate();
    }),
    allowEditStartDate: Ember.computed('model.job.{isDraft,startdate}', function () {
      if (!this.get('model.job.startdate')) {
        return true;
      }
      return this.model.job.isDraft || Ember.isPresent(this.model.job.changedAttributes().startdate) || this.model.job.startdate && (0, _moment.default)(this.model.job.startdate).isAfter((0, _moment.default)());
    }),
    actions: {
      setDeadline: function setDeadline(date) {
        if (date) {
          Ember.set(this, 'model.job.nodeadline', false);
          Ember.set(this, 'model.job.deadline', date);
        }
      },
      setOption: function setOption(option) {
        Ember.set(this, 'model.job.autoCloseOption', option);
      },
      resetDeadline: function resetDeadline() {
        Ember.set(this, 'model.job.nodeadline', true);
        Ember.set(this, 'model.job.deadline', null);
      },
      setStartdate: function setStartdate(date) {
        if (date) {
          Ember.set(this, 'model.job.startdate', date);
        }
      }
    }
  });
  _exports.default = _default;
});