define("admin/templates/components/crad/crad-tag-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "cwhWF5sJ",
    "block": "{\"symbols\":[\"@tooltip\",\"&default\"],\"statements\":[[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"ember-tooltip\",[],[[\"@text\",\"@side\"],[[32,1],\"top\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[18,2,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/crad/crad-tag-container.hbs"
    }
  });
  _exports.default = _default;
});