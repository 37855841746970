define("admin/components/crad/categories-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['crad-categories-dropdown'],
    actions: {
      set: function set(category) {
        this.set('selectedCategory', category);
        this.set('active');
      }
    }
  });
  _exports.default = _default;
});