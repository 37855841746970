define("admin/components/jobs/english-modal", ["exports", "admin/components/edit-modal"], function (_exports, _editModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _editModal.default.extend({
    isValid: Ember.computed('job.{entitle,enbodyhtml}', function () {
      return this.get('job.entitle') && this.get('job.enbodyhtml');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.options = {
        menubar: false,
        toolbar: 'bold italic | bullist numlist',
        statusbar: false,
        plugins: 'paste',
        paste_as_text: true,
        min_height: 309,
        content_style: '.mce-content-body {padding: 40px 10px 10px;font-family:"source-sans-pro",sans-serif;font-size:16px;}'
      };
    },
    actions: {
      save: function save() {
        if (this.get('job.questions.length')) {
          this.get('job.questions').forEach(function (question) {
            question.save();
          });
        }
        this.toggle();
      }
    }
  });
  _exports.default = _default;
});