define("admin/components/spotlight-react/spotlight-react", ["exports", "admin/react-component", "react", "admin/compiled/spotlight/Spotlight"], function (_exports, _reactComponent, _react, _Spotlight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  */
  {
    "id": "urTYnS1h",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/components/spotlight-react/spotlight-react.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _reactComponent.default.extend({
    renderComponent: function renderComponent() {
      this.reactRender(_react.default.createElement(_Spotlight.Spotlight, null));
    }
  }));
  _exports.default = _default;
});