define("admin/templates/components/client-blacklist-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nmwRO1jJ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@overlayClass\"],[[30,[36,0],[[32,0],\"close\"],null],\"modal-popup client-blacklist-modal\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"default-dialog small text-center\"],[12],[2,\"\\n    \"],[11,\"a\"],[24,0,\"close\"],[4,[38,0],[[32,0],\"close\"],null],[12],[13],[2,\"\\n\\n    \"],[10,\"img\"],[14,\"src\",\"/img/icons/no-access.svg\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n\\n    \"],[2,[35,2,[\"client\",\"invoice\",\"invoiceDetail\"]]],[2,\"\\n\\n\"],[6,[37,3],[[35,2,[\"user\",\"isAgent\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"a\"],[24,6,\"#\"],[24,0,\"btn\"],[4,[38,0],[[32,0],\"openInvoices\"],null],[12],[1,[30,[36,1],[\"client.settings.invoices\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"currentSession\",\"unless\"]}",
    "meta": {
      "moduleName": "admin/templates/components/client-blacklist-modal.hbs"
    }
  });
  _exports.default = _default;
});