define("admin/templates/components/ui/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "L1w40xzo",
    "block": "{\"symbols\":[\"@tooltip\",\"&default\"],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[1,[30,[36,0],[\"general.loading\"],null]]],\"parameters\":[]},{\"statements\":[[18,2,null]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"ember-tooltip\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,1]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"loading\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/ui/button.hbs"
    }
  });
  _exports.default = _default;
});