define("admin/templates/components/forms/textarea-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kdQD8Lk0",
    "block": "{\"symbols\":[],\"statements\":[[10,\"label\"],[12],[1,[34,1]],[13],[2,\"\\n\"],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"tooltipstered\"],[12],[2,\"\\n    \"],[8,\"ember-tooltip\",[],[[\"@text\",\"@event\",\"@side\"],[[34,0],\"click\",\"right\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"textarea\",[[16,0,[34,4]],[16,\"placeholder\",[34,5]]],[[\"@value\"],[[34,3]]],null]],\"hasEval\":false,\"upvars\":[\"tooltip\",\"label\",\"if\",\"value\",\"height\",\"placeholder\"]}",
    "meta": {
      "moduleName": "admin/templates/components/forms/textarea-field.hbs"
    }
  });
  _exports.default = _default;
});