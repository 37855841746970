define("admin/models/job-user", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    accessLevel: (0, _model.attr)('string'),
    image: (0, _model.attr)('string'),
    initials: (0, _model.attr)('string'),
    jobTitle: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    roleName: (0, _model.attr)('string')
  });
  _exports.default = _default;
});