define("admin/controllers/client/applications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    job: Ember.computed.alias('model.job'),
    actions: {
      refresh: function refresh() {
        this.send('refreshModel');
      }
    }
  });
  _exports.default = _default;
});