define("admin/routes/client/hire", ["exports", "admin/mixins/model-loader", "admin/mixins/with-hidden-nav"], function (_exports, _modelLoader, _withHiddenNav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_withHiddenNav.WithHiddenNav, _modelLoader.default, {
    queryParams: {
      job: {
        refreshModel: true
      },
      application: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var job = _ref.job,
        application = _ref.application;
      this.set('currentSession.jobId', job);
      return Ember.RSVP.hash({
        job: this.store.findRecord('job', job),
        application: this.store.findRecord('jobapplication', application)
      });
    },
    deactivate: function deactivate() {
      this.set('currentSession.jobId');
      this._super();
    }
  });
  _exports.default = _default;
});