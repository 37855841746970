define("admin/controllers/admin/messages/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    queryParams: ['type'],
    type: 'GENERAL',
    actions: {}
  });
  _exports.default = _default;
});