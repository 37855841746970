define("admin/templates/components/ui/icon-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "pL6gXICG",
    "block": "{\"symbols\":[],\"statements\":[[8,\"x-file-input\",[],[[\"@class\",\"@action\",\"@accept\"],[\"icon-file-input\",[30,[36,5],[[32,0],\"didSelectIcon\"],null],\"image/png,image/jpg,image/jpeg\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[11,\"div\"],[24,0,\"icon\"],[16,5,[31,[\"background-image: url(\",[34,8],\")\"]]],[4,[38,5],[[32,0],\"triggerFileInput\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"input\",[[16,\"placeholder\",[34,10]]],[[\"@value\",\"@autocomplete\",\"@enter\"],[[34,9],\"off\",[30,[36,5],[[32,0],\"onSaveAction\"],null]]],null],[2,\"\\n\"],[6,[37,4],[[35,11]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"button\"],[24,0,\"btn red\"],[4,[38,5],[[32,0],\"onDeleteAction\"],null],[12],[2,\"\\n    \"],[1,[34,7]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[35,12]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"button\"],[16,0,[31,[\"btn \",[30,[36,4],[[30,[36,3],[[35,2],[35,1]],null],\"disabled\"],null]]]],[4,[38,5],[[32,0],\"onSaveAction\"],null],[12],[2,\"\\n\"],[6,[37,6],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      Vista...\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"actionText\",\"loading\",\"disabled\",\"or\",\"if\",\"action\",\"unless\",\"deleteActionText\",\"icon\",\"value\",\"placeholder\",\"onDelete\",\"onSave\"]}",
    "meta": {
      "moduleName": "admin/templates/components/ui/icon-input.hbs"
    }
  });
  _exports.default = _default;
});